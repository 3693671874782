export const formData = (data: ObjectType) => {
    let formData = new FormData()

    for (let key in data) {
        if (Array.isArray(data[key])) {
            data[key].forEach((item: any) => formData.append(key, item))
        } else {
            formData.append(key, data[key])
        }
    }

    return formData
}

export const jsonData = (data: ObjectType) => {
    return JSON.stringify(data)
}

export const throwAdapterError = (err: any, name: string) => {
    if (err === 'isCancel') throw Error('Запрос отменен')

    console.log(`Ошибка адаптера (${name}): ${err?.message || 'неизвестная ошибка'}`)

    const message = (
        err?.message && err.message.indexOf('SQLSTATE') > -1
            ? 'Ошибка базы данных'
            : err?.message || 'Ошибка во время запроса'
    ).replace('The given data was invalid', 'Данные заполнены неверно')

    throw Error(message)
}
