import { Group, clsx } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { store } from '../../table.store'
import { CreateButton, Filter, Reset } from './ui'
import style from './filters.style.scss'

interface FiltersProps {}

export const Filters: React.FC<FiltersProps> = observer(({ ...rest }) => {
    const create = !!store.data.create && <CreateButton className={style.create} />
    const reset = store.params.reset && <Reset />
    const isExistFilters = store.data.filters.length

    return isExistFilters ? (
        <Group spacing={16} className={style.root} {...rest}>
            <Group className={clsx(style.grid, { [style.grid_reset]: !!reset })} spacing={16}>
                {create}
                {store.data.filters.map((filter) => (
                    <Filter key={filter.data.id} filter={filter} />
                ))}
            </Group>
            {reset}
        </Group>
    ) : (
        create
    )
})
