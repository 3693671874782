import React from 'react'
import clsx from 'clsx'
import style from './edit-modal.style.scss'
import { Button, Group, LoadingOverlay, Modal, ScrollArea } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { store } from '@/widget/table/table.store'
import { Editable } from '../editable'
import { useForm, FormProvider } from 'react-hook-form'
import { core } from '../../table.core'
import { useImmer } from 'use-immer'

type EditModalProps = React.HTMLAttributes<HTMLElement> & {}

export const EditModal: React.FC<EditModalProps> = observer(({ className, ...rest }) => {
    const editrow = store.data.editRows[0]

    const form = useForm()
    const [state, setState] = useImmer({ render: false })

    const fields = React.useMemo(() => {
        if (editrow) {
            return store.getFieldsForEdit(form)
        }

        return []
    }, [editrow])
    const title =
        !!fields.length && `Редактирование пользователя - ${editrow.name?.value || editrow.login?.value || ''}`

    const handleClose = () => {
        core.unedit(editrow)
    }

    const onSubmit = (data: ObjectType) => {
        const column = store.params.onClickEdit([editrow])

        core.update({
            ...data,
            ...column,
        })
    }

    React.useEffect(() => {
        if (!!fields.length && !state.render) {
            setTimeout(() => {
                setState((draft) => {
                    draft.render = true
                })
            }, 300)
        }
    }, [fields])

    return (
        <Modal
            title={title}
            opened={!!fields.length}
            onClose={handleClose}
            className={clsx(style.root, className)}
            classNames={{
                content: style.content,
                close: style.close,
            }}
            scrollAreaComponent={ScrollArea.Autosize}
            {...rest}
        >
            <LoadingOverlay visible={store.state.creating || !state.render} overlayBlur={1} />

            {state.render && (
                <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} autoComplete='off'>
                        <Editable fields={fields} />
                        <Group position='right'>
                            <Button variant='subtle' px={20} onClick={handleClose}>
                                Отмена
                            </Button>
                            <Button variant='filled' type='submit'>
                                Сохранить
                            </Button>
                        </Group>
                    </form>
                </FormProvider>
            )}
        </Modal>
    )
})
