import React from 'react'
import style from './index.style.scss'
import { Paper, Table } from '@/pages/analytics/components/ui'
import { AnalyticsTableHead } from './components/head'
import { AnalyticsTablePagination } from './components/pagination'
import { AnalyticsTableBody } from './components/body'
import { useSearchParams } from 'react-router-dom'
import { AnalyticsTableAdd } from './components/add'
import { ReferenceControl } from './components/control'
import { AnalyticsTableSort } from './components/sort'
import { observer } from 'mobx-react-lite'
import { TableStoreHandlesRows, TableStoreOptions } from '@/pages/analytics/store/table/interfaces'
import { TableStore } from '@/pages/analytics/store/table/store'
import { store } from '@/pages/analytics/store'
import { ErrorBoundary } from 'react-error-boundary'
import { notifyError } from '@/theme/@deprecated/components/ui'
import { TableErrorFallback } from './components/error'
import { Box, LoadingOverlay } from '@mantine/core'
import { Empty } from './components/empty'

interface IAnalyticsTableProps {
    schemes: TableStore['schemes']
    scheme: TableStore['scheme']
    data?: {
        columns: TableStore['columns']
        rows: TableStore['rows']
        size: TableStore['size']
        page: TableStore['page']
        count: TableStore['count']
        filters: TableStore['filters']
    }
    handlesRows: TableStoreHandlesRows
    options?: TableStoreOptions
    mode?: TableStore['mode']
    /** Доп. парамеры для запросы данных в селект */
    selectRequestData?: any
}

/**
 * TODO: Пофиксить двойную инициализацию во время смены таблицы
 * Из за этого ошибка, возникшая в текущих даных,
 * всплывает во время дублирующей инициализации новой схемы с данными, предыдущей схемы
 */
export const AnalyticsTable: React.FC<IAnalyticsTableProps> = observer(
    ({ schemes, scheme, data, handlesRows, options, mode, selectRequestData, ...rest }) => {
        const [searchParams, setSearchParams] = useSearchParams()
        const isViewMode = store.table.isMode('view')

        React.useEffect(() => {
            try {
                const params = new URLSearchParams(searchParams)

                store.table.initialize({
                    schemes: schemes,
                    scheme: scheme,
                    columns: data.columns,
                    rows: data.rows,
                    handlesRows: handlesRows,
                    order: searchParams.get('sort_type'),
                    orderBy: searchParams.get('sort_by'),
                    size: data.size,
                    page: data.page,
                    dense: searchParams.get('dense') !== '0',
                    count: data.count,
                    options: options,
                    filters: data.filters,
                    mode: mode,
                    selectRequestData: selectRequestData,

                    onChange: {
                        dense: (checked) => {
                            params.set('dense', checked ? '1' : '0')

                            setSearchParams(params)
                        },
                        page: (page) => {
                            params.set('page', String(page))

                            setSearchParams(params)
                        },
                        size: (size) => {
                            params.set('page', '1')
                            params.set('size', String(size))

                            setSearchParams(params)
                        },
                        sort: (type, value) => {
                            params.set('sort_by', type)
                            params.set('sort_type', value)
                            params.set('page', '1')

                            setSearchParams(params)
                        },
                        search: (value) => {
                            if (value) {
                                params.set('f_search', value)
                                params.set('page', '1')
                            } else {
                                params.delete('f_search')
                                params.set('page', '1')
                            }

                            setSearchParams(params)
                        },
                        resetFilter: (filters) => {
                            filters.forEach((filter) => params.delete(`f_${filter.column}__${filter.value}`))
                            filters.forEach((filter) => params.delete(`f_${filter.value}`))

                            setSearchParams(params)
                        },
                        filter: (filters) => {
                            if (filters.length) {
                                filters.forEach((filter) => {
                                    const searchKey = `f_${filter.column}__${filter.value}`
                                    const filterValue = filter.id

                                    // TODO: Временно скрываем множественный выбор
                                    /**
                                     * Если значение из этой же колонки уже есть в url,
                                     * то удаляем предыдущее
                                     */
                                    // @ts-ignore
                                    for (const key of searchParams.keys()) {
                                        if (key.includes(`f_${filter.column}`)) {
                                            params.delete(key)
                                        }
                                    }

                                    params.set(searchKey, filterValue)
                                })

                                params.delete('page')
                            }

                            setSearchParams(params)
                        },
                        archive: (state) => {
                            if (state) {
                                params.set('f_archive', state.toString())
                                params.set('page', '1')
                            } else {
                                params.delete('f_archive')
                                params.set('page', '1')
                            }

                            setSearchParams(params)
                        },
                    },
                })
            } catch (err) {
                console.log(err)
                notifyError('Ошибка инициализации компонента таблицы')
            }
        }, [data])

        return (
            <>
                {!store.table.state.error ? (
                    <ErrorBoundary FallbackComponent={TableErrorFallback}>
                        <ReferenceControl />
                        <Paper classes={{ root: style.root }} {...rest} nopadding>
                            {!!store.table.rows.length && (
                                <>
                                    <Box>
                                        <Table.Container>
                                            <Table
                                                size={store.table.dense ? 'small' : 'medium'}
                                                stickyHeader
                                                className={style.table}
                                            >
                                                <AnalyticsTableHead />
                                                <AnalyticsTableBody />
                                            </Table>
                                            <AnalyticsTableSort />
                                        </Table.Container>
                                        <LoadingOverlay visible={store.table.state.loading} overlayBlur={2} />
                                    </Box>
                                    {!isViewMode && <AnalyticsTableAdd style={{ padding: '10px' }} />}
                                    <AnalyticsTablePagination />
                                </>
                            )}

                            {!store.table.rows.length && (
                                <>
                                    <Empty error={store.table.error} />
                                    <LoadingOverlay visible={store.table.state.loading} overlayBlur={2} />
                                </>
                            )}
                        </Paper>
                    </ErrorBoundary>
                ) : (
                    <Empty error />
                )}
            </>
        )
    }
)
