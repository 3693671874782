import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import { IModalProps, Modal } from '@/theme/@deprecated/components/ui/modal'
import { IUseModalProps, useModal } from '@/theme/@deprecated/components/ui/modal/hook'
import { ModalTitle } from '@/theme/@deprecated/components/modal/_components'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { ReferenceRowSelect } from '../select'
import { observer } from 'mobx-react-lite'
import { prepareRowData } from '@/pages/analytics/store/table/utils'
import { Scroll, notifyError } from '@/theme/@deprecated/components/ui'
import { store } from '@/pages/analytics/store'
import Box from '@mui/material/Box'
import { TableStore } from '@/pages/analytics/store/table/store'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { LoadingOverlay } from '@mantine/core'

interface IAnalyticsTableAddModalProps extends IModalProps {
    classes?: {
        root?: string
    }
}

export const AnalyticsTableAddModal: React.FC<IAnalyticsTableAddModalProps> = observer(({ classes, ...rest }) => {
    const cls = { root: clsx(style.root, classes?.root) }
    const isPassportMode = store.table.isMode('passport')
    const label = isPassportMode ? 'Привязать значение' : 'Создание новой строки'

    const [imageData, setImageData] = React.useState<string | ArrayBuffer>('')
    const [filename, setFilename] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    let archive = {}

    const handleCreate = () => {
        /** -1 проставляем для новой строки */
        const prepareData = prepareRowData([store.table.editDataRows[-1]], {
            passport: store.table.scheme?.passport,
        })

        if (Object.keys(archive).length) {
            for (let key in archive) {
                prepareData[0][key] = {
                    value: archive[key].value,
                }
            }
        }

        setLoading(true)

        store.table.handlesRows.create(prepareData, (err) => {
            if (!err) {
                store.table.handleEditDataRowsReset()
                rest.setOpen(false)
            }

            setLoading(false)
        })()
    }

    const handleFileUpload = (column: TableStore['columns'][0]) => (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return
        }

        const file = e.target.files[0]
        const { name } = file

        setFilename(name)

        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = (evt) => {
            if (!evt?.target?.result) {
                return
            }
            const { result } = evt.target

            setImageData(result)

            store.table.handleChangeField(-1, column, 'image')({ target: { value: result } })
        }

        // try {
        //     store.table.handlesRows.uploadImage(
        //         {
        //             id: 123,
        //             image: 'image',
        //         },
        //         (err) => {
        //             if (err) {
        //                 setFilename(null)
        //                 setImageData(null)

        //                 e.target.value = null
        //             }
        //         }
        //     )
        // } catch (err) {
        //     console.log(err)
        // }
    }

    return (
        // <MantineProvider>
        //     <Modal
        //         {...rest}
        //         zIndex={999999}
        //         title='Создание новой строки'
        //         overflow='inside'
        //         styles={{
        //             title: {
        //                 font: 'var(--head-3)',
        //             },
        //         }}
        //     >
        //         <div>
        //             <TextInput label='Название' placeholder='Название' />
        //             <Group position='right' mt={12} pb={2}>
        //                 <Button variant='default' onClick={() => setOpened(false)}>
        //                     Отмена
        //                 </Button>
        //                 <Button onClick={handleCreate}>Создать</Button>
        //             </Group>
        //         </div>
        //     </Modal>
        // </MantineProvider>
        <Modal className={cls.root} {...rest}>
            <Modal.Header>
                <ModalTitle title={label} className={clsx(style.title)} />
            </Modal.Header>
            <Modal.Body>
                <Scroll className={style.fields} height={500}>
                    {store.table.columns.map((column, i) => {
                        const value = store.table.editDataRows[-1]?.[column.id]?.value

                        if (isPassportMode && column.id === 'name' && column.type === 'select') {
                            return (
                                <ReferenceRowSelect
                                    key={i}
                                    column={column}
                                    className={style.input}
                                    row={{ id: { value: -1 } }}
                                    mode='view'
                                />
                            )
                        } else if (!column.editable) return

                        if (column.id !== 'id') {
                            if (column.type === 'string' || column.type === 'number') {
                                return (
                                    <TextField
                                        className={style.input}
                                        key={i}
                                        label={column.label}
                                        variant='outlined'
                                        size='small'
                                        value={value || ''}
                                        onChange={store.table.handleChangeField(-1, column, 'text')}
                                        type={column.type}
                                        fullWidth
                                    />
                                )
                            }

                            // eslint-disable-next-line no-constant-condition
                            if (column.type === 'image' && false) {
                                return (
                                    <React.Fragment key={i}>
                                        <Button
                                            component='label'
                                            variant='outlined'
                                            // startIcon={<UploadFileIcon />}
                                            fullWidth
                                        >
                                            {filename && `Заменить - ${filename}`}
                                            {!filename && `Загрузить фото`}
                                            <input
                                                type='file'
                                                accept='.jpeg,.png,.jpg'
                                                hidden
                                                onChange={handleFileUpload(column)}
                                            />
                                        </Button>
                                        <Box sx={{ marginTop: 2, display: 'flex', alignItems: 'flex-start' }}>
                                            <img src={imageData as string} alt='' />
                                        </Box>
                                    </React.Fragment>
                                )
                            }

                            if (column.type === 'select') {
                                return (
                                    <ReferenceRowSelect
                                        key={i}
                                        column={column}
                                        className={style.input}
                                        row={{ id: { value: -1 } }}
                                    />
                                )
                            }

                            if (column.type === 'boolean') {
                                const boolValue = value !== undefined ? value : false

                                archive[column.id] = {
                                    value: boolValue,
                                }

                                return (
                                    <FormGroup className={style.input} key={i}>
                                        <FormControlLabel
                                            sx={{ marginLeft: 0 }}
                                            control={
                                                <Checkbox
                                                    checked={boolValue}
                                                    onChange={() =>
                                                        store.table.handleChangeField(
                                                            -1,
                                                            column,
                                                            'boolean'
                                                        )({ target: { value: !boolValue } })
                                                    }
                                                    sx={{ padding: 0, marginRight: '12px' }}
                                                />
                                            }
                                            label={column.label}
                                        />
                                    </FormGroup>
                                )
                            }

                            if (column.type === 'date') {
                                return (
                                    <TextField
                                        className={style.input}
                                        label={column.label}
                                        variant='outlined'
                                        size='small'
                                        value={value}
                                        onChange={store.table.handleChangeField(-1, column, 'date')}
                                        type={'date'}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        key={i}
                                    />
                                )
                            }
                        }
                    })}
                </Scroll>
                <div className={style.actions}>
                    <Button variant='outlined' onClick={() => rest.setOpen(false)}>
                        Отмена
                    </Button>
                    <Button variant='contained' onClick={handleCreate}>
                        Создать
                    </Button>
                </div>
            </Modal.Body>

            <LoadingOverlay visible={loading} />
        </Modal>
    )
})

export const useAnalyticsTableAddModal = (data?: IUseModalProps) =>
    useModal<IAnalyticsTableAddModalProps>({ ...data, instance: AnalyticsTableAddModal })
