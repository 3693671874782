import React from 'react'
import { Box, Select } from '@mantine/core'
import { store } from '../../catalog.store'
import { observer } from 'mobx-react-lite'
import { core } from '../../catalog.core'
import { tojs } from '@/shared'

interface GridSortProps {}

export const GridSort: React.FC<GridSortProps> = observer(({ ...rest }) => {
    const sorts = tojs(store.getSorts()).map((sort) => ({
        value: sort.value,
        label: sort.label,
    }))
    const sort = store.getCurrentSort()

    const handleChange = (id: string) => {
        core.changeSort(id)
    }

    return (
        <Box ml={'auto'} {...rest}>
            <Select value={sort.value || null} data={sorts} disabled={store.state.searching} onChange={handleChange} />
        </Box>
    )
})
