import * as v1 from './adapters'

export const service = new (class Service {
    /* -------------------------------------------------------------------------- */
    /*                                  Analytics                                 */
    /* -------------------------------------------------------------------------- */

    analytics = {
        scheme: v1.AnalyticsScheme,
        reference_get: v1.AnalyticsReferenceRead,
        reference_create: v1.AnalyticsReferenceCreate,
        reference_delete: v1.AnalyticsReferenceDelete,
        reference_update: v1.AnalyticsReferenceUpdate,
        reference_upload_image: v1.AnalyticsReferenceUploadImage,
        filters: v1.AnalyticsFilters,
        filter: v1.AnalyticsFilter,
        export: v1.AnalyticsExport,
        residual_get: v1.AnalyticsResidualRead,
        residual_create: v1.AnalyticsResidualCreate,
    }

    /* -------------------------------------------------------------------------- */
    /*                                    Auth                                    */
    /* -------------------------------------------------------------------------- */

    auth = {
        csrf: v1.AuthCsrf,
        login: v1.AuthLogin,
        sso: v1.AuthSso,
        session: v1.AuthSession,
    }

    /* -------------------------------------------------------------------------- */
    /*                                  Carstock                                  */
    /* -------------------------------------------------------------------------- */

    carstock = {
        controller: {
            car: v1.CarstockCarController,
            favorites: v1.CarstockFavoritesController,
            filter_values: v1.CarstockFilterValuesController,
            filters: v1.CarstockFiltersController,
            filter_options: v1.CarstockFilterOptionsController,
            filter_search: v1.CarstockFilterSearchController,
            templates: v1.CarstockTemplatesController,
        },

        car: v1.CarstockCar,
        favorites: v1.CarstockFavorites,
        favorite_add: v1.CarstockFavoriteAdd,
        favorite_remove: v1.CarstockFavoriteRemove,
        filter_values: v1.CarstockFilterValues,
        filters: v1.CarstockFilters,
        options: v1.CarstockOptions,
        search: v1.CarstockSearch,
        search_edit: v1.CarstockSearchEdit,
        search_save: v1.CarstockSearchSave,
        templates: v1.CarstockTemplates,
        templates_remove: v1.CarstockTemplatesRemove,
    }

    /* -------------------------------------------------------------------------- */
    /*                                   Catalog                                  */
    /* -------------------------------------------------------------------------- */

    catalog = {
        controller: {
            categories: v1.CatalogCategoriesController,
            complectation: v1.CatalogComplectationController,
            complectation_options: v1.CatalogComplectationOptionsController,
            complectation_packages: v1.CatalogComplectationPackagesController,
            filters: v1.CatalogFiltersController,
            filters_groups: v1.CatalogFiltersGroupsController,
            filters_tabs: v1.CatalogFiltersTabsController,
            filter_options: v1.CatalogFilterOptionsController,
            models: v1.CatalogModelsController,
            modifications: v1.CatalogModificationsController,
            filter_values: v1.CatalogFilterValuesController,
        },

        categories: v1.CatalogCategories,
        complectation: v1.CatalogComplectation,
        complectation_options: v1.CatalogComplectationOptions,
        complectation_packages: v1.CatalogComplectationPackages,
        filters: v1.CatalogFilterFilters,
        filters_groups: v1.CatalogFiltersGroups,
        filters_tabs: v1.CatalogFiltersTabs,
        options: v1.CatalogOptions,
        models: v1.CatalogModels,
        modifications: v1.CatalogModifications,
        filterValues: v1.CatalogFilterValues,
    }

    /* -------------------------------------------------------------------------- */
    /*                               Configurations                               */
    /* -------------------------------------------------------------------------- */

    configurations = {
        controller: {
            configurations: v1.ConfigurationsController,
        },

        configurations: v1.Configurations,
        edit: v1.ConfigurationsEdit,
        remove: v1.ConfigurationsRemove,
        save: v1.ConfigurationsSave,
    }

    /* -------------------------------------------------------------------------- */
    /*                                    Users                                   */
    /* -------------------------------------------------------------------------- */

    users = {
        controller: {
            users: v1.UsersController,
        },

        active: v1.UsersActive,
        cities: v1.UsersCities,
        create: v1.UsersCreate,
        delete: v1.UsersDelete,
        disable: v1.UsersDisable,
        roles: v1.UsersRoles,
        statuses: v1.UsersStatuses,
        update: v1.UsersUpdate,
        users: v1.Users,
    }
})()
