import React from 'react'
import { Badge, Box } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import style from './title.style.scss'
import { store } from '../../table.store'

interface TitleProps {}

export const Title: React.FC<TitleProps> = observer(({ ...rest }) => {
    const total = store.total
    const title = store.data.title

    return (
        <Box {...rest}>
            <Box className={style.root}>
                {title}
                <Badge className={style.badge} sx={{ opacity: store.state.searching ? 0.8 : 1 }}>
                    {total}
                </Badge>
            </Box>
        </Box>
    )
})
