import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'

type CatalogFiltersGroupsRequest = {
    params: {
        vehicle_type_id: number
    }
}

type CatalogFiltersGroupsResponse = {
    data: {
        id: number
        name: string
        sort_order: number
    }[]
}

export const CatalogFiltersGroupsController = { ref: null }

export const CatalogFiltersGroups = async (req: CatalogFiltersGroupsRequest) => {
    CatalogFiltersGroupsController.ref = new AbortController()

    try {
        const response = await apiConfig.fetch.ilsa<CatalogFiltersGroupsResponse>({
            url: '/interface-builder/catalog/filters-groups',
            config: {
                signal: CatalogFiltersGroupsController.ref.signal,
                params: req.params,
                cache: true,
            },
        })

        return response.data.data
    } catch (err: any) {
        throwAdapterError(err, 'CatalogFiltersGroups')
    }
}
