import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'
import { AnalyticsResidualReadPayload } from './interfaces'

export type AnalyticsResidualReadRequest = {
    id: string
}

export type AnalyticsResidualReadResponse = AnalyticsResidualReadPayload

export const AnalyticsResidualRead = async (request?: AnalyticsResidualReadRequest) => {
    try {
        const response: ApiResponse<AnalyticsResidualReadResponse> =
            await apiConfig.fetch.ilsa<AnalyticsResidualReadResponse>({
                url: `/modifications/${request.id}/residual-prices`,
            })

        if (!response?.data || response.status !== 200) {
            throw Error('Не удалось получить список остаточных стоимостей')
        }

        const data = { '1': null, '2': null, '3': null, '4': null, '5': null }

        const getYear = (year: number) => ({
            title: `${year} год`,
            value: String(year),
            months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].reduce((acc, curr) => {
                acc[String(curr)] = {
                    id: null,
                    value: '',
                }

                return acc
            }, {}),
        })

        /** Собираем года, которые пришли в апи */
        response.data.data.forEach((row) => {
            const year = row.year.value

            if (!Object.values(data).find((y) => (y as any)?.value == year)) {
                data[year] = getYear(year)
            }
        })

        /** Собираем года, которых нет в апи */
        Object.keys(data).forEach((year) => {
            if (data[year] === null) {
                data[year] = getYear(parseInt(year))
            }
        })

        /** Собираем значения месяцев */
        response.data.data.forEach((row) => {
            const id = row.id.value
            const year = row.year.value
            const month = row.month.value
            const value = row.coefficient.value

            data[year].months[month] = {
                id: id,
                value: value === '0.0' ? '' : value,
            }
        })

        return Object.values(data)
    } catch (err: any) {
        throwAdapterError(err, 'AnalyticsResidualRead')
    }
}
