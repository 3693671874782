import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import Instance, { TableRowProps } from '@mui/material/TableRow'

interface ITableRowProps extends TableRowProps {
    selected?: boolean
}

export const TableRow: React.FC<ITableRowProps> = ({ classes, children, selected, ...rest }) => {
    const cls = { root: clsx(style.root, classes?.root, { [style.selected]: selected }) }

    return (
        <Instance classes={cls} {...rest} data-row>
            {children}
        </Instance>
    )
}
