import React from 'react'
import { Box, Button, Text, Paper, Group } from '@mantine/core'
import { useLocation, useNavigate } from 'react-router-dom'
import { IconArrowLeft } from '@tabler/icons-react'

type DroppedFormProps = {}

export const DroppedForm: React.FC<DroppedFormProps> = () => {
    const navigate = useNavigate()
    const { state } = useLocation()
    const email = (state as any)?.email

    React.useEffect(() => {
        // if (!email) navigate('/reset')
    }, [])

    return (
        <Paper radius={32} py={32} px={32} sx={{ width: 440 }}>
            <Text size='lg' mb={12} weight={500}>
                Сброс пароля
            </Text>
            <Text size={12} mt={6} mb={16} color={'gray'}>
                Ссылка для сброса пароля отправлена на {email}
            </Text>
            <Button variant='subtle' color='dark.4' onClick={() => navigate('/')}>
                <Group align='center' noWrap>
                    <IconArrowLeft size={20} stroke={1.5} />
                    <span>Вернуться на авторизацию</span>
                </Group>
            </Button>
        </Paper>
    )
}
