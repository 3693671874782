import React from 'react'
import clsx from 'clsx'
import style from './cell-multi-select.style.scss'
import { TableCell } from '@/widget/table/table.types'
import { CellNull } from '../cell-null'

type CellMultiSelectProps = React.HTMLAttributes<HTMLTableCellElement> & {
    cell: TableCell
}

export const CellMultiSelect: React.FC<CellMultiSelectProps> = ({ className, cell, ...rest }) => {
    const values = (cell.value as unknown as any[]) || []
    const value = (values.map((option) => option.value) as any).join(', ')

    return !values.length ? (
        <CellNull cell={cell} />
    ) : (
        <div className={clsx(style.root, className, 'elisa-table-widget-cell')} title={value} {...rest}>
            {value}
        </div>
    )
}
