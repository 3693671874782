import { makeAutoObservable } from 'mobx'
import { CarstockFilterLinks, CarstockFilterMeta, CarstockFilters, CarstockParam, DefaultData } from './carstock.types'
import {
    CarstockCarGradeModel,
    CarstockCarModel,
    CarstockCarModificationModel,
    CarstockFilterModel,
    CarstockOptionModel,
} from './models'
import { R, clone, tojs } from '@/shared'

type StoreState = State & {
    searching?: boolean
    searched?: boolean
    searchingModifications?: boolean
    loadingOptions?: boolean
    loadedOptions?: boolean
    saving?: boolean
}

export type StoreData = {
    tabs?: CarstockParam
    sorts?: CarstockParam
    filters?: CarstockFilters
    cars?: CarstockCarModel[]
    modifications?: CarstockCarModificationModel[]
    options?: CarstockOptionModel[]
    links?: CarstockFilterLinks
    meta?: CarstockFilterMeta
    car?: CarstockCarModel
    limit?: {
        min: number
        max: number
    }
    default_data?: DefaultData
}

export type StoreParams = {
    hiddenOptions?: boolean
    hiddenTabs?: boolean
    load?: boolean
    title?: string
    save?: boolean
    onClickCar?: (car: CarstockCarModel) => void
    onClickCarGrade?: (grade: CarstockCarGradeModel) => void
}

const initialState: StoreState = {
    loading: false,
    loaded: false,
    init: false,
    error: null,
    searching: false,
    searched: false,
    searchingModifications: false,
    loadingOptions: false,
    loadedOptions: false,
    saving: false,
}

export const initial_default_data: DefaultData = {
    id: null,
    name: null,
    limit: null,
    page: null,
    sort: [],
    options: {},
    filters: {},
    tab: null,
}

const initialData: StoreData = {
    tabs: new Map([]),
    sorts: new Map([]),
    filters: new Map([]),
    cars: [],
    modifications: [],
    options: [],
    links: null,
    meta: null,
    car: null,
    limit: {
        min: 12,
        max: 42,
    },
    default_data: initial_default_data,
}

const initialParams: StoreParams = {
    hiddenOptions: false,
    hiddenTabs: false,
    load: false,
    save: false,
    onClickCar: null,
    onClickCarGrade: null,
}

class Store {
    state = initialState
    data = initialData
    params = initialParams

    constructor() {
        makeAutoObservable(this)
    }

    /* -------------------------------------------------------------------------- */
    /*                                    Core                                    */
    /* -------------------------------------------------------------------------- */

    setState(states: Partial<StoreState>) {
        this.state = {
            ...this.state,
            ...states,
        }
    }

    setData(data: Partial<StoreData>) {
        this.data = {
            ...this.data,
            ...data,
        }
    }

    setParams(params: Partial<StoreParams>) {
        this.params = {
            ...this.params,
            ...params,
        }
    }
    reset() {
        this.setState(initialState)
        this.setData(initialData)
        this.setParams(initialParams)
    }

    /* -------------------------------------------------------------------------- */
    /*                                    Data                                    */
    /* -------------------------------------------------------------------------- */

    getSorts() {
        const sorts = Array.from(this.data.sorts.values())

        return sorts
    }

    getTabs() {
        const tabs = Array.from(this.data.tabs.values())

        return tabs
    }

    getCurrentSort() {
        const sort = Array.from(tojs(this.data.sorts).values()).find((sort) => sort.enabled)

        return sort
    }

    getCurrentSortValues() {
        const sorts = Array.from(tojs(this.data.sorts).values())
            .filter((sort) => sort.enabled)
            .map((sort) => sort.value)

        return sorts
    }

    getCurrentTab() {
        const tab = tojs(this.getTabs()).find((tab) => tab.enabled)

        return tab
    }

    getSortValues() {
        const sort = Array.from(tojs(this.data.sorts).values())
            .filter((sort) => sort.enabled)
            .map((sort) => sort.value)

        return sort
    }

    /* -------------------------------------------------------------------------- */
    /*                                   Filters                                  */
    /* -------------------------------------------------------------------------- */

    get filters() {
        return Array.from(this.data.filters.values()).flat()
    }

    getFilterDependsOn(filter: CarstockFilterModel) {
        const depends = Array.from(this.data.filters.values())
            .flat()
            .filter((currentFilter) => {
                return filter.data.depends_on.includes(currentFilter.data.id)
            })

        return depends
    }

    getFilterDependsFor(filter: CarstockFilterModel) {
        const depends = Array.from(this.data.filters.values())
            .flat()
            .filter((currentFilter) => {
                return filter.data.depends_for.includes(currentFilter.data.id)
            })

        return depends
    }

    getFilterValues() {
        const values = Array.from(tojs(this.data.filters).values())
            .flat()
            .filter((filter) => filter.data.value)
            .reduce((acc, curr) => {
                const value = tojs(curr.data.value)

                if (curr.data.type === 'range-select') {
                    if (curr.data.options?.dotsValue && (value[0]?.id || value[1]?.id)) {
                        acc[curr.data.param[0]] = `${value[0]?.id || '1'}..${value[1]?.id || '999999999'}`
                    } else {
                        curr.data.param.forEach((param, i) => {
                            acc[param] = value[i]?.id || null
                        })
                    }
                } else {
                    acc[curr.data.param[0]] = value.map((value) => value?.id || null)
                }

                return acc
            }, {})

        return values
    }

    getFilterValuesForSearchOptions() {
        const values = {}
        const tab = this.getCurrentTab()

        if (tab.value !== null) {
            values[tab.param] = tab.value
        }

        return values
    }

    getFilterValuesByDependsOnForLoadValues(filter: CarstockFilterModel) {
        // const depends = tojs(this.getFilterDependsOn(filter))

        // const values = depends
        //     .filter((filter) => filter.data.value)
        //     .reduce((acc, curr) => {
        //         const value = tojs(curr.data.value)

        //         if (curr.data.type === 'range-select') {
        //             curr.data.param.forEach((param, i) => {
        //                 acc[param] = value[i]?.id || null
        //             })
        //         } else {
        //             acc[curr.data.param[0]] = value.map((value) => value?.id || null)
        //         }

        //         return acc
        //     }, {})

        const values = this.getFilterValues()

        return R.omit([filter.data.id], values)
    }

    async getFiltersValuesForSearchModifictions() {
        const filters = Array.from(this.data.filters.values()).flat()
        const filterBrand = filters.find((filter) => filter.data.name === 'Марка')
        const filterModel = filters.find((filter) => filter.data.name === 'Модель')

        if (filterBrand && filterModel) {
            const brandValues = filterBrand.state.loaded ? filterBrand.data.values : await filterBrand.loadValues()
            const brandValue = filterBrand.getValueById(this.data.car.data.brand_id)
            const filterBrandDepends = store.getFilterDependsFor(filterBrand)

            filterBrand.set.value(brandValue.label)
            filterBrandDepends.forEach((depend) => depend.reset())

            const filterModelValuesByDepends = this.getFilterValuesByDependsOnForLoadValues(filterModel)

            const filterModelValues = await filterModel.loadValues(filterModelValuesByDepends)
            const [filterModelValue] = filterModel.getValueByValue(this.data.car.data.model_name)
            const filterModelDepends = store.getFilterDependsFor(filterModel)

            filterModel.set.value(filterModelValue.label)
            filterModelDepends.forEach((depend) => depend.reset())

            return {
                brand: brandValue,
                model: brandValue,
            }
        }

        return null
    }

    getAdditionalValues() {
        const values = {}

        const tab = this.getCurrentTab()
        const sort = this.getCurrentSort()

        values['limit'] = this.getLimit()
        values['page'] = this.getPage()
        values[sort.param] = this.getCurrentSortValues()

        if (tab.value !== null) {
            values[tab.param] = tab.value
        }

        return values
    }

    getValuesForSearch(): ObjectType {
        const values = this.getFilterValues()
        const additional_values = this.getAdditionalValues()
        const options = this.getSelectedOptionsValues()

        return {
            ...values,
            ...additional_values,
            ...options,
        }
    }

    getValuesForSearchSave() {
        const filter_values = this.getFilterValues()
        const additional_values = this.getAdditionalValues()
        const options = this.getSelectedOptionsValues()
        const filters_data = {}

        const tab = this.getCurrentTab()

        for (const key in filter_values) {
            const value = filter_values[key]
            const isExist = !!value
            const isExistArray = isExist && Array.isArray(value) && value.length

            if ((isExist && !Array.isArray(value)) || isExistArray) {
                filters_data[key] = value
            }
        }

        const data = {}

        data['options'] = options
        data['filters'] = filters_data

        if (tab.value !== null) {
            delete additional_values[tab.param]
            data['tab'] = tab.id
        }

        return {
            ...additional_values,
            ...data,
        }
    }

    async getValuesForSearchModifications() {
        const filtersValues = await this.getFiltersValuesForSearchModifictions()
        const values = this.getValuesForSearch()

        return {
            ...values,
            // ...{
            //     brand_id: filtersValues.brand.id,
            //     model_id: filtersValues.model.id,
            // },
        }
    }

    getOptionsItems(): CarstockOptionModel['data']['options'] {
        return this.data.options.reduce((acc, curr) => {
            return acc.concat(
                curr.data.groups.reduce((acc, curr) => {
                    return acc.concat(curr.options)
                }, [])
            )
        }, [])
    }

    getOptionByIdItem(id: number) {
        return this.data.options.find((option) =>
            option.data.groups.find((group) => group.options.find((item) => item.id === id))
        )
    }

    getSelectedOptionsValues() {
        const values = {}

        this.data.options.reduce((acc, curr) => {
            const concat = acc.concat(curr.data.selected)

            if (curr.data.type_param_key === 'carstock_options_ids') {
                concat.forEach((id) => {
                    values[id] = '1'
                })
            } else {
                values[curr.data.type_param_key] = concat
            }

            return concat
        }, [])

        return values
    }

    getOptionsSelectedItems() {
        const options = this.getOptionsItems()
        const filtered = options.filter((option) => option.selected)

        return filtered
    }

    getTotal() {
        return this.data.meta?.total || 0
    }

    getTotalPages() {
        return Math.ceil(this.data.meta?.total / this.getLimit()) || 0
    }

    getPage() {
        return this.data.meta?.current_page || 1
    }

    getLimit() {
        return parseInt(this.data.meta?.per_page) || store.data.limit.min
    }
}

export const store = new Store()
