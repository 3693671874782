import React from 'react'
import { Route } from 'react-router-dom'
import { Button, Link } from '@/theme/@deprecated/components/ui'
import { CarstockPage, CatalogPage, ConfigurationsPage, UsersPage, AnalyticsPage } from '@/pages'
import { Tabs } from '@mantine/core'
import ProposalPage from '@/pages/@deprecated/references/pages/proposal/Proposal'

export const routes = {
    catalog: {
        route: (
            <>
                <Route path='/catalog' element={<CatalogPage />} />
                <Route path='/catalog/lcv' element={<CatalogPage.Lcv />} />
                <Route path='/catalog/hcv' element={<CatalogPage.Hcv />} />
                <Route path='/catalog/bus' element={<CatalogPage.Bus />} />
                <Route path='/catalog/sv' element={<CatalogPage.Sv />} />
                <Route path='/catalog/wh' element={<CatalogPage.Wh />} />
                <Route path='/catalog/tr' element={<CatalogPage.Tr />} />
                <Route path='/catalog/complectation/:id' element={<CatalogPage.Complectation />} />
            </>
        ),
        link: (
            <Link to={'/catalog'} Component={Button} type='white'>
                Справочник
            </Link>
        ),
        path: '/catalog',
        visible: true,
        tab: <Tabs.Tab value='/catalog'>Справочник</Tabs.Tab>,
        children: [
            {
                path: '/catalog',
                visible: true,
                tab: <Tabs.Tab value='/catalog'>Легковые</Tabs.Tab>,
            },
            {
                path: '/catalog/lcv',
                visible: true,
                tab: <Tabs.Tab value='/catalog/lcv'>ЛКТ</Tabs.Tab>,
            },
            {
                path: '/catalog/hcv',
                visible: true,
                tab: <Tabs.Tab value='/catalog/hcv'>Грузовые</Tabs.Tab>,
            },
            {
                path: '/catalog/bus',
                visible: true,
                tab: <Tabs.Tab value='/catalog/bus'>Автобусы</Tabs.Tab>,
            },
            {
                path: '/catalog/sv',
                visible: true,
                tab: <Tabs.Tab value='/catalog/sv'>Самоходная техника</Tabs.Tab>,
            },
            {
                path: '/catalog/wh',
                visible: true,
                tab: <Tabs.Tab value='/catalog/wh'>Складская техника</Tabs.Tab>,
            },
            {
                path: '/catalog/tr',
                visible: true,
                tab: <Tabs.Tab value='/catalog/tr'>Прицепы</Tabs.Tab>,
            },
        ],
    },
    carstock: {
        route: (
            <>
                <Route path='/carstock' element={<CarstockPage />} />
                <Route path='/carstock/car/:id' element={<CarstockPage.Car />} />
                <Route path='/carstock/favorite' element={<CarstockPage.Favorite />} />
                <Route path='/carstock/templates' element={<CarstockPage.Templates />} />
            </>
        ),
        link: (
            <Link to={'/carstock'} Component={Button} type='white'>
                Авто в наличии
            </Link>
        ),
        path: '/carstock',
        visible: true,
        tab: <Tabs.Tab value='/carstock'>Авто в наличии</Tabs.Tab>,
        children: [
            {
                path: '/carstock',
                visible: true,
                tab: <Tabs.Tab value='/carstock'>Каталог</Tabs.Tab>,
            },
            {
                path: '/carstock/favorite',
                visible: true,
                tab: <Tabs.Tab value='/carstock/favorite'>Избранное</Tabs.Tab>,
            },
            {
                path: '/carstock/templates',
                visible: true,
                tab: <Tabs.Tab value='/carstock/templates'>Шаблоны поиска</Tabs.Tab>,
            },
        ],
    },
    configurations: {
        route: (
            <>
                <Route path='/configurations' element={<ConfigurationsPage />} />
            </>
        ),
        link: (
            <Link to={'/configurations'} Component={Button} type='white'>
                Конфигурации
            </Link>
        ),
        path: '/configurations',
        visible: true,
        tab: <Tabs.Tab value='/configurations'>Конфигурации</Tabs.Tab>,
    },
    analytics: {
        route: <Route path={`/analytics/*`} element={<AnalyticsPage />} />,
        link: (
            <Link to={`/analytics`} Component={Button} type='white'>
                Интерфейс аналитика
            </Link>
        ),
        path: `/analytics`,
        linkPath: '/analytics/references/brands',
        visible: true,
        tab: <Tabs.Tab value={`/analytics`}>Интерфейс аналитика</Tabs.Tab>,
    },
    proposal: {
        route: <Route path='/proposal' element={<ProposalPage />} />,
        link: (
            <Link to='/proposal' Component={Button} type='white'>
                Распознавание КП
            </Link>
        ),
        path: '/proposal',
        visible: false,
        tab: <Tabs.Tab value='/proposal'> КП</Tabs.Tab>,
    },
    users: {
        route: <Route path='/users' element={<UsersPage />} />,
        link: (
            <Link
                to={'/users'}
                Component={Button}
                type='white'
                // beforeCallback={onCallbackUsers}
            >
                Список пользователей
            </Link>
        ),
        path: '/users',
        visible: false,
        tab: <Tabs.Tab value='/users'>Пользователи</Tabs.Tab>,
    },
}
