import React from 'react'
import { Box } from '@mantine/core'
import { TableWidget } from '@/widget'
import style from './templates.style.scss'
import { service } from '@/api'
import { R } from '@/shared'
import { MantineProvider } from '@/theme'
import { IconTrash } from '@tabler/icons-react'

type CarstockTemplatesPageProps = {}

export const CarstockTemplatesPage: React.FC<CarstockTemplatesPageProps> = ({ ...rest }) => {
    return (
        <MantineProvider withNotify>
            <Box className={style.root} {...rest}>
                <TableWidget
                    coreProps={{
                        widget: {
                            name: 'carstock-templates',
                        },
                        service: {
                            get: {
                                request: async (props) => {
                                    const response = await service.carstock.templates(props)

                                    const hide = ['id', 'filter_template_type_id', 'filters']

                                    const order = {
                                        name: 1,
                                    }

                                    const label = {
                                        name: 'Название шаблона',
                                    }

                                    response.columns.map((column, i) => {
                                        column.params = column.params || {}

                                        column.params.order = i

                                        if (hide.includes(column.id)) {
                                            column.params.hidden = true
                                        }

                                        if (order[column.id] !== undefined) {
                                            column.params.order = order[column.id]
                                        }

                                        if (label[column.id] !== undefined) {
                                            column.label = label[column.id]
                                        }

                                        return column
                                    })

                                    response.columns = R.sort((a, b) => {
                                        return a.params.order > b.params.order ? 1 : -1
                                    }, response.columns)

                                    // response.rows.map((row) => {
                                    //     row.name.value = `${row.name.value} - ${row.filters.value}`
                                    // })

                                    return response
                                },
                                controller: service.carstock.controller.templates,
                            },
                            remove: {
                                request: service.carstock.templates_remove,
                            },
                        },
                        params: {
                            selected: true,
                            getLinkCell(row) {
                                const data = JSON.parse(row.filters.value)

                                data['id'] = row.id.value
                                data['name'] = row.name.value

                                return '/carstock/?data=' + JSON.stringify(data)
                            },
                            onClickDelete(rows) {
                                return [rows[0].id.value]
                            },
                        },

                        data: {
                            page: 'Шаблоны поиска',
                            title: 'Сохраненные шаблоны',
                            menu: [
                                {
                                    id: 'delete',
                                },
                            ],
                            actions: [
                                {
                                    label: 'Удалить',
                                    onClick: (rows, core) => {
                                        const ids = rows.map((row) => row.id.value)

                                        core.delete(ids)
                                    },
                                    icon: <IconTrash size={20} />,
                                    confirm: true,
                                },
                            ],
                        },
                    }}
                />
            </Box>
        </MantineProvider>
    )
}
