import clsx from 'clsx'
import React from 'react'
import style from './index.style.scss'

interface IContainer extends React.HTMLAttributes<HTMLDivElement> {
    className?: string
    children?: React.ReactNode
}

export const Container = React.forwardRef<HTMLDivElement, IContainer>(({ className, children, ...props }, ref) => {
    const prefix = style['ui-container']
    const classes = clsx(prefix, className)

    return (
        <div className={classes} {...props} ref={ref}>
            {children}
        </div>
    )
})
