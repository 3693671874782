import React from 'react'
import clsx from 'clsx'
import style from './row.style.scss'

type RowProps = React.HTMLAttributes<HTMLTableRowElement> & {}

export const Row: React.FC<RowProps> = ({ className, children, ...rest }) => {
    return (
        <tr className={clsx(style.root, className)} {...rest}>
            {children}
        </tr>
    )
}
