import {
    ButtonStylesParams,
    CheckboxStylesParams,
    ContextStylesParams,
    CSSObject,
    getStylesRef,
    MantineTheme,
    MantineThemeOverride,
    rem,
    TabsStylesParams,
} from '@mantine/core'
import { createEmotionCache } from '@mantine/core'
import { IconCheck, IconChevronDown } from '@tabler/icons-react'
import React from 'react'
import { styles } from '../styles'
import { merge } from '@/shared'

const buttonStyles = (
    theme: MantineTheme,
    { color }: ButtonStylesParams,
    { variant, size }: ContextStylesParams
): CSSObject => {
    const variants: ObjectType<CSSObject> = {
        default: {
            background: styles.white,
            color: styles.primary,

            '&:hover': {
                backgroundColor: theme.fn.darken(styles.white, 0.025),
            },
        },
        outline: {
            background: styles.white,
            color: styles.accent,
            borderColor: styles.accent,

            '&:hover': {
                background: styles.accent,
                color: styles.white,
            },
        },
        light: {
            background: styles.accentLight,
            color: styles.accent,

            '&:hover:not([disabled])': {
                backgroundColor: theme.fn.darken(styles.accentLight, 0.025),
            },
        },
        filled: {
            background: styles.accent,
            color: styles.white,

            '&:hover': {
                backgroundColor: theme.fn.darken(styles.accent, 0.025),
            },
        },
        subtle: {
            background: 'transparent',
            color: color ? color : styles.accent,
            paddingLeft: 0,
            paddingRight: 0,

            '&:hover': {
                color: color ? theme.fn.darken(color, 0.025) : styles.accentDark,
                backgroundColor: 'transparent',
            },

            leftIcon: {
                ref: getStylesRef('leftIcon'),
                color: color ? color : styles.accent,
                pointerEvents: 'none',
            },
        },
        xs: {
            height: 32,
            padding: '0 20px',
        },
    }

    return {
        ...theme.fn.focusStyles(),
        ...variants[variant],
        ...variants[size],
    }
}

const tabsStyles = (
    theme: MantineTheme,
    params: TabsStylesParams,
    { variant }: ContextStylesParams
): Record<string, CSSObject> => {
    const variants = {
        default: {
            tabsList: {
                borderBottomColor: styles.border,
                columnGap: 40,
            },
            tab: {
                fontSize: rem(16),
                lineHeight: rem(24),
                fontWeight: 400,
                color: styles.primary,
                padding: '10px 0 10px',

                ['&:hover']: {
                    background: 'transparent',
                    color: styles.accent,
                },

                ['&[data-active]']: {
                    // textShadow: '0 0 .60px #1e1e1e, 0 0 .60px #1e1e1e',
                    borderBottomColor: styles.accent,
                    color: styles.accent,

                    ['&:hover']: {
                        borderBottomColor: styles.accent,
                    },
                },
            },
        },
    }

    return {
        ...variants[variant],
    }
}

const checkboxStyles = (
    theme: MantineTheme,
    { color }: CheckboxStylesParams,
    { variant, size }: ContextStylesParams
): Record<string, CSSObject> => {
    const variants = {
        default: {
            input: {
                border: '1px solid',
                borderColor: styles.border,
                borderRadius: rem(3),
                background: styles.white,

                '&:hover': {
                    borderColor: theme.fn.darken(styles.border, 0.05),
                },

                '&:checked': {
                    borderColor: styles.accentLight,
                    background: styles.accentLight,

                    '&:hover': {
                        borderColor: theme.fn.darken(styles.accentLight, 0.05),
                        background: theme.fn.darken(styles.accentLight, 0.05),
                    },
                },

                [`&:checked + svg`]: {
                    color: `${styles.accent}!important`,
                },
            },
            icon: {
                width: {
                    xl: 24,
                    lg: 22,
                    md: 18,
                    sm: 16,
                    xs: 14,
                }[size],
            },
        },
        option: {
            input: {
                border: '2px solid',
                borderColor: styles.borderDark,
                borderRadius: 0,
                background: styles.background,

                '&:checked': {
                    borderColor: 'transparent',
                    background: 'transparent',

                    '&:hover': {
                        borderColor: 'transparent',
                        background: 'transparent',
                    },
                },

                [`&:checked + svg`]: {
                    width: 24,
                    color: `${color || styles['blue']}!important`,
                },

                '&[data-border-radio="true"]': {
                    borderRadius: '50%',
                },
            },
        },
    }

    const style = merge(variants['default'], variants[variant] || {})

    return style
}

export const theme: MantineThemeOverride = {
    colorScheme: 'light',
    cursorType: 'pointer',
    fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif',
    headings: {
        fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif',
    },
    loader: 'dots',
    fontSizes: {
        xs: rem(10),
        sm: rem(14),
        md: rem(16),
        lg: rem(18),
        xl: rem(24),
    },
    components: {
        Button: {
            defaultProps: {
                variant: 'light',
            },
            styles: (theme, params: ButtonStylesParams, context: ContextStylesParams) => ({
                root: {
                    border: '1px solid',
                    borderColor: 'transparent',
                    borderRadius: 12,
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: '24px',
                    transition: 'all .1s ease',
                    padding: '0 44px',
                    height: 48,

                    ...buttonStyles(theme, params, context),
                },
                rightIcon: {
                    marginLeft: 8,
                },
            }),
        },
        Container: {
            defaultProps: {
                size: 'lg',
                sizes: {
                    lg: 1304,
                },
                w: '100%',
            },
        },
        Input: {
            styles: () => ({
                input: {
                    padding: '0 12px',
                    borderRadius: 12,
                    minHeight: 48,
                    borderColor: styles.border,
                    fontSize: 16,
                    fontWeight: 400,
                    lineHeight: '24px',

                    '&:focus:not([data-invalid])': {
                        borderColor: styles.accent,
                    },
                },
            }),
        },
        TextInput: {
            styles: () => ({
                label: {
                    fontSize: rem(14),
                    lineHeight: rem(20),
                    marginBottom: 6,
                },
            }),
        },
        PasswordInput: {
            styles: () => ({
                input: {
                    borderColor: styles.border,

                    '&:focus-within:not([data-invalid])': {
                        borderColor: styles.accent,
                    },
                },
                innerInput: {
                    height: 46,
                    maxWidth: 'calc(100% - 48px)',
                    fontSize: 16,
                },
                visibilityToggle: {
                    width: 28,
                    height: 28,
                    borderRadius: 8,
                },
                rightSection: {
                    width: 48,
                },
                label: {
                    fontSize: rem(14),
                    lineHeight: rem(20),
                    marginBottom: 6,
                },
            }),
        },
        Select: {
            defaultProps: {
                maxDropdownHeight: 320,
                rightSection: <IconChevronDown color={styles.secondary} size={16} />,
                transitionProps: { transition: 'pop-top-left', duration: 100, timingFunction: 'ease' },
            },
            styles: () => ({
                root: {
                    '.mantine-ScrollArea-scrollbar': {
                        borderRadius: 0,
                    },
                },
                input: {
                    padding: '0 48px 0 12px',
                    borderRadius: 12,
                    height: 48,
                    borderColor: styles.border,
                    fontSize: 16,
                    fontWeight: 400,
                    lineHeight: '24px',

                    '&:focus': {
                        borderColor: styles.accent,
                    },
                },
                item: {
                    borderLeft: '2px solid transparent',
                    borderRadius: 0,
                    fontSize: 16,
                    fontWeight: 400,
                    lineHeight: '24px',
                    padding: '8px 12px 8px 10px',
                    color: styles.primary,
                    '&[data-selected]': {
                        '&, &:hover': {
                            background: `${styles.white}!important`,
                            color: styles.accent,
                            borderColor: styles.accentDark,
                        },
                    },
                    '&[data-hovered]': {
                        background: styles.accentLight,
                    },
                },
                label: {
                    marginBottom: 6,
                },
                dropdown: {
                    border: 'none',
                    borderRadius: 4,
                    // overflow: 'hidden',
                },
                itemsWrapper: {
                    padding: 0,
                    borderRadius: 4,
                    overflow: 'hidden',
                },
                rightSection: {
                    width: 36,
                },
            }),
        },
        Menu: {
            defaultProps: {
                offset: 23,
                transitionProps: { transition: 'pop-top-right' },
                position: 'bottom-end',
                width: 284,
            },
            styles: (theme, params, context) => ({
                dropdown: {
                    padding: '0!important',
                    border: 0,
                    borderRadius: 15,
                    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)',

                    '& > div': {
                        padding: '10px 20px',
                    },
                },

                item: {
                    padding: 0,
                    fontSize: 16,
                    lineHeight: '24px',
                    color: styles.dark,
                    paddingTop: 15,
                    paddingBottom: 15,
                    borderBottom: '1px solid' + styles.border,

                    '&:last-child': {
                        borderBottom: 0,
                    },

                    '&:hover': {
                        background: 'transparent',
                        color: styles.accent,
                    },
                },

                itemIcon: {
                    color: styles.accent,
                    marginRight: 10,

                    svg: {
                        width: 24,
                        height: 24,
                    },
                },

                label: {
                    fontSize: 16,
                    lineHeight: '24px',
                    color: styles.dark,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    borderBottom: '1px solid',
                    borderColor: styles.border,
                    padding: 0,
                    paddingBottom: 15,
                },
            }),
        },
        Popover: {
            defaultProps: {
                // maxDropdownHeight: 320,
                // rightSection: <IconChevronDown color={styles.secondary} size={16} />,
                transitionProps: { transition: 'pop-top-left', duration: 100, timingFunction: 'ease' },
            },
            styles: () => ({
                dropdown: {
                    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)',
                    borderRadius: '15px',
                },
            }),
        },
        MultiSelect: {
            defaultProps: {
                maxDropdownHeight: 285,
                rightSection: <IconChevronDown color={styles.secondary} size={16} />,
                transitionProps: { transition: 'pop-top-left', duration: 100, timingFunction: 'ease' },
            },
            styles: () => ({
                root: {
                    '.mantine-ScrollArea-scrollbar': {
                        borderRadius: 0,
                    },
                },
                input: {
                    padding: '2px 48px 2px 12px',
                    borderRadius: 12,
                    minHeight: 48,
                    borderColor: styles.border,

                    '&:focus-within': {
                        borderColor: styles.accent,
                    },
                },
                searchInput: {
                    fontSize: 16,
                    fontWeight: 400,
                    lineHeight: '24px',
                },
                values: {
                    minHeight: 42,
                },
                item: {
                    borderLeft: '2px solid transparent',
                    borderRadius: 0,
                    fontSize: 16,
                    fontWeight: 400,
                    lineHeight: '24px',
                    padding: '8px 12px 8px 10px',
                    color: styles.primary,
                    '&[data-selected]': {
                        '&, &:hover': {
                            background: `${styles.white}!important`,
                            color: styles.accent,
                            borderColor: styles.accentDark,
                        },
                    },
                    '&[data-hovered],&:hover': {
                        cursor: 'pointer',
                        background: styles.accentLight,
                    },
                },
                label: {
                    marginBottom: 6,
                },
                dropdown: {
                    border: 'none',
                    borderRadius: 4,
                    // overflow: 'hidden',
                },
                itemsWrapper: {
                    padding: 0,
                    borderRadius: 4,
                    overflow: 'hidden',
                },
                rightSection: {
                    width: 36,
                },
                value: {
                    background: styles.background,
                    color: styles.secondary,
                    height: rem(24),
                    fontSize: 12,

                    span: {
                        lineHeight: 1,
                    },
                    svg: {
                        color: styles.secondary,
                    },
                },
            }),
        },
        Modal: {
            defaultProps: {
                zIndex: 1000,
            },
            // defaultProps: {
            //     rightSection: <IconChevronDown color={styles.secondary} size={16} />,
            //     transitionProps: { transition: 'pop-top-left', duration: 100, timingFunction: 'ease' },
            // },
            styles: () => ({
                content: {
                    borderRadius: 24,
                },
                body: {
                    padding: 24,
                },
                header: {
                    padding: 24,
                },
                overlay: {},
                title: {
                    fontSize: 18,
                    fontWeight: 600,
                },
                close: {
                    width: 32,
                    height: 32,
                    borderRadius: 8,

                    '& svg': {
                        width: 24,
                        height: 24,
                    },
                },
                root: {},
                inner: {},
            }),
        },
        Drawer: {
            // defaultProps: {
            //     maxDropdownHeight: 320,
            //     rightSection: <IconChevronDown color={styles.secondary} size={16} />,
            //     transitionProps: { transition: 'pop-top-left', duration: 100, timingFunction: 'ease' },
            // },
            // styles: () => ({
            //     body: {
            //         padding: '0px!important',
            //     },
            //     root: {},
            //     inner: {},
            //     content: {
            //         minWidth: '516px',
            //     },
            //     header: {},
            //     overlay: {},
            //     title: {},
            //     close: {},
            // }),
        },
        Loader: {
            defaultProps: {
                color: styles.accent,
            },
        },
        Pagination: {
            styles: () => ({
                control: {
                    border: 'none',
                    padding: rem(4),
                    color: styles.primary,
                    fontWeight: 400,
                    fontSize: 16,
                    borderRadius: 6,
                    lineHeight: '24px',
                },
            }),
        },
        Checkbox: {
            defaultProps: {
                icon: IconCheck,
            },
            styles: checkboxStyles,
        },
        ScrollArea: {
            defaultProps: {
                type: 'auto',
                scrollbarSize: 8,
            },
            styles: (theme) => ({
                scrollbar: {
                    padding: 0,
                    borderRadius: 4,
                    background: styles.border,

                    '&:hover': {
                        background: theme.fn.darken(styles.border, 0.025),
                    },

                    '&[data-orientation="vertical"]': {
                        cursor: 'pointer',
                    },

                    '&[data-orientation="horizontal"]': {
                        cursor: 'pointer',
                        // display: 'none',
                    },

                    '&[data-orientation="vertical"] .mantine-ScrollArea-thumb': {
                        background: styles.secondary,

                        ' &:hover': {
                            background: theme.fn.darken(styles.secondary, 0.15),
                        },
                    },
                },
            }),
        },
        ScrollAreaAutosize: {
            defaultProps: {
                type: 'auto',
                scrollbarSize: 8,
            },
        },
        Tabs: {
            styles: (theme, params, context) => tabsStyles(theme, params, context),
        },
        SegmentedControl: {
            styles: (theme, params, context) => ({
                root: {
                    padding: 0,
                    height: 48,
                    background: 'transparent',
                    border: '0px solid transparent',
                    // borderColor: styles.border,
                    borderRadius: 12,
                    overflow: 'visible',
                    // maxWidth: 'calc(100% + 4px)',
                    position: 'relative',
                    left: 4,
                },
                control: {
                    display: 'flex',
                    flexDirection: 'column',

                    // '&:last-child': {
                    //     maxWidth: 'calc(100% + 4px)',
                    // },
                },
                label: {
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 16,
                    lineHeight: '24px',
                    fontWeight: 400,
                    color: styles.dark,

                    '&[data-active]': {
                        color: `${styles.accent}!important`,
                    },
                },
                indicator: {
                    top: 4,
                    borderRadius: 12,
                    height: 48,
                    boxShadow: 'none',
                    background: styles.white,
                    border: '1px solid',
                    borderColor: styles.accent,
                },
            }),
        },
    },
}

export const themeCache = createEmotionCache({ key: 'elisa' })
