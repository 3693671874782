import React from 'react'
import { Box } from '@mantine/core'
import style from './carstock.style.scss'
import { service } from '@/api'
import { useNavigate } from 'react-router-dom'
import { CarstockWidget } from '@/widget'
import { CarstockCarPage, CarstockFavoritePage, CarstockTemplatesPage } from './[id]'
import { MantineProvider } from '@/theme'

interface CarstockPageProps {}

export const CarstockPage = ({ ...rest }: CarstockPageProps) => {
    return (
        <MantineProvider withNotify>
            <Box className={style.root} {...rest}>
                <CarstockWidget
                    coreProps={{
                        widget: {
                            name: 'carstock',
                        },
                        service: {
                            filters: {
                                request: service.carstock.filters,
                                controller: service.carstock.controller.filters,
                            },
                            search: {
                                request: service.carstock.search,
                                controller: service.carstock.controller.filter_search,
                            },
                            options: {
                                request: service.carstock.options,
                                controller: service.carstock.controller.filter_options,
                            },
                            save: { request: service.carstock.search_save },
                            edit: { request: service.carstock.search_edit },
                        },
                        params: {
                            load: true,
                            save: true,
                            // onClickCar: (car) => {
                            //     navigate('/carstock/car/' + car.data.id)
                            // },
                        },
                        data: {
                            tabs: new Map([
                                [
                                    'all',
                                    {
                                        id: 'all',
                                        param: '9005',
                                        enabled: false,
                                        value: null,
                                        label: 'Все',
                                    },
                                ],
                                [
                                    'in',
                                    {
                                        id: 'in',
                                        param: '9005',
                                        enabled: true,
                                        value: 'IN_STOCK',
                                        label: 'В наличии',
                                    },
                                ],
                                [
                                    'out',
                                    {
                                        id: 'out',
                                        param: '9005',
                                        enabled: false,
                                        value: 'OUT_OF_STOCK',
                                        label: 'В пути',
                                    },
                                ],
                            ]),
                            sorts: new Map([
                                [
                                    'price_asc',
                                    {
                                        id: 'price_asc',
                                        enabled: true,
                                        param: 'sort',
                                        value: 'price_asc',
                                        label: 'По возрастанию цены',
                                    },
                                ],
                                [
                                    'price_desc',
                                    {
                                        id: 'price_desc',
                                        enabled: false,
                                        param: 'sort',
                                        value: 'price_desc',
                                        label: 'По убыванию цены',
                                    },
                                ],
                            ]),
                        },
                    }}
                />
            </Box>
        </MantineProvider>
    )
}

CarstockPage.Car = CarstockCarPage
CarstockPage.Favorite = CarstockFavoritePage
CarstockPage.Templates = CarstockTemplatesPage
