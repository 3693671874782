import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import ButtonBase from '@mui/material/ButtonBase'
import { Icon } from '@/pages/analytics/components/ui'
import { notifyError, Scroll } from '@/theme/@deprecated/components/ui'
import { FilterPopoverMenuItem, FilterPopoverMenuType } from '..'
import { observer } from 'mobx-react-lite'
import { store } from '@/pages/analytics/store'
import { Input } from '../input'
import { debounce } from '@/shared'
import { toJS } from 'mobx'
import { uniqueByKey } from '@/shared/lib/array'

interface IFilterPopoverMenuProps extends React.HTMLAttributes<HTMLDivElement> {
    classes?: {
        root?: string
    }
    menu: FilterPopoverMenuType
    setMenu: ReactSetState<FilterPopoverMenuType>
    handleClose?: () => void
    setLoading: ReactSetState<boolean>
    // eslint-disable-next-line no-unused-vars
    handleGetFilter: FunctionType
}

export const FilterPopoverMenu: React.FC<IFilterPopoverMenuProps> = observer(
    // eslint-disable-next-line no-unused-vars
    ({ classes, menu, setMenu, handleClose, handleGetFilter, setLoading, ...rest }) => {
        const cls = { root: clsx(style.root, classes?.root) }
        const isEmpty = menu.items.filter((item) => !item.visible).length === menu.items.length
        const columns = toJS(store.table.columns)

        const handleClickMenu = (item: FilterPopoverMenuItem) => async () => {
            try {
                const newStack = [...menu.stack, menu.current]

                const items = await handleGetFilter(item, {
                    'page[size]': 50,
                })()

                setMenu(
                    (prev) =>
                        ({
                            ...prev,
                            stack: newStack,
                            current: item,
                            items: items,
                        } as FilterPopoverMenuType)
                )
            } catch (err) {
                console.log(err)
                notifyError(err?.message)
            }
        }

        const handleClickFilter = (item: FilterPopoverMenuItem) => () => {
            store.table.toggleFilter(item)
        }

        const handleSearch = React.useCallback(
            () =>
                debounce(async (e) => {
                    try {
                        const value = e.target.value

                        setLoading(true)

                        const items = await handleGetFilter(menu.current, { search: value, 'page[size]': 50 })()

                        setMenu((prev) => ({
                            ...prev,
                            items: items,
                        }))

                        setTimeout(() => {
                            setLoading(false)
                        }, 200)
                    } catch (err) {
                        console.log(err)
                        notifyError(err?.message)
                    }
                }, 200),
            [menu]
        )

        // TODO: временно отфильтровал пустые объекты и дубликаты, убрать когда будет исправлено на бэке
        const items = uniqueByKey(
            menu.items.filter((el) => el.id !== ''),
            'id'
        )

        return (
            <div className={cls.root} {...rest}>
                {menu.current ? <Input onChange={handleSearch()} /> : null}

                <Scroll height={240}>
                    <div className={style.rows}>
                        {items.map((item) => {
                            if (item.id === 'is_archival') return

                            const label =
                                item.type === 'menu'
                                    ? columns.find((column) => column.id === item.id)?.label
                                    : item.value

                            return (item.type === 'menu' && item.visible && label) ||
                                (item.type === 'filter' && item.visible) ? (
                                <ButtonBase
                                    key={item.id}
                                    className={clsx(style.row, {
                                        [style.rowActive]: store.table.isFilterExist(item),
                                    })}
                                    onClick={item.type === 'menu' ? handleClickMenu(item) : handleClickFilter(item)}
                                >
                                    <span className={style.title}>{label}</span>
                                    {item.type === 'menu' && (
                                        <Icon icon='angleRight' sx={{ fontSize: 20 }} className={style.icon} />
                                    )}
                                </ButtonBase>
                            ) : null
                        })}
                        {items.length === 0 || isEmpty ? (
                            <div className={style.notfound}>Значений не найдено</div>
                        ) : null}
                    </div>
                </Scroll>
            </div>
        )
    }
)
