import { service } from '@/api'
import { showToast, showToastError } from '@/shared/vendors/mantine'
import { StoreData, StoreParams, initial_default_data, store } from './catalog.store'
import { BodyTab, CatalogFilterGroup } from './catalog.types'
import { CatalogCarModel, CatalogFilterModel, CatalogOptionModel } from './models'
import { isBrandModel } from './catalog.lib'

type CoreService = {
    filters?: ServiceItem<
        (req: {
            params: {
                vehicle_type_id: number
                filter_group_id?: number
            }
        }) => Promise<CatalogFilterModel[]>
    >
    filtersGroups?: ServiceItem<(req: { params: { vehicle_type_id: number } }) => Promise<CatalogFilterGroup[]>>
    models?: ServiceItem<typeof service.catalog.models>
    modifications?: ServiceItem<typeof service.catalog.modifications>
    options?: ServiceItem<typeof service.catalog.options>
    save?: ServiceItem<typeof service.carstock.search_save>
}

export type CoreProps = {
    widget: {
        id: number
        name: string
    }
    service: CoreService
    params?: StoreParams
    data?: StoreData
}

const initialWidget: CoreProps['widget'] = {
    id: null,
    name: null,
}

const serviceItem = {
    request: null,
    controller: { ref: null },
}

const initialService: CoreService = {
    filters: serviceItem,
    filtersGroups: serviceItem,
    models: serviceItem,
    modifications: serviceItem,
    options: serviceItem,
    save: serviceItem,
}

const initialData = {
    /**
     * Храним значений ture/false с ключом fitler.data.id,
     * предотвращаем повторную загрузку фильтров, если фильтра не изменялись  */
    changeFilters: {},
}

class Core {
    widget = initialWidget
    service = initialService
    store = store
    data: CoreProps['data'] = {}

    init(props: CoreProps) {
        const default_data_url = this.getDefaultParamsFromUrl()

        if (this.widget.name && this.widget.name === props.widget.name) {
            if (
                default_data_url &&
                JSON.stringify(store.data.default_data) === JSON.stringify(default_data_url) &&
                Object.keys(default_data_url).length
            ) {
                return this
            } else if (
                !Object.keys(default_data_url).length &&
                JSON.stringify(store.data.default_data) === JSON.stringify(initial_default_data)
            ) {
                return this
            }
        }

        store.reset()

        this.abort()

        this.widget = props.widget || initialWidget
        this.service = props.service || initialService
        this.data = props.data || {}

        if (props.data) {
            store.setData(props.data)
        }

        if (props.params) {
            store.setParams(props.params)
        }

        store.setData({
            default_data: {
                ...store.data.default_data,
                ...default_data_url,
            },
        })

        if (default_data_url.tab !== undefined) {
            const tab_value = default_data_url.tab

            store.getTabs().forEach((tab) => {
                if (tab.value === tab_value) {
                    tab.enabled = true
                } else {
                    tab.enabled = false
                }
            })
        }

        if (default_data_url.sort !== undefined) {
            const sort_value = Object.values(default_data_url.sort)[0]

            store.getSorts().forEach((sort) => {
                if (sort.value === sort_value) {
                    sort.enabled = true
                } else {
                    sort.enabled = false
                }
            })
        }

        if (default_data_url.page) {
            store.data.meta = {
                ...store.data.meta,
                per_page: default_data_url.page.size,
            }
        }

        if (default_data_url.view_mode) {
            this.changeMode(default_data_url.view_mode)
        }

        return this
    }

    abort() {
        Object.values(this.service || {}).forEach((service) => {
            service?.controller?.ref?.abort?.()
        })
    }

    async mount() {
        try {
            if (store.state.init) return

            store.setState({ loading: true })

            const default_data = store.data.default_data
            const default_options =
                default_data.filters?.universal_option_id || default_data.filters?.specific_option_id

            await this.filters()
            await this.loadBodyTabs()

            if (default_options?.length) {
                await this.loadOptions()
            }

            if (!store.state.searched && store.params.load) {
                this.search({ first: true })
            }

            store.setState({ init: true, loading: false })
        } catch (err) {
            console.log(err)
            store.setState({ init: false, loading: false, loaded: false })
            showToastError({ message: err?.message })
        }
    }

    async unmount() {
        // ...
    }

    async filters() {
        const params = { params: { vehicle_type_id: this.widget.id } }
        const filters = await this.service.filters.request(params)
        const groups = await this.service.filtersGroups.request(params)
        const filters_finded: { value: any; model: CatalogFilterModel }[] = []
        const cache = {}

        const groups_map = groups.reduce((acc, value) => {
            const group = value

            acc[value.id] = group

            group.__visible = !group.name.toLowerCase().includes('опции')

            return acc
        }, {})

        const filters_default = {
            // start_production_year: (store.data.default_data as any).filters.start_production_year,
            brand_id: (store.data.default_data as any).filters.brand_id,
            model_id: (store.data.default_data as any).filters.model_id,
            engine_volume_cm: (store.data.default_data as any).filters.engine_volume_cm,
            engine_volume_l: (store.data.default_data as any).filters.engine_volume_l,
            full_weight_t: (store.data.default_data as any).filters.full_weight_t,
            // engine_power_kw: (store.data.default_data as any).filters.engine_power_kw,
            // full_weight_t: (store.data.default_data as any).filters.full_weight_t,
            // awning_availability: (store.data.default_data as any).filters.awning_availability,
            // side_length: (store.data.default_data as any).filters.side_length,
        }

        // const params = new URLSearchParams(location.search)
        // const filtersParams = JSON.parse(params.get('filters') || '{}')

        /** Собираем модели, у которых должно быть значение по умолчанию */
        if (Object.keys(filters_default).length) {
            for (const key in filters_default) {
                const finded = filters.find((filter) => filter.get.type() === key)
                const value = filters_default[key]

                if (value !== undefined) {
                    if (value.includes('_T_')) {
                        const findedByType = filters.find((filter) => filter.getTypeValueByValue(key))

                        if (findedByType) {
                            const type = value.split('_T_')[1]

                            findedByType.set.type(type)

                            filters_finded.push({
                                value: value.replace('_T_' + type, ''),
                                model: findedByType,
                            })
                        }
                    } else if (finded) {
                        filters_finded.push({
                            value,
                            model: finded,
                        })
                    }
                }
            }
        }

        /**
         * Проставляем значения по умолчанию.
         * Запрашиваем список значения, т.к. из url мы получаем только id.
         * Для корректной работы ui нам нужен id/value/label
         */
        await Promise.all(
            filters_finded.map((filter) =>
                filter.model.loadValues({}, () => {
                    if (filter.model.data.type === 'switch') {
                        this.setFilterValue([filter.value], filter.model, {})
                    } else {
                        this.setFilterValue(filter.value, filter.model, {})
                    }

                    filter.model.setState({ loaded: false })
                })
            )
        )

        const filters_filtered = filters.filter((filter) => {
            // this.data.changeFilters[filter.data.id] = true

            // if (filtersParams[filter.get.type()]) {
            // console.log(filter.get.type(), filtersParams[filter.get.type()])
            // this.setFilterValue(filtersParams[filter.get.type()], filter)
            // }

            // if (filter.data.id === 23) {
            //     filter.setData({
            //         value: [{ id: '0..2.5', label: 'до 2,5 тонн', value: '0..2.5' }],
            //         defaultValues: [
            //             { id: '0..2.5', label: 'до 2,5 тонн', value: '0..2.5' },
            //             { id: '0..3.5', label: 'до 3,5 тонн', value: '0..3.5' },
            //         ],
            //     })
            // }

            // if (filter.data.id === 45) {
            //     filter.setData({
            //         // value: [{ id: '0', label: 'Нет', value: '0' }],
            //         defaultValues: [
            //             { id: '0', label: 'Нет', value: '0' },
            //             { id: '1', label: 'Да', value: '1' },
            //         ],
            //     })
            // }

            // if (filter.data.id === 2) {
            //     filter.setData({
            //         depends_for: [3],
            //     })
            // }

            // if (filter.data.id === 3) {
            //     filter.setData({
            //         depends_on: [2],
            //     })
            // }

            if (!cache[filter.get.type()]) {
                filter.setData({ __group: groups_map[filter.data.group_id] })
                cache[filter.get.type()] = 1
                return true
            }

            return false
        })

        store.setData({ filters: filters_filtered, groups })
    }

    async resetFilters() {
        store.data.filters.forEach((filter) => filter.setData({ value: [], values: [] }))

        const default_data = initial_default_data

        store.setData({
            tabs: this.data.tabs,
            sorts: this.data.sorts,
            default_data,
        })
    }

    reset() {
        // this.changeBodyTabs(null)
        this.resetFilters()

        let isModels = store.isCurrentMode('models')

        this.changeMode('models')

        if (store.state.loadedOptions) {
            this.resetOptions()

            if (!isModels) {
                this.loadOptions({ force: true })
            }
        }

        this.changePage(1)
    }

    async search(props?: { first?: boolean; model?: typeof store.data.model }) {
        this.abort()

        const { first, model } = props || {}

        const values = store.getValuesForSearch()
        const is_exist_brand_model = values['brand_id']?.length === 1 && values['model_id']?.length === 1

        const default_data = store.data.default_data

        /**
         * Режим модификаций активируется, когда выбраны бренд и модель
         */
        if (is_exist_brand_model || model) {
            const modelData = model || store.getModelDataByFilters()

            store.setData({ model: modelData })

            let needOptions = false

            if (!store.isCurrentMode('modifications')) {
                this.changeMode('modifications')
                this.resetOptions()

                needOptions = true

                // if (!first) {
                //     this.loadOptions({ force: true })
                // }
            }

            core.modifications(default_data.page?.number || 1, () => {
                // Ожидаем пока прогрузим brand_id и model_id и только потом делаем запрос опций
                if ((store.state.loadingOptions || store.state.loadedOptions) && needOptions) {
                    this.loadOptions({ force: true })
                }
            })
        } else {
            if (!store.isCurrentMode('models')) {
                this.changeMode('models')

                if (store.state.loadingOptions || store.state.loadedOptions) {
                    this.resetOptions()
                    this.loadOptions({ force: true })
                }

                // if (!first) {
                //     this.loadOptions({ force: true })
                // }
            }

            core.models(default_data.page?.number || 1)
        }
    }

    async changeModification(card: CatalogCarModel) {
        this.search({
            model: {
                brand_id: card.data.brand_id,
                brand_name: card.data.brand_name,
                model_id: card.data.model_id,
                model_name: card.data.model_name,
            },
        })
    }

    async models(page?: number) {
        try {
            store.setState({ searching: true })

            // store.meta.current_page = page || 1

            const values = store.getValuesForSearch()
            const { cars, meta, links } = await this.service.models.request({
                filters: {
                    ...values,
                    vehicle_type_id: this.widget.id,
                    page: {
                        ...values.page,
                        number: page || 1,
                    },
                },
            })

            if (!cars.length) {
                showToast({ message: 'Моделей по данным параметрам не найдено!', color: 'yellow.6' })
            }

            store.setData({ cars, modifications: [], model: null, meta, links })
            store.setState({ searching: false, searched: true })

            // this.updateSearchParams()
            this.updateHistoryUrl()
        } catch (err) {
            console.log(err)
            store.setState({ searching: false, searched: false })
            showToastError({ message: err?.message })
        }
    }

    async modifications(page?: number, loadOptions?: FunctionType) {
        try {
            store.setState({ searching: true, searchingModifications: true })

            const values = await store.getValuesForSearchModifications()

            loadOptions?.()

            const { modifications, meta } = await this.service.modifications.request({
                filters: {
                    ...values,
                    vehicle_type_id: this.widget.id,
                    page: {
                        ...values.page,
                        number: page || 1,
                    },
                },
            })

            if (!modifications.length) {
                showToast({ message: 'Модификаций по данным параметрам не найдено!', color: 'yellow.6' })
            }

            store.setData({ cars: [], modifications, meta, links: null })
            store.setState({ searching: false, searched: true, searchingModifications: false })

            // this.updateSearchParams()
            this.updateHistoryUrl()
        } catch (err) {
            console.log(err)
            store.setState({ searching: false, searched: false, searchingModifications: false })
            showToastError({ message: err?.message })
        }
    }

    getOptions() {
        const universal_options = store.getOptionsItems
    }

    async changePage(page: number) {
        if (store.isCurrentMode('models')) {
            this.models(page)
        } else {
            this.modifications(page)
        }
    }

    async changeLimit(value?: number) {
        store.data.meta.per_page = value
            ? value
            : store.data.meta.per_page === store.data.limit.min
            ? store.data.limit.max
            : store.data.limit.min

        if (store.isCurrentMode('models')) {
            this.models()
        } else {
            this.modifications()
        }
    }

    async changeViewState(id: string) {
        const state = store.data.tabs.get(id)

        if (!state.enabled) {
            const states = store.getTabs()

            states.forEach((state) => (state.enabled = false))

            state.enabled = true

            // store.data.filters.forEach((filter) => {
            //     if (isBrandModel(filter)) {
            //         filter.setData({ value: [], values: [] })
            //     }
            // })

            // if (store.isCurrentMode('models')) {
            //     this.models()
            // } else {
            //     this.modifications()
            // }

            this.search()
        }
    }

    async changeSort(id: string) {
        const sort = store.data.sorts.get(id)

        if (!sort.enabled) {
            const sorts = store.getSorts()

            sorts.forEach((sort) => (sort.enabled = false))

            sort.enabled = true

            if (store.isCurrentMode('models')) {
                this.models()
            } else {
                this.modifications()
            }
        }
    }

    async changeMode(id: string) {
        const mode = store.data.modes.get(id)

        if (!mode.enabled) {
            const modes = store.getModes()

            modes.forEach((mode) => (mode.enabled = false))

            mode.enabled = true
        }
    }

    changeFilter(value: string[], filter: CatalogFilterModel, options?: { isGroup?: boolean }) {
        const filters = store.filters
        const dependsFor = store.getFilterDependsFor(filter)

        this.setFilterValue(value, filter, options)

        // Object.keys(this.data.changeFilters).forEach((item) => {
        //     if (parseInt(item) !== filter.data.id) {
        //         this.data.changeFilters[item] = true
        //     } else {
        //         this.data.changeFilters[item] = false
        //     }
        // })

        if (!value.length) {
            filter.setState({ loaded: false })
        }

        /** Сбрасываем параметры зависимые на текущий фильтр */
        dependsFor.forEach((curr) => curr.reset())

        filters.forEach((iterate_filter) => {
            if (
                iterate_filter.state.loaded &&
                iterate_filter.data.id !== filter.data.id
                //  &&
                // !iterate_filter.data.value[0]
            ) {
                iterate_filter.setState({ loaded: false })
            }
        })

        this.updateSearchParams()
    }

    setFilterValue(value: string[], filter: CatalogFilterModel, options: { isGroup?: boolean }) {
        if (filter.data.type === 'select') {
            filter.set.value(value[0])
        } else if (filter.data.type === 'multi-select') {
            filter.set.multiValue(value)
        } else if (filter.data.type === 'range-select') {
            filter.set.rangeValue(value)
        } else if (filter.data.type === 'group-select') {
            filter.set.groupValue(value, options?.isGroup)
        } else if (filter.data.type === 'range-integer') {
            filter.set.rangeIntegerValue(value)
        } else if (filter.data.type === 'switch') {
            filter.set.switchValue(value[0])
        } else if (filter.data.type === 'bool-select') {
            filter.set.value(value[0])
        }
    }

    changeFilterType(value: string, filter: CatalogFilterModel) {
        const dependsFor = store.getFilterDependsFor(filter)

        filter.set.type(value)
        filter.set.rangeValue([null, null])

        /** Сбрасываем параметры зависимые на текущий фильтр */
        dependsFor.forEach((dependFilter) => dependFilter.reset())
    }

    async changeOption(id: number) {
        try {
            const option = store.getOptionByIdItem(id)

            option.setSelectedOption(id)
        } catch (err) {
            console.log(err)
        }
    }

    async loadFilterValues(filter: CatalogFilterModel) {
        const filters = store.getFilterValuesByDependsOnForLoadValues(filter)

        await filter.loadValues(filters)
    }

    async loadOptions(props?: { force?: boolean }) {
        const { force } = props || {}

        try {
            if ((store.state.loadingOptions || store.state.loadedOptions) && !force) return

            // console.log('loadOptions')

            store.setState({ loadingOptions: true })

            const default_data = store.data.default_data

            // const values = store.getFilterValuesForSearchOptions()

            // const { options } = await this.service.options.request({
            //     params: values,
            // })

            const filter = store.getOptionsFilter()

            const filters = store.getFilterValuesByDependsOnForLoadValues(filter, { isOptions: true })

            const options = (await filter.loadValues(filters)).map((group) => {
                const isTabs = group.items[0]?.type === 'group'

                const model = new CatalogOptionModel({
                    id: group.id,
                    name: group.label,
                    parent_id: null,
                    /**
                     * В свойстве items поля приходят с type item/group.
                     * В groupесть вложенность items с type item
                     */
                    groups: isTabs
                        ? group.items
                              .map((item: any) => {
                                  if (item.type === 'item') return null

                                  return {
                                      name: item.label,
                                      options: item.items.map((option) => {
                                          return {
                                              id: option.id,
                                              name: option.value,
                                          }
                                      }),
                                  }
                              })
                              .filter(Boolean)
                        : [
                              {
                                  name: 'Базовые опции',
                                  options: group.items.map((item: any) => {
                                      return {
                                          id: item.id,
                                          name: item.value,
                                      }
                                  }),
                              },
                          ],

                    type_param_key: null,
                    selected: [],
                })

                return model
            })

            const default_options =
                default_data.filters?.universal_option_id || default_data.filters?.specific_option_id

            if (default_options) {
                default_options.forEach((id) => {
                    const option = store.getOptionByIdItem(id, options)

                    if (option) {
                        option.setSelectedOption(id)
                    }
                })
            }

            if (!options.length) {
                showToast({ message: 'Опций не найдено!', color: 'yellow.6' })
            }

            store.setData({ options })

            store.setState({ loadingOptions: false, loadedOptions: !!options.length })
        } catch (err) {
            console.log(err)
            store.setState({ loadingOptions: false, loadedOptions: false })
            showToastError({ message: err?.message })
        }
    }

    async resetOptions() {
        store.data.options.forEach((option) => {
            option.resetSelected()
        })
    }

    searchOptions(value: string) {
        const options = store.getOptionsItems()

        return options.filter((option) => option.name?.toLowerCase?.().includes?.(value.toLowerCase()))
    }

    async updateSearchParams() {
        // const filters = store.getFilterValues()
        // const sort = store.getCurrentSort()
        // const query = `${location.pathname}?filters=${JSON.stringify(filters || {})}&meta=${JSON.stringify({
        //     page: store.getPage(),
        // })}&sorts=${JSON.stringify({
        //     [sort.param]: store.getCurrentSortValues()[0],
        // })}`
        // window.history.replaceState({ query: query }, '', query)
    }

    //TODO: Вынести запрос в локальный сервис
    async loadBodyTabs() {
        try {
            const response = await service.catalog.filters_tabs({
                params: { vehicle_type_id: this.widget.id },
            })

            const tab_value = store.data.default_data.filters?.body_type_purpose_id

            response.data.forEach((tab) => {
                if (tab.id === tab_value) {
                    tab.selected = true
                }
            })

            this.store.setData({ body_tabs: response })
        } catch (err) {
            console.log(err)
            showToastError({ message: 'Не удалось загрузить вкладки типа кузова' })
        }
    }

    changeBodyTabs(tab_id: number) {
        const data = this.store.data.body_tabs.data.map((curr) => {
            if (curr.id !== tab_id || (curr.id === tab_id && curr.selected)) {
                curr.selected = false
            } else {
                curr.selected = true
            }

            return curr
        })

        this.store.setBodyData(data)
        this.resetFilters()
    }

    /* -------------------------------------------------------------------------- */
    /*                                     Url                                    */
    /* -------------------------------------------------------------------------- */

    getDefaultParamsFromUrl(): ObjectType {
        const params = new URLSearchParams(location.search)
        const data = JSON.parse(params.get('data') || '{}')

        return data
    }

    updateHistoryUrl() {
        const values = store.getValuesForUrl()
        const defaults_params = this.getDefaultParamsFromUrl()

        const query_data = { ...defaults_params, ...values }

        // for (const key in query_data.filters || {}) {
        //     const value = (query_data.filters || {})[key]

        //     if (value.includes('..')) {
        //         ;(query_data.filters || {})[key] = value.split('..').map((value) => parseFloat(value))
        //     }
        // }

        const query_data_json = JSON.stringify(query_data)

        // console.log('updateHistoryUrl', defaults_params, values)
        // console.log('updateHistoryUrl query_data', query_data)

        const query = `${location.pathname}?data=${query_data_json}`

        window.history.pushState({ query }, '', query)
    }

    /* -------------------------------------------------------------------------- */
    /*                                   Другое                                   */
    /* -------------------------------------------------------------------------- */

    changeLegacy(checked: boolean) {
        this.store.data.legacy = checked

        store.data.filters.forEach((filter) => {
            if (isBrandModel(filter)) {
                filter.setData({ value: [], values: [] })
            }
        })
    }
}

export const core = new Core()
