import { tojs } from '@/shared'
import { BaseModel } from '@/shared/core/model'

type CarstockOptionGroup = {
    name: string
    options: CarstockOptionItem[]
}

type CarstockOptionItem = {
    id: number
    type: string
    name: string

    selected?: boolean
    group?: string
    checkboxes?: boolean
}

interface ModelProps {
    id: number
    name: string
    parent_id: any
    groups: CarstockOptionGroup[]
    options: CarstockOptionItem[]

    type_param_key: 'universal_options_ids' | 'specific_options_ids' | 'carstock_options_ids'
    selected?: CarstockOptionItem['id'][]
}

export class CarstockOptionModel extends BaseModel<ModelProps, CarstockOptionModel> {
    constructor(props: ModelProps) {
        super(props, CarstockOptionModel)
    }

    setSelectedOption(id: number) {
        const item = this.data.groups
            .find((group) => group.options.find((item) => item.id === id))
            .options.find((item) => item.id === id)

        item.selected = !item.selected

        if (item.selected) {
            this.data.selected.push(item.id)
        } else {
            this.data.selected = this.data.selected.filter((selected) => selected !== item.id)
        }
    }

    resetSelected() {
        this.setData({
            selected: [],
            groups: tojs(this.data.groups).map((group) => {
                group.options.map((item) => {
                    item.selected = false

                    return item
                })

                return group
            }),
        })
    }
}
