import React from 'react'
import { Box, Button, Group } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { store } from '../../catalog.store'
import { core } from '../../catalog.core'
import style from './grid-state.style.scss'

interface GridStateProps {}

export const GridState: React.FC<GridStateProps> = observer(({ ...rest }) => {
    const tabs = store.getTabs()

    const handleChange = (e: any) => {
        const id = e.currentTarget.dataset.id

        core.changeViewState(id)
    }

    return (
        <Box {...rest}>
            <Group spacing={6} className={style.root}>
                {tabs.map((tab) => {
                    return (
                        <Button
                            data-id={tab.id}
                            key={tab.value}
                            variant={tab.enabled ? 'filled' : 'default'}
                            className={style.button}
                            onClick={handleChange}
                            sx={{ opacity: store.state.searching && tab.enabled ? 0.6 : 1 }}
                            disabled={store.state.searching && !tab.enabled}
                        >
                            {tab.label}
                        </Button>
                    )
                })}
            </Group>
        </Box>
    )
})
