import React from 'react'
import clsx from 'clsx'
import style from './logo.style.scss'
import { Link } from 'react-router-dom'
import { ReactComponent as IconLogo } from './assets/logo.svg'

type LogoProps = React.HTMLAttributes<HTMLElement> & {}

export const Logo: React.FC<LogoProps> = ({ className, ...rest }) => {
    return (
        <Link to={'/catalog'} className={clsx(style.root, className)} {...rest}>
            <IconLogo />
        </Link>
    )
}
