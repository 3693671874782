import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'

/**
 * id.1 - carstock
 * id.2 - catalog
 */
export type CarstockSearchEditRequest = {
    id: 1 | 2
    name: string
    data?: {
        [property: string]: number | string
    }
}

export type CarstockSearchEditResponse = {
    success: boolean
}

export const CarstockSearchEdit = async (req: CarstockSearchEditRequest) => {
    try {
        const response = await apiConfig.fetch.ilsa<CarstockSearchEditResponse>({
            url: '/filter-templates',
            config: {
                method: 'PUT',
                data: [
                    {
                        filters: {
                            value: JSON.stringify(req.data),
                        },
                        name: {
                            value: req.name, // names[req.id],
                        },
                        id: {
                            value: req.id,
                        },
                    },
                ],
            },
            mock: {
                enable: false,
                data: {
                    success: true,
                },
            },
        })

        if (!response?.data?.success) {
            throw Error('Произошла ошибка при редактирование шаблона поиска')
        }

        return response
    } catch (err: any) {
        throwAdapterError(err, 'CarstockSearchEdit')
    }
}
