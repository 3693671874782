import React from 'react'
import style from './index.style.scss'
import { ControlAdd } from './add'
import { ControlVisible } from './visible'
import { ControlArchive } from './archive'
import { ControlFilter } from './filter'
import { observer } from 'mobx-react-lite'
import { store } from '@/pages/analytics/store'
import { ControlReport } from './report'
// import { ControlSort } from './sort'
// import { ControlSearch } from './search'

interface ControlTopProps {}

export const ControlTop: React.FC<ControlTopProps> = observer(({ ...rest }) => {
    const isArhiveMode = store.table.isArchiveMode()
    const isViewMode = store.table.isMode('view')
    const isPassportMode = store.table.isMode('passport')
    const isVisible = !!((isPassportMode && store.table.scheme.passport) || !isPassportMode) && !isViewMode
    const isReport = !!store.table.scheme?.report

    return (
        <div className={style.root} {...rest}>
            <div className={style.row}>
                {isVisible && <ControlAdd classes={{ root: style.item }} />}

                <ControlVisible classes={{ root: style.item }} />
                {isArhiveMode && <ControlArchive classes={{ root: style.item }} />}
                <ControlFilter classes={{ root: style.item }} />
                {isReport && <ControlReport />}
            </div>
            <div className={style.row}>
                {/* <ControlSort classes={{ root: style.item }} /> */}
                {/* <ControlSearch classes={{ root: style.item }} /> */}
            </div>
        </div>
    )
})
