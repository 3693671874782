import React from 'react'
import { Box, LoadingOverlay, SimpleGrid } from '@mantine/core'
import { store } from '../../carstock.store'
import { observer } from 'mobx-react-lite'
import { GridState } from '../grid-state'
import { GridSort } from '../grid-sort'
import style from './grid.style.scss'
import { GridTitle } from '../grid-title'
import { Empty } from '../empty'
import { CarCard } from '../car-card'

interface GridProps {}

export const Grid: React.FC<GridProps> = observer(({ ...rest }) => {
    const isEmpty = !store.data.cars.length

    const cols = isEmpty ? 1 : 3

    return (
        <Box {...rest} mt={100}>
            <GridTitle />
            <Box className={style.sort}>
                <GridState />
                <GridSort />
            </Box>
            <SimpleGrid cols={cols} spacing={24} verticalSpacing={40} className={style.grid}>
                <LoadingOverlay
                    loaderProps={{ variant: 'bars' }}
                    visible={store.state.searching}
                    overlayOpacity={0}
                    radius={24}
                />

                {store.data.cars.map((car) => (
                    <CarCard key={car.data.id} car={car} />
                ))}

                {isEmpty && !store.state.searching && <Empty />}
            </SimpleGrid>
        </Box>
    )
})
