import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'

export type CarstockFavoriteAddRequest = {
    data: {
        offer_id: {
            id: number
        }
    }[]
}

export type CarstockFavoriteAddResponse = {
    success: boolean
}

export const CarstockFavoriteAdd = async (req: CarstockFavoriteAddRequest) => {
    try {
        const response = await apiConfig.fetch.ilsa<CarstockFavoriteAddResponse>({
            url: '/ilsa/favorites',
            config: {
                method: 'POST',
                data: req.data,
            },
            mock: {
                enable: false,
                data: {
                    success: true,
                },
            },
        })

        if (!response?.data?.success) {
            throw Error('Произошла ошибка при добавление в избранное')
        }

        return response
    } catch (err: any) {
        throwAdapterError(err, 'CarstockFavoriteAdd')
    }
}
