import { service } from '@/api'
import { CatalogCarGradeModel, CatalogWidgetCoreProps } from '@/widget'
import { useNavigate } from 'react-router-dom'

type UseCatalogPageProps = {
    widget: CatalogWidgetCoreProps['widget']
}

export const useCatalogPage = (props: UseCatalogPageProps) => {
    const { widget } = props
    const navigate = useNavigate()

    // const handleClickGrade = (grade: CatalogCarGradeModel) => {
    //     navigate('/catalog/complectation/' + grade.data.id)
    // }

    const coreProps: CatalogWidgetCoreProps = {
        widget,
        service: {
            filters: { request: service.catalog.filters, controller: service.catalog.controller.filters },
            filtersGroups: {
                request: service.catalog.filters_groups,
                controller: service.catalog.controller.filters_groups,
            },
            models: { request: service.catalog.models, controller: service.catalog.controller.models },
            modifications: {
                request: service.catalog.modifications,
                controller: service.catalog.controller.modifications,
            },
            options: {
                request: service.catalog.options,
                controller: service.catalog.controller.filter_options,
            },
        },
        params: {
            load: true,
            // onClickCarGrade: handleClickGrade,
        },
        data: {
            modes: new Map([
                [
                    'models',
                    {
                        enabled: true,
                        value: 'models',
                    },
                ],
                [
                    'modifications',
                    {
                        enabled: false,
                        value: 'modifications',
                    },
                ],
            ]),
            sorts: new Map([
                [
                    'asc',
                    {
                        id: 'rrp_asc',
                        enabled: true,
                        param: 'rrp',
                        value: 'asc',
                        label: 'По возрастанию цены',
                    },
                ],
                [
                    'desc',
                    {
                        id: 'rrp_desc',
                        enabled: false,
                        param: 'rrp',
                        value: 'desc',
                        label: 'По убыванию цены',
                    },
                ],
            ]),
            tabs: new Map([
                [
                    'all',
                    {
                        id: 'all',
                        param: 'is_archival',
                        enabled: false,
                        value: null,
                        label: 'Все',
                    },
                ],
                [
                    'active',
                    {
                        id: 'active',
                        param: 'is_archival',
                        enabled: true,
                        value: false,
                        label: 'Активные',
                    },
                ],
                [
                    'archive',
                    {
                        id: 'archive',
                        param: 'is_archival',
                        enabled: false,
                        value: true,
                        label: 'Архивные',
                    },
                ],
            ]),
        },
    }

    return { coreProps }
}
