import React, { useRef, useState } from 'react'
import DocumentsStore from '../../store/DocumentsStore'
import styles from './Download.style.scss'

function ext(name) {
    const m = name.match(/\.([^.]+)$/)
    return m && m[1]
}

const Download = () => {
    const { getDocuments, setDocument, setDocumentStatus, uploadFile } = DocumentsStore
    const filePicker = useRef(null)
    const [selectedFile, setSelectedFile] = useState(null)

    const handleFilePicker = () => {
        filePicker.current.click()
    }

    const handleSelectFile = (event) => {
        setSelectedFile(event.target.files[0])
    }

    const handleFileNameChange = (event) => {
        const file = new File([selectedFile], `${event.target.value}.${ext(selectedFile.name)}`)

        setSelectedFile(file)
    }

    const handleUploadFile = async () => {
        const formData = new FormData()

        formData.append('document', selectedFile)

        setSelectedFile(null)

        setDocument({
            created_at: new Date(selectedFile.lastModified),
            filename: selectedFile.name,
            size: {
                value: selectedFile.size,
                human: `${(selectedFile.size / 1e6).toFixed(1)} Мб`,
            },
            id: `${selectedFile.lastModified}/${selectedFile.size}`,
            extension: ext(selectedFile.name),
            status: {
                name: 'uploaded',
                title: 'Идет загрузка',
            },
            updated_at: new Date(),
        })

        uploadFile(formData).then((res) => {
            setSelectedFile(null)
            if (res) getDocuments()
            else setDocumentStatus(`${selectedFile.lastModified}/${selectedFile.size}`)
        })
    }

    return (
        <div className={styles.container}>
            <div className={styles.panel}>
                <div>
                    <svg width='53' height='69' viewBox='0 0 53 69' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <g opacity='0.2'>
                            <path
                                d='M0.00597993 34.496C0.00597993 26.6518 -0.00747491 18.7942 0.00597993 10.95C0.00597993 6.13317 2.76422 2.15053 7.20432 0.670498C8.33453 0.293762 9.57237 0.105394 10.7699 0.0919396C17.6722 0.0381202 24.5745 0.0784848 31.4903 0.0650299C33.374 0.0650299 34.8809 0.939595 36.2802 2.0698C37.0875 2.71563 37.8679 3.38838 38.6079 4.11494C42.3618 7.86884 46.1292 11.6227 49.8427 15.4305C51.0133 16.6279 52.1031 17.9465 52.6279 19.588C52.8432 20.2338 52.9777 20.9469 52.9777 21.6197C53.0046 33.7694 53.0046 45.9326 52.9912 58.0823C52.9777 64.2985 48.3492 68.9135 42.1331 68.9269C31.7325 68.9404 21.3185 68.9404 10.9179 68.9269C4.66136 68.9269 0.0328896 64.2985 0.0328896 58.042C0.00597993 50.1978 0.00597993 42.3536 0.00597993 34.496ZM5.26682 34.4287C5.26682 42.1922 5.26682 49.9421 5.28028 57.7056C5.28028 58.3514 5.32064 59.0107 5.4821 59.6296C6.1683 62.1726 8.15962 63.6661 10.9044 63.6661C21.305 63.6795 31.719 63.6795 42.1196 63.6661C43.3979 63.6661 44.5953 63.3432 45.6314 62.5359C47.1921 61.3115 47.7438 59.67 47.7438 57.746C47.7303 46.6726 47.7438 35.5858 47.7438 24.5125C47.7438 24.2434 47.7303 23.9743 47.7034 23.7052C47.5285 22.3732 46.9903 21.7542 45.6986 21.4582C45.2008 21.3371 44.6761 21.2833 44.1648 21.2699C42.5771 21.2295 40.976 21.216 39.3883 21.2026C37.8006 21.1891 36.1995 21.216 34.6118 21.1891C33.2529 21.1757 32.2707 20.476 31.9612 19.3324C31.8536 18.9018 31.8267 18.4443 31.8267 18.0003C31.7998 14.8115 31.8132 11.6362 31.7594 8.4474C31.746 7.82848 31.598 7.1961 31.3827 6.61754C31.0867 5.79679 30.4543 5.28551 29.5528 5.28551C23.3232 5.28551 17.0802 5.27206 10.8506 5.29896C9.02073 5.29896 7.48687 5.98516 6.37012 7.4652C5.52247 8.56849 5.29373 9.8467 5.29373 11.2056C5.26682 18.9556 5.26682 26.6922 5.26682 34.4287Z'
                                fill='#BFC5CE'
                            />
                            <path
                                d='M26.3506 31.8184C22.045 31.8184 17.7529 31.8318 13.4474 31.8184C11.8866 31.8184 10.7295 30.7958 10.6488 29.41C10.5546 27.8357 11.6983 26.6517 13.3801 26.5979C14.2143 26.571 15.0351 26.5979 15.8693 26.5979C23.6865 26.5979 31.5038 26.5979 39.3211 26.5979C40.8146 26.5979 41.716 27.163 42.1735 28.3739C42.6848 29.7194 41.8371 31.3609 40.4378 31.6973C40.0342 31.7915 39.5902 31.8318 39.1731 31.8318C34.9079 31.8184 30.6292 31.8184 26.3506 31.8184Z'
                                fill='#BFC5CE'
                            />
                            <path
                                d='M26.5793 47.7759C30.831 47.7759 35.0962 47.7893 39.3479 47.7759C40.5051 47.7759 41.4873 48.1123 42.0389 49.1886C42.9673 51.0185 41.7564 52.9022 39.5901 53.0367C39.4825 53.0502 39.3614 53.0367 39.2538 53.0367C30.7368 53.0367 22.2199 53.0367 13.7165 53.0367C12.223 53.0367 11.2946 52.4716 10.8237 51.301C10.2451 49.8479 11.2004 48.1257 12.7343 47.8566C13.111 47.7893 13.4877 47.7759 13.8779 47.7759C18.1027 47.7759 22.341 47.7759 26.5793 47.7759Z'
                                fill='#BFC5CE'
                            />
                            <path
                                d='M23.9422 37.187C27.3866 37.187 30.8445 37.1735 34.289 37.2004C36.3206 37.2139 37.5719 39.0303 36.805 40.8601C36.3206 42.0038 35.3384 42.4343 34.1544 42.4343C31.3693 42.4343 28.5976 42.4343 25.8124 42.4343C21.7087 42.4343 17.6049 42.4343 13.5147 42.4343C11.9405 42.4343 10.7699 41.4521 10.6488 40.0797C10.5142 38.4652 11.6579 37.2273 13.3801 37.2139C15.6002 37.187 17.8202 37.2004 20.0403 37.2004C21.3454 37.187 22.6505 37.187 23.9422 37.187Z'
                                fill='#BFC5CE'
                            />
                        </g>
                    </svg>
                </div>
                <div className={styles.info}>
                    {selectedFile ? (
                        <>
                            <div className={styles.editing}>
                                <input
                                    className={styles.input}
                                    type='text'
                                    placeholder='Введите название КП'
                                    onChange={handleFileNameChange}
                                />
                                <div className={styles.fileName}>
                                    <svg
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <path
                                            d='M12.3692 5.96346L16.8553 13.7336C17.7174 15.2268 17.2062 17.1345 15.713 17.9966C14.2198 18.8587 12.3121 18.3475 11.45 16.8543L6.57383 8.40855C6.03551 7.47613 6.35534 6.28249 7.28776 5.74416C8.22017 5.20583 9.41382 5.52566 9.95215 6.45808L14.0481 13.5525C14.2627 13.9241 14.1342 14.4037 13.7626 14.6183C13.3909 14.8328 12.9114 14.7043 12.6968 14.3327L8.99092 7.91393L7.97742 8.49907L11.6833 14.9179C12.2216 15.8503 13.4153 16.1701 14.3477 15.6318C15.2801 15.0935 15.6 13.8998 15.0616 12.9674L10.9656 5.87294C10.1035 4.37972 8.19583 3.86855 6.70262 4.73066C5.2094 5.59277 4.69823 7.50047 5.56034 8.99369L10.4365 17.4395C11.6224 19.4935 14.2442 20.196 16.2982 19.0101C18.3522 17.8242 19.0547 15.2024 17.8688 13.1484L13.3827 5.37832L12.3692 5.96346Z'
                                            fill='#788196'
                                        />
                                    </svg>
                                    <span>{selectedFile.name}</span>
                                </div>
                            </div>
                            <button className={styles.button} onClick={handleUploadFile}>
                                Загрузить
                            </button>
                        </>
                    ) : (
                        <>
                            <div className={styles.wrapper}>
                                <div>Нажмите на кнопку чтобы выбрать и загрузить коммерческое предложение</div>
                                <div className={styles.prompt}>
                                    Поддерживаются файлы в формате pdf, doc, docx, jpg, jpeg, png.
                                </div>
                            </div>
                            <input
                                className={styles.hidden}
                                type='file'
                                accept='.pdf, .jpg, .jpeg, .png, .docx, .doc, .xlsx'
                                ref={filePicker}
                                onChange={handleSelectFile}
                            />
                            <button className={styles.button} onClick={handleFilePicker}>
                                Загрузить коммерческое предложение
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Download
