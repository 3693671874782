import React from 'react'
import {
    Box,
    createStyles,
    LoadingOverlay,
    Paper,
    ScrollArea,
    Table,
    Text,
    Stack,
    Group,
    ActionIcon,
    Tooltip,
} from '@mantine/core'
import { IconDatabaseOff, IconRotateClockwise2, IconDeviceFloppy } from '@tabler/icons-react'
import { EditTableDialog } from './dialog'
import { EditTableCell } from './cell'
import { clone } from '@/shared'

export interface EditTableData {
    title: string
    value: string
    months: {
        [property: string]: {
            id: string
            value: string
        }
    }
}

export interface EditTableProps {
    data: EditTableData[]
    refData: ReactRef<EditTableData[]>
    visibleLoader: boolean
    setVisibleLoader: FunctionType<boolean>
    handleSetData: FunctionType
    handleSaveData: FunctionType
}

const useStyles = createStyles(() => ({
    root: {},
}))

export const EditTable: React.FC<EditTableProps> = ({
    data,
    visibleLoader,
    setVisibleLoader,
    handleSetData,
    handleSaveData,
    refData,
    ...rest
}) => {
    const { classes } = useStyles()
    const [opened, setOpened] = React.useState(false)
    const [reset, setReset] = React.useState(0)

    const handleEdit =
        ({ year, month }) =>
        (value: string) => {
            const newData = clone(refData.current).map((item) => {
                if (item.value === year) {
                    item.months[month] = {
                        ...item.months[month],
                        value: value || '0.0',
                    }
                }

                return item
            })

            refData.current = newData

            setOpened(true)
        }

    const handleCancel = () => {
        setVisibleLoader(false)
        setOpened(false)
        setReset((v) => v + 1)
        handleSetData(true)
    }

    const handleSave = async () => {
        setVisibleLoader(true)
        setOpened(false)

        await handleSaveData()
    }

    const handleRefresh = () => {
        setOpened(false)
        handleSetData()
    }

    /** Сброс редактирования */
    React.useEffect(() => {
        setReset((v) => v + 1)
    }, [data])

    return (
        <Paper sx={{ position: 'relative' }}>
            <ScrollArea
                sx={{
                    '& [data-radix-scroll-area-viewport]': {
                        padding: 2,
                    },
                }}
            >
                <Table className={classes.root} verticalSpacing={10} horizontalSpacing={20} withColumnBorders {...rest}>
                    <Box component='caption' p={12} style={{ fontSize: 15 }}>
                        <Group>
                            <Text>Введите остаточные стоимости мофицикации </Text>
                            <Group ml='auto' sx={{ position: 'absolute', right: 10 }}>
                                <Tooltip label='Обновить' position='top' withArrow sx={{ fontSize: 12 }}>
                                    <ActionIcon variant='default' onClick={handleRefresh} color='blue.4' size={36}>
                                        <IconRotateClockwise2 size={16} />
                                    </ActionIcon>
                                </Tooltip>
                                <Tooltip label='Сохранить' position='top' withArrow sx={{ fontSize: 12 }}>
                                    <ActionIcon variant='default' onClick={handleSave} color='blue.4' size={36}>
                                        <IconDeviceFloppy size={16} />
                                    </ActionIcon>
                                </Tooltip>
                                {/* <TextInput
                                    placeholder='Поиск по значениям'
                                    icon={<IconSearch size={14} stroke={1.5} />}
                                    // value={search}
                                    // onChange={handleSearchChange}
                                /> */}
                            </Group>
                        </Group>
                    </Box>
                    <thead>
                        <tr>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => (
                                <Box
                                    key={month}
                                    component='th'
                                    sx={{ minWidth: 60, textAlign: 'center !important' as any }}
                                >
                                    {month}
                                </Box>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.length ? (
                            data.map((item, i) => (
                                <React.Fragment key={i}>
                                    <tr>
                                        <td colSpan={12} align='center'>
                                            {item.title}
                                        </td>
                                    </tr>
                                    <tr>
                                        {Object.values(item.months).map((month, i) => (
                                            <EditTableCell
                                                key={i}
                                                handleEdit={handleEdit({
                                                    year: item.value,
                                                    month: Object.keys(item.months)[i],
                                                })}
                                                value={month.value}
                                                reset={reset}
                                            />
                                        ))}
                                    </tr>
                                </React.Fragment>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={12}>
                                    <Stack p={12} spacing='xs' align={'center'}>
                                        <Box
                                            sx={{
                                                padding: 10,
                                                borderRadius: '50%',
                                                fontSize: 0,
                                                backgroundColor: 'rgb(233, 236, 239)',
                                            }}
                                        >
                                            <IconDatabaseOff color='rgb(134, 142, 150)' />
                                        </Box>
                                        <Text color='rgb(134, 142, 150)'>Записей нет</Text>
                                    </Stack>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </ScrollArea>

            <LoadingOverlay visible={visibleLoader} overlayBlur={0.5} transitionDuration={100} />
            <EditTableDialog opened={opened} setOpened={setOpened} onCancel={handleCancel} onSave={handleSave} />
        </Paper>
    )
}
