import { toJS } from 'mobx'
import { showToast, showToastError } from '@/shared/vendors/mantine'
import { array, tojs } from '@/shared'
import { BaseModel } from '@/shared/core/model'

type Value = {
    id: any
    value: string
    label?: string
    type?: string
    items?: {
        id: any
        value: string
        label: string
        type?: string
        __group?: {
            id: any
            value: string
            label: string
            type?: string
        }
    }[]
    __group?: {
        id: any
        value: string
        label: string
        type?: string
    }
}

type Param = {
    name: any
    units?: string
}

type Group = {
    id: number
    name: string
    sort_order: number
}

type ModelProps = {
    id: any
    label: string

    params: Param[]
    placeholders: string[]

    value: Value[]
    values: Value[]
    defaultValues: Value[]

    depends_on: any[]
    depends_for: any[]

    group_id: number
    sort_order: number
    type:
        | 'select'
        | 'multi-select'
        | 'range-select'
        | 'group-select'
        | 'range-integer'
        | 'string'
        | 'switch'
        | 'bool-select'
        | 'universal-options'
        | 'specific-options'

    __type?: Value
    __types?: Value[]
    __group?: Group
    __searchParam?: string[]
    __service?: ModelService
    __options?: {
        hidden?: boolean
        dotsValue?: boolean
        writable?: boolean
    }
    __filter_type_id?: number
}

type ModelService = {
    loadValues?: (props: {
        name: string
        type: number
        filters?: {
            [property: string]: number
        }
    }) => Promise<ModelProps['values']>
}

export class TableFilterModel extends BaseModel<ModelProps, TableFilterModel, ModelService> {
    constructor(props: ModelProps) {
        super(props, TableFilterModel)
    }

    async loadValues(filters?: ObjectType) {
        try {
            if (this.data.defaultValues?.length) {
                if (!this.state.loaded) {
                    this.setState({ loaded: true })
                }

                return this.data.defaultValues
            }

            if (this.state.loaded && this.data.values.length) {
                return this.data.values
            }

            if (!this.__service.loadValues && !this.state.loaded) return

            this.setState({ loading: true })

            const values = await this.__service.loadValues({
                name: this.get.type(),
                type: this.data.__filter_type_id,
                filters,
            })

            /** Временно режим для оптимизации */
            this.setData({
                // values: values.slice(0, 150),
                // value: [],
                values: values,
                type: this.data.type === 'multi-select' && values.length > 500 ? 'select' : this.data.type,
            })

            if (values.length < 1) {
                showToast({ message: 'Список параметров пуст!', color: 'yellow.6' })
                this.setState({ loading: false, loaded: false })
            } else {
                this.setState({ loading: false, loaded: true })
            }

            return values
        } catch (err) {
            console.log(err)
            this.setState({ loading: false, loaded: true })
            showToastError({ message: 'Часть параметров не удалось загрузить!' })
        }
    }

    reset() {
        this.setData({ value: [], values: [] })
        this.setState({ loaded: false })
    }

    getValueByValue(value: string | string[]) {
        if (value && typeof value === 'string') {
            const currentValue = this.get.values().find((currentValue) => currentValue.value === value)

            return [currentValue]
        } else if (value && typeof value === 'object') {
            const currentValue = this.get.values().filter((currentValue) => value.includes(currentValue.value))

            return currentValue
        }

        return []
    }

    getGroupValueByValue(value: string[], isGroup?: boolean) {
        if (!isGroup) {
            let items = []

            this.get.values().forEach((item) => {
                // const filtered = group.items.filter((item) => {
                //     return value.includes(item.value)
                // })

                if (value.includes(item.value)) {
                    /** Отсекаем выбор группы с схожим лейблом у item */
                    // if ((item as Value['items'][0]).__group.label !== value[0]) {
                    items = items.concat([item])
                    // } else {
                    //     items_group.push(item)
                    // }
                }
            })

            return items
        }

        let currentValues = tojs(this.data.value)
        const group = this.get.values().find((item) => item.__group.label === value[0]).__group

        let countByGroup = 0
        let existCountByGroup = 0

        const valuesByGroup = this.get.values().filter((item) => {
            const isExist = item.__group.label === group.label

            if (isExist) {
                const isExistCurrent = currentValues.find((curr) => curr.id === item.id)

                countByGroup += 1

                if (!isExistCurrent) {
                    currentValues.push(item)
                } else {
                    existCountByGroup += 1
                }
            }

            return isExist
        })

        if (countByGroup > 1 && countByGroup === existCountByGroup) {
            currentValues = currentValues.filter((item) => {
                return !valuesByGroup.find((curr) => curr.id === item.id)
            })
        }

        return currentValues
    }

    getTypeValueByValue(value: string) {
        const curr = this.get.types().find((curr) => curr.value === value)

        return curr
    }

    getRangeValueByValue(value: string[]) {
        const currentValue = value.map((value) => {
            if (value === null) return null

            const currentValue = this.get.values().find((currentValue) => currentValue.value === value)

            return currentValue
        })

        return currentValue
    }

    getRangeIntegerValue(value: string[]) {
        const currentValue = value.map((value) => {
            if (value === null) return null

            const currentValue: ModelProps['value'][0] = {
                id: value as unknown as number,
                value: value,
                label: value,
            }

            return currentValue
        })

        return currentValue
    }

    getValueById(id: any) {
        const currentValue = this.get.values().find((currentValue) => currentValue.id === id)

        return currentValue
    }

    set = {
        string: (value: string) => {
            if (value) {
                this.setData({
                    value: [{ id: Math.floor(Math.random() * 9999) + 999, value: value, label: value }],
                })
            } else {
                this.setData({ value: [] })
            }
        },
        //TODO (Для все set) Пробрасывать значения не как string а как полноценный Value
        value: (value: string) => {
            if (value) {
                const [newValue] = this.getValueByValue(value)

                if (newValue) {
                    return this.setData({ value: [newValue] })
                }
            }

            this.setData({ value: [] })
        },
        multiValue: (value: string[]) => {
            const newValue = this.getValueByValue(value)

            if (!value && !value.length) {
                return this.setData({ value: [] })
            }

            this.setData({ value: newValue })
        },
        groupValue: (value: string[], isGroup?: boolean) => {
            const newValue = this.getGroupValueByValue(value, isGroup)

            if (!value && !value.length) {
                return this.setData({ value: [] })
            }

            this.setData({ value: newValue })
        },
        rangeValue: (value: string[]) => {
            const newValue = this.getRangeValueByValue(value)

            this.setData({ value: newValue })
        },
        rangeIntegerValue: (value: string[]) => {
            const newValue = this.getRangeIntegerValue(value)

            this.setData({ value: newValue })
        },
        switchValue: (value: string) => {
            if (value) {
                const [newValue] = this.getValueByValue(value)

                if (newValue) {
                    return this.setData({ value: [newValue] })
                }
            }

            this.setData({ value: [] })
        },
        type: (value: string) => {
            const newType = this.getTypeValueByValue(value)

            if (newType) {
                this.setData({ values: [], __type: newType })
            } else {
                this.setData({ __type: null })
            }
        },
    }

    get = {
        value: (val?: ModelProps['values']) => {
            const value = toJS(val || this.data.value)
            // const typeHandler = this.typeHandlers[this.data.type_value?.typeHandler]

            const newValue: ModelProps['values'] = value.map((value) => {
                // if (typeHandler) {
                //     return typeHandler(value)
                // }

                return value
            })

            return array.uniqueByKey(newValue, 'value')
        },
        values: (): Value[] => {
            // if (this.data.type === 'group-select') {
            //     const currentGroups = this.get.groups()

            //     const values = currentGroups.reduce((acc, curr) => {
            //         return acc.concat(curr.values)
            //     }, [])

            //     return values
            // } else {
            const currentValues = toJS(this.data.values)
            const defaultValues = toJS(this.data.defaultValues)

            const values = defaultValues
                .concat(currentValues)
                .filter((value) => value.id !== null && value.id !== undefined)

            /** Если нет значений в списке, то пробуем подставить текущие значения */
            // const valuesFromValue = tojs(this.data.value)

            const unique: ModelProps['values'] = array.uniqueByKey(values, 'id')

            const format = this.get.value(unique)

            if (this.data.type === 'group-select') {
                return format.reduce((arr, curr) => {
                    const group = {
                        id: curr.id,
                        label: curr.label,
                        value: curr.value,
                    }

                    curr.items.forEach((item) => {
                        item.__group = group
                    })

                    return arr.concat(curr.items)
                }, [])
            }

            return format
            // }
        },
        singleValue: () => {
            return this.data.value.length ? this.data.value[0].value : null
        },
        rangleValue: () => {
            return this.data.value.length ? this.data.value : []
        },
        switchValue: () => {
            return this.data.value.length ? this.data.value[0].value : this.get.values()[0]?.value || null
        },
        types: () => {
            return this.data.__types.length > 1 ? this.data.__types : []
        },
        type: () => {
            return this.data.__type?.value || null
        },
    }
}
