import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'
import { CatalogFilter } from './filters.types'
import { CatalogFilterModel } from '@/widget/catalog/models'
import { CatalogFilterValues } from '../values'

type CatalogFiltersRequest = {
    params: {
        vehicle_type_id: number
        filter_group_id?: number
    }
}

type CatalogFiltersResponse = {
    data: CatalogFilter[]
}

export const CatalogFiltersController = { ref: null }

export const CatalogFilterFilters = async (req: CatalogFiltersRequest) => {
    CatalogFiltersController.ref = new AbortController()

    try {
        const response = await apiConfig.fetch.ilsa<CatalogFiltersResponse>({
            url: '/interface-builder/catalog/filters',
            config: {
                signal: CatalogFiltersController.ref.signal,
                params: req.params,
                cache: true,
                cacheTimeout: false,
            },
        })

        const filters = response.data.data.map((filter, i) => {
            const props = {
                id: filter.id,
                label: filter.label,

                params: filter.params,
                placeholders: filter.placeholders,

                value: [],
                values: (filter.values || []).map((value) => ({
                    ...value,
                    label: value.value,
                    items: (value.items || []).map((value) => ({
                        ...value,
                        label: value.value,
                    })),
                })),
                defaultValues: (filter.defaultValues || []).map((value) => ({
                    ...value,
                    label: value.value,
                    items: (value.items || []).map((value) => ({
                        ...value,
                        label: value.value,
                    })),
                })),

                depends_on: filter.depends_on,
                depends_for: filter.depends_for,

                group_id: filter.group_id,
                sort_order: filter.id,
                type: filter.type as any,

                __vehicle_type_id: req.params.vehicle_type_id,
                __type: {
                    id: filter.params[0].name as any,
                    value: filter.params[0].name,
                    label: filter.params[0].units,
                },
                __types: filter.params.map((param) => ({
                    id: param.name as any,
                    value: param.name,
                    label: param.units,
                })),
                __service: {
                    loadValues: CatalogFilterValues,
                },
            }

            if (props.type === 'switch' && props.defaultValues.length) {
                props.defaultValues = props.defaultValues.map((item) => ({ ...item, value: item.id }))
                // props.value = [props.defaultValues[0]]
            }

            return new CatalogFilterModel(props)
        })

        return filters
    } catch (err: any) {
        throwAdapterError(err, 'CatalogFilterFilters')
    }
}
