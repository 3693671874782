import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'
import { PackageType } from './complectation-packages.types'
import { ComplectationPackageModel } from '@/widget/complectation/models'

type CatalogComplectationPackagesRequest = {
    data: {
        id: string
    }
}

type CatalogComplectationPackagesResponse = {
    data: PackageType[]
}

export const CatalogComplectationPackagesController = { ref: null }

export const CatalogComplectationPackages = async (req: CatalogComplectationPackagesRequest) => {
    CatalogComplectationPackagesController.ref = new AbortController()

    try {
        const response = await apiConfig.fetch.ilsa<CatalogComplectationPackagesResponse>({
            url: '/catalog/complectations/' + req.data.id + '/option-packages',
            config: {
                signal: CatalogComplectationPackagesController.ref.signal,
                cache: true,
            },
        })

        const packages = response.data.data.map(
            (item) =>
                new ComplectationPackageModel({
                    ...item,
                    selected: false,
                })
        )

        return { packages }
    } catch (err: any) {
        throwAdapterError(err, 'CatalogComplectationPackages')
    }
}
