import React from 'react'
import { SimpleGrid, clsx } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { core } from '../../catalog.core'
import style from './filter-tabs.style.scss'
import { store } from '../../catalog.store'

interface FilterTabsProps {}

export const FilterTabs: React.FC<FilterTabsProps> = observer(({ ...rest }) => {
    const body_tabs = store.data.body_tabs

    if (!body_tabs.data?.length) return null

    const handleChange = (event: React.MouseEvent<HTMLDivElement>) => {
        const button = (event.target as HTMLElement).closest<HTMLElement>('.' + style.tab)

        if (button) {
            const id = button.dataset.id

            core.changeBodyTabs(parseInt(id))
        }
    }

    return (
        <div className={style.root} {...rest}>
            <SimpleGrid spacing={24} verticalSpacing={8} cols={4} className={style.grid} onClick={handleChange}>
                {body_tabs.data.map((tab) => (
                    <button
                        key={tab.id}
                        data-id={tab.id}
                        className={clsx(style.tab, { [style.tab_active]: tab.selected })}
                    >
                        {tab.name}
                    </button>
                ))}
            </SimpleGrid>
        </div>
    )
})
