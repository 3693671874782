import React from 'react'
import clsx from 'clsx'
import style from './sub-navigation.style.scss'
import { Container, Tabs } from '@mantine/core'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { routes } from '@/app/app.routes'

type SubNavigationProps = React.HTMLAttributes<HTMLElement> & {}

export const SubNavigation: React.FC<SubNavigationProps> = ({ className, ...rest }) => {
    const navigate = useNavigate()
    const location = useLocation()

    const defaultValue = location.pathname
    const children = routes[defaultValue.split('/')[1]]?.children

    const handleChange = (value: string) => {
        navigate(value)
    }

    return children?.length ? (
        <div className={clsx(style.root, className)} {...rest}>
            <Container>
                <Tabs
                    classNames={{
                        tabsList: style.tabs_list,
                        tab: style.tab,
                    }}
                    variant='default'
                    value={defaultValue}
                    onTabChange={handleChange}
                >
                    <Tabs.List>
                        {/* <Tabs.Tab value='Легковые'>Легковые</Tabs.Tab>
                        <Tabs.Tab value='ЛКТ'>ЛКТ</Tabs.Tab> */}

                        {children.map((route, key) => {
                            if (route && route.visible) {
                                return (
                                    <Link key={key} to={route.path}>
                                        {route.tab}
                                    </Link>
                                )
                            }

                            return null
                        })}
                    </Tabs.List>
                </Tabs>
            </Container>
        </div>
    ) : null
}
