import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'

export type UsersStatusesResponse = {
    data: {
        id: number
        value: string
    }[]
}

export const userStatuses: UsersStatusesResponse['data'] = [
    {
        id: 0,
        value: 'Заблокирован',
    },
    {
        id: 1,
        value: 'Активен',
    },
]

export const UsersStatuses = async () => {
    try {
        const response = await apiConfig.fetch.ilsa<UsersStatusesResponse>({
            url: '/auth/users/statuses',
            mock: {
                enable: true,
                data: {
                    data: userStatuses,
                },
            },
        })

        if (!response?.data?.data?.length) {
            throw Error('Не удалось получить список статусов')
        }

        const statuses = userStatuses.map((status) => ({
            id: status.id,
            value: status.value,
            label: status.value,
        }))

        return statuses
    } catch (err: any) {
        throwAdapterError(err, 'UsersStatuses')
    }
}
