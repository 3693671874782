import React from 'react'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import { Icon } from '@/pages/analytics/components/ui'
import style from './index.style.scss'

interface InputProps {
    onChange: FunctionType
}

export const Input: React.FC<InputProps> = ({ onChange, ...rest }) => {
    return (
        <TextField
            placeholder='Найти'
            size='small'
            fullWidth
            InputProps={{
                endAdornment: (
                    <InputAdornment position='start'>
                        <Icon icon='search' sx={{ fontSize: 20 }} />
                    </InputAdornment>
                ),
            }}
            className={style.root}
            sx={{
                '& .MuiInputBase-adornedEnd': {
                    paddingRight: '0px',
                },
            }}
            onChange={onChange}
            {...rest}
        />
    )
}
