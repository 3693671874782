import React from 'react'
import clsx from 'clsx'
import style from './cell-null.style.scss'
import { TableCell } from '@/widget/table/table.types'

type CellNullProps = React.HTMLAttributes<HTMLTableCellElement> & {
    cell: TableCell
}

export const CellNull: React.FC<CellNullProps> = ({ className, cell, ...rest }) => {
    return (
        <div className={clsx(style.root, className, 'elisa-table-widget-cell')} {...rest}>
            -
        </div>
    )
}
