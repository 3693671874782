import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import { Button, Icon } from '@/pages/analytics/components/ui'
import { Icons } from '@/pages/analytics/components/ui/icon'
import IconButton from '@mui/material/IconButton'
import { Scroll } from '@/theme/@deprecated/components/ui'
import { observer } from 'mobx-react-lite'
import { store } from '@/pages/analytics/store'

interface IVisiblePopoverDataProps {
    classes?: {
        root?: string
    }
    title: string
    icon: Icons
    isVisible?: boolean
    search: string
}

export const VisiblePopoverData: React.FC<IVisiblePopoverDataProps> = observer(
    ({ classes, title, icon, isVisible, search, ...rest }) => {
        const cls = { root: clsx(style.root, classes?.root) }

        const visibleData = React.useMemo(
            () =>
                store.table.columns.filter((column) => {
                    const isExist = !store.table.hiddenColumns.includes(column.id)
                    const isSearchExist = search.length
                        ? column.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                        : true

                    return isSearchExist && isExist
                }),
            [store.table.columns, store.table.hiddenColumns, search]
        )
        const hiddenData = React.useMemo(
            () =>
                store.table.columns.filter((column) => {
                    const isExist = store.table.hiddenColumns.includes(column.id)
                    const isSearchExist = search.length
                        ? column.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                        : true

                    return isSearchExist && isExist
                }),
            [store.table.columns, store.table.hiddenColumns, search]
        )
        const data = isVisible ? visibleData : hiddenData
        const RowTag = isVisible ? /* ButtonBase */ 'div' : 'div'
        const ButtonLabel = isVisible ? 'Скрыть все' : 'Показать все'

        const handleChange = (id: string) => (e) => {
            e.stopPropagation()

            const newHiddenColumns = isVisible
                ? [...store.table.hiddenColumns, id]
                : store.table.hiddenColumns.filter((hiddenId) => hiddenId !== id)

            store.table.hiddenColumns = newHiddenColumns
        }

        const handleChangeAll = () => {
            const newHiddenColumns = isVisible
                ? [
                      ...store.table.hiddenColumns,
                      ...visibleData.filter((column) => column.id !== 'id').map((column) => column.id),
                  ]
                : []

            store.table.hiddenColumns = newHiddenColumns
        }

        return (
            <>
                {(isVisible && visibleData.length) || (!isVisible && hiddenData.length) ? (
                    <div className={cls.root} {...rest}>
                        <div className={style.header}>
                            <div className={style.title}>{title}</div>
                            <Button variant='text' className={style.reset} onClick={handleChangeAll}>
                                {ButtonLabel}
                            </Button>
                        </div>
                        <div className={style.rows}>
                            <Scroll height={216}>
                                {data.map((column, i) => {
                                    if (column.id !== 'id') {
                                        return (
                                            <div key={i} className={style.row}>
                                                <RowTag className={style.content}>
                                                    {/* {isVisible ? (
                                                    <div className={style.drag}>
                                                        <Icon icon='drag' sx={{ fontSize: 20 }} />
                                                    </div>
                                                ) : null} */}

                                                    <div className={style.column}>{column.label}</div>
                                                </RowTag>
                                                <IconButton className={style.icon} onClick={handleChange(column.id)}>
                                                    <Icon
                                                        icon={icon}
                                                        sx={{ fontSize: 20, opacity: isVisible ? 1 : 0.45 }}
                                                    />
                                                </IconButton>
                                            </div>
                                        )
                                    }
                                })}
                            </Scroll>
                        </div>
                    </div>
                ) : null}
            </>
        )
    }
)
