export const sortAlphabetByKey = <T>(arr: T[], key: keyof T, type: 'asc' | 'desc') => {
    const arrSort = arr.sort((a, b) => {
        if (a[key] > b[key]) return -1
        else if (b[key] > a[key]) return 1
        else return 0
    })

    if (type === 'asc') {
        arrSort.reverse()
    }

    return arrSort
}

export const sortAlphabetByKeyTitle = <T>(arr: T[], key: keyof T, type: 'asc' | 'desc') => {
    const arrSort = arr.sort((a, b) => {
        if ((a[key] as any).title > (b[key] as any).title) return -1
        else if ((b[key] as any).title > (a[key] as any).title) return 1
        else return 0
    })

    if (type === 'asc') {
        arrSort.reverse()
    }

    return arrSort
}

export const sortArrayObjectsByKey = <T>(arr: T[], key: string) =>
    arr.sort(function (a, b) {
        var textA = String(a[key]).toLowerCase()
        var textB = String(b[key]).toLowerCase()

        return textA < textB ? -1 : textA > textB ? 1 : 0
    })

export const sortArray = <T extends string>(arr: T[]) =>
    arr.sort(function (a, b) {
        var textA = String(a).toLowerCase()
        var textB = String(b).toLowerCase()

        return textA < textB ? -1 : textA > textB ? 1 : 0
    })

export const convertArrayToObject = (arr: string[], value?) => {
    return arr.reduce((acc, curr) => {
        acc[curr as any] = value || []
        return acc
    }, {})
}

export const sortString = (str: string) => {
    return str.split('').sort(function (a, b) {
        var textA = String(a).toLowerCase()
        var textB = String(b).toLowerCase()

        return textA < textB ? -1 : textA > textB ? 1 : 0
    })
}

export const compareString = (str1: string, str2: string) => {
    const sortStr1 = sortString(str1 || '')
    const sortStr2 = sortString(str2 || '')

    return sortStr1 === sortStr2
}
