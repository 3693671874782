import { toJS } from 'mobx'
import { showToast, showToastError } from '@/shared/vendors/mantine'
import { array, tojs } from '@/shared'
import { BaseModel } from '@/shared/core/model'

type Value = {
    id: number | string
    value: string
    label: string

    type?: string
    typeHandler?: 'hp_to_kw' | 'liters_to_cm3'

    group?: Omit<Group, 'values'>
}

type Group = {
    id: number
    value: string
    label: string
    type: string
    values: Value[]
}

type ModelProps = {
    id: string
    name: string

    param: string[]
    label: string[]

    category: string
    type: 'select' | 'multi-select' | 'range-select' | 'group-select' | 'string'

    defaultValues: Value[]
    values: Value[]
    value: Value[]
    groups?: Group[]

    depends_on: string[]
    depends_for: string[]

    type_value?: Value
    types_value?: Value[]

    options?: {
        hidden?: boolean
        dotsValue?: boolean
        writable?: boolean
    }
    searchParam?: string[]

    meta?: ObjectType

    service?: ModelService
}

type ModelService = {
    values(props: any): Promise<ModelProps['values'] | ModelProps['groups']>
}

/**
 * @todo Перенести логику сохранения range type в url из widget.catalog
 */
export class CarstockFilterModel extends BaseModel<ModelProps, CarstockFilterModel, ModelService> {
    constructor(props: ModelProps) {
        super(props, CarstockFilterModel)
    }

    async loadValues(filters?: ObjectType, callback?: () => void) {
        try {
            if (this.data.defaultValues?.length) {
                if (!this.state.loaded) {
                    this.setState({ loaded: true })
                }
            }

            if (
                this.state.loaded &&
                (this.data.values.length || this.data.groups.length || this.data.defaultValues?.length)
            ) {
                return this.data.defaultValues?.length
                    ? this.data.defaultValues
                    : this.data.values.length
                    ? this.data.values
                    : this.data.groups
            }

            this.setState({ loading: true })

            const isGroup = this.data.type === 'group-select'

            const values = await this.service.values({
                id: this.data.id,
                filters,
                isGroup: isGroup,
            })

            if (isGroup) {
                this.setData({
                    groups: values as ModelProps['groups'],
                })
            } else {
                this.setData({
                    values,
                })
            }

            if (values.length < 1) {
                showToast({ message: 'Список параметров пуст!', color: 'yellow.6' })
                this.setState({ loading: false, loaded: false })
            } else {
                this.setState({ loading: false, loaded: true })
            }

            callback?.()

            return values
        } catch (err) {
            console.log(err)
            this.setState({ loading: false, loaded: false })
            showToastError({ message: 'Часть параметров не удалось загрузить!' })
        }
    }

    reset() {
        this.setData({ value: [], values: [], groups: [] })
        this.setState({ loaded: false })
    }

    /**
     * TODO: Переделать на поиск по label/id
     */
    getValueByValue(value: string | string[]) {
        if (value && (typeof value === 'string' || typeof value === 'number')) {
            const currentValue = this.get
                .values()
                .find((currentValue) => currentValue.value === value || currentValue.id === value)

            return [currentValue]
        } else if (value && typeof value === 'object') {
            const currentValue = this.get
                .values()
                .filter((currentValue) => value.includes(currentValue.value) || value.includes(currentValue.id))

            return currentValue
        }

        return []
    }

    getTypeValueByValue(value: string) {
        const currentType = this.get
            .types()
            .find((currentType) => currentType.value === value || currentType.id === value)

        return currentType
    }

    getRangeValueByValue(value: string | string[]) {
        const normal_value = typeof value === 'string' ? value.split('..') : value

        if (this.data.options?.writable) return this.getRangeValueWritableByValue(normal_value)

        const currentValue = normal_value.map((value) => {
            if (value === null) return null

            const currentValue = this.get
                .values()
                .find((currentValue) => currentValue.value === value || currentValue.id === value)

            return currentValue
        })

        return currentValue
    }

    getRangeValueWritableByValue(value: string[]) {
        const currentValue = value.map((value) => {
            if (value === null) return null

            const currentValue: ModelProps['value'][0] = {
                id: value as unknown as number,
                value: value,
                label: value,
            }

            return currentValue
        })

        return currentValue
    }

    getValueById(id: number) {
        const currentValue = this.get.values().find((currentValue) => currentValue.id === id)

        return currentValue
    }

    set = {
        string: (value: string) => {
            if (value) {
                this.setData({ value: [{ id: value, value: value, label: value }] })
            } else {
                this.setData({ value: [] })
            }
        },
        value: (value: string) => {
            if (value) {
                const [newValue] = this.getValueByValue(value)

                if (newValue) {
                    return this.setData({ value: [newValue] })
                }
            }

            this.setData({ value: [] })
        },
        multiValue: (value: string[]) => {
            const newValue = this.getValueByValue(value)

            // console.log(111, newValue, this.data.values)

            if (!value && !value.length) {
                return this.setData({ value: [] })
            }

            this.setData({ value: newValue })
        },
        rangeValue: (value: string[]) => {
            const newValue = this.getRangeValueByValue(value)

            this.setData({ value: newValue })
        },
        type: (value: string) => {
            const newType = this.getTypeValueByValue(value)

            if (newType) {
                this.setData({ type_value: newType })
            } else {
                this.setData({ type_value: null })
            }
        },
    }

    typeHandlers = {
        hp_to_kw: (value: ModelProps['values'][0]) => {
            return {
                id: value.id,
                label: String(Math.round(parseInt(value.label) / 1.36)),
                value: String(Math.round(parseInt(value.value) / 1.36)),
            }
        },
        liters_to_cm3: (value: ModelProps['values'][0]) => {
            const newValue = String(Math.round(parseInt(value.value) / 100) / 10)

            return {
                id: value.id,
                label: newValue,
                value: newValue,
            }
        },
    }

    get = {
        value: (val: ModelProps['values']) => {
            const value = toJS(val || this.data.value)
            const typeHandler = this.typeHandlers[this.data.type_value?.typeHandler]

            const newValue: ModelProps['values'] = value.map((value) => {
                if (typeHandler) {
                    return typeHandler(value)
                }

                return value
            })

            return array.uniqueByKey(newValue, 'value')
        },
        values: () => {
            if (this.data.type === 'group-select') {
                const currentGroups = this.get.groups()

                const values = currentGroups.reduce((acc, curr) => {
                    return acc.concat(curr.values)
                }, [])

                return values
            } else {
                const currentValues = toJS(this.data.values)
                const defaultValues = toJS(this.data.defaultValues)

                const values = defaultValues.concat(currentValues).filter((value) => value.id)
                const unique: ModelProps['values'] = array.uniqueByKey(values, 'id')

                const format = this.get.value(unique)

                return format
            }
        },
        groups: () => {
            // Пока без механизма defaultValues (defaultGroups)
            const currentGroups = toJS(this.data.groups)

            return currentGroups
        },
        singleValue: () => {
            return this.data.value.length ? this.data.value[0].value : null
        },
        rangleValue: () => {
            return this.data.value.length ? this.data.value : []
        },
        typeValue: () => {
            return tojs(this.data.type_value)
        },
        types: () => {
            return this.data.types_value?.length ? this.data.types_value : []
        },
        typesFormat: () => {
            return this.data.types_value?.length
                ? tojs(this.data.types_value).map((value) => ({
                      id: value.id,
                      label: value.label,
                      value: value.value,
                  }))
                : []
        },
    }
}
