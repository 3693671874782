import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'
import { AnalyticsFilterResponseData } from '../filter/interfaces'

export type AnalyticsFiltersRequest = {
    url: string
}

export type AnalyticsFiltersResponse = {
    data: AnalyticsFilterResponseData[]
}

export const AnalyticsFilters = async (request?: AnalyticsFiltersRequest) => {
    try {
        const response: ApiResponse<AnalyticsFiltersResponse> = await apiConfig.fetch.ilsa<AnalyticsFiltersResponse>({
            url: `/available-filters${request.url}`,
        })

        if (!response?.data?.data || response.status !== 200) {
            throw Error('Не удалось получить список фильтров')
        }

        const data = response.data.data.map((filter) => ({
            id: filter.id,
            value: filter?.value || filter?.id,
            url: filter?.url,
            type: 'menu',
            visible: true,
        }))

        return data
    } catch (err: any) {
        throwAdapterError(err, 'getFilters')
    }
}
