import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'
import { Car } from './car.types'
import { CarstockFavoriteAdd } from '../favorite-add'
import { CarstockFavoriteRemove } from '../favorite-remove'
import { CarModel } from '@/widget/car/models'

type CarstockCarRequest = {
    data: {
        id: string
    }
}

type CarstockCarResponse = {
    data: Car
}

export const CarstockCarController = { ref: null }

export const CarstockCar = async (req: CarstockCarRequest) => {
    CarstockCarController.ref = new AbortController()

    try {
        const response = await apiConfig.fetch.ilsa<CarstockCarResponse>({
            url: '/ilsa/offer/' + req.data.id,
            config: {
                signal: CarstockCarController.ref.signal,
                cache: true,
            },
        })

        const car = new CarModel({
            ...response.data.data,
            service: {
                favoriteAdd: CarstockFavoriteAdd,
                favoriteRemove: CarstockFavoriteRemove,
            },
        })

        return { car }
    } catch (err: any) {
        throwAdapterError(err, 'CarstockCar')
    }
}
