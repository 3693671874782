import React from 'react'
import { ActionIcon, Box, Collapse, Paper as PaperCore, Title, clsx } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { useDisclosure } from '@mantine/hooks'
import style from './paper.style.scss'
import { IconMinus, IconPlus } from '@tabler/icons-react'

interface PaperProps {
    title?: string
    order?: number
    children: React.ReactNode
    onOpen?(): void
}

export const Paper: React.FC<PaperProps> = observer(({ children, title, order, onOpen, ...rest }) => {
    const [opened, { toggle }] = useDisclosure(false, {
        onOpen,
    })
    const ref = React.useRef({ open: true })

    const handleOpen = () => {
        ref.current.open = true
        toggle()
    }

    return (
        <PaperCore
            key={title}
            className={clsx(style.root, { [style.main]: order === 0 })}
            pb={order === 0 ? 'inherit' : opened ? 48 : '24px !important'}
            {...rest}
        >
            <Title order={2} className={style.title} onClick={handleOpen}>
                {title}

                {order !== 0 && (
                    <ActionIcon size={38} className={style.icon} variant='transparent'>
                        {opened ? <IconMinus size={36} stroke={2} /> : <IconPlus size={36} stroke={2} />}
                    </ActionIcon>
                )}
            </Title>

            <Collapse in={order === 0 ? true : opened}>
                <Box className={style.children} mih={78}>
                    {!!(ref.current.open || order === 0) && children}
                </Box>
            </Collapse>
        </PaperCore>
    )
})
