import React from 'react'
import { Box, Container, LoadingOverlay, clsx } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import style from './complectation.style.scss'
import { CoreProps, core } from './complectation.core'
import { store } from './complectation.store'
import { Header, Options, Menu, Specifications, Installed } from './ui'

type ComplectationWidgetProps = {
    coreProps: CoreProps
}

export const ComplectationWidget: React.FC<ComplectationWidgetProps> = observer(({ coreProps, ...rest }) => {
    React.useEffect(() => {
        const widget = core.init(coreProps)

        widget.mount()
    }, [])

    return (
        <div className={style.root} {...rest}>
            <Container>
                <Box className={style.wrapper}>
                    <LoadingOverlay visible={store.state.loading} radius={24} />

                    {store.state.init && (
                        <>
                            <div className={clsx(style.content, { [style.content_loading]: store.state.loading })}>
                                <Header />
                                <Specifications />
                                <Installed />
                                <Options />
                            </div>
                        </>
                    )}
                </Box>
            </Container>

            {store.state.init && <Menu />}
        </div>
    )
})
