import React from 'react'
import { Box } from '@mantine/core'
import { store } from '../../carstock.store'
import { observer } from 'mobx-react-lite'
import { Paper } from '../paper'
import { FiltersGrid } from '../filters-grid'

interface FiltersProps {}

export const Filters: React.FC<FiltersProps> = observer(({ children, ...rest }) => {
    return (
        <Box {...rest}>
            {Array.from(store.data.filters.keys()).map((category, idx) => (
                <Paper key={category} title={category} order={idx}>
                    <FiltersGrid category={category} />
                </Paper>
            ))}
        </Box>
    )
})
