import React from 'react'
import style from './specification-list.style.scss'
import clsx from 'clsx'

interface SpecificationListProps extends React.HTMLAttributes<HTMLDivElement> {
    data: Partial<SelectItemData>[]
    grid?: number
}

export const SpecificationList: React.FC<SpecificationListProps> = ({ data, className, grid, ...rest }) => {
    return (
        <div className={clsx(style.root, className, { [style.grid]: !!grid })} {...rest}>
            {data.map((attr) => (
                <div key={attr.id} className={style.row}>
                    <div className={style.label}>{attr.label}</div>
                    {!!attr.value && <div className={style.value}>{attr.value}</div>}
                </div>
            ))}
        </div>
    )
}
