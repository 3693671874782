import clsx from 'clsx'
import React from 'react'

interface Apploader {
    isActive?: boolean
    isTransparent?: boolean
}

export const Apploader: React.FC<Apploader> = ({ isActive, isTransparent }) => {
    const prefix = 'app-loader'
    const classes = clsx(prefix, {
        [`${prefix}-active`]: isActive,
        [`${prefix}-transparent`]: isTransparent,
    })

    React.useEffect(() => {
        if (!isTransparent) {
            if (isActive) {
                document.body.classList.add(`${prefix}-body`)
            } else {
                document.body.classList.remove(`${prefix}-body`)
            }
        } else {
            document.body.classList.remove(`${prefix}-body`)
        }
    }, [isActive, isTransparent])

    return (
        <div className={classes} data-app-loader>
            <div className={`${prefix}-container`}>
                <div className='app-loader-inner'>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => (
                        <div key={i} />
                    ))}
                </div>
            </div>
        </div>
    )
}
