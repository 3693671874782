import { makeAutoObservable } from 'mobx'
import {
    BodyTab,
    CatalogDefaultData,
    CatalogFilterGroup,
    CatalogFilterLinks,
    CatalogFilterMeta,
    CatalogParam,
} from './catalog.types'
import {
    CatalogCarGradeModel,
    CatalogCarModel,
    CatalogCarModificationModel,
    CatalogFilterModel,
    CatalogOptionModel,
} from './models'
import { R, tojs } from '@/shared'
import { isBrandModel } from './catalog.lib'

type StoreState = State & {
    searching?: boolean
    searched?: boolean
    searchingModifications?: boolean
    loadingOptions?: boolean
    loadedOptions?: boolean
    saving?: boolean
}

export type StoreData = {
    tabs?: CatalogParam
    sorts?: CatalogParam
    modes?: CatalogParam
    groups?: CatalogFilterGroup[]
    filters?: CatalogFilterModel[]
    cars?: CatalogCarModel[]
    modifications?: CatalogCarModificationModel[]
    options?: CatalogOptionModel[]
    links?: CatalogFilterLinks
    meta?: CatalogFilterMeta
    model?: {
        brand_id: number | string
        brand_name: string
        model_id: number | string
        model_name: string
    }
    limit?: {
        min: number
        max: number
    }
    body_tabs?: {
        id: string
        data: BodyTab[]
    }
    default_data?: CatalogDefaultData
    legacy?: boolean
}

export type StoreParams = {
    hiddenOptions?: boolean
    hiddenTabs?: boolean
    load?: boolean
    title?: string
    onClickCar?: (car: CatalogCarModel) => void
    onClickCarGrade?: (grade: CatalogCarGradeModel) => void
}

const initialState: StoreState = {
    loading: false,
    loaded: false,
    init: false,
    error: null,
    searching: false,
    searched: false,
    searchingModifications: false,
    loadingOptions: false,
    loadedOptions: false,
    saving: false,
}

export const initial_default_data: CatalogDefaultData = {
    tab: undefined,
    sort: null,
    view_mode: null,
    page: null,
    filters: {
        specific_option_id: [],
        universal_option_id: [],
        body_type_purpose_id: null,
    },
}

const initialData: StoreData = {
    tabs: new Map([]),
    sorts: new Map([]),
    modes: new Map([]),
    groups: [],
    filters: [],
    cars: [],
    modifications: [],
    options: [],
    links: null,
    meta: null,
    model: null,
    limit: {
        min: 12,
        max: 42,
    },
    body_tabs: null,
    default_data: initial_default_data,
    legacy: false,
}

const initialParams: StoreParams = {
    hiddenOptions: false,
    hiddenTabs: false,
    load: false,
    onClickCar: null,
    onClickCarGrade: null,
}

class Store {
    state = initialState
    data = initialData
    params = initialParams

    constructor() {
        makeAutoObservable(this)
    }

    /* -------------------------------------------------------------------------- */
    /*                                    Core                                    */
    /* -------------------------------------------------------------------------- */

    setState(states: Partial<StoreState>) {
        this.state = {
            ...this.state,
            ...states,
        }
    }

    setData(data: Partial<StoreData>) {
        this.data = {
            ...this.data,
            ...data,
        }
    }

    setParams(params: Partial<StoreParams>) {
        this.params = {
            ...this.params,
            ...params,
        }
    }
    reset() {
        this.setState(initialState)
        this.setData(initialData)
        this.setParams(initialParams)
    }

    /* -------------------------------------------------------------------------- */
    /*                                    Data                                    */
    /* -------------------------------------------------------------------------- */

    getModes() {
        const modes = Array.from(this.data.modes.values())

        return modes
    }

    getSorts() {
        const sorts = Array.from(this.data.sorts.values())

        return sorts
    }

    getTabs() {
        const tabs = Array.from(this.data.tabs.values())

        return tabs
    }

    getCurrentSort() {
        const sort = Array.from(tojs(this.data.sorts).values()).find((sort) => sort.enabled)

        return sort
    }

    getCurrentSortValues() {
        const sorts = Array.from(tojs(this.data.sorts).values())
            .filter((sort) => sort.enabled)
            .map((sort) => sort.value)

        return sorts
    }

    getCurrentTab() {
        const tab = tojs(this.getTabs()).find((tab) => tab.enabled)

        return tab
    }

    getCurrentModeValue() {
        const mode = tojs(this.getModes()).find((mode) => mode.enabled)?.value

        return mode
    }

    getSortValues() {
        const sort = Array.from(tojs(this.data.sorts).values())
            .filter((sort) => sort.enabled)
            .map((sort) => sort.value)

        return sort
    }

    setBodyData(data: BodyTab[]) {
        this.setData({ body_tabs: { id: this.data.body_tabs.id, data } })
    }

    getCurrentBodyTab() {
        const data = this.data.body_tabs?.data || []
        const tab = data.find((tab) => tab.selected)

        if (tab) {
            return { id: this.data.body_tabs.id, tab }
        }

        return null
    }

    /* -------------------------------------------------------------------------- */
    /*                                   Filters                                  */
    /* -------------------------------------------------------------------------- */

    get filters() {
        return Array.from(this.data.filters.values()).flat()
    }

    getModelDataByFilters(): typeof this.data.model {
        const filters = this.data.filters

        const brand = filters.find((filter) => filter.data.params[0].name === 'brand_id')
        const model = filters.find((filter) => filter.data.params[0].name === 'model_id')

        const brand_value = brand.data.value[0]
        const model_value = model.data.value[0]

        return {
            brand_id: brand_value.id,
            brand_name: brand_value.label,
            model_id: model_value.id,
            model_name: model_value.label,
        }
    }

    getFiltersByGroupId(group_id: number) {
        const filters = this.data.filters.filter((filter) => filter.data.group_id === group_id)

        return filters
    }

    getFilterDependsOn(filter: CatalogFilterModel) {
        const depends = this.data.filters.filter((curr) => {
            return filter.data.depends_on.includes(curr.data.id)
        })

        return depends
    }

    getFilterDependsFor(filter: CatalogFilterModel) {
        const depends = this.data.filters.filter((curr) => {
            return filter.data.depends_for.includes(curr.data.id)
        })

        return depends
    }

    getFilterValues(filters: CatalogFilterModel[] = this.data.filters, props?: { rangeSelectWithType?: boolean }) {
        // const duplicate_types = {}
        const { rangeSelectWithType } = props || {}

        const values = filters
            .filter((curr) => curr.data.value)
            .reduce((acc, curr) => {
                const value = tojs(curr.data.value)
                const type = curr.get.type()

                // if (acc[type]) {
                //     duplicate_types[type] = curr.data.label

                //     return acc
                // }

                /** Обрабатываем опции */
                if (['universal_option_id', 'specific_option_id'].includes(type)) {
                    // const options: any = this.getSelectedOptionsValues()

                    // acc[options[0]] = options[1]

                    return acc
                } else {
                    if (curr.data.type === 'range-select') {
                        if (value[0]?.id || value[1]?.id) {
                            acc[type] = `${value[0]?.id || '0'}..${value[1]?.id || '999999999'}${
                                rangeSelectWithType ? `_T_${curr.get.type()}` : ''
                            }`
                        }
                    } else if (curr.data.type === 'range-integer') {
                        if (value[0]?.value || value[1]?.value) {
                            acc[type] = `${value[0]?.value || '0'}..${value[1]?.value || '999999999'}`
                        }
                    } else if (curr.data.type === 'switch') {
                        if (value[0]?.value) {
                            acc[type] = value[0]?.value
                        }
                    } else {
                        acc[type] = value.map((value) =>
                            value?.id !== null && value?.id !== undefined ? value?.id : null
                        )
                    }

                    return acc
                }
            }, {})

        const isModels = this.isCurrentMode('models')
        const optionsFilter = this.getOptionsFilter()

        if (optionsFilter) {
            const options: any[] = this.getSelectedOptionsValues()

            values[options[0]] = options[1]
        }

        const body_tab = this.getCurrentBodyTab()

        if (body_tab) {
            values[body_tab.id] = body_tab.tab.id
        }

        // if (Object.keys(duplicate_types).length) {
        //     console.warn('Дубликаты параметров:', duplicate_types)
        // }

        return R.filter((value: any) => {
            const isExist = value !== undefined && value !== null

            return isExist && (Array.isArray(value) ? !!value.length : true)
        }, values)
    }

    getFilterValuesForSearchOptions() {
        const values = {}
        const tab = this.getCurrentTab()

        if (tab.value !== null) {
            values[tab.param] = tab.value
        }

        return values
    }

    getFilterValuesByDependsOnForLoadValues(filter: CatalogFilterModel, { isOptions }: { isOptions?: boolean } = {}) {
        // const depends = tojs(this.getFilterDependsOn(filter))

        // const values = this.getFilterValues(depends)
        const values = this.getFilterValues()

        if (!isOptions) {
            /**
             * https://trello.com/c/keLAdngc/855
             * В запрос на получения данных по фильтру, добавляем параметр вкладку (все/акт/арх)
             */
            const tab = this.getCurrentTab()

            if (tab.value !== null) {
                values[tab.param] = tab.value
            }

            /**
             * https://trello.com/c/nAE5mm9i/857
             * Для фильтров бренд/модель добавляем флаг legacy
             */
            if (this.data.legacy === false && isBrandModel(filter)) {
                values['model_is_legacy'] = false
            }
        }

        return R.omit([filter.get.type()], values)
    }

    async setFiltersBrandModel() {
        try {
            const filters = this.data.filters
            const filterBrand = filters.find((filter) => filter.data.params[0].name === 'brand_id')
            const filterModel = filters.find((filter) => filter.data.params[0].name === 'model_id')
            let reset = !filterBrand.data.value.length && !filterModel.data.value.length

            if (filterBrand && filterModel) {
                const brandValues = filterBrand.data.values.length
                    ? filterBrand.data.values
                    : await filterBrand.loadValues()

                const brandValue = filterBrand.getValueById(this.data.model.brand_id)
                const brandDepends = store.getFilterDependsFor(filterBrand)

                filterBrand.set.value(brandValue.value)
                reset ? brandDepends.forEach((depend) => depend.reset()) : null

                const filterModelValuesByDepends = this.getFilterValuesByDependsOnForLoadValues(filterModel)

                const modelValues = filterModel.data.values.length
                    ? filterModel.data.values
                    : await filterModel.loadValues(filterModelValuesByDepends)

                const modelValue = filterModel.getValueById(this.data.model.model_id)
                const modelDepends = store.getFilterDependsFor(filterModel)

                filterModel.set.value(modelValue.value)
                reset ? modelDepends.forEach((depend) => depend.reset()) : null
            }
        } catch (err) {
            console.log(err)

            return null
        }

        return null
    }

    getValuesForSearch() {
        let values: ObjectType = this.getFilterValues()

        const tab = this.getCurrentTab()
        const sort = this.getCurrentSort()

        values['view_mode'] = this.getCurrentModeValue()
        values['page'] = {
            size: this.getLimit(),
            number: this.getPage(),
        }
        values['sorts'] = {
            [sort.param]: this.getCurrentSortValues()[0],
        }
        values[tab.param] = tab.value
        // values['universal_options_ids'] = this.getOptionsSelectedIds()

        // const optionsValues = this.getSelectedOptionsValues()

        if (this.data.body_tabs) {
            const body_tabs_selected = this.data.body_tabs.data.filter((item) => item.selected).map((item) => item.id)

            if (body_tabs_selected.length) {
                values[this.data.body_tabs.id] = body_tabs_selected
            }
        }

        values = R.filter(
            (value: any) => {
                const isExist = value !== undefined && value !== null

                return isExist && (Array.isArray(value) ? !!value.length : true)
            },
            {
                ...values,
                // ...optionsValues,
            }
        )

        return Object.keys(values).length ? values : null
    }

    async getValuesForSearchModifications() {
        await this.setFiltersBrandModel()

        const values = this.getValuesForSearch()

        return values
    }

    getValuesForUrl() {
        const values: ObjectType = {}

        values['filters'] = this.getFilterValues(this.data.filters, { rangeSelectWithType: true })

        const tab = this.getCurrentTab()
        const sort = this.getCurrentSort()

        values['sort'] = { [sort.param]: this.getCurrentSortValues()[0] }
        values['tab'] = tab.value

        values['view_mode'] = this.getCurrentModeValue()
        values['page'] = { size: this.getLimit(), number: this.getPage() }

        return values
    }

    getGroups() {
        return this.data.groups.filter((group) => group.__visible)
    }

    /* -------------------------------------------------------------------------- */
    /*                                   Options                                  */
    /* -------------------------------------------------------------------------- */

    getOptionsFilter() {
        const isModels = this.isCurrentMode('models')

        if (isModels) {
            const universal = this.data.filters.find((filter) => filter.data.type === 'universal-options')
            return universal
        } else {
            const specific = this.data.filters.find((filter) => filter.data.type === 'specific-options')
            return specific
        }
    }

    getOptionsItems(): CatalogOptionModel['data']['options'] {
        return this.data.options.reduce((acc, curr) => {
            return acc.concat(
                curr.data.groups.reduce((acc, curr) => {
                    return acc.concat(curr.options)
                }, [])
            )
        }, [])
    }

    getOptionByIdItem(id: number, options = this.data.options) {
        return options.find((option) =>
            option.data.groups.find((group) => group.options.find((item) => item.id === id))
        )
    }

    getSelectedOptionsValues() {
        // const values = {}
        const filter = this.getOptionsFilter()

        const values = this.data.options.reduce((acc, curr) => {
            const concat = acc.concat(curr.data.selected)

            // if (curr.data.type_param_key === 'carstock_options_ids') {
            //     concat.forEach((id) => {
            //         values[id] = '1'
            //     })
            // } else {
            // values[curr.data.type_param_key] = concat
            // }

            return concat
        }, [])

        return [filter.get.type(), values]
    }

    getOptionsSelectedItems() {
        const options = this.getOptionsItems()
        const filtered = options.filter((option) => option.selected)

        return filtered
    }

    /* -------------------------------------------------------------------------- */
    /*                                    Meta                                    */
    /* -------------------------------------------------------------------------- */

    getTotal() {
        return this.data.meta?.total || 0
    }

    getTotalPages() {
        return Math.ceil(this.data.meta?.total / this.getLimit()) || 0
    }

    getPage() {
        return this.data.meta?.current_page || 1
    }

    getLimit() {
        return this.data.meta?.per_page || store.data.limit.min
    }

    isCurrentMode(mode: string) {
        return this.getCurrentModeValue() === mode
    }
}

export const store = new Store()
