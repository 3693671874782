export const getDeduplicationData = <T>(data: T): T => {
    /** Определяем по каким параметрам будет сравнение */
    const getCompareData = (item) =>
        JSON.stringify({
            brand: item.edition.equipment.brand.name,
            model: item.edition.equipment.model.name,
            version: item.edition.equipment.version.name,
            color: item.edition.attributes.find((attr) => attr.id === 2201).value,
            dealer: item.dealer.name,
            year: item.edition.equipment.model_year,
            fuel: item.edition.attributes.find((item) => item.id === 6101).value,
            drive: item.edition.attributes.find((item) => item.id === 6301).value,
            price: item.prices.find((item) => item.code === 'minimal')?.price,
        })

    const data_json = (data as any).map((item) => ({
        ...item,
        json: getCompareData(item),
    }))

    const items = {}

    data_json.forEach((item) => {
        if (items[item.json]) {
            items[item.json].deduplication = items[item.json].deduplication || []
            items[item.json].deduplication.push({
                id: item.id,
                name: `${item.edition.equipment.brand.name} ${item.edition.equipment.model.name}`,
            })
        } else {
            items[item.json] = item
        }
    })

    return Object.values(items) as any
}
