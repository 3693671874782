import { ModalLabel, ModalTitle } from '@/theme/@deprecated/components/modal/_components'
import { IModalProps, Modal } from '@/theme/@deprecated/components/ui/modal'
import { IUseModalProps, useModal } from '@/theme/@deprecated/components/ui/modal/hook'
import Button from '@mui/material/Button'
import { useLoader } from '@/theme/@deprecated/components/ui'
import clsx from 'clsx'
import React from 'react'
import style from './index.style.scss'

interface IModalConfirmProps extends IModalProps {
    title: string
    label?: string | JSX.Element
    onAction: FunctionType
    loader?: boolean
}

export const ModalConfirm: React.FC<IModalConfirmProps> = ({ className, title, label, onAction, loader, ...props }) => {
    const { Loader } = useLoader()

    return (
        <Modal {...props} className={clsx(style.root, className)}>
            <Modal.Header>
                <ModalTitle title={title} className={clsx(style.title)} />
            </Modal.Header>
            <Modal.Body className={style.body}>
                <ModalLabel label={label} className={style.label} />
                <div className={style.actions}>
                    <Button variant='outlined' onClick={props.onClose}>
                        Отмена
                    </Button>
                    <Button variant='contained' onClick={onAction}>
                        Принять
                    </Button>
                </div>
            </Modal.Body>
            <Loader active={loader} center overlay />
        </Modal>
    )
}

export const useModalConfirm = (data?: IUseModalProps) =>
    useModal<IModalConfirmProps>({ ...data, instance: ModalConfirm })
