import React from 'react'
import { toast, ToastContainer, ToastOptions } from 'react-toastify'

export const notifyOptions: ToastOptions = {
    position: 'bottom-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    draggable: true,
    progress: undefined,
    theme: 'colored',
    isLoading: false,
}

export const notifyContainer = <ToastContainer {...notifyOptions} />

export const notifyError = (msg: string, params: ToastOptions = {}) =>
    toast.error(msg, {
        ...notifyOptions,
        ...params,
    })

export const notifySuccess = (msg: string, params: ToastOptions = {}) =>
    toast.success(msg, {
        ...notifyOptions,
        ...params,
    })

export const notifyWarning = (msg: string, params: ToastOptions = {}) =>
    toast.warning(msg, {
        ...notifyOptions,
        ...params,
    })

export const notifyLoading = (msg: string, params: ToastOptions = {}) =>
    toast.loading(msg, {
        ...notifyOptions,
        ...params,
    })
