import React from 'react'
import { Button, createStyles, Dialog, Group, Text } from '@mantine/core'

interface EditTableDialogProps {
    opened: boolean
    setOpened: FunctionType<boolean>
    onCancel?: FunctionType
    onSave?: FunctionType
}

const useStyles = createStyles(() => ({
    root: {},
}))

export const EditTableDialog: React.FC<EditTableDialogProps> = ({ opened, setOpened, onSave, onCancel, ...rest }) => {
    const { classes } = useStyles()

    return (
        <Dialog
            className={classes.root}
            opened={opened}
            withCloseButton
            onClose={onCancel ? onCancel : () => setOpened(false)}
            size='md'
            sx={{ width: '264px' }}
            radius='md'
        >
            <Text size='sm' style={{ marginBottom: 10 }} weight={500}>
                Сохранить изменения?
            </Text>

            <Group align='flex-end' position='left'>
                <Button variant='default' onClick={onCancel ? onCancel : () => setOpened(false)}>
                    Отменить
                </Button>
                <Button onClick={onSave ? onSave : () => setOpened(false)} sx={{ flex: '0 0 1' }}>
                    Сохранить
                </Button>
            </Group>
        </Dialog>
    )
}
