import React from 'react'
import { Box } from '@mantine/core'
import { store } from '../../catalog.store'
import { observer } from 'mobx-react-lite'
import { Paper } from '../paper'
import { FiltersList } from '../filters-list'

interface FiltersProps {}

export const Filters: React.FC<FiltersProps> = observer(({ children, ...rest }) => {
    const groups = store.getGroups()

    return (
        <Box {...rest}>
            {groups.map((group, idx) => (
                <Paper key={idx} title={group.name} order={group.sort_order}>
                    <FiltersList group_id={group.id} />
                </Paper>
            ))}
        </Box>
    )
})
