import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'

export type UsersRolesResponse = {
    success: boolean
    data: {
        id: number
        name: string
    }[]
}

export const UsersRoles = async () => {
    try {
        const response = await apiConfig.fetch.ilsa<UsersRolesResponse>({ url: '/auth/roles' })

        if (!response?.data?.data?.length) {
            throw Error('Не удалось получить список ролей')
        }

        const roles = response.data.data.map((role) => ({
            id: String(role.id),
            value: role.name,
            label: role.name,
        }))

        return roles
    } catch (err: any) {
        throwAdapterError(err, 'UsersRoles')
    }
}
