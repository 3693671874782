import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'
import { Option } from './complectation-options.types'
import { ComplectationOptionModel } from '@/widget/complectation/models'

type CatalogComplectationOptionsRequest = {
    data: {
        id: string
    }
}

type CatalogComplectationOptionsResponse = {
    data: Option[]
}

export const CatalogComplectationOptionsController = { ref: null }

export const CatalogComplectationOptions = async (req: CatalogComplectationOptionsRequest) => {
    CatalogComplectationOptionsController.ref = new AbortController()

    try {
        const response = await apiConfig.fetch.ilsa<CatalogComplectationOptionsResponse>({
            url: '/catalog/complectations/' + req.data.id + '/specific-options',
            config: {
                signal: CatalogComplectationOptionsController.ref.signal,
                cache: true,
            },
        })

        const generate = (data: Option[], basic: boolean) =>
            data
                .map((option) => {
                    const props = {
                        id: option.id,
                        value: option.value,
                        items: [],
                        selected: [],
                    }

                    const getItems = (items: Option['items']) => {
                        if (basic)
                            return items.reduce((acc, curr) => {
                                if (curr.type !== 'group' && curr.basic) {
                                    return [...acc, { ...curr, selected: false }]
                                }

                                if (curr.type === 'group') {
                                    const items = curr.items.reduce((acc_inner, curr_inner) => {
                                        if (curr_inner.basic) {
                                            return [...acc_inner, { ...curr_inner, selected: false }]
                                        }

                                        return acc_inner
                                    }, [])

                                    if (items.length) {
                                        return [
                                            ...acc,
                                            {
                                                ...curr,
                                                items,
                                            },
                                        ]
                                    }
                                }

                                return acc
                            }, [])

                        if (!basic) {
                            const reduceItems = items.reduce((acc, curr) => {
                                if (curr.type !== 'group' && !curr.basic) {
                                    return [...acc, { ...curr, selected: false, parent_id: option.id }]
                                }

                                if (curr.type === 'group') {
                                    const items = curr.items.reduce((acc_inner, curr_inner) => {
                                        if (!curr_inner.basic) {
                                            return [
                                                ...acc_inner,
                                                { ...curr_inner, selected: false, parent_id: curr.id },
                                            ]
                                        }

                                        return acc_inner
                                    }, [])

                                    if (items.length) {
                                        const acc_items = [
                                            ...acc,
                                            {
                                                ...curr,
                                                items,
                                            },
                                        ]

                                        // acc_items.map(acc_item => {
                                        //     acc_item.parent_id =
                                        // })

                                        return acc_items
                                    }
                                }

                                return acc
                            }, [])

                            return reduceItems
                        }
                    }

                    const model = new ComplectationOptionModel({
                        ...props,
                        items: getItems(option.items),
                    })

                    return model.data.items.length ? model : null
                })
                .filter(Boolean)

        const basic_options = generate(response.data.data, true)
        const specific_options = generate(response.data.data, false)

        return { basic_options, specific_options }
    } catch (err: any) {
        throwAdapterError(err, 'CatalogComplectationOptions')
    }
}
