import { BaseModel } from '@/shared/core/model'
import { number } from '@/shared'
import { apiConfig } from '@/api'

interface CatalogCardGradeProps {
    id: number
    brand_name: string
    complectation_name: string
    image: string
    is_archival: boolean
    model_name: string
    rrp: number
    is_parallel_import: boolean
    // id: number
    // name: string
    // rrp_current: number
    // release_year: any
    // is_archival: boolean
    // vtbl_discount: any[]
    // specific_colors_with_hex: {
    //     id: number
    //     specific_name: string
    //     hex_code?: string
    //     price: number
    //     image: string
    // }[]
    // options: {
    //     id: number
    //     name: string
    // }[]
    // in_stock_count: number
    // ilsa_data: any
}

export class CatalogCarGradeModel extends BaseModel<CatalogCardGradeProps, CatalogCarGradeModel> {
    constructor(props: CatalogCardGradeProps) {
        super(props, CatalogCarGradeModel)
    }

    get image() {
        const image = this.data.image

        return image ? apiConfig.hosts.image_catalog + image : '/static/car-placeholder.png'
    }

    get price() {
        const price = number.format(this.data.rrp)

        return price
    }

    get priceType() {
        const labelType = this.data.is_parallel_import ? 'СРЦ' : 'РРЦ'

        return labelType
    }

    get priceLabel() {
        const price = this.price

        const priceLabel = `от ${price} ₽`
        const priceLabelRequest = `по запросу`

        return this.data.rrp ? priceLabel : priceLabelRequest
    }
}
