import React from 'react'
import { Box, Container, LoadingOverlay, clsx } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { store } from './carstock.store'
import { Grid, Filters, Search, Options, Reset, Pagination } from './ui'
import { CoreProps, core } from './carstock.core'
import { ErrorBoundary } from 'react-error-boundary'
import { Paper } from './ui/paper'
import style from './carstock.style.scss'
import { useLocation } from 'react-router-dom'
import { useMounted } from '@/shared/hooks'

type CarstockWidgetProps = {
    coreProps: CoreProps
}

export const CarstockWidget: React.FC<CarstockWidgetProps> = observer(({ coreProps, ...rest }) => {
    const location = useLocation()
    const mounted = useMounted()

    React.useEffect(() => {
        if (mounted) {
            const catalog = core.init(coreProps)

            catalog.mount()
        }
    }, [location.search])

    React.useEffect(() => {
        const catalog = core.init(coreProps)

        catalog.mount()

        return () => {
            catalog.unmount()
        }
    }, [])

    return (
        <div className={style.root} {...rest}>
            <Container>
                <Box className={style.wrapper}>
                    <LoadingOverlay visible={store.state.loading} radius={24} />

                    {store.state.init && (
                        <ErrorBoundary
                            fallbackRender={() => (
                                <Paper order={0}>Произошла ошибка, обратитесь к администратору!</Paper>
                            )}
                        >
                            <div
                                className={clsx(style.filters, {
                                    [style.filters_loading]: store.state.searchingModifications,
                                })}
                            >
                                <Reset />
                                <Filters />

                                {!store.params?.hiddenOptions && <Options />}
                            </div>

                            <Search />
                            <Grid />
                            <Pagination />
                        </ErrorBoundary>
                    )}
                </Box>
            </Container>
        </div>
    )
})
