import { createFetch } from './api.lib'

export type AppConfigResponse = {
    api: {
        hosts: {
            base: string
            ilsa: string
            local: string
            image_catalog: string
            image_carstock: string
        }
    }
}

export const apiConfig = new (class AppConfig {
    hosts = {
        base: process.env.PROJECT_API_BASE_URL,
        ilsa: process.env.PROJECT_API_ILSA_URL,
        local: process.env.PROJECT_API_LOCAL_URL,
        image_catalog: process.env.PROJECT_API_IMAGE_CATALOG_URL,
        image_carstock: process.env.PROJECT_API_IMAGE_CARSTOCK_URL,
        image_analytics: process.env.PROJECT_API_IMAGE_ANALYTICS_URL,
    }

    fetch = {
        base: createFetch(process.env.PROJECT_API_BASE_URL),
        ilsa: createFetch(process.env.PROJECT_API_ILSA_URL),
        local: createFetch(process.env.PROJECT_API_LOCAL_URL),
    }

    async initialize() {
        try {
            const { data } = await this.fetch.local<AppConfigResponse>({
                url: '/static/api/api.config.json',
                config: {
                    method: 'GET',
                },
            })

            this.setHosts(data.api.hosts)
        } catch (err) {
            console.log('Ошибка при инициализации appConfig')
        }
    }

    setHosts(hosts: Partial<typeof this.hosts>) {
        for (const key in hosts) {
            if (this.hosts[key] && hosts[key]) {
                this.hosts[key] = hosts[key]
                this.fetch[key] = createFetch(hosts[key])
            }
        }
    }
})()
