import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'
import { CatalogOption, CatalogOptionGroup } from './options.types'
import { storage } from '@/shared'
import { sleep } from '@/shared'
import { CatalogOptionModel } from '@/widget/catalog/models'

type CatalogOptionsRequest = {
    params: {
        [property: string]: number
    }
}

type CatalogOptionsResponse = {
    data: CatalogOption[]
    success: boolean
}

export const CatalogFilterOptionsController = { ref: null }

/** @deprecated */
export const CatalogOptions = async (req: CatalogOptionsRequest) => {
    CatalogFilterOptionsController.ref = new AbortController()

    try {
        const url = `/filters-data/universal-options`
        const fromCache = storage.getWithExpiry(url + JSON.stringify(req.params))

        const response =
            fromCache ||
            (await apiConfig.fetch.ilsa<CatalogOptionsResponse>({
                url,
                config: {
                    method: 'GET',
                    params: req.params,
                    signal: CatalogFilterOptionsController.ref.signal,
                    // cache: true,
                },
            }))

        if (fromCache) {
            await sleep(600)
        }

        storage.setWithExpiry(url + JSON.stringify(req.params), response, 1000 * 1000)

        const options: CatalogOptionModel[] = response.data.data.reduce((acc, option) => {
            // if (option.name === 'Сиденья') {
            // }

            const baseGroup = {
                name: 'Базовые опции',
                options: option.options.map((option) => ({
                    ...option,
                    selected: false,
                })),
            }

            const groups: CatalogOptionGroup[] = (option.groups || []).map((group) => ({
                ...group,
                options: group.options.map((option) => ({
                    ...option,
                    selected: false,
                    group: group.name,
                })),
            }))

            groups.unshift(baseGroup)

            const accOption: CatalogOption = {
                ...option,
                groups,
                options: baseGroup.options,
                selected: [],
                type_param_key: 'universal_options_ids',
            }

            acc.push(new CatalogOptionModel(accOption))

            return acc
        }, [])

        return { options }
    } catch (err: any) {
        throwAdapterError(err, 'CatalogOptions')
    }
}
