export const capitalizeFirstLetter = (str: string) => {
    const lower = str.toLocaleLowerCase()

    return lower.charAt(0).toUpperCase() + lower.slice(1)
}

export const formatPhone = (phone: string) => {
    return phone
        ?.replace(/\)/g, '')
        .replace(/\(/g, '')
        .replace(/-/g, '')
        .replace(/_/g, '')
        .replace(/ /g, '')
        .replace(/\+/g, '')
}
