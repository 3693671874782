import { showToast, showToastError } from '@/shared/vendors/mantine'
import { StoreData, StoreParams, store } from './table.store'
import { TableParams, TableRow } from './table.types'
import { TableFilterModel } from './models'
import { tojs } from '@/shared'

type CoreService = {
    get: {
        request(props: { params: TableParams }): Promise<{
            meta: StoreData['meta']
            rows: StoreData['rows']
            columns: StoreData['columns']
        }>
        controller?: { ref: AbortController }
    }
    remove?: {
        request(props: { data: any[] }): Promise<any>
        controller?: { ref: AbortController }
    }
    create?: {
        request(props: { data: ObjectType }): Promise<any>
        controller?: { ref: AbortController }
    }
    update?: {
        request(props: { data: ObjectType }): Promise<any>
        controller?: { ref: AbortController }
    }
}

export type CoreProps = {
    widget: {
        name: string
    }
    service: CoreService
    params: StoreParams
    data: StoreData
}

const initialWidget: CoreProps['widget'] = {
    name: null,
}

const initialService: CoreService = {
    get: {
        request: null,
        controller: { ref: null },
    },
    remove: {
        request: null,
        controller: { ref: null },
    },
    create: {
        request: null,
        controller: { ref: null },
    },
}

class Core {
    widget = initialWidget
    service = initialService
    store = store

    init(props: CoreProps) {
        if (this.widget.name && this.widget.name === props.widget.name) return this

        Object.values(this.service || {}).forEach((service) => {
            service?.controller?.ref?.abort?.()
        })

        this.widget = props.widget || initialWidget
        this.service = props.service || initialService

        store.reset()

        if (props.data) {
            store.setData(props.data)
        }

        if (props.params) {
            store.setParams(props.params)
        }

        return this
    }

    async mount() {
        try {
            if (store.state.init) {
                this.search()

                return
            }

            store.setState({ loading: true })

            try {
                await this.search()

                store.setState({ init: true, loading: false, loaded: true })
            } catch (err) {
                console.log(err)
                store.setState({ init: false, loading: false, loaded: false })
            }
        } catch (err) {
            console.log(err)
            store.setState({ init: false, loading: false, loaded: false })
            showToastError({ message: err?.message })
        }
    }

    async unmount() {
        // ...
    }

    async search(page?: number) {
        try {
            this.service?.get?.controller?.ref?.abort?.()

            store.setState({ searching: true })

            const params = store.getParamsForSearch()

            const { rows, columns, meta } = await this.service.get.request({
                params: {
                    ...params,
                    'page[number]': page || 1,
                },
            })

            store.setData({ rows, columns, meta, selected: [] })
            store.setState({ searching: false })
        } catch (err) {
            store.setState({ searching: false })
            showToastError({ message: err?.message })
            throw err
        }
    }

    changeState(id: string) {
        store.changeState(id)

        this.search(1)
    }

    changeSearch(search: string) {
        store.setData({
            search,
        })

        this.search(1)
    }

    changePage(page: number) {
        this.search(page)
    }

    changeLimit(value?: number) {
        store.setLimit(
            value
                ? value
                : store.data.meta.per_page === store.data.limit.min
                ? store.data.limit.max
                : store.data.limit.min
        )

        this.search()
    }

    async changeFilter(value: string[], filter: TableFilterModel, options?: { isGroup?: boolean }) {
        const dependsFor = store.getFilterDependsFor(filter)

        if (filter.data.type === 'select') {
            filter.set.value(value[0])
        } else if (filter.data.type === 'multi-select') {
            filter.set.multiValue(value)
        } else if (filter.data.type === 'group-select') {
            filter.set.groupValue(value, options?.isGroup)
        }

        /** Сбрасываем параметры зависимые на текущий фильтр */
        dependsFor.forEach((dependFilter) => dependFilter.reset())

        this.search(1)
    }

    async loadFilterValues(filter: TableFilterModel) {
        const filters = store.getFilterValuesByDependsOnForLoadValues(filter)

        await filter.loadValues(filters)
    }

    async resetFilters() {
        Array.from(store.data.filters.values())
            .flat()
            .forEach((filter) => filter.setData({ value: [] }))

        this.search(1)
    }

    async delete(ids: any[]) {
        try {
            if (store.state.searching) return

            this.service?.remove?.controller?.ref?.abort?.()

            store.setState({ searching: true })

            await this.service.remove.request({
                data: ids,
            })

            await this.search(1)

            store.setState({ searching: false })

            showToast({ message: 'Значение удалено' })
        } catch (err) {
            console.log(err)
            store.setState({ searching: false })
            showToastError({ message: err?.message })
        }
    }

    async create(data: ObjectType) {
        try {
            if (store.state.creating) return

            this.service?.create?.controller?.ref?.abort?.()

            store.setState({ creating: true })

            await this.service.create.request({
                data: data,
            })

            store.setState({ creating: false, createModalOpened: false })

            await this.search(1)

            showToast({ message: 'Значение добавлено' })
        } catch (err) {
            console.log(err)
            store.setState({ creating: false })
            showToastError({ message: err?.message })
        }
    }

    async update(data: ObjectType) {
        try {
            if (store.state.creating) return

            this.service?.create?.controller?.ref?.abort?.()

            store.setState({ creating: true })

            await this.service.update.request({
                data: data,
            })

            store.setState({ creating: false })
            store.setData({ editRows: [] })

            await this.search()

            showToast({ message: 'Значение обновлено' })
        } catch (err) {
            console.log(err)
            store.setState({ creating: false })
            showToastError({ message: err?.message })
        }
    }

    edit(row: TableRow) {
        const editRow = tojs(row)

        store.setData({
            editRows: [editRow],
        })
    }

    unedit(row: TableRow) {
        const editRows = store.data.editRows.filter((accRow) => JSON.stringify(accRow) !== JSON.stringify(row))

        store.setData({
            editRows,
        })
    }

    select(row: TableRow) {
        const isExist = store.isSelected(row)

        if (isExist) {
            const currSelected = store.data.selected.filter(
                (selected) => JSON.stringify(selected) !== JSON.stringify(row)
            )

            store.setData({ selected: currSelected })
        } else {
            store.setData({ selected: [...store.data.selected, row] })
        }
    }

    selectAll() {
        const isExist = store.isSelectedAll()

        if (isExist) {
            store.setData({ selected: [] })
        } else {
            store.setData({ selected: [...store.data.rows] })
        }
    }

    actions(action: any) {
        action.onClick(tojs(store.data.selected), this)
    }
}

export const core = new Core()
