import React from 'react'
import { Box } from '@mantine/core'
import { useParams } from 'react-router-dom'
import { service } from '@/api'
import style from './complectation.style.scss'
import { ComplectationWidget } from '@/widget/complectation'
import { MantineProvider } from '@/theme'

type CmoplectationPageProps = {}

export const CmoplectationPage: React.FC<CmoplectationPageProps> = ({ ...rest }) => {
    const { id } = useParams()

    return (
        <MantineProvider withNotify>
            <Box className={style.root} {...rest}>
                <ComplectationWidget
                    coreProps={{
                        widget: {
                            id,
                            name: 'catalog',
                        },
                        service: {
                            complectation: {
                                request: service.catalog.complectation,
                                controller: service.catalog.controller.complectation,
                            },
                            options: {
                                request: service.catalog.complectation_options,
                                controller: service.catalog.controller.complectation_options,
                            },
                            packages: {
                                request: service.catalog.complectation_packages,
                                controller: service.catalog.controller.complectation_packages,
                            },
                            save: {
                                request: service.configurations.save,
                            },
                            edit: {
                                request: service.configurations.edit,
                            },
                        },
                    }}
                />
            </Box>
        </MantineProvider>
    )
}
