import React from 'react'
import clsx from 'clsx'
import style from './cell-datetime.style.scss'
import { TableCell } from '@/widget/table/table.types'
import dayjs from 'dayjs'

type CellDatetimeProps = React.HTMLAttributes<HTMLTableCellElement> & {
    cell: TableCell
}

export const CellDatetime: React.FC<CellDatetimeProps> = ({ className, cell, ...rest }) => {
    return (
        <div className={clsx(style.root, className, 'elisa-table-widget-cell')} title={cell.value} {...rest}>
            {dayjs(cell.value).format('DD.MM.YY  HH:MM:ss')}
        </div>
    )
}
