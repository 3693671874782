import { filter, sort, reduce, keys, omit, pick } from 'rambda'

export const R = {
    filter,
    sort,
    reduce,
    keys,
    omit,
    pick,
}
