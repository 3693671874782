import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'

type UsersDeleteRequest = { data: string[] }
type UsersDeleteResponse = {
    success: boolean
    message: string
}

export const UsersDelete = async (props: UsersDeleteRequest) => {
    try {
        const response = await apiConfig.fetch.ilsa<UsersDeleteResponse>({
            url: '/auth/users',
            config: {
                method: 'DELETE',
                data: props.data,
            },
        })

        if (!response?.data?.success) {
            throw Error('Не удалось удалить пользователей')
        }

        return response
    } catch (err: any) {
        throwAdapterError(err, 'UsersDelete')
    }
}
