import { throwAdapterError } from '@/shared'
import { Row } from './favorites.types'
import { apiConfig } from '@/api/api.config'
import { TableModel, TableParams } from '@/widget/table/table.types'

export type CarstockFavoritesRequest = {
    params: TableParams
}

export type CarstockFavoritesResponse = {
    data: Row[]
    meta: TableModel['meta']
    columns: TableModel['columns']
    success: boolean
}

export const CarstockFavoritesController = { ref: null }

export const CarstockFavorites = async (req: CarstockFavoritesRequest) => {
    CarstockFavoritesController.ref = new AbortController()

    try {
        const response = await apiConfig.fetch.ilsa<CarstockFavoritesResponse>({
            url: '/ilsa/favorites',
            config: {
                method: 'GET',
                params: req.params,
                signal: CarstockFavoritesController.ref.signal,
            },
        })

        if (!response?.data?.success) {
            throw Error('Произошла ошибка при поиске избранного')
        }

        const meta = response.data.meta
        const columns = response.data.columns.map((column) => {
            if (column.id === 'picture') {
                column.type = 'image'
            }

            if (column.id === 'created_at') {
                column.type = 'datetime'
            }

            if (column.id === 'availability') {
                column.type = 'boolean'
            }

            if (column.id === 'name') {
                column.type = 'link'
            }

            return column
        })

        const rows = response.data.data.map((row) => {
            const newRow: TableModel['rows'][0] = {}

            const values = Object.values(row)
            const keys = Object.keys(row)

            values.forEach((cell, i) => {
                const column = columns.find((column) => column.id === keys[i])

                if (keys[i] === 'availability') {
                    cell.value = cell.value === 'IN_STOCK' ? 'Да' : 'Нет'
                }

                if (keys[i] === 'offer_id') {
                    cell.value = cell.value || String(cell.id)
                }

                newRow[keys[i]] = {
                    ...cell,
                    column,
                }
            })

            return newRow
        })

        return {
            meta,
            rows,
            columns,
        }
    } catch (err: any) {
        throwAdapterError(err, 'CarstockFavorites')
    }
}
