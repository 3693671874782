import React from 'react'
import { Box } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { FilterSelect } from '../filter-select'
import { FilterMultiSelect } from '../filter-multi-select'
import { FilterRangeSelect } from '../filter-range-select'
import { FilterGroupSelect } from '../filter-group-select'
import { CarstockFilterModel } from '../../models'
import { FilterMultiSelectCustom } from '../filter-multi-select-custom'

export interface FilterProps {
    filter: CarstockFilterModel
}

export const Filter: React.FC<FilterProps> = observer(({ filter, ...rest }) => {
    return (
        <Box {...rest}>
            {
                {
                    select: <FilterSelect filter={filter} />,
                    // 'multi-select': <FilterMultiSelect filter={filter} />,
                    'multi-select': <FilterMultiSelectCustom filter={filter} />,
                    'range-select': <FilterRangeSelect filter={filter} />,
                    'group-select': <FilterGroupSelect filter={filter} />,
                }[filter.data.type]
            }
        </Box>
    )
})
