import clsx from 'clsx'
import React from 'react'
import style from './index.style.scss'

interface ILoader {
    active?: boolean
    center?: boolean
    overlay?: boolean
    top?: boolean
}

/** @deprecated */
const Loader: React.FC<ILoader> = ({ active, center, overlay, top }) => {
    const classes = clsx(style.root, {
        [style.active]: active,
        [style.center]: center,
        [style.centerTop]: top,
        [style.overlay]: overlay,
    })
    const items = [1, 2, 3, 4, 5]

    return (
        <div className={classes}>
            <div className={clsx(style.container, { [style.top]: top })}>
                {items.map((i) => (
                    <div className={style.item} key={i} />
                ))}
            </div>
        </div>
    )
}

export { Loader }

export const useLoader = (param = false) => {
    const [active, setActive] = React.useState(param)

    return {
        active,
        setActive,
        Loader,
    }
}
