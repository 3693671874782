import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import { ControlButton } from '../../button'
import { observer } from 'mobx-react-lite'
import { store } from '@/pages/analytics/store'

interface IControlEditProps {
    classes?: {
        root?: string
    }
}

export const ControlEdit: React.FC<IControlEditProps> = observer(({ classes, ...rest }) => {
    const cls = { root: clsx(style.root, classes?.root) }

    const label = store.table.editRows.length ? 'Отменить' : 'Редактировать'

    const handleEdit = () => {
        if (store.table.editRows.length) {
            store.table.editRows = []
        } else {
            const toEditRows = store.table.rows.filter((row) => store.table.selected.includes(row.id.value))

            store.table.editRows = [...store.table.editRows, ...toEditRows]
        }
    }

    return (
        <div className={cls.root} {...rest}>
            <ControlButton icon='edit' label={label} onClick={handleEdit} />
        </div>
    )
})
