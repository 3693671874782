import React from 'react'
import { Button, Checkbox, Group } from '@mantine/core'
import { IconX } from '@tabler/icons-react'
import style from './reset.style.scss'
import { core } from '../../catalog.core'
import { observer } from 'mobx-react-lite'

interface ResetProps {}

export const Reset: React.FC<ResetProps> = observer(({ ...rest }) => {
    // const values = store.getFilterValues()

    const handleClick = () => {
        core.reset()
    }

    const handleChangeLegacy = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.currentTarget.checked

        core.changeLegacy(checked)
    }

    return (
        <Group spacing={20} className={style.root}>
            <Checkbox onChange={handleChangeLegacy} label={'Отображать legacy'} />
            <Button
                onClick={handleClick}
                rightIcon={<IconX size={20} />}
                className={style.button}
                // disabled={!Object.keys(values).length}
                {...rest}
            >
                Сбросить все
            </Button>
        </Group>
    )
})
