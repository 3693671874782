import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import { Button } from '@/pages/analytics/components/ui'
import { observer } from 'mobx-react-lite'
import { store } from '@/pages/analytics/store'

interface IControlResetProps {
    classes?: {
        root?: string
    }
}

export const ControlReset: React.FC<IControlResetProps> = observer(({ classes, ...rest }) => {
    const cls = { root: clsx(style.root, classes?.root) }

    return (
        <Button className={cls.root} {...rest} onClick={store.table.handleFiltersReset}>
            Сбросить все фильтры
        </Button>
    )
})
