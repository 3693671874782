import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import { AnalyticsTableAdd } from '../../../add'

interface IControlAddProps {
    classes?: {
        root?: string
    }
}

export const ControlAdd: React.FC<IControlAddProps> = ({ classes, ...rest }) => {
    const cls = { root: clsx(style.root, classes?.root) }

    return (
        <div className={cls.root} {...rest}>
            <AnalyticsTableAdd />
        </div>
    )
}
