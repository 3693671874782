import React from 'react'
import { Box, Container, LoadingOverlay, clsx } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import style from './car.style.scss'
import { CoreProps, core } from './car.core'
import { store } from './car.store'
import { Header, Options, Specifications } from './ui'

type CarWidgetProps = {
    coreProps: CoreProps
}

export const CarWidget: React.FC<CarWidgetProps> = observer(({ coreProps, ...rest }) => {
    React.useEffect(() => {
        const widget = core.init(coreProps)

        widget.mount()
    }, [])

    return (
        <div className={style.root} {...rest}>
            <Container>
                <Box className={style.wrapper}>
                    <LoadingOverlay visible={store.state.loading} radius={24} />

                    {store.state.init && (
                        <>
                            <div className={clsx(style.content, { [style.content_loading]: store.state.loading })}>
                                <Header />
                                <Specifications />
                                <Options />
                            </div>
                        </>
                    )}
                </Box>
            </Container>
        </div>
    )
})
