import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'
import { CarstockOption } from './options.types'
import { storage } from '@/shared'
import { sleep } from '@/shared'
import { CarstockOptionModel } from '@/widget/carstock/models'

type CarstockOptionsRequest = {
    params: {
        [property: string]: number
    }
}

type CarstockOptionsResponse = {
    data: CarstockOption[]
    success: boolean
}

export const CarstockFilterOptionsController = { ref: null }

export const CarstockOptions = async (req: CarstockOptionsRequest) => {
    CarstockFilterOptionsController.ref = new AbortController()

    try {
        const url = `/ilsa/attributes`
        const fromCache = storage.getWithExpiry(url + JSON.stringify(req.params))

        const response: ApiResponse<CarstockOptionsResponse> =
            fromCache ||
            (await apiConfig.fetch.ilsa({
                url,
                config: {
                    method: 'GET',
                    params: req.params,
                    signal: CarstockFilterOptionsController.ref.signal,
                },
            }))

        if (fromCache) {
            await sleep(600)
        }

        storage.setWithExpiry(url + JSON.stringify(req.params), response, 1000 * 1000)

        const options = response.data.data
            .filter((option) => option.type === 'BOOLEAN')
            .map((option) => {
                return {
                    ...option,
                    group: 'Базовые опции',
                    selected: false,
                    checkboxes: true,
                }
            })

        const model = new CarstockOptionModel({
            id: null,
            name: 'Опции',
            parent_id: null,
            groups: [
                {
                    name: 'Базовые опции',
                    options: options,
                },
            ],
            options: [],
            selected: [],
            type_param_key: 'carstock_options_ids',
        })

        return { options: [model] }
    } catch (err: any) {
        throwAdapterError(err, 'CarstockOptions')
    }
}
