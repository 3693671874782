import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'

export type CarstockTemplatesRemoveRequest = {
    data: number[]
}

export type CarstockTemplatesRemoveResponse = {
    success: boolean
}

export const CarstockTemplatesRemove = async (req: CarstockTemplatesRemoveRequest) => {
    try {
        const response = await apiConfig.fetch.ilsa<CarstockTemplatesRemoveResponse>({
            url: '/filter-templates',
            config: {
                method: 'DELETE',
                data: req.data,
            },
            mock: {
                enable: false,
                data: {
                    success: true,
                },
            },
        })

        if (!response?.data?.success) {
            throw Error('Произошла ошибка при удаление')
        }

        return response
    } catch (err: any) {
        throwAdapterError(err, 'CarstockTemplatesRemove')
    }
}
