import { ApiAnalyticsTypes } from '@/api'
import { service } from '@/api'
import { clone } from '@/shared'
import { makeAutoObservable } from 'mobx'
import { AppStoreController } from './controller'

export class AppStore {
    state = {
        init: false,
        loading: false,
        error: null,
    }
    controller: AppStoreController

    title = 'Аналитик'
    schemes: ApiAnalyticsTypes['getScheme']['res']['payload'] = []
    table: ApiAnalyticsTypes['getReference']['res'] = null

    constructor() {
        makeAutoObservable(this)
        this.controller = new AppStoreController(this)
    }

    async initialize() {
        try {
            this.setState({ loading: true })

            const schemes = await service.analytics.scheme()

            this.setSchemes(schemes)

            this.setState({ init: true })
        } catch (err: any) {
            console.log(err)
            this.setState({ loading: false, error: { message: err?.message, status: 400 } })
        }
    }

    setState(state: Partial<typeof this.state>) {
        this.state = {
            ...clone(this.state),
            ...state,
        }
    }

    setSchemes(schemes: typeof this.schemes) {
        this.schemes = schemes
    }

    getSchemeByPath(id: string, reference: string) {
        return this.schemes.find((scheme) => scheme.id === id)?.items.find((item) => item.path === `/${reference}`)
    }

    setTable(table: typeof this.table) {
        this.table = table
    }

    setTitle(title: typeof this.title) {
        this.title = title
    }

    // getScheme(id: string) {
    //     return this.schemes
    //         .find((scheme) => scheme.items.find((item) => item.id === id))
    //         ?.items.find((item) => item.id === id)
    // }
}
