import React from 'react'
import { useNavigate, Link } from 'react-router-dom'
import DocumentsStore from '../../store/DocumentsStore'
import styles from './HistoryItemActions.style.scss'

const HistoryItemActions = ({ data }) => {
    const navigate = useNavigate()
    const { deleteDocument, deleteDocumentFront } = DocumentsStore

    // data.entities

    const handleDeleteDocument = (event) => {
        event.stopPropagation()
        deleteDocument(data.id)
    }

    const handleDeleteDocumentFront = (event) => {
        event.stopPropagation()
        deleteDocumentFront(data.id)
    }

    switch (data.status.name) {
        case 'done':
            return (
                <div className={styles.container}>
                    <Link
                        className={styles.blueButton}
                        to={`/reference-book?document_id=${data.id}`}
                        onClick={(e) => e.stopPropagation()}
                        target='_blank'
                    >
                        Перейти к подбору
                    </Link>
                    <button className={styles.whiteButton} onClick={handleDeleteDocument}>
                        Удалить
                    </button>
                </div>
            )
        case 'pending':
            return (
                <div className={styles.container}>
                    <button className={styles.whiteButton}>Отменить</button>
                </div>
            )
        case 'new':
            return (
                <div className={styles.container}>
                    <button className={styles.whiteButton}>Отменить</button>
                </div>
            )
        case 'front-error':
            return (
                <div className={styles.container} onClick={handleDeleteDocumentFront}>
                    <button className={styles.whiteButton}>Удалить</button>
                </div>
            )
        default:
            return (
                <div className={styles.container}>
                    <button className={styles.whiteButton} onClick={handleDeleteDocument}>
                        Удалить
                    </button>
                </div>
            )
    }
}

export default HistoryItemActions
