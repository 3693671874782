import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import Instance, { TableContainerProps } from '@mui/material/TableContainer'
import { Scroll } from '@/theme/@deprecated/components/ui'

interface ITableContainerProps extends TableContainerProps {}

export const TableContainer: React.FC<ITableContainerProps> = ({ classes, children, ...rest }) => {
    const cls = { root: clsx(style.root, classes?.root) }

    return (
        <Instance classes={cls} {...rest}>
            <Scroll height={582}>{children}</Scroll>
        </Instance>
    )
}
