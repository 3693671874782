import { makeAutoObservable } from 'mobx'
import { ComplectationOptionModel, ComplectationModel, ComplectationPackageModel } from './models'
import { DefaultData } from './complectation.types'
import { R } from '@/shared'

type StoreState = State & {
    loadingOptions?: boolean
    loadedOptions?: boolean
}

type StoreData = {
    complectation: ComplectationModel
    basic_options: ComplectationOptionModel[]
    specific_options: ComplectationOptionModel[]
    packages: ComplectationPackageModel[]
    additional_equipment: string
    additional_equipment_price: number
    name: string
    default_data: DefaultData
}

const state: StoreState = {
    loading: false,
    init: false,
    error: null,
    saving: false,
    loadingOptions: false,
    loadedOptions: false,
}

export const initial_default_data = {
    id: { value: null },
    name: { value: null },
    complectation_id: { value: null },

    additional_equipment: { value: null },
    additional_equipment_price: { value: null },

    specific_color_id: [],
    specific_option_id: [],
    option_package_id: [],
    parameter_value_id: [],
}

const data: StoreData = {
    complectation: null,
    basic_options: [],
    specific_options: [],
    packages: [],
    additional_equipment: '',
    additional_equipment_price: 0,
    name: '',
    default_data: initial_default_data,
}

class Store {
    state = state
    data = data

    constructor() {
        makeAutoObservable(this)
    }

    /* -------------------------------------------------------------------------- */
    /*                                    Core                                    */
    /* -------------------------------------------------------------------------- */

    setState(states: Partial<StoreState>) {
        this.state = {
            ...this.state,
            ...states,
        }
    }

    setData(data: Partial<StoreData>) {
        this.data = {
            ...this.data,
            ...data,
        }
    }

    reset() {
        this.setState(state)
        this.setData(data)
    }

    /* -------------------------------------------------------------------------- */
    /*                                   Options                                  */
    /* -------------------------------------------------------------------------- */

    // get specific_options() {

    // }

    get specific_options_items(): ComplectationOptionModel['data']['items'] {
        const items = this.data.specific_options.reduce((acc, curr) => {
            curr.data.items.forEach((item) => {
                if (item.type === 'group') {
                    item.items.forEach((item_inner) => {
                        acc.push(item_inner)
                    })
                } else {
                    acc.push(item)
                }
            })

            return acc
        }, [])

        return items
    }

    getBasicOptionsItems(): ComplectationOptionModel['data']['items'] {
        const items = this.data.basic_options.reduce((acc, curr) => {
            curr.data.items.forEach((item) => {
                if (item.type === 'group') {
                    item.items.forEach((item_inner) => {
                        acc.push(item_inner)
                    })
                } else {
                    acc.push(item)
                }
            })

            return acc
        }, [])

        return items
    }

    getOptionByIdItem(id: number) {
        return this.data.specific_options.find((option) => {
            return option.data.items.find((item) => {
                if (item.type === 'group') {
                    return item.items.find((item_inner) => item_inner.id === id)
                }

                return item.id === id
            })
        })
    }

    // getSelectedOptionsValues() {
    // const values = {}
    // const filter = this.getOptionsFilter()
    // const values = this.data.options.reduce((acc, curr) => {
    //     const concat = acc.concat(curr.data.selected)
    // if (curr.data.type_param_key === 'carstock_options_ids') {
    //     concat.forEach((id) => {
    //         values[id] = '1'
    //     })
    // } else {
    // values[curr.data.type_param_key] = concat
    // }
    //     return concat
    // }, [])
    // return [filter.get.type(), values]
    // }

    getOptionsSelectedItems() {
        const options = this.specific_options_items
        const filtered = options.filter((option) => option.selected)

        return filtered
    }

    getOptionsSelectedItemsIds() {
        const items = this.getOptionsSelectedItems()

        const specific_option_id = []
        const parameter_value_id = []

        items.forEach((item) => {
            if (item.parameter_id === 'specific_option_id') {
                specific_option_id.push({ id: item.id })
            }

            if (item.parameter_id === 'parameter_value_id') {
                parameter_value_id.push({ id: item.id })
            }
        })

        return { specific_option_id, parameter_value_id }
    }

    /* -------------------------------------------------------------------------- */
    /*                                  Packages                                  */
    /* -------------------------------------------------------------------------- */

    get packages() {
        return this.data.packages
    }

    getPackageById(id: number) {
        return this.packages.find((item) => item.data.id === id)
    }

    getPackagesSelected() {
        const filtered = this.packages.filter((item) => item.data.selected)

        return filtered
    }

    getPackagesSelectedItemsIds() {
        const items = this.getPackagesSelected()

        return items.map((item) => ({ id: item.data.id }))
    }

    /* -------------------------------------------------------------------------- */
    /*                                    Menu                                    */
    /* -------------------------------------------------------------------------- */

    get menu_count() {
        const packages = this.getPackagesSelected()
        const options = this.getOptionsSelectedItems()
        const color = this.data.complectation.data.selected_color
        const is_exist_additional = this.is_exist_additional

        return packages.length + options.length + ((color && 1) || 0) + (is_exist_additional ? 1 : 0)
    }

    get rrc_amount() {
        const price = this.data.complectation.price_with_color
        const color = this.data.complectation.data.selected_color
        const packages = this.getPackagesSelected()
        const options = this.getOptionsSelectedItems()
        const additional_equipment = this.additional_equipment?.value || 0

        const packages_amount = packages.reduce((acc, curr) => acc + curr.data.price || 0, 0)
        const options_amount = options.reduce((acc, curr) => acc + curr.price || 0, 0)

        return { amount: price + packages_amount + options_amount + additional_equipment, packages, options, color }
    }

    getValuesForSave() {
        const complectation = this.data.complectation
        const option_package_id = this.getPackagesSelectedItemsIds()
        const { specific_option_id, parameter_value_id } = this.getOptionsSelectedItemsIds()

        const additional_equipment = store.data.additional_equipment
        const additional_equipment_price = store.data.additional_equipment_price
        const is_exist_additional = !!(additional_equipment && additional_equipment_price)

        const data = {
            id: null,
            complectation_id: { value: complectation.data.id },
            name: { value: this.data.name },
            specific_color_id: [{ id: complectation.color.id }],
            option_package_id,
            specific_option_id,
            parameter_value_id,
        }

        if (is_exist_additional) {
            data['additional_equipment'] = { value: additional_equipment }
            data['additional_equipment_price'] = { value: additional_equipment_price }
        }

        if (this.data.default_data.id?.value) {
            data['id'] = this.data.default_data.id
        }

        return R.filter((value) => value !== undefined && value !== null, data) as typeof data
    }

    /* -------------------------------------------------------------------------- */
    /*                                  Installed                                 */
    /* -------------------------------------------------------------------------- */

    get additional_equipment() {
        return this.is_exist_additional
            ? {
                  label: this.data.additional_equipment,
                  value: this.data.additional_equipment_price,
              }
            : null
    }

    get is_exist_additional() {
        return !!(this.data.additional_equipment && this.data.additional_equipment_price)
    }
}

export const store = new Store()
