import React from 'react'
import clsx from 'clsx'
import style from './cell-checkbox.style.scss'
import { Checkbox } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { store } from '@/widget/table/table.store'
import { TableRow } from '@/widget/table/table.types'
import { core } from '@/widget/table/table.core'

type CellCheckboxProps = React.HTMLAttributes<HTMLTableCellElement> & {
    row?: TableRow
}

export const CellCheckbox: React.FC<CellCheckboxProps> = observer(({ className, row, ...rest }) => {
    const isExist = store.isSelected(row)

    const handleClick = () => {
        core.select(row)
    }

    return (
        <div className={clsx(style.root, className, 'elisa-table-widget-cell')} {...rest}>
            <Checkbox checked={isExist} onChange={handleClick} />
        </div>
    )
})
