import React from 'react'
import { Center } from '@mantine/core'
import { AuthPageRoutes } from './auth.routes'
import style from './auth.style.scss'
import { MantineProvider } from '@/theme'
import { AppCheckAuth } from '@/app/app.lib'

interface AuthPageProps {}

export const AuthPage: React.FC<AuthPageProps> = ({ ...rest }) => {
    return (
        <MantineProvider withNotify>
            <AppCheckAuth isAuthPage />

            <Center className={style.root} {...rest}>
                <AuthPageRoutes />
            </Center>
        </MantineProvider>
    )
}
