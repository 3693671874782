import React from 'react'
import { Updater, useImmer } from 'use-immer'
import { store } from './carstock.store'
import { CarstockFilterModel } from './models'

type State = {
    disabled: boolean
}

export const useFilter = (filter: CarstockFilterModel): [State, Updater<State>] => {
    const [state, setState] = useImmer<State>({
        disabled: false,
    })

    const dependsOn = store.getFilterDependsOn(filter)

    React.useEffect(() => {
        /**
         * Если текущий фильтр зависим от других данных,
         * то он будет заблокироавн, пока не будут заполнены все параметры, от которых он зависим
         */
        if (dependsOn.length) {
            const isDisabled = dependsOn.some((depend) => {
                if (depend.data.type === 'range-select') {
                    return !depend.data.value?.some((value) => value?.id)
                }

                return !depend.data.value.length
            })

            if (isDisabled) {
                setState((draft) => {
                    draft.disabled = true
                })
            } else {
                setState((draft) => {
                    draft.disabled = false
                })
            }
        }
    }, [dependsOn])

    return [state, setState]
}
