import { CatalogCarGradeModel } from '../catalog-car-grade'
import { BaseModel } from '@/shared/core/model'

interface CatalogCarModificationProps {
    id: number
    modification_name: string
    engine_volume_l: string
    engine_power_hp: number
    engine_type_name: string
    transmission_type_name: string
    drive_type_name: string
    body_type_name: string
    complectations: CatalogCarGradeModel[]
}

export class CatalogCarModificationModel extends BaseModel<CatalogCarModificationProps, CatalogCarModificationModel> {
    constructor(props: CatalogCarModificationProps) {
        super(props, CatalogCarModificationModel)
    }
}
