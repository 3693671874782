import clsx from 'clsx'
import React from 'react'
import style from './index.style.scss'

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    error?: boolean
    sizes?: 'sm'
    groupClassName?: string
    autoComplete?: string
}

export const Input = React.forwardRef<HTMLInputElement, IInputProps>(
    ({ className, error, sizes, groupClassName, autoComplete, ...rest }, ref) => {
        const classes = clsx(style.root, style[`size-${sizes}`], { [style.error]: error }, className)

        return (
            <>
                {/* {icon ? (
                    <div className={clsx(style.group, groupClassName)}>
                        <input className={classes} ref={ref} {...rest} autoComplete={autoComplete} />
                        <Icon icon={icon} className={style.icon} />
                    </div>
                ) : (
                    <input className={classes} ref={ref} {...rest} autoComplete={autoComplete} />
                )} */}
            </>
        )
    }
)

Input.defaultProps = {
    autoComplete: 'off',
}
