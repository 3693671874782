import React from 'react'
import {
    Avatar,
    Box,
    Checkbox,
    Group,
    Loader,
    MultiSelect,
    ScrollArea,
    ScrollAreaProps,
    Sx,
    Text,
    useMantineTheme,
} from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { IconChevronDown } from '@tabler/icons-react'
import { styles } from '@/theme/styles'
import { useFilter } from '../filter'
import { core } from '@/widget/table/table.core'
import { TableFilterModel } from '@/widget/table/models'
import { tojs } from '@/shared'

interface FilterMultiSelectProps {
    filter: TableFilterModel
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    id: string
    value: string
    label: string

    description?: string
    selected: boolean
    hovered: boolean
    image?: string
    sx?: Sx | (Sx | undefined)[]
}

const SelectItem = React.forwardRef<HTMLDivElement, ItemProps>(
    ({ image, label, value, id, selected, hovered, sx, description, ...rest }: ItemProps, ref) => {
        return (
            <Box
                ref={ref}
                sx={sx}
                {...(selected ? { 'data-selected': '' } : {})}
                {...(hovered ? { 'data-hovered': '' } : {})}
                {...rest}
            >
                <Group noWrap>
                    <Checkbox size='xs' checked={selected} onChange={() => null} />
                    <Text>{label}</Text>
                </Group>
            </Box>
        )
    }
)

interface DropdownProps extends ScrollAreaProps {}

const Dropdown = React.forwardRef<HTMLDivElement, DropdownProps>(({ children, ...rest }: DropdownProps, ref) => {
    const theme = useMantineTheme()
    const styles = (theme.components.MultiSelect.styles as any)().item

    const child = children as any
    const props = child.props.children.props

    const data = props?.data || []
    const value = props?.value || []
    const hovered = props?.hovered

    const onItemSelect = props.onItemSelect
    const isItemSelected = props.isItemSelected
    const itemsRefs = props.itemsRefs

    const items = React.useMemo(() => {
        return data.map((curr: any, idx: number) => {
            const isSelected = isItemSelected(curr.value)

            return (
                <SelectItem
                    key={curr.id}
                    id={curr.id}
                    value={curr.value}
                    label={curr.label}
                    hovered={hovered === idx}
                    selected={isSelected}
                    sx={styles}
                    ref={(node: HTMLDivElement) => {
                        if (itemsRefs && itemsRefs.current && !itemsRefs.current[curr.value]) {
                            itemsRefs.current[curr.value] = node
                        }
                    }}
                    onMouseDown={() => {
                        onItemSelect(curr)
                    }}
                />
            )
        })
    }, [data])

    return (
        <ScrollArea ref={ref} {...rest}>
            {items}
        </ScrollArea>
    )
})

export const FilterMultiSelect: React.FC<FilterMultiSelectProps> = observer(({ filter, ...rest }) => {
    const [state] = useFilter(filter)
    const value = tojs(filter.data.value)?.map((value) => value.value)

    const handleChangeValue = (value: string[]) => {
        core.changeFilter(value, filter)
    }

    const handleDropdownOpen = async () => {
        // if (!filter.state.loading && core.data.changeFilters[filter.data.id]) {
        await core.loadFilterValues(filter)

        //     core.data.changeFilters[filter.data.id] = false
        // }
    }

    return (
        <Box {...rest}>
            <MultiSelect
                label={filter.data.label}
                placeholder={filter.data.placeholders[0]}
                value={value}
                data={filter.get.values()}
                searchable
                clearable
                disableSelectedItemFiltering
                onChange={handleChangeValue}
                rightSection={
                    filter.state.loading ? (
                        <Loader variant='dots' size={20} />
                    ) : value.length ? null : (
                        <IconChevronDown color={styles.secondary} size={16} />
                    )
                }
                onFocus={handleDropdownOpen}
                disabled={state.disabled}
                itemComponent={SelectItem}
                dropdownComponent={Dropdown}
            />
        </Box>
    )
})
