import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AuthForm, DroppedForm, ResetForm } from './ui'

interface AuthPageRoutesProps {}

export const AuthPageRoutes: React.FC<AuthPageRoutesProps> = () => {
    return (
        <Routes>
            <Route path='/' element={<AuthForm />} />
            <Route path='/reset' element={<ResetForm />} />
            <Route path='/dropped' element={<DroppedForm />} />
            <Route path='*' element={<Navigate to={'/'} />} />
        </Routes>
    )
}
