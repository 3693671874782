import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'

/**
 * id.1 - carstock
 * id.2 - catalog
 */
export type CarstockSearchSaveRequest = {
    id: 1 | 2
    name: string
    data?: {
        [property: string]: number | string
    }
}

export type CarstockSearchSaveResponse = {
    success: boolean
}

// const names = { 1: 'Авто в наличии', 2: 'Справочник ТС' }

export const CarstockSearchSave = async (req: CarstockSearchSaveRequest) => {
    try {
        const response = await apiConfig.fetch.ilsa<CarstockSearchSaveResponse>({
            url: '/filter-templates',
            config: {
                method: 'POST',
                data: [
                    {
                        filters: {
                            value: JSON.stringify(req.data),
                        },
                        name: {
                            value: req.name, // names[req.id],
                        },
                        filter_template_type_id: {
                            id: req.id,
                        },
                    },
                ],
            },
            mock: {
                enable: false,
                data: {
                    success: true,
                },
            },
        })

        if (!response?.data?.success) {
            throw Error('Произошла ошибка при сохранение шаблона поиска')
        }

        return response
    } catch (err: any) {
        throwAdapterError(err, 'CarstockSearchSave')
    }
}
