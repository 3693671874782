import { ApiAnalyticsTypes } from '@/api'
import { service } from '@/api'
import { notifyWarning } from '@/theme/@deprecated/components/ui'
import { makeAutoObservable } from 'mobx'
import { AppStore } from './store'

export class AppStoreController {
    store: AppStore

    constructor(store: AppStore) {
        makeAutoObservable(this)

        this.store = store
    }

    async get(req: ApiAnalyticsTypes['getReference']['req']) {
        const response = await service.analytics.reference_get(req)

        this.store.setTable(response)

        // if (!response?.data?.length) {
        //     notifyWarning('Значений таблицы не найдено')
        // }

        return response
    }

    async post(req: ApiAnalyticsTypes['createReference']['req']) {
        const response = await service.analytics.reference_create(req)

        return response
    }

    async update(req: ApiAnalyticsTypes['updateReference']['req']) {
        const response = await service.analytics.reference_update(req)

        return response
    }

    async delete(req: ApiAnalyticsTypes['deleteReference']['req']) {
        const response = await service.analytics.reference_delete(req)

        return response
    }
}
