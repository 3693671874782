import React from 'react'
import clsx from 'clsx'
import style from './cell.style.scss'
import { CellString } from '../cell-string'
import { CellNumber } from '../cell-number'
import { CellSelect } from '../cell-select'
import { TableCell, TableRow } from '@/widget/table/table.types'
import { CellImage } from '../cell-image'
import { CellDatetime } from '../cell-datetime'
import { CellBoolean } from '../cell-boolean'
import { CellLink } from '../cell-link'
import { CellMultiSelect } from '../cell-multi-select'
import { CellCheckbox } from '../cell-checkbox'

type CellProps = React.HTMLAttributes<HTMLTableCellElement> & {
    cell?: TableCell
    row?: TableRow
    idx?: number
    checkbox?: boolean
}

export const Cell: React.FC<CellProps> = ({ className, cell, idx, row, checkbox, ...rest }) => {
    return (
        <td className={clsx(style.root, { [style.checkbox]: checkbox }, className)} {...rest}>
            {!!cell &&
                {
                    string: <CellString cell={cell} />,
                    number: <CellNumber cell={cell} />,
                    select: <CellSelect cell={cell} />,
                    image: <CellImage cell={cell} />,
                    datetime: <CellDatetime cell={cell} />,
                    boolean: <CellBoolean cell={cell} />,
                    link: <CellLink idx={idx} cell={cell} />,
                    'multi-select': <CellMultiSelect cell={cell} />,
                }[cell.column.type]}

            {checkbox && <CellCheckbox row={row} />}
        </td>
    )
}
