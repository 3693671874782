import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'

export type AnalyticsReferenceCreateRequest = {
    url: string
    data: {
        name: {
            value: string
        }
        [property: string]: {
            id?: number
            value?: string
        }
    }[]
}

export type AnalyticsReferenceCreateResponse = {
    success: boolean
    message: string
}

export const AnalyticsReferenceCreate = async (req: AnalyticsReferenceCreateRequest) => {
    try {
        const response: ApiResponse<AnalyticsReferenceCreateResponse> =
            await apiConfig.fetch.ilsa<AnalyticsReferenceCreateResponse>({
                url: req.url,
                config: {
                    method: 'POST',
                    data: req.data,
                },
            })

        if (!response?.data?.success) {
            throw Error('Не удалось создать новую запись в таблице аналитика')
        }

        const payload = response.data

        return payload
    } catch (err: any) {
        throwAdapterError(err, 'AnalyticsReferenceCreate')
    }
}
