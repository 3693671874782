import React from 'react'
import { Box, Group, Input, Loader, NumberInput, Select, rem } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { IconChevronDown } from '@tabler/icons-react'
import { styles } from '@/theme/styles'
import { core } from '../../carstock.core'
import { debounce } from '@/shared'
import { useImmer } from 'use-immer'
import { useFilter } from '../../carstock.lib'
import { CarstockFilterModel } from '../../models'

interface FilterRangeSelectProps {
    filter: CarstockFilterModel
}

export const FilterRangeSelect: React.FC<FilterRangeSelectProps> = observer(({ filter, ...rest }) => {
    const [state] = useFilter(filter)
    const refInputs = React.useRef({
        left: null,
        right: null,
    })

    const value = filter.get.rangleValue()
    const values = filter.get.values()

    const types = filter.get.typesFormat()
    const typeValue = filter.get.typeValue()?.value || null

    const leftValue = value[0]?.value || null
    const rightValue = value[1]?.value || null

    const isWritable = filter.data.options?.writable

    const [localValues, setLocalValues] = useImmer<{
        left: any
        right: any
    }>({
        left: parseInt(leftValue) || '',
        right: parseInt(rightValue) || '',
    })

    const handleChangeValue = (value: string[]) => {
        core.changeFilter(value, filter)
    }

    const handleChangeType = (value: string) => {
        core.changeFilterType(value, filter)
    }

    const handleDropdownOpen = () => {
        core.loadFilterValues(filter)
    }

    const rightSection = (value: string) =>
        filter.state.loading ? (
            <Loader variant='dots' size={20} />
        ) : value ? null : (
            <IconChevronDown color={styles.secondary} size={16} />
        )

    const handleChangeDebounce = React.useCallback(
        debounce((value: string[]) => {
            // refInputs.current.left.value = value[0]
            // refInputs.current.right.value = value[1]
            handleChangeValue(value)
        }, 600),
        []
    )

    React.useEffect(() => {
        if (filter.data.value.length === 0 && (localValues.left || localValues.right)) {
            setLocalValues((draft) => {
                draft.left = ''
                draft.right = ''
            })
        }
    }, [filter.data.value])

    return (
        <div {...rest}>
            <Group spacing={16} mb={6} noWrap>
                <Input.Label>{filter.data.name}</Input.Label>
                {!!types.length && (
                    <Select
                        value={typeValue}
                        size='xs'
                        variant='unstyled'
                        data={types}
                        sx={{
                            // position: 'absolute',
                            // bottom: '100%',
                            // right: 0,
                            // marginBottom: 6,
                            marginLeft: 'auto',
                            flexShrink: 0,
                            '.mantine-Input-input': {
                                height: 24,
                                paddingRight: 30,
                                paddingLeft: 0,
                                textAlign: 'right',
                                maxWidth: '90px',
                                minHeight: 24,
                                fontSize: rem(14),
                            },
                            '.mantine-Select-item': {
                                padding: '2px 8px',
                                fontSize: rem(14),
                            },
                            '.mantine-Input-rightSection': {
                                cursor: 'pointer',
                                pointerEvents: 'none',
                            },
                        }}
                        onChange={handleChangeType}
                        disabled={state.disabled}
                    />
                )}
            </Group>
            <Group spacing={16} noWrap align='flex-end'>
                {!isWritable ? (
                    <Select
                        // label={filter.data.name}
                        placeholder={filter.data.label[0]}
                        value={filter.state.loading && !value.length ? null : leftValue}
                        data={values}
                        type='number'
                        searchable
                        clearable
                        onChange={(val) =>
                            handleChangeValue([val, parseInt(val) > parseInt(rightValue) ? val : rightValue])
                        }
                        rightSection={rightSection(leftValue)}
                        onDropdownOpen={handleDropdownOpen}
                        disabled={state.disabled}
                    />
                ) : (
                    <NumberInput
                        placeholder={filter.data.label[0]}
                        value={localValues.left}
                        onChange={(value) => {
                            const valRight =
                                value > localValues.right
                                    ? localValues.right && value
                                        ? value
                                        : localValues.right
                                    : localValues.right

                            setLocalValues((draft) => {
                                draft.left = value
                                draft.right = valRight
                            })

                            handleChangeDebounce([value, valRight])
                        }}
                        onKeyUp={({ currentTarget }) => {
                            currentTarget?.blur()
                            currentTarget?.focus()
                        }}
                        parser={(value) => value.replace(/ /g, '')}
                        formatter={(value) =>
                            !Number.isNaN(parseFloat(value))
                                ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ' ')
                                : ''
                        }
                        hideControls
                        min={1}
                        max={999999999999}
                        ref={(ref) => {
                            if (!refInputs.current.left) {
                                refInputs.current.left = ref
                            }
                        }}
                    />
                )}
                <Box sx={{ position: 'relative' }}>
                    {!isWritable ? (
                        <Select
                            placeholder={filter.data.label[1]}
                            value={filter.state.loading && !value.length ? null : rightValue}
                            data={values}
                            type='number'
                            searchable
                            clearable
                            onChange={(val) =>
                                handleChangeValue([parseInt(val) < parseInt(leftValue) ? val : leftValue, val])
                            }
                            rightSection={rightSection(rightValue)}
                            onDropdownOpen={handleDropdownOpen}
                            disabled={state.disabled}
                        />
                    ) : (
                        <NumberInput
                            placeholder={filter.data.label[1]}
                            value={localValues.right}
                            onChange={(value) => {
                                const valLeft =
                                    value < localValues.left
                                        ? localValues.left && value
                                            ? value
                                            : localValues.left
                                        : localValues.left

                                setLocalValues((draft) => {
                                    draft.left = valLeft
                                    draft.right = value
                                })

                                handleChangeDebounce([valLeft, value])
                            }}
                            onKeyUp={({ currentTarget }) => {
                                currentTarget?.blur()
                                currentTarget?.focus()
                            }}
                            parser={(value) => value.replace(/ /g, '')}
                            formatter={(value) =>
                                !Number.isNaN(parseFloat(value))
                                    ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ' ')
                                    : ''
                            }
                            hideControls
                            min={1}
                            max={999999999999}
                            ref={(ref) => {
                                if (!refInputs.current.right) {
                                    refInputs.current.right = ref
                                }
                            }}
                            // component={IMaskInput}
                            // mask={Number}
                            // onAccept={(value, mask) => {
                            //     const val = value.replaceAll(' ', '')

                            //     handleChangeDebounce([parseInt(val) < parseInt(leftValue) ? val : leftValue, val])
                            // }}
                            // {...{ thousandsSeparator: ' ' }}
                        />
                    )}
                </Box>
            </Group>
        </div>
    )
})
