import React from 'react'
import { observer } from 'mobx-react-lite'
import style from './index.style.scss'

// @ts-ignore
export const DataPicker = observer(({ data, onChange }) => {
    const date = data.value ? data.value.split('.').reverse().join('-') : null

    const onChangeHandler = (event) => {
        const date = event.target.value.split('-').reverse().join('.')

        onChange({ ...data, value: date })
    }

    return (
        <label className={style.root}>
            <div className={style.label}>{data.label}</div>
            <input className={style.input} type='date' value={date} onChange={onChangeHandler} />
        </label>
    )
})
