import React from 'react'
import style from './index.style.scss'
import Instance from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'
import AppBar from '@mui/material/AppBar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { observer } from 'mobx-react-lite'
import { store } from '@/pages/analytics/store'

interface IToolbarProps {
    setOpen: ReactSetState<boolean>
    open: boolean
}

export const Toolbar: React.FC<IToolbarProps> = observer(({ setOpen, open, ...rest }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const isOpen = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleOpen = React.useCallback(() => {
        setOpen(!open)
    }, [open])

    return (
        <AppBar color='inherit' position='static' className={style.paper}>
            <Instance className={style.root} {...rest}>
                <IconButton
                    size='large'
                    edge='start'
                    color='inherit'
                    aria-label='menu'
                    onClick={handleOpen}
                    className={style.button}
                >
                    <MenuIcon className={style.icon} />
                </IconButton>

                <h2 className={style.title}>{store.app.title}</h2>

                <IconButton
                    size='large'
                    edge='start'
                    color='inherit'
                    onClick={handleClick}
                    className={style.accountButton}
                >
                    {/* <AccountCircleIcon className={style.accountIcon} /> */}
                </IconButton>

                <Menu id='basic-menu' anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
                    <MenuItem onClick={handleClose} className={style.menuItem}>
                        <AccountCircleIcon className={style.menuIcon} />
                        Профиль
                    </MenuItem>
                    <MenuItem onClick={handleClose} className={style.menuItem}>
                        <LogoutIcon className={style.menuIcon} />
                        Выход
                    </MenuItem>
                </Menu>
            </Instance>
        </AppBar>
    )
})
