import { default as theme } from './index.scss'

export const styles: {
    background: string
    border: string
    borderDark: string
    white: string
    primary: string
    secondary: string
    tertiary: string
    accent: string
    accentDark: string
    accentLight: string
    blue: string
    blueLight: string
    dark: string
} = theme as any
