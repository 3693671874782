import React from 'react'
import { Box, Group, Input, Loader, Select, rem } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { IconChevronDown } from '@tabler/icons-react'
import { styles } from '@/theme/styles'
import { core } from '../../catalog.core'
import { useFilter } from '../../catalog.lib'
import { CatalogFilterModel } from '../../models'

interface FilterRangeSelectProps {
    filter: CatalogFilterModel
}

export const FilterRangeSelect: React.FC<FilterRangeSelectProps> = observer(({ filter, ...rest }) => {
    const [state] = useFilter(filter)

    const value = filter.get.rangleValue()
    const values = filter.get.values()

    const leftValue = value[0]?.value || null
    const rightValue = value[1]?.value || null

    const type = filter.get.type()
    const types = filter.get.types()

    const handleChangeValue = (value: string[]) => {
        core.changeFilter(value, filter)
    }

    const handleChangeType = (value: string) => {
        core.changeFilterType(value, filter)
    }

    const handleDropdownOpen = () => {
        core.loadFilterValues(filter)
    }

    const rightSection = (value: string) =>
        filter.state.loading ? (
            <Loader variant='dots' size={20} />
        ) : value ? null : (
            <IconChevronDown color={styles.secondary} size={16} />
        )

    return (
        <div {...rest}>
            <Group spacing={16} mb={6} noWrap>
                <Input.Label>{filter.data.label}</Input.Label>
                {!!types.length && (
                    <Select
                        value={type}
                        size='xs'
                        variant='unstyled'
                        data={types}
                        sx={{
                            marginLeft: 'auto',
                            flexShrink: 0,
                            '.mantine-Input-input': {
                                height: 24,
                                paddingRight: 30,
                                paddingLeft: 0,
                                textAlign: 'right',
                                maxWidth: '90px',
                                minHeight: 24,
                                fontSize: rem(14),
                            },
                            '.mantine-Select-item': {
                                padding: '2px 8px',
                                fontSize: rem(14),
                            },
                            '.mantine-Input-rightSection': {
                                cursor: 'pointer',
                                pointerEvents: 'none',
                            },
                        }}
                        onChange={handleChangeType}
                        disabled={state.disabled}
                    />
                )}
            </Group>
            <Group spacing={16} noWrap align='flex-end'>
                <Select
                    // label={filter.data.label}
                    placeholder={filter.data.placeholders[0]}
                    value={leftValue}
                    data={filter.state.loading && !value.length ? [] : values}
                    type='number'
                    searchable
                    clearable
                    onChange={(val) =>
                        handleChangeValue([val, parseFloat(val) > parseFloat(rightValue) ? val : rightValue])
                    }
                    rightSection={rightSection(leftValue)}
                    onDropdownOpen={handleDropdownOpen}
                    disabled={state.disabled}
                />
                <Box sx={{ position: 'relative' }}>
                    <Select
                        placeholder={filter.data.placeholders[1]}
                        value={rightValue}
                        data={filter.state.loading && !value.length ? [] : values}
                        type='number'
                        searchable
                        clearable
                        onChange={(val) =>
                            handleChangeValue([parseFloat(val) < parseFloat(leftValue) ? val : leftValue, val])
                        }
                        rightSection={rightSection(rightValue)}
                        onDropdownOpen={handleDropdownOpen}
                        disabled={state.disabled}
                    />
                </Box>
            </Group>
        </div>
    )
})
