import { styles } from '@/theme/styles'
import { Badge, Sx } from '@mantine/core'
import React from 'react'

interface CountProps {
    visible: boolean
    count: number
    sx?: Sx | Sx[]
}

export const Count: React.FC<CountProps> = ({ visible, count, sx, ...rest }) => {
    return (
        <Badge
            variant='filled'
            bg={visible ? styles.accent : 'transparent'}
            sx={{
                fontSize: 14,
                height: 20,
                padding: '0 16px',
                borderRadius: 200,
                position: 'absolute',
                top: 8,
                right: 0,
                transform: 'translateX(100%)',
                ...sx,
            }}
            {...rest}
        >
            {count}
        </Badge>
    )
}
