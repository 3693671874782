import React from 'react'

export const useDrag = () => {
    const [clicked, setClicked] = React.useState(false)
    const [dragging, setDragging] = React.useState(false)
    const position = React.useRef(0)

    const dragStart = React.useCallback((ev: React.MouseEvent) => {
        position.current = ev.clientX
        setClicked(true)
    }, [])

    const dragStop = React.useCallback(
        () =>
            // NOTE: need some delay so item under cursor won't be clicked
            window.requestAnimationFrame(() => {
                setDragging(false)
                setClicked(false)
            }),
        []
    )

    const dragMove = (ev: React.MouseEvent, cb: (posDif: number) => void) => {
        const newDiff = position.current - ev.clientX

        const movedEnough = Math.abs(newDiff) > 5

        if (clicked && movedEnough) {
            setDragging(true)
        }

        if (dragging && movedEnough) {
            position.current = ev.clientX
            cb(newDiff)
        }
    }

    return {
        dragStart,
        dragStop,
        dragMove,
        dragging,
        position,
        setDragging,
    }
}

export function onDragWheel(apiObj: any, ev: React.WheelEvent): void {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15

    if (isThouchpad) {
        ev.stopPropagation()
        return
    }

    if (ev.deltaY < 0) {
        apiObj.scrollNext()
    } else if (ev.deltaY > 0) {
        apiObj.scrollPrev()
    }
}

export const useMounted = () => {
    const mounted = React.useRef(false)

    React.useEffect(() => {
        mounted.current = true

        return () => {
            mounted.current = false
        }
    }, [])

    return mounted.current
}
