import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import { ControlButton } from '../../button'

interface IControlTagProps {
    classes?: {
        root?: string
    }
    label: string
    onClick?: React.HTMLAttributes<HTMLElement>['onClick']
}

export const ControlTag: React.FC<IControlTagProps> = ({ classes, label, ...rest }) => {
    const cls = { root: clsx(style.root, classes?.root) }

    return <ControlButton classes={cls} label={label} icon='cross' {...rest} endIcon />
}
