import { showToastError } from '@/shared/vendors/mantine'
import { store } from './car.store'
import { CarModel } from './models'

type CoreService = {
    car: {
        request: (props: {
            data: {
                id: string
            }
        }) => Promise<{
            car: CarModel
        }>
        controller: { ref: AbortController }
    }
}

type CoreParams = {
    car_id: string
}

export type CoreProps = {
    service: CoreService
    params: CoreParams
}

class Core {
    service: CoreService = {
        car: {
            request: null,
            controller: { ref: null },
        },
    }
    params: CoreParams = {
        car_id: null,
    }

    init(props: CoreProps) {
        Object.values(this.service || {}).forEach((service) => {
            service?.controller?.ref?.abort?.()
        })

        this.service = {
            car: {
                request: null,
                controller: { ref: null },
            },
        }
        this.params = { car_id: null }

        this.service = props.service || {
            car: {
                request: null,
                controller: { ref: null },
            },
        }
        this.params = props.params || { car_id: null }

        store.reset()

        return this
    }

    async mount() {
        try {
            if (store.state.init) return

            store.setState({ loading: true })

            const { car } = await this.service.car.request({ data: { id: this.params.car_id } })

            store.setData({ car })
            store.setState({ init: true, loading: false, loaded: true })
        } catch (err) {
            console.log(err)
            store.setState({ init: false, loading: false, loaded: false })
            showToastError({ message: err?.message })
        }
    }
}

export const core = new Core()
