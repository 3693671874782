import clsx from 'clsx'
import React from 'react'
import { Modal as ModalComponent, ModalProps } from 'react-responsive-modal'
import ModalBody from './body'
import ModalHeader from './header'
import style from './index.style.scss'
import { IconX } from '@tabler/icons-react'

export interface IModalProps extends ModalProps {
    open: boolean
    onClose: any
    setOpen: any
    // icon?: Icons
    className?: string
}

interface IModalDotNotaion extends React.ForwardRefExoticComponent<IModalProps & React.RefAttributes<HTMLDivElement>> {
    Header: typeof ModalHeader
    Body: typeof ModalBody
}

export const Modal = React.forwardRef<HTMLDivElement, IModalProps>(
    // eslint-disable-next-line no-unused-vars
    ({ className, open, onClose, children, setOpen, classNames, ...props }, ref) => {
        const classes = clsx(style.root, className)
        const refTimeout = React.useRef(null)

        const addPaddingRightToBody = React.useCallback(() => {
            const padding = window.innerWidth - document.body.clientWidth

            document.body.style.setProperty('padding-right', `${padding}px`)
            document.body.classList.add(style.body)
        }, [])

        const removePaddingRightFromBody = React.useCallback(() => {
            if (document.body.style.paddingRight) {
                refTimeout.current = setTimeout(() => {
                    document.body.style.setProperty('padding-right', '0')
                    document.body.classList.remove(style.body)
                }, 200)
            }
        }, [])

        React.useEffect(() => {
            if (open) {
                if (!document.body.classList.contains(style.body)) {
                    addPaddingRightToBody()
                }
            } else {
                removePaddingRightFromBody()
            }
        }, [open])

        React.useEffect(() => {
            return () => {
                document.body.style.setProperty('padding-right', '0')
                document.body.classList.remove(style.body)

                clearTimeout(refTimeout.current)
            }
        }, [])

        return (
            <ModalComponent
                ref={ref}
                open={open}
                onClose={onClose}
                center
                closeIcon={<IconX className={clsx(style.icon, classNames?.closeIcon)} />}
                classNames={{
                    modal: classes,
                    closeButton: clsx(style.close, classNames?.closeButton),
                }}
                focusTrapped={false}
                {...props}
            >
                {children}
            </ModalComponent>
        )
    }
) as IModalDotNotaion

Modal.defaultProps = {
    // icon: 'Close',
}

Modal.Header = ModalHeader
Modal.Body = ModalBody
