import React from 'react'
import { Box, clsx, Collapse, Group } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { CarGradeCard } from '../car-grade-card'
import style from './car-modification-card.style.scss'
import { CatalogCarModificationModel } from '../../models'

interface CarModificationCardProps {
    modification: CatalogCarModificationModel
    defaultOpen?: boolean
}

export const CarModificationCard: React.FC<CarModificationCardProps> = ({ modification, defaultOpen, ...rest }) => {
    const [opened, { toggle }] = useDisclosure(defaultOpen || false)

    return (
        <Box className={style.root} {...rest}>
            <Group position='center' className={clsx(style.title, { [style.active]: opened })}>
                <button className={style.button} onClick={toggle}>
                    <span>{modification.data.modification_name}</span>
                    <span className={style.show}></span>
                </button>
            </Group>
            <Collapse in={opened}>
                <div className={style.grid}>
                    {modification.data.complectations.map((complectation, i) => {
                        return <CarGradeCard key={i} complectation={complectation} />
                    })}
                </div>
            </Collapse>
        </Box>
    )
}
