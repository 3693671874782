import { AnalyticsTable } from '@/pages/analytics/components/table'
import { useTableSearchParams } from '@/pages/analytics/components/table/hooks/params'
import { Container } from '@/pages/analytics/components/ui'
import { store } from '@/pages/analytics/store'
import { notifyError } from '@/theme/@deprecated/components/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'
import style from './index.style.scss'

const residualSchemes = [
    {
        id: 'interactive',
        title: 'Интерактивные модули',
        items: [
            {
                id: 'modifications',
                path: '/modifications',
                api: '/modifications',
                title: 'Остаточные стоимости',
            },
        ],
    },
]
export const residualScheme = residualSchemes[0].items[0]

interface AnalyticsResidualScreenProps {}

export const AnalyticsResidualScreen: React.FC<AnalyticsResidualScreenProps> = observer(({ ...rest }) => {
    const tableSearchParams = useTableSearchParams()

    const handleGetTable = React.useCallback(async () => {
        try {
            /** Первая загрузка */
            if (!store.app.table) {
                store.app.setState({ loading: true })
            }

            store.table.setState({ loading: true })

            await store.app.controller.get({
                url: residualScheme.api,
                data: {
                    ...tableSearchParams.data,
                    minimal_column_set: true,
                },
            })
        } catch (err) {
            console.log(err)
            notifyError(err?.message)
        } finally {
            /** Первая загрузка */
            if (store.app.state.loading) {
                store.app.setState({ loading: false })
            }
            store.table.setState({ loading: false })
        }
    }, [tableSearchParams])

    React.useEffect(() => {
        store.app.setTitle('Остаточные стоимости')
    }, [])

    React.useEffect(() => {
        handleGetTable()
    }, [handleGetTable])

    return (
        <div className={style.root} {...rest}>
            <Container fluid>
                {store.app.table && (
                    <AnalyticsTable
                        schemes={residualSchemes}
                        scheme={residualScheme}
                        handlesRows={{
                            get: handleGetTable,
                            create: (() => ({})) as any,
                            update: (() => ({})) as any,
                            delete: (() => ({})) as any,
                        }}
                        data={{
                            columns: store.app.table.columns,
                            rows: store.app.table.data,
                            size: parseInt(store.app.table.meta?.per_page),
                            page: store.app.table.meta?.current_page - 1,
                            count: store.app.table.meta?.total,
                            filters: tableSearchParams.forTable,
                        }}
                        mode='view'
                        // data={{
                        //     columns: [
                        //         {
                        //             editable: false,
                        //             id: 'id',
                        //             label: 'ID',
                        //             type: 'number',
                        //         },
                        //         {
                        //             editable: true,
                        //             id: 'name',
                        //             label: 'Название',
                        //             type: 'string',
                        //         },
                        //         {
                        //             editable: false,
                        //             id: 'brand_id',
                        //             label: 'Марка',
                        //             type: 'string',
                        //         },
                        //         {
                        //             editable: false,
                        //             id: 'model_id',
                        //             label: 'Модель',
                        //             type: 'string',
                        //         },
                        //     ],
                        //     rows: [
                        //         {
                        //             id: { value: 1 },
                        //             name: { value: 'UX 250h' },
                        //             brand_id: { id: 81, value: 'EXEED' },
                        //             model_id: { id: 1170, value: 'TXL' },
                        //         },
                        //         {
                        //             id: { value: 2 },
                        //             name: { value: 'G30 LCI' },
                        //             brand_id: { id: 103, value: 'BMW' },
                        //             model_id: { id: 1551, value: '5 Series' },
                        //         },
                        //     ],
                        //     size: 5,
                        //     page: 0,
                        //     count: 2,
                        //     filters: tableSearchParams.forTable,
                        // }}
                        options={{
                            editPage: {
                                modifications: true,
                            },
                            archiveMode: {
                                modifications: false,
                            },
                        }}
                    />
                )}
            </Container>
        </div>
    )
})
