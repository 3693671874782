import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'

type UsersUpdateRequest = {
    data: {
        id: string
        login: string
        first_name: string
        family_name: string
        email: string
        city: string
        roles: string[]
        brands?: string[]
        status: string
    }
}

type UsersUpdateResponse = {
    success: boolean
}

export const UsersUpdate = async (req: UsersUpdateRequest) => {
    try {
        const response = await apiConfig.fetch.ilsa<UsersUpdateResponse>({
            url: '/auth/users',
            config: {
                method: 'PUT',
                data: JSON.stringify({
                    ...req.data,
                    roles: req.data.roles.map((role) => parseInt(role)),
                    brands: req.data.brands.map((brand) => parseInt(brand)),
                    status: parseInt(req.data.status),
                    is_active: parseInt(req.data.status) ? true : false,
                }),
            },
        })

        if (!response?.data?.success) {
            throw Error('Не удалось обновить пользователей')
        }

        return response
    } catch (err: any) {
        throwAdapterError(err, 'UsersUpdate')
    }
}
