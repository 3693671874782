import { useForm } from '@mantine/form'
import { TextInput, PasswordInput, Text, Paper, Group, Button, Divider, Checkbox, Anchor, Stack } from '@mantine/core'
import React from 'react'
import { IconShieldLock } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'
import { appStore } from '@/app/app.store'
import { styles } from '@/theme/styles'

type AuthFormProps = {}

export const AuthForm: React.FC<AuthFormProps> = (props) => {
    const navigate = useNavigate()
    const form = useForm({
        initialValues: {
            email: '',
            password: '',
            remember: true,
        },

        validate: {
            email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Не корректный email'),
            password: (val) => (val.length <= 6 ? 'Пароль должен содержать не менее 6 симво' : null),
        },
    })

    const handleSubmit = form.onSubmit((data) => {
        appStore.login(data)
    })

    return (
        <Paper mx={20} radius={32} py={32} px={32} sx={{ width: 460 }} {...props}>
            <Text size='lg' mb={16} weight={500} align='center'>
                Добро пожаловать в elisa-project
            </Text>

            {/* <Group grow position='center' mb='md' mt='md' hidden>
                <Button
                    size='xs'
                    leftIcon={<IconShieldLock size={16} color={styles.accent} />}
                    variant='default'
                    h={36}
                    sx={{ fontSize: 14 }}
                >
                    SSO
                </Button>
            </Group> */}

            <Divider label='Продолжить с email' sx={{ '& >div': { fontSize: 12 } }} labelPosition='center' mb='lg' />

            <form onSubmit={handleSubmit}>
                <Stack>
                    <TextInput
                        required
                        label='Email'
                        placeholder='name@elisa.ru'
                        value={form.values.email}
                        onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
                        error={form.errors.email && 'Не корректный email'}
                    />

                    <PasswordInput
                        required
                        label='Пароль'
                        placeholder='Ваш пароль'
                        value={form.values.password}
                        onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
                        error={form.errors.password && 'Пароль должен содержать не менее 6 символов'}
                    />
                    <Group position='apart' mt='lg' hidden>
                        <Checkbox
                            label='Запомнить меня'
                            checked={form.values.remember}
                            onChange={(event) => form.setFieldValue('remember', event.currentTarget.checked)}
                        />
                        <Anchor onClick={() => navigate('/reset')} color={styles.accent} size='sm'>
                            Забыли пароль?
                        </Anchor>
                    </Group>
                </Stack>

                <Group position='apart' mt='xl'>
                    <Button type='submit' size='sm' fullWidth>
                        Войти
                    </Button>
                </Group>
            </form>
        </Paper>
    )
}
