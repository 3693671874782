import React from 'react'
import style from './options.style.scss'
import { Paper } from '../paper'
import { observer } from 'mobx-react-lite'
import { store } from '../../complectation.store'
import { Box, LoadingOverlay, Tabs } from '@mantine/core'
import { OptionsSearch } from './ui/options-search'
import { OptionsSelected } from './ui/options-selected'
import { core } from '../../complectation.core'
import { OptionCheckbox } from './ui/option-checkbox'
import { Masonry } from 'react-plock'
import { OptionsPackages } from './ui/options-packages'
import { OptionsEmpty } from './ui/empty'
// import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'

type OptionsProps = {}

export const Options: React.FC<OptionsProps> = observer(() => {
    const basic_options = store.data.basic_options
    const specific_options = store.data.specific_options
    const is_hide_tabs = store.state.loadedOptions && basic_options.length === 0 && specific_options.length === 0
    const has_options = store.data.complectation.data.has_options

    const handleOpen = () => {
        core.loadOptions()
    }

    const handleChange = (e: React.MouseEvent<HTMLDivElement>) => {
        const target = (e.target as any).closest('[data-option-id]')

        if (target && target.dataset.optionId) {
            const id = parseInt((e.target as any).closest('[data-option-id]').dataset.optionId)

            if (id) {
                core.toggleSelectedOption(id)
            }
        }
    }

    return has_options ? (
        <Paper title='Опции' className={style.root} onOpen={handleOpen}>
            <div>
                {!is_hide_tabs && <OptionsSearch />}

                <OptionsSelected />
                <OptionsPackages />

                <LoadingOverlay
                    loaderProps={{ variant: 'bars' }}
                    visible={store.state.loadingOptions}
                    overlayOpacity={0.5}
                />

                {store.state.loadedOptions && (
                    <>
                        <Tabs variant='default' defaultValue={'basic'} display={is_hide_tabs ? 'none' : 'block'}>
                            <Tabs.List>
                                <Tabs.Tab value={'basic'} className={style.tab}>
                                    Базовые опции
                                </Tabs.Tab>
                                <Tabs.Tab value={'additional'} className={style.tab}>
                                    Дополнительные опции
                                </Tabs.Tab>
                            </Tabs.List>

                            <Tabs.Panel value={'basic'}>
                                {!store.state.loadingOptions && (
                                    <div className={style.inner} onClick={handleChange}>
                                        {/* <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 768: 2 }}>
                                    <Masonry>
                                        {basic_options.map((option) => (
                                            <div key={option.data.id} className={style.option}>
                                                <div className={style.grid}>
                                                    {option.data.items.map((item) => (
                                                        <OptionCheckbox item={item} key={item.id} />
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </Masonry>
                                </ResponsiveMasonry> */}
                                        {/* if (i % 2 == 0) {
                                if (i % 2 != 0) { */}
                                        <Masonry
                                            items={basic_options}
                                            config={{
                                                columns: [1, 2],
                                                gap: [0, 0],
                                                media: [768, 1024],
                                            }}
                                            render={(option) => (
                                                <div key={option.data.id} className={style.option}>
                                                    <div className={style.grid}>
                                                        {option.data.items.map((item) => {
                                                            if (item.type !== 'group') {
                                                                return <OptionCheckbox item={item} key={item.id} />
                                                            }

                                                            return item.items.map((item_inner) => (
                                                                <OptionCheckbox item={item_inner} key={item_inner.id} />
                                                            ))
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </div>
                                )}
                            </Tabs.Panel>

                            <Tabs.Panel value={'additional'}>
                                {!store.state.loadingOptions && (
                                    <div className={style.inner} onClick={handleChange}>
                                        <Masonry
                                            items={specific_options}
                                            config={{
                                                columns: [1, 2],
                                                gap: [0, 0],
                                                media: [768, 1024],
                                            }}
                                            render={(option) => (
                                                <div key={option.data.id} className={style.option}>
                                                    <div className={style.grid}>
                                                        {option.data.items.map((item) => {
                                                            if (item.type !== 'group') {
                                                                return (
                                                                    <OptionCheckbox
                                                                        item={item}
                                                                        key={item.id}
                                                                        isParameter={
                                                                            item.parameter_id === 'parameter_value_id'
                                                                        }
                                                                    />
                                                                )
                                                            }

                                                            return item.items.map((item_inner) => (
                                                                <OptionCheckbox
                                                                    item={item_inner}
                                                                    key={item_inner.id}
                                                                    isParameter={
                                                                        item_inner.parameter_id === 'parameter_value_id'
                                                                    }
                                                                />
                                                            ))
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </div>
                                )}
                            </Tabs.Panel>
                        </Tabs>

                        <Tabs variant='default' defaultValue={'basic'} display={is_hide_tabs ? 'block' : 'none'}>
                            <Tabs.List>
                                <Tabs.Tab value={'basic'}>Базовые опции</Tabs.Tab>
                            </Tabs.List>

                            <Tabs.Panel value={'basic'}>
                                <Box component={OptionsEmpty} mt={20} />
                            </Tabs.Panel>
                        </Tabs>
                    </>
                )}
            </div>
        </Paper>
    ) : null
})
