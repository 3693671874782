import React from 'react'
import { RowCharacteristics } from './characteristics'
import { Container } from '@/pages/analytics/components/ui'

// @ts-ignore
export const RowFields = ({ data, scheme }) => {
    return (
        <Container>
            {/* @ts-ignore */}
            <RowCharacteristics data={data} scheme={scheme} />
        </Container>
    )
}
