import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'

type UsersDisableRequest = {
    ids: string[]
}
type UsersDisableResponse = {
    success: boolean
    message: string
}

export const UsersDisable = async (req: UsersDisableRequest) => {
    try {
        const response = await apiConfig.fetch.ilsa<UsersDisableResponse>({
            url: '/auth/users/mass_inactive',
            config: {
                method: 'POST',
                data: req.ids,
            },
        })

        if (!response?.data?.success) {
            throw Error('Не удалось активировать пользователей')
        }

        return response
    } catch (err: any) {
        throwAdapterError(err, 'UsersDisable')
    }
}
