import React from 'react'
import style from './index.style.scss'
import { ControlButton } from '../../button'
import { observer } from 'mobx-react-lite'
import { store } from '@/pages/analytics/store'
import { useSearchParams } from 'react-router-dom'
import { useModalAlert } from '@/theme/@deprecated/components/modal/alert'
import { service } from '@/api'
import { useTableSearchParams } from '@/pages/analytics/components/table/hooks/params'

interface IControlReportProps {}

export const ControlReport: React.FC<IControlReportProps> = observer(({ ...rest }) => {
    const [searchParams] = useSearchParams()
    const tableSearchParams = useTableSearchParams()
    const { Modal: ModalAlert, ...modalAlertState } = useModalAlert()
    const { Modal: ModalAlertFail, ...modalAlertFailState } = useModalAlert()
    const [filelink, setFilelink] = React.useState('')

    const handleClick = async () => {
        try {
            store.app.setState({ loading: true })

            const file = await service.analytics.export({
                id: store.table.scheme?.report,
                params: tableSearchParams.data,
            })
            const filelink = `${file.link}?${searchParams.toString()}`

            modalAlertState.setOpen(true)

            setFilelink(filelink)

            try {
                const a = document.createElement('a')
                a.href = filelink
                // a.target = '_blank'
                a.download = `${store.table.scheme?.report}_report.${file.extension}`
                document.body.appendChild(a)

                a.click()
                document.body.removeChild(a)
            } catch (err) {
                console.log(err)
            }

            store.app.setState({ loading: false })
        } catch (err) {
            console.log(err)
            store.app.setState({ loading: false })
            modalAlertFailState.setOpen(true)
        }
    }

    const handleAgainClick = () => {
        modalAlertState.setOpen(false)
        console.log(filelink)
        window.open(filelink, '_blank')
    }

    return (
        <div className={style.root} {...rest}>
            <ModalAlert
                title='Загрузка отчета'
                label={
                    <div>
                        Загрузка PDF-файла должна начаться автоматически, если этого не произошло, нажмите кнопку
                        «Скачать».
                    </div>
                }
                actionLabel={'Скачать'}
                onAction={handleAgainClick}
                {...modalAlertState}
            />
            <ModalAlertFail
                title='Что-то пошло не так'
                label={<div>Произошла ошибка во время выполнения запроса, пожалуйста, повторите попытку.</div>}
                actionLabel={'Попробовать еще'}
                onAction={() => modalAlertFailState.setOpen(false)}
                {...modalAlertFailState}
            />
            <ControlButton icon='download' label='Отчет' onClick={handleClick} />
        </div>
    )
})
