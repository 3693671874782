import { showToast, showToastError } from '@/shared/vendors/mantine'
import { store } from './complectation.store'
import { ComplectationModel, ComplectationOptionModel, ComplectationPackageModel } from './models'
import { DefaultData } from './complectation.types'

type CoreService = {
    complectation: ServiceItem<
        (props: {
            data: {
                id: string
            }
        }) => Promise<{
            complectation: ComplectationModel
        }>
    >
    options: ServiceItem<
        (props: {
            data: {
                id: string
            }
        }) => Promise<{
            basic_options: ComplectationOptionModel[]
            specific_options: ComplectationOptionModel[]
        }>
    >
    packages: ServiceItem<
        (props: {
            data: {
                id: string
            }
        }) => Promise<{
            packages: ComplectationPackageModel[]
        }>
    >
    save: ServiceItem<
        (props: {
            data: {
                name: { value: string }
                complectation_id: { value: number }

                additional_equipment?: { value: any }
                additional_equipment_price?: { value: any }

                specific_color_id?: { id: any }[]
                specific_option_id?: { id: any }[]
                parameter_value_id?: { id: any }[]
                option_package_id?: { id: any }[]
            }[]
        }) => Promise<any>
    >
    edit: ServiceItem<
        (props: {
            data: {
                id: { value: any }
                name: { value: string }
                complectation_id: { value: number }

                additional_equipment?: { value: any }
                additional_equipment_price?: { value: any }

                specific_color_id?: { id: any }[]
                specific_option_id?: { id: any }[]
                parameter_value_id?: { id: any }[]
                option_package_id?: { id: any }[]
            }[]
        }) => Promise<any>
    >
}

export type CoreProps = {
    widget: {
        id: string
        name: string
    }
    service: CoreService
}

const initialWidget: CoreProps['widget'] = {
    id: null,
    name: null,
}

const serviceItem = {
    request: null,
    controller: { ref: null },
}

const initialService: CoreService = {
    complectation: serviceItem,
    options: serviceItem,
    packages: serviceItem,
    save: serviceItem,
    edit: serviceItem,
}

class Core {
    widget = initialWidget
    service = initialService
    store = store

    init(props: CoreProps) {
        this.abort()

        this.widget = props.widget || initialWidget
        this.service = props.service || initialService

        store.reset()

        return this
    }

    abort() {
        Object.values(this.service || {}).forEach((service) => {
            service?.controller?.ref?.abort?.()
        })
    }

    error(err: any, state?: State) {
        console.log(err)
        store.setState({ ...state })
        showToastError({ message: err?.message })
    }

    async mount() {
        try {
            if (store.state.init) return

            store.setState({ loading: true })

            const { complectation } = await this.service.complectation.request({
                data: { id: this.widget.id },
            })

            const default_data_url = this.getDefaultParamsFromUrl()
            const default_data_color_id = default_data_url.specific_color_id?.[0]?.id

            complectation.setData({ id: this.widget.id })

            if (default_data_color_id) {
                complectation.setColor(complectation.getColorById(default_data_color_id))
            }

            store.setData({
                complectation,
                name:
                    default_data_url.name?.value ||
                    `${complectation.data.header.title} ${complectation.data.header.subtitle}`,
                additional_equipment: default_data_url.additional_equipment?.value,
                additional_equipment_price: default_data_url.additional_equipment_price?.value,
                default_data: {
                    ...store.data.default_data,
                    ...default_data_url,
                },
            })

            if (
                default_data_url.specific_option_id?.length ||
                default_data_url.option_package_id?.length ||
                default_data_url.parameter_value_id?.length
            ) {
                await this.loadOptions()
            }

            store.setState({ init: true, loading: false })
        } catch (err) {
            this.error(err, { loading: false, loaded: false, init: false })
        }
    }

    getDefaultParamsFromUrl(): DefaultData {
        const params = new URLSearchParams(location.search)
        const data = JSON.parse(params.get('data') || '{}')

        return data
    }

    /* -------------------------------------------------------------------------- */
    /*                                  Settings                                  */
    /* -------------------------------------------------------------------------- */

    async save(callback?: () => void) {
        try {
            if (store.state.saving) return

            store.setState({ saving: true })

            const data = this.store.getValuesForSave()

            await this.service.save.request({
                data: [data],
            })

            // this.updateHistoryUrl(data)

            store.setState({ saving: false })

            showToast({ message: 'Конфигурация сохранена' })
            callback?.()
        } catch (err) {
            this.error(err, { saving: false })
        }
    }

    async edit(callback?: () => void) {
        try {
            if (store.state.saving) return

            store.setState({ saving: true })

            const data = this.store.getValuesForSave()

            await this.service.edit.request({
                data: [data],
            })

            this.updateHistoryUrl(data)

            store.setState({ saving: false })

            showToast({ message: 'Конфигурация сохранена' })
            callback?.()
        } catch (err) {
            this.error(err, { saving: false })
        }
    }

    /* -------------------------------------------------------------------------- */
    /*                                   Options                                  */
    /* -------------------------------------------------------------------------- */

    async loadOptions() {
        try {
            if (store.state.loadingOptions || store.state.loadedOptions) return

            store.setState({ loadingOptions: true })

            const { basic_options, specific_options } = await this.service.options.request({
                data: {
                    id: this.widget.id,
                },
            })

            const { packages } = await this.service.packages.request({
                data: {
                    id: this.widget.id,
                },
            })

            const default_data = this.store.data.default_data

            const packages_ids = (default_data.option_package_id || []).map((value) => value.id)
            const options_ids = (default_data.specific_option_id || []).map((value) => value.id)
            const parameters_ids = (default_data.parameter_value_id || []).map((value) => value.id)

            packages.forEach((pkg) => {
                if (packages_ids.includes(pkg.data.id)) {
                    pkg.toggleSelect()
                }
            })

            specific_options.forEach((option) => {
                option.data.items.forEach((item) => {
                    if (item.type !== 'group' && (options_ids.includes(item.id) || parameters_ids.includes(item.id))) {
                        option.toggleSelectedOption(item.id)
                    }

                    if (item.type === 'group') {
                        item.items.forEach((item_inner) => {
                            if (options_ids.includes(item_inner.id) || parameters_ids.includes(item_inner.id)) {
                                option.toggleSelectedOption(item_inner.id)
                            }
                        })
                    }
                })
            })

            store.setData({
                basic_options,
                specific_options,
                packages,
            })
            store.setState({ loadingOptions: false, loadedOptions: true })
        } catch (err) {
            this.error(err, { loadingOptions: false, loadedOptions: false })
        }
    }

    async toggleSelectedOption(id: number) {
        try {
            const option = store.getOptionByIdItem(id)

            option.toggleSelectedOption(id)
        } catch (err) {
            console.log(err)
        }
    }

    async resetOptions() {
        store.data.specific_options.forEach((option) => {
            option.resetSelected()
        })
        store.data.packages.forEach((option) => {
            option.unselect()
        })
    }

    searchOptions(value: string) {
        const options = store.specific_options_items
        const basic_options = store.getBasicOptionsItems()

        return []
            .concat(options)
            .concat(basic_options)
            .filter((option) => option.value?.toLowerCase?.().includes?.(value.toLowerCase()))
    }

    async toggleSelectedPackage(id: number) {
        try {
            const item = store.getPackageById(id)

            item.toggleSelect()
        } catch (err) {
            console.log(err)
        }
    }

    updateHistoryUrl(data?: ObjectType) {
        const values = data || store.getValuesForSave()
        const defaults_params = this.getDefaultParamsFromUrl()

        const query = `${location.pathname}?data=${JSON.stringify({ ...defaults_params, ...values })}`

        // window.history.replaceState({ query }, '', query)
        window.history.pushState({ query }, '', query)
    }
}

export const core = new Core()
