import React from 'react'
import { Box, clsx, ScrollArea, SimpleGrid } from '@mantine/core'
import style from './modifications.style.scss'
import { store } from '../../catalog.store'
import { observer } from 'mobx-react-lite'
import { CarModificationCard } from '../car-modification-card'

interface ModificationsProps {}

export const Modifications: React.FC<ModificationsProps> = observer(({ ...rest }) => {
    return (
        <Box {...rest}>
            <div className={clsx(style.root, { [style.searching]: store.state.searching })}>
                <Box className={style.title} mb={38}>
                    Комплектации {store.data.model.model_name}
                </Box>
                {/* <ScrollArea.Autosize
                    mah={600}
                    styles={{
                        root: {
                            paddingRight: 20,
                        },
                    }}
                > */}
                <SimpleGrid cols={1} spacing={16}>
                    {store.data.modifications.map((modification, i) => (
                        <CarModificationCard
                            key={modification.data.id}
                            modification={modification}
                            defaultOpen={false}
                        />
                    ))}
                </SimpleGrid>
                {/* </ScrollArea.Autosize> */}
            </div>
        </Box>
    )
})
