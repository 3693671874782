import React from 'react'
import { Box, Loader, Select } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { IconChevronDown } from '@tabler/icons-react'
import { styles } from '@/theme/styles'
import { core } from '../../carstock.core'
import { useFilter } from '../../carstock.lib'
import { CarstockFilterModel } from '../../models'

interface FilterSelectProps {
    filter: CarstockFilterModel
}

export const FilterSelect: React.FC<FilterSelectProps> = observer(({ filter, ...rest }) => {
    const [state] = useFilter(filter)
    const value = filter.data.value.length ? filter.data.value[0].value : null

    const handleChangeValue = (value: string) => {
        core.changeFilter([value], filter)
    }

    const handleDropdownOpen = () => {
        core.loadFilterValues(filter)
    }

    return (
        <Box {...rest}>
            <Select
                label={filter.data.name}
                placeholder={filter.data.label[0]}
                value={value}
                data={filter.state.loading && !value ? [] : filter.get.values()}
                searchable
                clearable
                onChange={handleChangeValue}
                rightSection={
                    filter.state.loading ? (
                        <Loader variant='dots' size={20} />
                    ) : value ? null : (
                        <IconChevronDown color={styles.secondary} size={16} />
                    )
                }
                onDropdownOpen={handleDropdownOpen}
                disabled={state.disabled}
            />
        </Box>
    )
})
