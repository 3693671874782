import React from 'react'
import style from './index.style.scss'
import { ControlEdit } from './edit'
import { ControlSelected } from './selected'
import { ControlDelete } from './delete'
import { ControlArchives } from './archives'
import { ControlCopy } from './double'
import { observer } from 'mobx-react-lite'
import { store } from '@/pages/analytics/store'

interface IControlCrudProps {}

export const ControlCrud: React.FC<IControlCrudProps> = observer(({ ...rest }) => {
    const isSelected = Boolean(store.table.selected.length)
    const isVisible = isSelected || store.table.duplicatesRows.length || store.table.editRows.length
    const isArhiveMode = store.table.isArchiveMode()
    const isPassportMode = store.table.isMode('passport')

    return (
        <>
            {isVisible ? (
                <div className={style.root} {...rest}>
                    <div className={style.divider}></div>
                    <div className={style.grid}>
                        {isSelected && <ControlSelected classes={{ root: style.item }} />}
                        {/* {!isPassportMode && <ControlEdit classes={{ root: style.item }} />} */}
                        <ControlEdit classes={{ root: style.item }} />
                        {!isPassportMode && <ControlCopy classes={{ root: style.item }} />}
                        {isArhiveMode && <ControlArchives classes={{ root: style.item }} />}
                        <ControlDelete classes={{ root: style.item }} />
                    </div>
                </div>
            ) : null}
        </>
    )
})
