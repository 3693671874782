import React from 'react'
import { ScrollArea, SimpleGrid, Stack, Text } from '@mantine/core'
import { core } from '../../carstock.core'
import { observer } from 'mobx-react-lite'
import { OptionCheckbox } from '../option-checkbox'
import { Count } from '../count'
import { store } from '../../carstock.store'

interface OptionsSelectedProps {}

export const OptionsSelected: React.FC<OptionsSelectedProps> = observer(({ ...rest }) => {
    const items = store.getOptionsSelectedItems()

    const handleChange = (e: React.MouseEvent<HTMLDivElement>) => {
        const id = parseInt((e.target as any).closest('[data-id]')?.dataset?.id)

        if (id) {
            core.changeOption(id)
        }
    }

    return (
        !!items.length && (
            <Stack mb={32} {...rest}>
                {
                    <Stack onClick={handleChange}>
                        <Text
                            size={18}
                            fw={600}
                            lh={'24px'}
                            mt={12}
                            sx={{ position: 'relative', alignSelf: 'flex-start' }}
                        >
                            Выбранные опции:
                            <Count count={items.length} visible={!!items.length} sx={{ top: -6, right: -2 }} />
                        </Text>
                        <ScrollArea.Autosize mah={220} mt={12} mb={24} offsetScrollbars pr={4}>
                            <SimpleGrid spacing={24} verticalSpacing={12} cols={2} pr={4}>
                                {items.map((item) => (
                                    <OptionCheckbox item={item} key={item.id} />
                                ))}
                            </SimpleGrid>
                        </ScrollArea.Autosize>
                    </Stack>
                }
            </Stack>
        )
    )
})
