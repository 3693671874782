import React from 'react'
import style from './header.style.scss'
import { Paper } from '../paper'
import { observer } from 'mobx-react-lite'
import { store } from '../../complectation.store'
import { SpecificationList } from '../specification-list'
import { IconChevronDown } from '@tabler/icons-react'
import { Box, Image, LoadingOverlay, Popover, Text } from '@mantine/core'
import { Colors } from '../colors'
import { number } from '@/shared'
import { useImmer } from 'use-immer'
import { LazyLoadImage } from 'react-lazy-load-image-component'

type Header = {}

export const Header: React.FC<Header> = observer(() => {
    const [state, setState] = useImmer({ loader: true, error: false })

    const complectation = store.data.complectation
    const date = complectation.rrp_updated

    const specifications = complectation.header_parameters
    const isColorsList = complectation.colors.length > 1

    if (complectation.color) {
        specifications.push({
            label: <div>Цвет</div>,
            value: (
                <Popover withArrow offset={6} shadow='sm' position='bottom-end'>
                    <Popover.Target>
                        <Box sx={{ cursor: isColorsList ? 'pointer' : 'initial' }} className={style.color}>
                            <div>
                                <Box
                                    w={32}
                                    h={32}
                                    sx={(theme) => ({
                                        borderRadius: '50%',
                                        backgroundColor: complectation.color.hex_code,
                                        boxShadow: theme.shadows.sm,
                                    })}
                                />
                            </div>
                            <Text lineClamp={1} title={complectation.color.name}>
                                {complectation.color.name}
                            </Text>
                            <Text sx={{ whiteSpace: 'nowrap' }}>{number.format(complectation.color.price)} ₽</Text>
                            {isColorsList && <IconChevronDown />}
                        </Box>
                    </Popover.Target>
                    {isColorsList && (
                        <Popover.Dropdown>
                            <Colors />
                        </Popover.Dropdown>
                    )}
                </Popover>
            ),
        } as any)
    }

    if (complectation.price) {
        // if (complectation.discount) {
        //     specifications.push({
        //         label: (
        //             <div className={style.price}>
        //                 <span>{complectation.price_type} {complectation.price} ₽, </span>
        //                 <span>
        //                     {complectation.discount.label.toLowerCase()} {complectation.discount.value}
        //                 </span>
        //             </div>
        //         ),
        //     } as any)
        // } else {
        specifications.push({
            label: <div className={style.price}>{complectation.price_label}</div>,
        } as any)
        // }
    }

    React.useLayoutEffect(() => {
        setState((draft) => {
            draft.error = false
        })
    }, [complectation.image])

    return (
        <Paper className={style.root}>
            <div className={style.inner}>
                <div className={style.image}>
                    <LoadingOverlay visible={state.loader} />
                    <div>
                        {!state.error ? (
                            <Box
                                component={LazyLoadImage}
                                height={'auto'}
                                src={complectation.image}
                                draggable={false}
                                afterLoad={async () => {
                                    setState((draft) => {
                                        draft.loader = false
                                    })
                                }}
                                onError={() =>
                                    setState((draft) => {
                                        draft.loader = false
                                        draft.error = true
                                    })
                                }
                                alt={complectation.data.header.title}
                            />
                        ) : (
                            <Image draggable={false} src={'/static/car-placeholder.png'} />
                        )}
                    </div>
                </div>
                <div className={style.info}>
                    <div className={style.title}>{complectation.data.header.title}</div>
                    <div className={style.modification}>{complectation.data.header.subtitle}</div>

                    <SpecificationList data={specifications} className={style.specification} />

                    <div className={style.info_bottom}>
                        {!!complectation.discount && (
                            <div className={style.info_bottom_item}>
                                <div>{complectation.discount.label}</div>
                                <div>{complectation.discount.value}</div>
                            </div>
                        )}
                        {!!date.value && (
                            <div className={style.info_bottom_item}>
                                <div>{complectation.rrp_updated.label}</div>
                                <div>{complectation.rrp_updated.value}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Paper>
    )
})
