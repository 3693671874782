import { number, tojs } from '@/shared'
import { sleep } from '@/shared'
import { showToastError } from '@/shared/vendors/mantine'
import { BaseModel } from '@/shared/core/model'
import { apiConfig } from '@/api'

// type Params = 1106 | 6104 | 6201 | 6105 | 6101 | 6301 | 2101

type CarstockCarModelProps = {
    id: number
    brand_id: number
    brand_name: string
    model_name: string
    model_year: number
    rrp_min: number
    specific_colors_with_hex: {
        specific_name?: string
        universal_name?: string
        price?: number
        image: string
        hex_code?: string
    }[]
    cheapest_complectation_id?: any
    complectation_count?: any
    in_stock_count?: number
    vtbl_discount?: any
    params?: {
        id: any
        name: string
        type: string
        value: string
        abbr?: string
    }[]
    dealer?: {
        id: number
        name: string
    }
    is_favorite?: boolean
    service?: CarstockCarSeviceModel
    deduplication?: { id: any; name: string }[]
    imageAbsolute?: boolean
}

type CarstockCarSeviceModel = {
    favoriteAdd?: (props: {
        data: {
            offer_id: {
                id: number
            }
        }[]
    }) => any
    favoriteRemove?: (props: { data: number[] }) => any
}

export class CarstockCarModel extends BaseModel<CarstockCarModelProps, CarstockCarModel, CarstockCarSeviceModel> {
    constructor(props: CarstockCarModelProps) {
        super(props, CarstockCarModel)
    }

    getImage() {
        const image = this.data.specific_colors_with_hex.find((item) => item.image)?.image
        return image ? (this.data.imageAbsolute ? image : apiConfig.hosts.image_carstock + image) : null
    }

    getRrpMinFormat() {
        return `от ${number.format(this.data.rrp_min, 0, '.', ' ')} ₽`
    }

    getFormatParams() {
        return tojs(this.data.params).map((attr) => {
            if (attr.id === 6104) {
                attr.value = `${(parseInt(attr.value) * 0.001).toFixed(1)} л`
            }

            if (attr.id === 6105) {
                attr.value = `${attr.value} л.с.`
            }

            if (attr.id === 1106) {
                return this.data.model_year
            }

            return attr.value
        })
    }

    async favoriteToggle() {
        try {
            if (this.state.loading) return

            this.setState({ loading: true })

            await sleep(200)

            if (this.data.is_favorite) {
                await this.service.favoriteRemove({
                    data: [this.data.id],
                })
            } else {
                await this.service.favoriteAdd({
                    data: [
                        {
                            offer_id: {
                                id: this.data.id,
                            },
                        },
                    ],
                })
            }

            this.setData({ is_favorite: !this.data.is_favorite })
            this.setState({ loading: false })
        } catch (err) {
            showToastError({ message: err?.message })
            this.setState({ loading: false })
        }
    }

    isFavoriteAvailable() {
        return !!(this.service.favoriteAdd && this.service.favoriteRemove)
    }

    get deduplication() {
        return this.data.deduplication
    }

    get booking_label() {
        return `${this.data.brand_name} ${this.data.model_name} - ${this.data.dealer.name}`
    }
}
