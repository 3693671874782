import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'
import { Color, Complectation } from './complectation.types'
import { ComplectationModel } from '@/widget/complectation'

type CatalogComplectationRequest = {
    data: {
        id: string
    }
}

type CatalogComplectationResponse = {
    data: Complectation
}

export const CatalogComplectationController = { ref: null }

export const CatalogComplectation = async (req: CatalogComplectationRequest) => {
    CatalogComplectationController.ref = new AbortController()

    try {
        const response = await apiConfig.fetch.ilsa<CatalogComplectationResponse>({
            url: '/catalog/complectations/' + req.data.id,
            config: {
                signal: CatalogComplectationController.ref.signal,
                cache: true,
            },
        })

        const { has_options, carstock_parameters, custom_parameters, parameters, header, is_parallel_import } =
            response.data.data

        const title = header.find((item) => item.id === 'title')
        const subtitle = header.find((item) => item.id === 'subtitle')
        const colors = (header.find((item) => item.id === 'colors')?.value || []) as Color[]
        const color = null

        // const vehicle_type_name = header.find((item) => item.id === 'vehicle_type_name')
        // const body_type_name = header.find((item) => item.id === 'body_type_name')
        // const origin_country_name = header.find((item) => item.id === 'origin_country_name')

        const complectation = new ComplectationModel({
            header: {
                title: title.value,
                subtitle: subtitle.value,
                colors: colors,
                // body_type: body_type_name?.value,
                // vehicle_type_name: vehicle_type_name?.value,
                // origin_country_name: origin_country_name?.value,
                // body_type_label: body_type_name.label,
                color: color,
                items: header.filter((item) => !['title', 'subtitle', 'colors'].includes(item.id)),
            },
            parameters,
            custom_parameters,
            carstock_parameters,
            has_options,
            is_parallel_import,
            // body_type_label: body_type_name.label === 'Вид техники' ? 'Вид техники' : 'Тип кузова',
        })

        return { complectation }
    } catch (err: any) {
        throwAdapterError(err, 'CatalogComplectation')
    }
}
