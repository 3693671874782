import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'
import { CarstockFilterValues } from '../filter-values'
import { CarstockFilter, CarstockFiltersMap } from './filters.types'
import { CarstockFilterModel } from '@/widget'

type CarstockFiltersResponse = {
    params: CarstockFilter[]
}

export const CarstockFiltersController = { ref: null }

export const CarstockFilters = async () => {
    CarstockFiltersController.ref = new AbortController()

    try {
        const response = await apiConfig.fetch.local<CarstockFiltersResponse>({
            url: '/static/api/carstock-filters.json',
            config: {
                signal: CarstockFiltersController.ref.signal,
            },
        })

        const filters = response.data.params
            .filter((param) => !param?.options?.hidden)
            .map(
                (param) =>
                    new CarstockFilterModel({
                        ...param,
                        service: {
                            values: CarstockFilterValues,
                        },
                    })
            )
            .reduce((acc, curr) => {
                if (!acc.get(curr.data.category)?.length) {
                    acc.set(curr.data.category, [])
                }

                acc.get(curr.data.category).push(curr)

                return acc
            }, new Map() as CarstockFiltersMap)

        return filters
    } catch (err: any) {
        throwAdapterError(err, 'CarstockFilters')
    }
}
