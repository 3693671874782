import clsx from 'clsx'
import React, { FormHTMLAttributes } from 'react'
import { FormField } from './field'
import style from './index.style.scss'
import { FormLabel } from './label'

interface IFormProps extends FormHTMLAttributes<any> {}

interface IForm extends React.ForwardRefExoticComponent<IFormProps & React.RefAttributes<HTMLFormElement>> {
    Field: typeof FormField
    Label: typeof FormLabel
}

export const Form = React.forwardRef<HTMLFormElement, IFormProps>(({ className, children, ...props }, ref) => {
    const classes = clsx(style.root, className)

    return (
        <form className={classes} ref={ref} {...props} autoComplete='off'>
            {children}
        </form>
    )
}) as IForm

Form.Field = FormField
Form.Label = FormLabel
