import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { DataPicker, Input, Select, Textarea, MultiSelect } from '@/pages/analytics/components/ui'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import style from './index.style.scss'
import { store } from '@/pages/analytics/store'
import { ErrorBoundary } from 'react-error-boundary'

// @ts-ignore
const ComponentSwitcher = observer(({ data }) => {
    const { changeInputValue, changeSelectValue, getSelectItems, getMultiSelectItems, loading } = store.passport

    switch (data.type) {
        case 'select':
            // @ts-ignore
            return <Select data={data} getList={getSelectItems} onChange={changeSelectValue} />
        case 'multiSelect':
            return (
                <MultiSelect
                    // @ts-ignore
                    data={data}
                    getList={getMultiSelectItems}
                    onChange={changeSelectValue}
                    loading={loading}
                />
            )
        case 'data':
            // @ts-ignore
            return <DataPicker data={data} onChange={changeInputValue} />
        case 'textarea':
            // @ts-ignore
            return <Textarea data={data} onChange={changeInputValue} />
        default:
            // @ts-ignore
            return <Input data={data} onChange={changeInputValue} />
    }
})

// @ts-ignore
export const RowCharacteristics = ({ data, scheme }) => {
    const { updateData } = store.passport
    const [show, setShow] = useState(false)
    const getData = (array) => (show ? array : array.slice(0, 12))

    const handleClick = () => {
        updateData({ url: `/${scheme.reference}` })
    }

    return (
        <div className={style.root}>
            <div className={style.title}>Характеристики</div>
            <ErrorBoundary
                fallbackRender={({ resetErrorBoundary }) => (
                    <div className={style.error}>
                        <div>Ошибка при чтении данных!</div>
                        <Button
                            variant='outlined'
                            onClick={() => {
                                setShow(false)
                                resetErrorBoundary()
                            }}
                        >
                            Сбросить
                        </Button>
                    </div>
                )}
            >
                <div className={style.wrapper}>
                    <div className={style.fields}>
                        {getData(data).map((item) => (
                            <div className={item.type === 'textarea' ? style.fullWidth : style.field} key={item.id}>
                                {/* @ts-ignore */}
                                <ComponentSwitcher data={item} />
                            </div>
                        ))}
                    </div>
                    {data.length > 12 && (
                        <button className={show ? style.show : style.button} onClick={() => setShow(!show)}>
                            {show ? 'Скрыть все характеристики' : 'Показать все характеристики'}
                            <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path d='M7 9.5L12 14.5L17 9.5H7Z' fill='#1976D2' />
                            </svg>
                        </button>
                    )}
                </div>
                <Stack spacing={2} direction='row'>
                    {/* @ts-ignore */}
                    <Button variant='contained' onClick={handleClick}>
                        Сохранить
                    </Button>
                    <Button variant='outlined'>Сбросить</Button>
                </Stack>
            </ErrorBoundary>
        </div>
    )
}
