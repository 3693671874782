import { store } from '@/pages/analytics/store'
import { observer } from 'mobx-react-lite'
import React from 'react'
import style from './index.style.scss'
import { ControlReset } from './reset'
import { ControlTag } from './tag'

interface IControlFiltersProps {}

export const ControlFilters: React.FC<IControlFiltersProps> = observer(({ ...rest }) => {
    return (
        <>
            {store.table.filters.length ? (
                <div className={style.root} {...rest}>
                    <div className={style.divider}></div>
                    <div className={style.filters}>
                        {store.table.filters.map((filter, i) => (
                            <ControlTag
                                key={i}
                                label={filter.column ? `${filter.column}:${filter.value}` : filter.value}
                                classes={{ root: style.item }}
                                onClick={() => store.table.removeFilter(filter)}
                            />
                        ))}
                        <ControlReset classes={{ root: style.item }} />
                    </div>
                </div>
            ) : null}
        </>
    )
})
