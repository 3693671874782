import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'

type AuthSsoResponse = {
    success: boolean
    message: string
    error?: {
        message: 'Unauthenticated' | ''
    }
}

export const AuthSso = async () => {
    try {
        const response = await apiConfig.fetch.base<AuthSsoResponse>({
            url: '/auth/login-via-sso',
            config: {
                method: 'GET',
            },
        })

        if (!response?.data?.success) {
            throw Error('Доступ запрещен')
        }

        return response
    } catch (err: any) {
        throwAdapterError(err, 'AuthSso')
    }
}
