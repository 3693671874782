import React from 'react'
import {
    Box,
    Checkbox,
    Group,
    Loader,
    MultiSelect,
    ScrollArea,
    ScrollAreaProps,
    Sx,
    useMantineTheme,
    Collapse,
    Text,
    ActionIcon,
    rem,
} from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { IconChevronDown, IconMinus, IconPlus } from '@tabler/icons-react'
import { styles } from '@/theme/styles'
import { core } from '../../catalog.core'
import { useDisclosure } from '@mantine/hooks'
import { useFilter } from '../../catalog.lib'
import { CatalogFilterModel } from '../../models'
import { tojs } from '@/shared'

interface FilterGroupSelectProps {
    filter: CatalogFilterModel
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    id: string
    value: string
    label: string

    selected: boolean
    hovered: boolean
    image?: string
    sx?: Sx | (Sx | undefined)[]
}

const SelectItem = React.forwardRef<HTMLDivElement, ItemProps>(
    ({ image, label, value, id, selected, hovered, sx, ...rest }: ItemProps, ref) => {
        return (
            <Box
                ref={ref}
                sx={sx}
                {...(selected ? { 'data-selected': '' } : {})}
                {...(hovered ? { 'data-hovered': '' } : {})}
                {...rest}
            >
                <Group noWrap>
                    <Checkbox size='xs' checked={selected} onChange={() => null} />
                    <Text>{label}</Text>
                </Group>
            </Box>
        )
    }
)

const GroupItem = React.forwardRef<HTMLDivElement, any>(({ group, defaultOpen, items, ...rest }: any, ref) => {
    const context = useContext()
    const [opened, { toggle }] = useDisclosure(defaultOpen || false)

    const handleClick = () => {
        if (!opened) toggle()

        context.handleChangeGroupValue(group)
    }

    return (
        <Box ref={ref} {...rest}>
            {!defaultOpen && (
                <Group position='apart' sx={{ padding: '8px 12px' }}>
                    <Text
                        sx={(theme) => ({
                            cursor: 'pointer',
                            flexGrow: 1,

                            '&:hover': {
                                color: styles.accent,
                            },
                        })}
                        onClick={handleClick}
                    >
                        {group}
                    </Text>
                    <ActionIcon
                        variant='light'
                        sx={(theme) => ({
                            background: styles.accentLight,
                            color: styles.accent,
                            transition: 'background .1s ease',

                            '&:hover': {
                                background: theme.fn.darken(styles.accentLight, 0.05),
                            },
                        })}
                        onClick={toggle}
                    >
                        {opened ? <IconMinus size={rem(16)} /> : <IconPlus size={rem(16)} />}
                    </ActionIcon>
                </Group>
            )}

            <Collapse in={opened}>{items}</Collapse>
        </Box>
    )
})

interface DropdownProps extends ScrollAreaProps {}

const Dropdown = React.forwardRef<HTMLDivElement, DropdownProps>(({ children, ...rest }: DropdownProps, ref) => {
    const theme = useMantineTheme()
    const styles = (theme.components.MultiSelect.styles as any)().item

    const child = children as any
    const props = child.props.children.props

    const data = props?.data || []
    const value = props?.value || []
    const hovered = props?.hovered

    const onItemSelect = props.onItemSelect
    const isItemSelected = props.isItemSelected
    const itemsRefs = props.itemsRefs

    const grouped = data.reduce((acc: any, curr: any, idx: number) => {
        if (!acc?.[curr.__group.label]) {
            acc[curr.__group.label] = []
        }

        const isSelected = isItemSelected(curr.value)

        acc[curr.__group.label].push(
            <SelectItem
                key={curr.id}
                id={curr.id}
                value={curr.value}
                label={curr.label}
                hovered={hovered === idx}
                selected={isSelected}
                sx={styles}
                ref={(node: HTMLDivElement) => {
                    if (itemsRefs && itemsRefs.current && !itemsRefs.current[curr.value]) {
                        itemsRefs.current[curr.value] = node
                    }
                }}
                onMouseDown={() => {
                    onItemSelect(curr)
                }}
            />
        )

        return acc
    }, {})

    return (
        <ScrollArea ref={ref} {...rest}>
            {Object.keys(grouped).map((group, i) => (
                <GroupItem key={group} defaultOpen={grouped[group].length === 1} group={group} items={grouped[group]} />
            ))}

            {/* {data.map((group, idx) => {
                const items = group.items.map((curr, i) => (
                    <SelectItem
                        key={curr.id}
                        id={curr.id}
                        value={curr.value}
                        label={curr.label}
                        hovered={hovered === i}
                        selected={isItemSelected(curr.value)}
                        sx={styles}
                        ref={(node: HTMLDivElement) => {
                            if (itemsRefs && itemsRefs.current && !itemsRefs.current[curr.value]) {
                                itemsRefs.current[curr.value] = node
                            }
                        }}
                        onMouseDown={() => {
                            onItemSelect(curr)
                        }}
                    />
                ))

                return <GroupItem key={group} defaultOpen={idx < 2} group={group} items={items} />
            })} */}
        </ScrollArea>
    )
})

const Context = React.createContext(null)
const useContext = () => React.useContext(Context)

export const FilterGroupSelect: React.FC<FilterGroupSelectProps> = observer(({ filter, ...rest }) => {
    const [state] = useFilter(filter)
    const value = tojs(filter.data.value)?.map((value) => value.value)
    // const data = filter.state.loaded ? filter.get.values() : []

    function handleChangeValue(value: string[]) {
        core.changeFilter(value, filter)
    }

    const handleChangeGroupValue = (value: string) => {
        core.changeFilter([value], filter, { isGroup: true })
    }

    const handleDropdownOpen = () => {
        core.loadFilterValues(filter)
    }

    return (
        <Box {...rest}>
            <Context.Provider value={{ handleChangeGroupValue }}>
                <MultiSelect
                    label={filter.data.label}
                    placeholder={filter.data.placeholders[0]}
                    value={value}
                    data={filter.state.loading && !value.length ? [] : filter.get.values()}
                    searchable
                    clearable
                    disableSelectedItemFiltering
                    onChange={handleChangeValue}
                    rightSection={
                        filter.state.loading ? (
                            <Loader variant='dots' size={20} />
                        ) : value.length ? null : (
                            <IconChevronDown color={styles.secondary} size={16} />
                        )
                    }
                    onFocus={handleDropdownOpen}
                    disabled={state.disabled}
                    itemComponent={SelectItem}
                    dropdownComponent={Dropdown}
                />
            </Context.Provider>
        </Box>
    )
})
