import { apiConfig } from '@/api/api.config'
import { sleep, throwAdapterError } from '@/shared'

export type ConfigurationsSaveRequest = {
    data: {
        name: { value: string }
        complectation_id: { value: number }

        additional_equipment?: { value: any }
        additional_equipment_price?: { value: any }

        specific_color_id?: { id: any }[]
        specific_option_id?: { id: any }[]
        parameter_value_id?: { id: any }[]
        option_package_id?: { id: any }[]
    }[]
}

export type ConfigurationsSaveResponse = {
    success: boolean
}

export const ConfigurationsSave = async (req: ConfigurationsSaveRequest) => {
    try {
        const response = await apiConfig.fetch.ilsa<ConfigurationsSaveResponse>({
            url: '/configurations',
            config: {
                method: 'POST',
                data: req.data,
            },
            mock: {
                enable: false,
                data: {
                    success: true,
                },
            },
        })

        if (!response?.data?.success) {
            throw Error('Произошла ошибка при сохранение')
        }

        return response
    } catch (err: any) {
        throwAdapterError(err, 'ConfigurationsSave')
    }
}
