import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import { ControlButton } from '../../button'
import { FilterPopover } from './popover'
import { observer } from 'mobx-react-lite'

interface IControlFilterProps {
    classes?: {
        root?: string
    }
}

export const ControlFilter: React.FC<IControlFilterProps> = observer(({ classes, ...rest }) => {
    const cls = { root: clsx(style.root, classes?.root) }

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div className={cls.root} {...rest}>
            <ControlButton
                icon={'arrowDropdown'}
                label='Фильтр'
                onClick={handleClick}
                endIcon
                isActive={Boolean(anchorEl)}
            />
            <FilterPopover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose} />
        </div>
    )
})
