import React from 'react'
import style from './options.style.scss'
import { Paper } from '../paper'
import { observer } from 'mobx-react-lite'
import { store } from '../../car.store'
import { Tabs } from '@mantine/core'
import { Option } from '../option'

type OptionsProps = {}

export const Options: React.FC<OptionsProps> = observer(() => {
    const car = store.data.car
    const simpleOptions = car.getOptions('simple')
    const fullOptions = car.getOptions('full')

    return (
        <Paper defaultValue={true} title='Опции' className={style.root}>
            <div className={style.inner}>
                <Tabs variant='default' defaultValue={'simple'}>
                    <Tabs.List>
                        <Tabs.Tab value={'simple'}>Краткая комплектация</Tabs.Tab>
                        <Tabs.Tab value={'full'}>Полная комплектация</Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value={'simple'}>
                        <div className={style.columns}>
                            <div>
                                {simpleOptions.map(
                                    (group) =>
                                        group.group !== 5 && (
                                            <div key={group.title} className={style.group}>
                                                <div className={style.title}>{group.title}</div>
                                                <div className={style.grid}>
                                                    {group.options.map((option) => (
                                                        <Option item={option} key={option.id} />
                                                    ))}
                                                </div>
                                            </div>
                                        )
                                )}
                            </div>
                            {simpleOptions.map(
                                (group) =>
                                    group.group === 5 && (
                                        <div key={group.title} className={style.group}>
                                            <div className={style.title}>{group.title}</div>
                                            <div className={style.grid}>
                                                {group.options.map((option) => (
                                                    <Option item={option} key={option.id} />
                                                ))}
                                            </div>
                                        </div>
                                    )
                            )}
                        </div>
                    </Tabs.Panel>

                    <Tabs.Panel value={'full'}>
                        <div className={style.columns}>
                            <div>
                                {fullOptions.map(
                                    (group) =>
                                        group.group !== 5 && (
                                            <div key={group.title} className={style.group}>
                                                <div className={style.title}>{group.title}</div>
                                                <div className={style.grid}>
                                                    {group.options.map((option) => (
                                                        <Option item={option} key={option.id} />
                                                    ))}
                                                </div>
                                            </div>
                                        )
                                )}
                            </div>
                            {fullOptions.map(
                                (group) =>
                                    group.group === 5 && (
                                        <div key={group.title} className={style.group}>
                                            <div className={style.title}>{group.title}</div>
                                            <div className={style.grid}>
                                                {group.options.map((option) => (
                                                    <Option item={option} key={option.id} />
                                                ))}
                                            </div>
                                        </div>
                                    )
                            )}
                        </div>
                    </Tabs.Panel>
                </Tabs>
            </div>
        </Paper>
    )
})
