import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Drawer } from '../drawer'
import { Toolbar } from '../toolbar'
import style from './index.style.scss'
import { observer } from 'mobx-react-lite'
import { LayoutErrorFallback } from '../error/layout'
import { AppError } from '../error/app'
import clsx from 'clsx'
import { store } from '@/pages/analytics/store'
import { StyledEngineProvider } from '@mui/material/styles'
import { Apploader } from '@/app'

export const Layout = observer(({ children, ...rest }) => {
    const [drawerOpen, setDrawerOpen] = React.useState(true)

    return (
        <StyledEngineProvider injectFirst>
            <div className={clsx(style.root, 'analytics-layout')} {...rest}>
                <ErrorBoundary FallbackComponent={LayoutErrorFallback}>
                    {!store.app.state.loading && store.app.state.error ? <AppError /> : ''}
                    {!store.app.state.error && store.app.state.init && store.app.schemes.length ? (
                        <>
                            <Drawer setOpen={setDrawerOpen} open={drawerOpen} />
                            <div style={{ flexGrow: 1, transition: '.1s ease all', marginLeft: drawerOpen ? 294 : 0 }}>
                                <Toolbar setOpen={setDrawerOpen} open={drawerOpen} />
                                <div className={style.content}>{children}</div>
                            </div>
                        </>
                    ) : null}
                    <Apploader isActive={store.app.state.loading} isTransparent />
                </ErrorBoundary>
            </div>
        </StyledEngineProvider>
    )
})
