import React from 'react'
import { Box, Center, Checkbox, clsx, createStyles } from '@mantine/core'
import { styles } from '@/theme/styles'
import { observer } from 'mobx-react-lite'
import { ComplectationOptionModel } from '@/widget/complectation/models'
import { IconCheck } from '@tabler/icons-react'
import { number } from '@/shared'
import { numberFormat } from '@/shared/lib/number'

interface OptionCheckboxProps {
    item: ComplectationOptionModel['data']['items'][0]
    /** Если true, значит опция относится к группе опций.
     * В такой группе опций можно выбрать только одну опцию */
    isParameter?: boolean
}

const useStyles = createStyles((theme) => ({
    root: {
        borderRadius: 12,
        padding: '8px 12px',
        fontSize: 14,
        lineHeight: '20px',
        cursor: 'pointer',
        transition: 'all .1s ease',
        display: 'flex',
        alignItems: 'center',
        background: styles.background,
        color: styles.primary,

        ':hover': {
            background: theme.fn.darken(styles.background, 0.04),
        },

        ':active': {
            background: theme.fn.darken(styles.background, 0.08),
        },
    },

    selected: {
        background: styles.blueLight,
        color: styles.blue,

        ':hover': {
            background: theme.fn.darken(styles.blueLight, 0.04),
        },

        ':active': {
            background: theme.fn.darken(styles.blueLight, 0.08),
        },
    },
}))

export const OptionCheckbox: React.FC<OptionCheckboxProps> = observer((props) => {
    const { item, isParameter } = props

    const { classes } = useStyles()

    const priceFormat = numberFormat({ value: Math.abs(item.price) })

    return (
        <Box
            data-option-id={item.basic ? null : item.id}
            data-is-parameter={isParameter}
            className={clsx(classes.root, { [classes.selected]: item.selected })}
        >
            {!item.basic && (
                <Center sx={{ height: 24, width: 24, flexShrink: 0 }} mr={12}>
                    <Checkbox
                        data-border-radio={isParameter}
                        variant='option'
                        size='sm'
                        checked={item.selected}
                        onChange={() => null}
                    />
                </Center>
            )}

            {item.basic && <Box component={IconCheck} mr={12} color={styles.tertiary} sx={{ flexShrink: 0 }} />}

            <Box>{item.value}</Box>

            {!item.basic && (
                <Box ml={12} sx={{ whiteSpace: 'nowrap' }}>
                    {item.price > 0 ? '+' : ''}
                    {priceFormat}
                </Box>
            )}
        </Box>
    )
})
