import { clone, number } from '@/shared'
import { BaseModel } from '@/shared/core/model'

interface ModelProps {
    id: number
    value: string
    items: Item[]

    selected?: Item[]
    selected_parameter_value?: {
        [key in number]: Item
    }
}

type Item = {
    basic: boolean
    id: number
    parameter_id: 'parameter_value_id' | 'specific_option_id'
    price: number
    value: string

    items?: Item[]
    type?: string
    selected?: boolean
    parent_id?: number
}

export class ComplectationOptionModel extends BaseModel<ModelProps, ComplectationOptionModel> {
    constructor(props: ModelProps) {
        super(props, ComplectationOptionModel)
    }

    toggleSelectedOption(id: number) {
        const item = this.getItemById(id)

        if (!this.data.selected_parameter_value) {
            this.data.selected_parameter_value = {}
        }

        /**
         * Можем выбрать только одну опцию с parameter_value_id и одинаковым parent_id
         */
        if (item.parameter_id === 'parameter_value_id') {
            item.selected = !item.selected

            if (item.selected) {
                if (this.data.selected_parameter_value[item.parent_id]) {
                    const selected_parameter = this.data.selected_parameter_value[item.parent_id]

                    this.data.selected = this.data.selected.filter((selected) => {
                        if (selected.id === selected_parameter.id) {
                            selected.selected = false
                        }

                        return selected.id !== selected_parameter.id
                    })
                }

                this.data.selected_parameter_value[item.parent_id] = item
            } else {
                this.data.selected_parameter_value[item.parent_id] = null
            }
        } else {
            item.selected = !item.selected
        }

        if (item.selected) {
            this.data.selected.push(item)
        } else {
            this.data.selected = this.data.selected.filter((selected) => selected.id !== item.id)
        }
    }

    getItemById(id: number) {
        let need_item: Item = null

        this.data.items.find((item) => {
            if (item.type === 'group') {
                const find = item.items.find((item) => item.id === id)

                need_item = find

                return find
            }

            need_item = item

            return item.id === id
        })

        return need_item
    }

    resetSelected() {
        this.setData({
            selected: [],
            items: this.data.items.map((item) => {
                if (item.type === 'group') {
                    item.items = item.items.map((item) => {
                        item.selected = false

                        return item
                    })

                    return item
                }

                item.selected = false

                return item
            }),
        })
    }
}
