import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import Popover from '@mui/material/Popover'
import { Icon } from '@/pages/analytics/components/ui'
import { TextField } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import { VisiblePopoverData } from './data'

interface IVisiblePopoverProps {
    classes?: {
        root?: string
    }
    open: boolean
    anchorEl: HTMLElement
    onClose: () => void
}

export const VisiblePopover: React.FC<IVisiblePopoverProps> = ({ classes, open, anchorEl, onClose, ...rest }) => {
    const cls = { root: clsx(style.root, classes?.root) }
    const [search, setSearch] = React.useState('')

    const handleSearch = (e) => {
        setSearch(e.currentTarget.value)
    }

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            classes={{
                paper: cls.root,
            }}
            {...rest}
        >
            <div className={style.wrapper}>
                <div className={style.close} onClick={onClose}>
                    <Icon icon='cross' />
                </div>
                <div className={style.title}>Видимость характеристик</div>
                <div className={style.search}>
                    <TextField
                        placeholder='Найти'
                        size='small'
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='start'>
                                    <Icon icon='search' sx={{ fontSize: 20 }} />
                                </InputAdornment>
                            ),
                        }}
                        className={style.input}
                        sx={{
                            '& .MuiInputBase-adornedEnd': {
                                paddingRight: '0px',
                            },
                        }}
                        onChange={handleSearch}
                    />
                </div>

                <VisiblePopoverData search={search} isVisible title='Видимые характеристики' icon='eye' />
                <VisiblePopoverData search={search} title='Скрытые характеристики' icon='eyeClose' />
            </div>
        </Popover>
    )
}
