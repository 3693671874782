import { tojs } from '@/shared'
import { BaseModel } from '@/shared/core/model'

interface ModelProps {
    id: number | string
    name: string
    parent_id: any
    groups: OptionGroup[]
    options?: OptionItem[]

    type_param_key: 'universal_options_ids' | 'specific_options_ids' | 'carstock_options_ids'
    selected?: OptionItem['id'][]
}

type OptionGroup = {
    name: string
    options: OptionItem[]
}

type OptionItem = {
    id: number
    name: string

    type?: string
    selected?: boolean
    group?: string
    checkboxes?: boolean
}

export class CatalogOptionModel extends BaseModel<ModelProps, CatalogOptionModel> {
    constructor(props: ModelProps) {
        super(props, CatalogOptionModel)
    }

    setSelectedOption(id: number) {
        const item = this.data.groups
            .find((group) => group.options.find((item) => item.id === id))
            .options.find((item) => item.id === id)

        item.selected = !item.selected

        if (item.selected) {
            this.data.selected.push(item.id)
        } else {
            this.data.selected = this.data.selected.filter((selected) => selected !== item.id)
        }
    }

    resetSelected() {
        this.setData({
            selected: [],
            groups: tojs(this.data.groups).map((group) => {
                group.options.map((item) => {
                    item.selected = false

                    return item
                })

                return group
            }),
        })
    }
}
