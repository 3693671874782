import { capitalizeFirstLetter, number, showToastError, tojs } from '@/shared'
import { sleep } from '@/shared'
import dayjs from 'dayjs'
import { BaseModel } from '@/shared/core/model'
import { Color, Dealer, Edition, MatchedOption, Price } from '../../car.types'

type CarModelProps = {
    id: number
    active: boolean
    year: number
    model_year: number
    availability: string
    has_additional_equipment: boolean
    additional_equipment_cost: any
    valid_time: string
    update_time: string
    created_at: string
    updated_at: string
    prices: Price[]
    edition: Edition
    dealer: Dealer
    color: Color
    photos: any[]
    comments: {
        code: string
        comment: string
    }[]
    attachments: any[]
    matched_options: MatchedOption[]

    is_favorite?: boolean

    service?: {
        favoriteAdd?: (props: {
            data: {
                offer_id: {
                    id: number
                }
            }[]
        }) => any
        favoriteRemove?: (props: { data: number[] }) => any
    }
}

export class CarModel extends BaseModel<CarModelProps, CarModel, CarModelProps['service']> {
    constructor(props: CarModelProps) {
        super(props, CarModel)
    }

    get equipment() {
        return this.data.edition.equipment
    }

    get attributes() {
        return this.data.edition.attributes
    }

    get price() {
        const leasing = this.data.prices.find((price) => price.code === 'leasing')?.price

        // new Intl.NumberFormat('ru-RU').format(leasing)
        return number.format(leasing)
    }

    get rrc() {
        const rrc = this.data.prices.find((price) => price.code === 'rrp_restored')?.price

        return number.format(rrc)
    }

    get image() {
        const image = this.data.edition.equipment.picture

        return image || '/static/car-placeholder.png'
    }

    get updateTime() {
        return dayjs(this.data.update_time).format('DD.MM.YYYY')
    }

    /** 1.6 Бензин, 90 л.с., Передний привод, 2023 MY */
    get modifiction() {
        const params = this.getModificationParams('object')

        return [
            `${params[6104].value} ${params[6101].value}`,
            params[6105].value,
            params[6301].value,
            params[1106]?.value,
        ]
            .filter(Boolean)
            .join(', ')
    }

    get isFavorite() {
        return !!(this.service.favoriteAdd && this.service.favoriteRemove)
    }

    get matchedOptions() {
        return this.data.matched_options || []
    }

    get matchedOptionsTotal() {
        return number.format(this.matchedOptions.reduce((a, b) => a + b.price, 0))
    }

    // get price() {
    //     return this.data.prices.find(price => price.code === 'leasing').price
    // }

    getFormatAttributes() {
        return tojs(this.attributes).map((attr) => {
            if (attr.id === 1106) {
                attr.value = `${this.data.model_year} MY`
            }

            if (attr.id === 6301) {
                attr.value = capitalizeFirstLetter(`${attr.value} ${attr.name}`)
            }

            if (attr.id === 6104) {
                attr.value = `${(parseInt(attr.value) * 0.001).toFixed(1)}`
            }

            if (attr.id === 6105) {
                attr.value = `${attr.value} л.с.`
            }

            return attr
        })
    }

    getModificationParams(as: 'object' | 'array' = 'array') {
        const ids = [1106, 6104, 6201, 6105, 6101, 6301, 2101, 1105]

        const params = this.getFormatAttributes().filter((attr) => ids.includes(attr.id))

        if (as === 'array') {
            return params
        }

        if (as === 'object') {
            const objectParams = params.reduce((acc, curr) => {
                acc[curr.id] = curr

                return acc
            }, {}) as ObjectType<CarModelProps['edition']['attributes'][0]>

            return objectParams
        }
    }

    getDealerParams() {
        const {
            name,
            place: { address, locality },
            phone_number,
        } = this.data.dealer
        const modification = this.getModificationParams('object')

        return [
            { label: 'Страна бренда', value: modification[1105].value },
            { label: 'Дилер', value: name },
            { label: 'Адрес', value: `${locality}, ${address}` },
            { label: 'Номер', value: phone_number },
        ]
    }

    getSpecifications() {
        const params = this.getFormatAttributes()

        const ids = [1103, 7101, 6104, 6105, 2101, 6201, 6301, 1106, 6101, 2302, 2102, 3101]
        const wheelFormula = {
            label: 'Колесная формула',
            value: params
                .find((param) => param.id === 6301)
                .value.toLocaleLowerCase()
                .includes('полный')
                ? '4x4'
                : '2x2',
        }

        const specifications = params
            .map((specification) => {
                if (ids.includes(specification.id)) {
                    if (specification.id === 1106) {
                        specification.name = 'Год выпуска'
                        specification.value = specification.value.replace(' MY', '')
                    }

                    if (specification.id === 6101) {
                        specification.name = 'Тип двигателя'
                    }

                    if (specification.id === 6104) {
                        specification.name = 'Объём двигателя'
                        specification.value = `${specification.value} л`
                    }

                    if (specification.id === 6105) {
                        specification.name = 'Мощность двигателя'
                    }

                    if (specification.id === 6201) {
                        specification.name = 'Тип КПП'
                    }

                    if (specification.id === 7101) {
                        specification.name = 'Гарантия'
                    }

                    return {
                        ...specification,
                        label: specification.name,
                        value: specification.value,
                    }
                }

                return null
            })
            .filter(Boolean)

        specifications.push(wheelFormula as any)

        return specifications
    }

    getOptions(type: 'simple' | 'full') {
        const attributes = this.getFormatAttributes()

        const groups = [
            {
                title: 'Кузов',
                group: 2,
                options: this.getOptionsByGroup(2, type, attributes),
            },
            {
                title: 'Салон',
                group: 3,
                options: this.getOptionsByGroup(3, type, attributes),
            },
            {
                title: 'Безопасность',
                group: 4,
                options: this.getOptionsByGroup(4, type, attributes),
            },
            {
                title: 'Гарантия',
                group: 7,
                options: this.getOptionsByGroup(7, type, attributes),
            },
            {
                title: 'Функциональное оборудование',
                group: 5,
                options: this.getOptionsByGroup(5, type, attributes),
            },
        ].filter((group) => !!group.options.length)

        return groups
    }

    getOptionsByGroup(id: number, type: 'simple' | 'full', attributes: CarModelProps['edition']['attributes']) {
        const optionsData = attributes.filter((item) => Math.floor(item.id / 1000) === id && item.has)
        const descriptionData = attributes.find((item) => Math.floor(item.id / 1000) === id && item.type === 'TEXT')

        if (descriptionData && type === 'full') {
            return [
                ...optionsData
                    .filter((item) => item.type !== 'TEXT')
                    .map((item) => {
                        if (item.descriptions?.length > 0) {
                            return {
                                ...item,
                                label: item.name,
                                value: item.value,
                                type: 'BOOLEAN',
                                // (
                                //     <ul className={options.subList}>
                                //         {item.descriptions.map((option, index) => (
                                //             <li className={option.standard ? options.item : options.pay} key={index}>
                                //                 {option.value}
                                //             </li>
                                //         ))}
                                //     </ul>
                                // )
                            }
                        }

                        return {
                            ...item,
                            label: item.name,
                            value: item.value,
                        }
                    }),
                ...descriptionData.descriptions.map((item) => ({
                    id: item.value,
                    name: item.value,
                    label: item.value,
                    value: item.value,
                    standard: item.standard,
                    type: 'BOOLEAN',
                })),
            ]
        } else {
            return optionsData
                .filter((item) => item.type !== 'TEXT')
                .map((item) => {
                    return {
                        ...item,
                        label: item.name,
                        value: item.value,
                    }
                })
        }
    }

    async favoriteToggle() {
        try {
            if (this.state.loading) return

            this.setState({ loading: true })

            await sleep(200)

            if (this.data.is_favorite) {
                await this.service.favoriteRemove({
                    data: [this.data.id],
                })
            } else {
                await this.service.favoriteAdd({
                    data: [
                        {
                            offer_id: {
                                id: this.data.id,
                            },
                        },
                    ],
                })
            }

            this.setData({ is_favorite: !this.data.is_favorite })
            this.setState({ loading: false })
        } catch (err) {
            showToastError({ message: err?.message })
            this.setState({ loading: false })
        }
    }
}
