import React from 'react'
import { Box } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { FilterSelect } from '../filter-select'
import { FilterMultiSelect } from '../filter-multi-select'
import { TableFilterModel } from '@/widget/table/models'
import { FilterGroupSelect } from '../filter-group-select'

export interface FilterProps {
    filter: TableFilterModel
}

export const Filter: React.FC<FilterProps> = observer(({ filter, ...rest }) => {
    return (
        <Box className='elisa-table-filter' {...rest}>
            {
                {
                    select: <FilterSelect filter={filter} />,
                    'multi-select': <FilterMultiSelect filter={filter} />,
                    // 'range-select': <FilterRangeSelect filter={filter} />,
                    'group-select': <FilterGroupSelect filter={filter} />,
                }[filter.data.type]
            }
        </Box>
    )
})
