import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'

export const AuthCsrf = async () => {
    try {
        const response = await apiConfig.fetch.base({ url: '/auth/csrf-cookie' })

        return response
    } catch (err: any) {
        throwAdapterError(err, 'AuthCsrf')
    }
}
