import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import { ControlButton } from '../../button'
import { observer } from 'mobx-react-lite'
import { useSearchParams } from 'react-router-dom'
import { store } from '@/pages/analytics/store'

interface IControlArchiveProps {
    classes?: {
        root?: string
    }
}

export const ControlArchive: React.FC<IControlArchiveProps> = observer(({ classes, ...rest }) => {
    const cls = { root: clsx(style.root, classes?.root) }
    const [searchParams] = useSearchParams()

    const handleClick = () => store.table.filterArchive(!searchParams.get('f_archive'))

    return (
        <div className={cls.root} {...rest}>
            <ControlButton
                icon={'archive'}
                label='Архив'
                onClick={handleClick}
                isActive={Boolean(searchParams.get('f_archive'))}
            />
        </div>
    )
})
