import React from 'react'
import { Box, Button, clsx, Group, Pagination as PaginationCore } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { store } from '../../carstock.store'
import { usePagination, useWindowScroll } from '@mantine/hooks'
import { core } from '../../carstock.core'
import style from './pagination.style.scss'
import { IconArrowUp } from '@tabler/icons-react'

interface PaginationProps {
    hideArrow?: boolean
    mt?: number
    mb?: number
}

export const Pagination: React.FC<PaginationProps> = observer(({ mt, mb, hideArrow, ...rest }) => {
    const total = store.getTotalPages()
    const page = store.getPage()
    const disabled = store.state.searching || total === 0 || total === 1

    const [, scrollTo] = useWindowScroll()
    const pagination = usePagination({
        total,
        initialPage: page,
        boundaries: 2,
        onChange: (page) => {
            core.changePage(page)
        },
    })

    const handleChangeLimit = () => {
        core.changeLimit()
    }

    return (
        store.state.searched && (
            <Box mt={mt || 90} mb={mb} sx={{ position: 'relative' }} {...rest}>
                {!hideArrow && !!store.data.cars.length && (
                    <Box className={style.scroll} mb={12}>
                        <Button variant='default' className={style.arrow} onClick={() => scrollTo({ y: 0 })}>
                            <IconArrowUp />
                        </Button>
                    </Box>
                )}

                <PaginationCore.Root value={page} total={total} onChange={pagination.setPage} disabled={disabled}>
                    <Group spacing={12} sx={{ position: 'relative' }}>
                        <PaginationCore.First />
                        <PaginationCore.Previous />
                        <Box className={clsx(style.label, { [style.disabled]: page === 1 || disabled })} mr={'auto'}>
                            Предыдущая
                        </Box>

                        {!!store.data.cars.length && (
                            <Box className={style.count}>
                                Страница {page} / {total || 1}
                            </Box>
                        )}

                        {/* <Pagination.Items /> */}

                        <Box
                            className={clsx(style.label, { [style.disabled]: page === total || disabled })}
                            ml={'auto'}
                        >
                            Следующая
                        </Box>
                        <PaginationCore.Next />
                        <PaginationCore.Last />
                        <PaginationCore.Control disabled={disabled} px={12} onClick={handleChangeLimit}>
                            {store.data.meta?.per_page === '12' ? 'Показать больше' : 'Показать меньше'}
                        </PaginationCore.Control>
                    </Group>
                </PaginationCore.Root>
            </Box>
        )
    )
})
