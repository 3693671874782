import React from 'react'
import style from './matched-options.style.scss'
import { observer } from 'mobx-react-lite'
import { store } from '../../car.store'
import { ScrollArea, Table } from '@mantine/core'
import { number } from '@/shared'

type MatchedOptionsProps = {}

export const MatchedOptions: React.FC<MatchedOptionsProps> = observer(() => {
    const car = store.data.car

    return (
        <div className={style.root}>
            <ScrollArea.Autosize
                mah={328}
                styles={{
                    root: {
                        paddingRight: 8,
                    },
                }}
            >
                <Table className={style.table} maw={740}>
                    <thead className={style.thead}>
                        <tr>
                            <th colSpan={3}>Базовая стоимость:</th>
                            <th>{car.rrc} ₽</th>
                        </tr>
                        <tr>
                            <th>№</th>
                            <th>Название</th>
                            <th>Название в справочнике</th>
                            <th>Стоимость</th>
                        </tr>
                    </thead>
                    <tbody>
                        {car.matchedOptions.map((option, idx) => (
                            <tr key={idx}>
                                <td>{idx + 1}</td>
                                <td>{option.ilsa_name}</td>
                                <td>{option.elisa_name}</td>
                                <td>{number.format(option.price)} ₽</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot className={style.tfoot}>
                        <tr>
                            <th colSpan={1}>Итого:</th>
                            <th colSpan={3}>{car.matchedOptionsTotal} ₽</th>
                        </tr>
                    </tfoot>
                </Table>
            </ScrollArea.Autosize>
        </div>
    )
})
