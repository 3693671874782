import React from 'react'
import clsx from 'clsx'
import style from './cell-select.style.scss'
import { StoreData } from '@/widget/table/table.store'
import { TableCell } from '@/widget/table/table.types'
import { CellNull } from '../cell-null'

type CellSelectProps = React.HTMLAttributes<HTMLTableCellElement> & {
    cell: TableCell
}

export const CellSelect: React.FC<CellSelectProps> = ({ className, cell, ...rest }) => {
    return cell.value === null ? (
        <CellNull cell={cell} />
    ) : (
        <div className={clsx(style.root, className, 'elisa-table-widget-cell')} title={cell.value} {...rest}>
            {cell.value}
        </div>
    )
}
