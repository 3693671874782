import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'
import { CatalogCarGradeModel, CatalogCarModificationModel } from '@/widget/catalog/models'
import { Links, Meta, Modification } from './modifications.types'

type CatalogModificationsRequest = {
    filters?: {
        [property: string]: number
    }
}
type CatalogModificationsResponse = {
    data: Modification[]
    links: Links
    meta: Meta
    success: boolean
}

export const CatalogModificationsController = { ref: null }

export const CatalogModifications = async (req: CatalogModificationsRequest) => {
    CatalogModificationsController.ref = new AbortController()

    try {
        const response = await apiConfig.fetch.ilsa<CatalogModificationsResponse>({
            url: '/catalog',
            config: {
                method: 'POST',
                data: {
                    view_mode: 'modifications',
                    ...req.filters,
                },
                cache: false,
                signal: CatalogModificationsController.ref.signal,
            },
        })

        const ref = {
            /** Апи не отдает поле мета. Собираем самостоятельно кол-во для вывода в view */
            total: 0,
        }

        const modifications = response.data.data.map((modification) => {
            const model = new CatalogCarModificationModel({
                ...modification,
                complectations: [],
            })

            const complectations = modification.complectations.map(
                (complectation) => new CatalogCarGradeModel(complectation)
            )

            ref.total += complectations.length

            model.setData({
                complectations,
            })

            return model
        })

        return {
            modifications,
            links: response.data.links,
            meta: {
                total: ref.total,
                ...response.data.meta,
            },
        }
    } catch (err: any) {
        throwAdapterError(err, 'CatalogModifications')
    }
}
