export const unique = (array: any[]) => {
    return [...new Set(array.map((item) => item))]
}

export const uniqueByKey = (array: any[], key: any) => {
    return [...new Map(array.map((item) => [item[key], item])).values()]
}

export const uniqueByParam = (array, param) => {
    const ids = array.map(function (item) {
        return item[param]
    })

    return array.filter(function (item, index) {
        return ids.indexOf(item[param]) === index
    })
}
