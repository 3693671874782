import React from 'react'
import { Box } from '@mantine/core'
import { CatalogWidget } from '@/widget'
import { useCatalogPage } from '../../catalog.lib'
import { MantineProvider } from '@/theme'

interface CatalogSvPageProps {}

export const CatalogSvPage: React.FC<CatalogSvPageProps> = ({ ...rest }) => {
    const { coreProps } = useCatalogPage({
        widget: {
            id: 10,
            name: 'catalog-self',
        },
    })

    return (
        <MantineProvider withNotify>
            <Box {...rest}>
                <CatalogWidget coreProps={coreProps} />
            </Box>
        </MantineProvider>
    )
}
