import { makeAutoObservable } from 'mobx'
import { CarModel } from './models'

type StoreState = State

type StoreData = {
    car: CarModel
}

class Store {
    state: StoreState = {
        loading: false,
        loaded: false,
        init: false,
        error: null,
    }
    data: StoreData = {
        car: null,
    }

    constructor() {
        makeAutoObservable(this)
    }

    setState(states: Partial<StoreState>) {
        this.state = {
            ...this.state,
            ...states,
        }
    }

    setData(data: Partial<StoreData>) {
        this.data = {
            ...this.data,
            ...data,
        }
    }

    reset() {
        this.setState({
            loading: false,
            loaded: false,
            init: false,
            error: null,
        })
        this.setData({ car: null })
    }
}

export const store = new Store()
