import { appStore } from '@/app/app.store'
import axios, { AxiosRequestConfig } from 'axios'
import { sleep } from '@/shared'
import { service } from './api.service'

const cache = {}

export const createFetch = (host?: string) => {
    const $fetch = axios.create({
        baseURL: host,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    })

    $fetch.interceptors.response.use(
        (config) => {
            return config
        },
        async (err) => {
            const originalRequest = err.config

            if (axios.isCancel(err)) {
                throw 'isCancel'
            }

            if (
                err.response.status == 401 &&
                err.config &&
                !err.config._isRetry &&
                !originalRequest.url.includes('/auth/login-via-sso')
            ) {
                originalRequest._isRetry = true

                try {
                    try {
                        await service.auth.sso()

                        appStore.state.auth = true
                    } catch (err) {
                        appStore.session = null
                        appStore.state.auth = false
                        throw err
                    }

                    return $fetch.request(originalRequest)
                } catch (err: any) {
                    if (err.response?.data) {
                        throw err.response.data
                    }
                    throw err
                }
            }

            throw err
        }
    )

    const request = async <T = any>(props: {
        url: string
        config?: AxiosRequestConfig & {
            cache?: boolean
            cacheTimeout?: boolean
        }
        mock?: {
            enable: boolean
            data: ObjectType
        }
    }): Promise<ApiResponse<T>> => {
        const { url, mock } = props

        const config: AxiosRequestConfig = {
            method: 'GET',
            url,
            ...props.config,
            params: props.config?.params || {},
        }

        const cacheUrl = config.url + JSON.stringify(config.params || {}) + JSON.stringify(config.data || {})
        const fromCache = cache[cacheUrl]

        if (window.__ELISA_CONFIG__.apiLog)
            console.log(
                `Запрос (${config?.method || 'GET'}): ${url}`,
                (config?.method === 'GET' ? config?.params : config.data) || ''
            )

        if (props.config?.cache && fromCache) {
            if (window.__ELISA_CONFIG__.apiLog) console.log(`Ответ (cache): ${url}`, fromCache)

            if (props.config.cacheTimeout !== false) {
                await sleep(300)
            }

            return Promise.resolve(fromCache)
        }

        if (mock && mock.enable) {
            if (window.__ELISA_CONFIG__.apiLog) console.log(`Ответ (заглушка): ${url}`, mock.data)

            await sleep(300)

            return Promise.resolve({ data: mock.data } as any)
        }

        return $fetch
            .request(config)
            .then(function (res) {
                if (res.data && window.__ELISA_CONFIG__.apiLog) console.log(`Ответ:`, res.data)

                const response = {
                    data: res.data,
                    headers: res.headers,
                    status: res.status,
                }

                cache[cacheUrl] = response

                return response
            })
            .catch(function (error) {
                if (error === 'isCancel') throw 'isCancel'

                if (window.__ELISA_CONFIG__.apiLog)
                    console.log(
                        `Ошибка: ${error?.message}`,
                        `${error.response?.data ? `\nОтвет:` : ''}`,
                        error.response?.data || ''
                    )

                if (error.response?.data) {
                    throw error.response.data?.error || error.response.data
                }
                throw error
            })
    }

    return request
}
