import React from 'react'
import { Badge, Box } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { store } from '../../catalog.store'
import style from './grid-title.style.scss'

interface GridTitleProps {}

export const GridTitle: React.FC<GridTitleProps> = observer(({ ...rest }) => {
    const total = store.getTotal()
    const title = store.isCurrentMode('models') ? 'Найдено автомобилей' : 'Найдено комплектаций'

    return (
        <Box {...rest}>
            <Box className={style.root}>
                {title}
                {!store.state.searching && !store.state.searchingModifications && (
                    <Badge className={style.badge}>{total}</Badge>
                )}
            </Box>
        </Box>
    )
})
