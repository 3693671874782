import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import Instance, { TableCellProps } from '@mui/material/TableCell'

interface ITableCellProps extends TableCellProps {}

export const TableCell: React.FC<ITableCellProps> = ({ classes, children, ...rest }) => {
    const cls = { root: clsx(style.root, classes?.root) }

    return (
        <Instance classes={cls} {...rest}>
            {children}
        </Instance>
    )
}
