import React from 'react'
import { Box } from '@mantine/core'
import { TableWidget } from '@/widget'
import style from './favorite.style.scss'
import { service } from '@/api'
import { R, number } from '@/shared'
import { TableFilterModel } from '@/widget/table/models/filter'
import { MantineProvider } from '@/theme'
import { IconTrash } from '@tabler/icons-react'

type CarstockFavoritePageProps = {}

export const CarstockFavoritePage: React.FC<CarstockFavoritePageProps> = ({ ...rest }) => {
    return (
        <MantineProvider withNotify>
            <Box className={style.root} {...rest}>
                <TableWidget
                    coreProps={{
                        widget: {
                            name: 'carstock-favorites',
                        },
                        service: {
                            get: {
                                request: async (props) => {
                                    const response = await service.carstock.favorites(props)

                                    const hide = [
                                        'id',
                                        'offer_id',
                                        'equipment_id',
                                        'version_id',
                                        'dealer_city_id',
                                        'model_id',
                                        'brand_id',
                                    ]

                                    const order = {
                                        name: 1,
                                        picture: 0,
                                        created_at: 2,
                                        price_on_save: 3,
                                        price: 4,
                                        availability: 5,
                                    }

                                    const label = {
                                        name: 'Название',
                                        picture: '',
                                        created_at: 'Дата добавления',
                                        price_on_save: 'РРЦ на дату добавления',
                                        price: 'Текущая РРЦ',
                                        availability: 'Наличие',
                                    }

                                    response.columns.map((column, i) => {
                                        column.params = column.params || {}
                                        column.params.order = i

                                        if (hide.includes(column.id)) {
                                            column.params.hidden = true
                                        }

                                        if (order[column.id] !== undefined) {
                                            column.params.order = order[column.id]
                                        }

                                        if (label[column.id] !== undefined) {
                                            column.label = label[column.id]
                                        }

                                        return column
                                    })

                                    response.columns = R.sort((a, b) => {
                                        return a.params.order > b.params.order ? 1 : -1
                                    }, response.columns)

                                    response.rows = response.rows.map((row) => {
                                        row['price'].value = number.format(row['price'].value)
                                        row['price_on_save'].value = number.format(row['price'].value)

                                        return row
                                    })

                                    return response
                                },
                                controller: service.carstock.controller.favorites,
                            },
                            remove: {
                                request: service.carstock.favorite_remove,
                            },
                        },
                        params: {
                            search: 'filters[name]',
                            selected: true,
                            getLinkCell(row) {
                                return '/carstock/car/' + row.offer_id.value
                            },
                            onClickDelete(rows) {
                                return [rows[0].offer_id.value]
                            },
                            onClickEdit(rows) {
                                return {
                                    [rows[0].id.value]: rows[0].id.value,
                                }
                            },
                        },
                        data: {
                            page: 'Избранное',
                            title: 'Найдено автомобилей',
                            states: new Map([
                                [
                                    'all',
                                    {
                                        id: 'all',
                                        param: 'filters[in_stock]',
                                        enabled: false,
                                        value: null,
                                        label: 'Все',
                                    },
                                ],
                                [
                                    'active',
                                    {
                                        id: 'active',
                                        param: 'filters[in_stock]',
                                        enabled: true,
                                        value: '1',
                                        label: 'В наличие',
                                    },
                                ],
                                [
                                    'archive',
                                    {
                                        id: 'archive',
                                        param: 'filters[in_stock]',
                                        enabled: false,
                                        value: '0',
                                        label: 'Архивные',
                                    },
                                ],
                            ]),
                            menu: [
                                {
                                    id: 'delete',
                                },
                            ],
                            actions: [
                                {
                                    label: 'Удалить',
                                    onClick: (rows, core) => {
                                        const ids = rows.map((row) => row.offer_id.value)

                                        core.delete(ids)
                                    },
                                    icon: <IconTrash size={20} />,
                                    confirm: true,
                                },
                            ],
                            filters: [
                                new TableFilterModel({
                                    id: '1101',
                                    label: null,
                                    params: [
                                        {
                                            name: '1101',
                                        },
                                    ],
                                    placeholders: ['Все марки'],
                                    value: [],
                                    values: [],
                                    defaultValues: [],
                                    depends_on: [],
                                    depends_for: ['1102'],
                                    group_id: 1,
                                    sort_order: 1,
                                    type: 'multi-select',
                                    __filter_type_id: 1,
                                    __searchParam: ['filters[brand_id]'],
                                    __type: {
                                        id: '1101',
                                        value: '1101',
                                    },
                                    __types: [
                                        {
                                            id: '1101',
                                            value: '1101',
                                        },
                                    ],
                                    __service: {
                                        loadValues: async ({ name, type, filters }) => {
                                            const response = await service.carstock.filter_values({
                                                id: name,
                                                filters,
                                            })

                                            return response
                                        },
                                    },

                                    // ...{
                                    //     id: '1101',
                                    //     param: ['1101'],
                                    //     searchParam: ['filters[brand_id]'],
                                    //     name: null,
                                    //     label: ['Все марки'],
                                    //     category: 'Фильтрация',
                                    //     type: 'select',
                                    //     defaultValues: [],
                                    //     value: [],
                                    //     values: [],
                                    //     depends_on: [],
                                    //     depends_for: ['1102'],
                                    // },
                                    // __service: {
                                    //     loadValues: service.carstock.filterValues,
                                    // },
                                }),
                                new TableFilterModel({
                                    id: '1102',
                                    label: null,
                                    params: [
                                        {
                                            name: '1102',
                                        },
                                    ],
                                    placeholders: ['Все модели'],
                                    value: [],
                                    values: [],
                                    defaultValues: [],
                                    depends_on: ['1101'],
                                    depends_for: [],
                                    group_id: 1,
                                    sort_order: 1,
                                    type: 'multi-select',
                                    __filter_type_id: 1,
                                    __searchParam: ['filters[model_id]'],
                                    __type: {
                                        id: '1102',
                                        value: '1102',
                                    },
                                    __types: [
                                        {
                                            id: '1102',
                                            value: '1102',
                                        },
                                    ],
                                    __service: {
                                        loadValues: async ({ name, type, filters }) => {
                                            const response = await service.carstock.filter_values({
                                                id: name,
                                                filters,
                                            })

                                            return response
                                        },
                                    },

                                    // ...{
                                    //     id: '1102',
                                    //     param: ['1102'],
                                    //     searchParam: ['filters[model_id]'],
                                    //     name: null,
                                    //     label: ['Все модели'],
                                    //     category: 'Фильтрация',
                                    //     type: 'select',
                                    //     defaultValues: [],
                                    //     value: [],
                                    //     values: [],
                                    //     depends_on: ['1101'],
                                    //     depends_for: [],
                                    // },
                                    // service: {
                                    //     values: service.carstock.filterValues,
                                    // },
                                }),
                                new TableFilterModel({
                                    id: 3,
                                    label: null,
                                    params: [
                                        {
                                            name: 'sorts[created_at]',
                                        },
                                    ],
                                    placeholders: ['Сначала новые'],
                                    value: [{ id: 'desc', value: 'desc', label: 'Сначала новые' }],
                                    values: [],
                                    defaultValues: [
                                        { id: 'desc', value: 'desc', label: 'Сначала новые' },
                                        { id: 'asc', value: 'asc', label: 'Сначала старые' },
                                    ],
                                    depends_on: [],
                                    depends_for: [],
                                    group_id: 1,
                                    sort_order: 1,
                                    type: 'select',
                                    __filter_type_id: null,
                                    __type: {
                                        id: 'sorts[created_at]',
                                        value: 'sorts[created_at]',
                                    },
                                    __types: [
                                        {
                                            id: 'sorts[created_at]',
                                            value: 'sorts[created_at]',
                                        },
                                    ],

                                    // ...{
                                    //     id: 'sorts_date',
                                    //     param: ['sorts[created_at]'],
                                    //     name: null,
                                    //     label: ['Сначала новые'],
                                    //     category: 'Сортировка',
                                    //     type: 'select',
                                    //     defaultValues: [
                                    //         { id: 'desc', value: 'desc', label: 'Сначала новые' },
                                    //         { id: 'asc', value: 'asc', label: 'Сначала старые' },
                                    //     ],
                                    //     value: [{ id: 'desc', value: 'desc', label: 'Сначала новые' }],
                                    //     values: [],
                                    //     depends_on: [],
                                    //     depends_for: [],
                                    // },
                                }),
                                new TableFilterModel({
                                    id: 4,
                                    label: null,
                                    params: [
                                        {
                                            name: 'sorts[price_on_save]',
                                        },
                                    ],
                                    placeholders: ['По возрастанию цены'],
                                    value: [{ id: 'asc', value: 'asc', label: 'По возрастанию цены' }],
                                    values: [],
                                    defaultValues: [
                                        { id: 'asc', value: 'asc', label: 'По возрастанию цены' },
                                        { id: 'desc', value: 'desc', label: 'По убыванию цены' },
                                    ],
                                    depends_on: [],
                                    depends_for: [],
                                    group_id: 1,
                                    sort_order: 1,
                                    type: 'select',
                                    __filter_type_id: null,
                                    __type: {
                                        id: 'sorts[price_on_save]',
                                        value: 'sorts[price_on_save]',
                                    },
                                    __types: [
                                        {
                                            id: 'sorts[price_on_save]',
                                            value: 'sorts[price_on_save]',
                                        },
                                    ],

                                    // ...{
                                    //     id: 'sorts_price',
                                    //     param: ['sorts[rrp_current]'],
                                    //     name: null,
                                    //     label: ['По возрастанию цены'],
                                    //     category: 'Сортировка',
                                    //     type: 'select',
                                    //     defaultValues: [
                                    //         { id: 'asc', value: 'asc', label: 'По возрастанию цены' },
                                    //         { id: 'desc', value: 'desc', label: 'По убыванию цены' },
                                    //     ],
                                    //     value: [],
                                    //     values: [],
                                    //     depends_on: [],
                                    //     depends_for: [],
                                    // },
                                }),

                                // new TableFilterModel({
                                //     ...{
                                //         id: '1101',
                                //         param: ['1101'],
                                //         searchParam: ['filters[brand_id]'],
                                //         name: null,
                                //         label: ['Все марки'],
                                //         category: 'Фильтрация',
                                //         type: 'select',
                                //         defaultValues: [],
                                //         value: [],
                                //         values: [],
                                //         depends_on: [],
                                //         depends_for: ['1102'],
                                //     },
                                //     service: {
                                //         values: service.carstock.filterValues,
                                //     },
                                // }),
                                // new TableFilterModel({
                                //     ...{
                                //         id: '1102',
                                //         param: ['1102'],
                                //         searchParam: ['filters[model_id]'],
                                //         name: null,
                                //         label: ['Все модели'],
                                //         category: 'Фильтрация',
                                //         type: 'select',
                                //         defaultValues: [],
                                //         value: [],
                                //         values: [],
                                //         depends_on: ['1101'],
                                //         depends_for: [],
                                //     },
                                //     service: {
                                //         values: service.carstock.filterValues,
                                //     },
                                // }),
                                // new TableFilterModel({
                                //     ...{
                                //         id: 'sorts_date',
                                //         param: ['sorts[created_at]'],
                                //         name: null,
                                //         label: ['Сначала новые'],
                                //         category: 'Сортировка',
                                //         type: 'select',
                                //         defaultValues: [
                                //             { id: 'asc', value: 'asc', label: 'Сначала новые' },
                                //             { id: 'desc', value: 'desc', label: 'Сначала старые' },
                                //         ],
                                //         value: [],
                                //         values: [],
                                //         depends_on: [],
                                //         depends_for: [],
                                //     },
                                // }),
                                // new TableFilterModel({
                                //     ...{
                                //         id: 'sorts_price',
                                //         param: ['sorts[price]'],
                                //         name: null,
                                //         label: ['По возрастанию цены'],
                                //         category: 'Сортировка',
                                //         type: 'select',
                                //         defaultValues: [
                                //             { id: 'asc', value: 'asc', label: 'По возрастанию цены' },
                                //             { id: 'desc', value: 'desc', label: 'По убыванию цены' },
                                //         ],
                                //         value: [],
                                //         values: [],
                                //         depends_on: [],
                                //         depends_for: [],
                                //     },
                                // }),
                            ],
                        },
                    }}
                />
            </Box>
        </MantineProvider>
    )
}
