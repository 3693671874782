import clsx from 'clsx'
import React from 'react'
import style from './index.style.scss'

interface IModalTitleProps {
    title: string
    className?: string
}

export const ModalTitle: React.FC<IModalTitleProps> = ({ title, className }) => {
    const classes = clsx(style.root, className)

    return <div className={classes}>{title}</div>
}
