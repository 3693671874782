import React from 'react'
import { Container, LoadingOverlay, clsx } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import style from './table.style.scss'
import { CoreProps, core } from './table.core'
import { store } from './table.store'
import { CreateModal, EditModal, Filters, Pagination, Search, Selected, States, Table, Title } from './ui'
import { Paper } from '../car/ui/paper'
import { ErrorBoundary } from 'react-error-boundary'

type TableWidgetProps = {
    coreProps: CoreProps
}

/**
 * Рефакторинг:
 * 1. Вынести логику создания/редактирования и сбора всех моделей/ui в core/store. генератор ui компонентов для редактирования как ui/filter
 * 1.1. Написать логику подгрузки/сброса зависимых данных для редактирования.
 * 2. Разделить модели на create/edit
 * 3. Обьединить логику обработке типов ui (string/select и пр.) в create/edit
 * 4. Модель для ячейки/колонки, чтобы убрать массивы editRows/hiddenColumns и прочие манипуляции со спискаи
 */
export const TableWidget: React.FC<TableWidgetProps> = observer(({ coreProps, ...rest }) => {
    React.useEffect(() => {
        const widget = core.init(coreProps)

        widget.mount()

        return () => {
            widget.unmount()
        }
    }, [])

    return (
        <div className={style.root} {...rest}>
            <Container>
                {!!store.data.page && <div className={style.title}>{store.data.page}</div>}

                <div className={style.wrapper}>
                    <LoadingOverlay visible={store.state.loading} radius={24} />

                    {store.state.init && (
                        <ErrorBoundary
                            fallbackRender={() => <Paper>Произошла ошибка, обратитесь к администратору!</Paper>}
                        >
                            <div
                                className={clsx(style.content, {
                                    [style.content_loading]: store.state.loading,
                                })}
                            >
                                <States />

                                {!!store.data.filters && <Filters />}

                                <Paper>
                                    <Search />
                                    {!!store.data.title && <Title />}
                                    {!!store.params.selected && <Selected />}
                                    <Table />
                                </Paper>

                                <Pagination />

                                <CreateModal />
                                <EditModal />
                            </div>
                        </ErrorBoundary>
                    )}
                </div>
            </Container>
        </div>
    )
})
