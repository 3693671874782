import React from 'react'
import { Button, Group } from '@mantine/core'
import { IconX } from '@tabler/icons-react'
import style from './reset.style.scss'
import { observer } from 'mobx-react-lite'
import { core } from '@/widget/table/table.core'

interface ResetProps {}

export const Reset: React.FC<ResetProps> = observer(({ ...rest }) => {
    const handleClick = () => {
        core.resetFilters()
    }

    return (
        <div className={style.root}>
            <Button
                onClick={handleClick}
                rightIcon={<IconX size={20} />}
                className={style.button}
                // disabled={!Object.keys(values).length}
                {...rest}
            >
                Сбросить все
            </Button>
        </div>
    )
})
