import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'

export type ConfigurationsEditRequest = {
    data: {
        id: { value: number }
        name: { value: string }
        complectation_id: { value: number }

        additional_equipment?: { value: any }
        additional_equipment_price?: { value: any }

        specific_color_id?: { id: any }[]
        specific_option_id?: { id: any }[]
        parameter_value_id?: { id: any }[]
        option_package_id?: { id: any }[]
    }[]
}

export type ConfigurationsEditResponse = {
    success: boolean
}

export const ConfigurationsEdit = async (req: ConfigurationsEditRequest) => {
    try {
        const response = await apiConfig.fetch.ilsa<ConfigurationsEditResponse>({
            url: '/configurations',
            config: {
                method: 'PUT',
                data: req.data,
            },
            mock: {
                enable: false,
                data: {
                    success: true,
                },
            },
        })

        if (!response?.data?.success) {
            throw Error('Произошла ошибка при обновление')
        }

        return response
    } catch (err: any) {
        throwAdapterError(err, 'ConfigurationsEdit')
    }
}
