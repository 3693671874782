import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'

type UsersActiveRequest = {
    ids: string[]
}
type UsersActiveResponse = {
    success: boolean
    message: string
}

export const UsersActive = async (data: UsersActiveRequest) => {
    try {
        const response = await apiConfig.fetch.ilsa<UsersActiveResponse>({
            url: '/auth/users/mass_active',
            config: {
                method: 'POST',
                data: data.ids,
            },
        })

        if (!response?.data?.success) {
            throw Error('Не удалось активировать пользователей')
        }

        return response
    } catch (err: any) {
        throwAdapterError(err, 'UsersActive')
    }
}
