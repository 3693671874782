import React from 'react'
import style from './colors.style.scss'
import { observer } from 'mobx-react-lite'
import { store } from '../../complectation.store'
import { Box, ScrollArea, Table, Text } from '@mantine/core'
import { number } from '@/shared'
import { styles } from '@/theme/styles'

type ColorsProps = {}

export const Colors: React.FC<ColorsProps> = observer(() => {
    const complectation = store.data.complectation

    return (
        <div className={style.root}>
            <ScrollArea.Autosize
                mah={328}
                styles={{
                    root: {
                        paddingRight: 8,
                    },
                }}
            >
                <Table className={style.table} miw={480} maw={640}>
                    <thead className={style.thead}>
                        <tr>
                            {/* <th>№</th> */}
                            <th>Цвет</th>
                            <th>Название</th>
                            <th>Стоимость</th>
                        </tr>
                    </thead>
                    <tbody>
                        {complectation.colors.map((color, idx) => (
                            <Box
                                component='tr'
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': { color: styles.accent },
                                    color: complectation.color.id === color.id ? styles.accent : styles.dark,
                                }}
                                key={idx}
                                onClick={() => complectation.setColor(color)}
                            >
                                {/* <td>{color.id}</td> */}
                                <td align='left'>
                                    <Box
                                        w={32}
                                        h={32}
                                        sx={(theme) => ({
                                            borderRadius: '50%',
                                            backgroundColor: color.hex_code,
                                            boxShadow: theme.shadows.sm,
                                        })}
                                    />
                                </td>
                                <td align='left'>
                                    <Text lineClamp={1} title={color.name}>
                                        {color.name}
                                    </Text>
                                </td>
                                <td>
                                    <Text sx={{ whiteSpace: 'nowrap' }}>{number.format(color.price)} ₽</Text>
                                </td>
                            </Box>
                        ))}
                    </tbody>
                    <tfoot className={style.tfoot}>
                        <tr>
                            <th colSpan={1}>Итого:</th>
                            <th colSpan={3}>{number.format(complectation.price_with_color)} ₽</th>
                        </tr>
                    </tfoot>
                </Table>
            </ScrollArea.Autosize>
        </div>
    )
})
