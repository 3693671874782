import React from 'react'
import { Box, Input, SegmentedControl, Stack } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { core } from '../../catalog.core'
import { useFilter } from '../../catalog.lib'
import { CatalogFilterModel } from '../../models'
import { styles } from '@/theme/styles'

interface FilterSwitchProps {
    filter: CatalogFilterModel
}

export const FilterSwitch: React.FC<FilterSwitchProps> = observer(({ filter, ...rest }) => {
    const [state] = useFilter(filter)

    const value = filter.get.switchValue()
    const values = filter.get.values()

    const handleChangeValue = (value: string[]) => {
        core.changeFilter(value, filter)
    }

    return (
        <div {...rest}>
            <Stack spacing={6}>
                <Input.Label>{filter.data.label}</Input.Label>
                <Box
                    sx={{
                        background: styles.white,
                        border: '1px solid',
                        borderColor: styles.border,
                        borderRadius: 12,
                        maxWidth: 'calc(100% - 1px)',
                    }}
                >
                    <SegmentedControl
                        onChange={(val) => {
                            handleChangeValue([val])
                        }}
                        onClick={(e: any) => {
                            const target = e.target

                            if (target.nodeName === 'LABEL') {
                                if (target.dataset.active === 'true') {
                                    e.preventDefault()
                                    handleChangeValue([])
                                }
                            }
                        }}
                        value={value}
                        data={values}
                        fullWidth
                        disabled={state.disabled}
                    />
                </Box>
            </Stack>
        </div>
    )
})
