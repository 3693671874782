import { apiConfig } from '@/api/api.config'
import { R, throwAdapterError } from '@/shared'

type CatalogFiltersTabsRequest = {
    params: {
        vehicle_type_id: number
    }
}

type CatalogFiltersTabsResponse = {
    data: { id: number; name: string }[]
}

export const CatalogFiltersTabsController = { ref: null }

export const CatalogFiltersTabs = async (req: CatalogFiltersTabsRequest) => {
    CatalogFiltersTabsController.ref = new AbortController()

    try {
        const response = await apiConfig.fetch.ilsa<CatalogFiltersTabsResponse>({
            url: '/interface-builder/catalog/body-type-purposes',
            config: {
                signal: CatalogFiltersTabsController.ref.signal,
                params: req.params,
                cache: true,
            },
        })

        const data = response.data.data.map((item) => ({
            ...item,
            selected: false,
        }))

        return {
            data: R.filter((item) => item.id !== null && item.name !== null, data),
            id: 'body_type_purpose_id',
        }
    } catch (err: any) {
        throwAdapterError(err, 'CatalogFiltersTabs')
    }
}
