import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import { Icon, Icons } from '@/pages/analytics/components/ui/icon'
import { Button } from '@/pages/analytics/components/ui'
import { ButtonProps } from '@/pages/analytics/components/ui/button'

export interface IControlButtonProps extends ButtonProps {
    classes?: {
        root?: string
        icon?: string
    }
    label: string
    icon: Icons
    endIcon?: boolean
    isActive?: boolean
}

export const ControlButton: React.FC<IControlButtonProps> = ({
    classes,
    label,
    icon,
    endIcon,
    isActive,
    disabled,
    ...rest
}) => {
    const cls = {
        root: clsx(style.root, classes?.root, { [style.isActive]: isActive }),
        icon: clsx(style.icon, classes?.icon, { [style.isActive]: isActive }),
        endIcon: clsx(style.endIcon, classes?.icon, { [style.isActive]: isActive }),
    }

    return (
        <Button size='large' color='inherit' className={cls.root} disabled={disabled} {...rest}>
            {!endIcon && <Icon icon={icon} className={cls.icon} />}
            {label}
            {endIcon && <Icon icon={icon} className={cls.endIcon} />}
        </Button>
    )
}
