import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import DocumentsStore from '../../store/DocumentsStore'
import styles from './History.style.scss'

import HistoryItem from '../HistoryItem'

const History = () => {
    const { documents, meta, getDocuments } = DocumentsStore
    const [openItemIndex, setOpenItemIndex] = useState(null)

    const handleOpenInfo = (id) => {
        if (id === openItemIndex) setOpenItemIndex(null)
        else setOpenItemIndex(id)
    }

    const handleMoreButton = () => {
        const params = {
            'page[size]': +meta.per_page + 10,
            'page[number]': 1,
        }

        getDocuments(params)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            getDocuments()
        }, 10000)

        if (!documents?.some((item) => item.status.name === 'pending' || item.status.name === 'new')) {
            clearInterval(interval)
        }

        return () => clearInterval(interval)
    }, [documents])

    useEffect(getDocuments, [getDocuments])

    return (
        <div className={styles.container}>
            <div className={styles.title}>История загрузок</div>
            {documents && (
                <div>
                    <ul>
                        {documents.map((item, index) => (
                            <HistoryItem
                                data={{
                                    ...item,
                                    index,
                                }}
                                isOpen={openItemIndex === index}
                                setIsOpen={handleOpenInfo}
                                key={item.id}
                            />
                        ))}
                    </ul>

                    {meta.current_page !== meta.last_page && (
                        <button className={styles.more} onClick={handleMoreButton}>
                            Показать еще {meta.total - 10}
                        </button>
                    )}
                </div>
            )}
        </div>
    )
}

export default observer(History)
