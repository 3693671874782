export const debounce = <T extends (...args: any[]) => any>(callback: T, waitFor: number = 500, set?: T) => {
    let timeout: TimeoutType

    return (...args: Parameters<T>): ReturnType<T> => {
        let result: any

        set?.(...args)

        clearTimeout(timeout)
        timeout = setTimeout(() => {
            result = callback(...args)
        }, waitFor)
        return result
    }
}

export const sleep = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms))
}
