import React from 'react'
import { Box } from '@mantine/core'
import style from './car.style.scss'
import { CarWidget } from '@/widget'
import { useParams } from 'react-router-dom'
import { service } from '@/api'
import { MantineProvider } from '@/theme'

type CarstockCarPageProps = {}

export const CarstockCarPage: React.FC<CarstockCarPageProps> = ({ ...rest }) => {
    const { id } = useParams()

    return (
        <MantineProvider withNotify>
            <Box className={style.root} {...rest}>
                <CarWidget
                    coreProps={{
                        service: {
                            car: {
                                request: service.carstock.car,
                                controller: service.carstock.controller.car,
                            },
                        },
                        params: {
                            car_id: id,
                        },
                    }}
                />
            </Box>
        </MantineProvider>
    )
}
