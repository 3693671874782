import { AppStore } from './app/store'
import { PassportStore } from './passport/store'
import { TableStore } from './table/store'

export const createStore = () => ({
    app: new AppStore(),
    table: new TableStore(),
    passport: new PassportStore(),
})

export const store = createStore()
