import React from 'react'
import { Box, clsx, Group, Pagination as PaginationCore } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { usePagination, useWindowScroll } from '@mantine/hooks'
import style from './pagination.style.scss'
import { store } from '../../table.store'
import { core } from '../../table.core'

interface PaginationProps {
    hideArrow?: boolean
    mt?: number
    mb?: number
}

export const Pagination: React.FC<PaginationProps> = observer(({ mt, mb, hideArrow, ...rest }) => {
    const total = store.pages
    const page = store.page
    const disabled = store.state.searching || total === 0 || total === 1

    const [, scrollTo] = useWindowScroll()
    const pagination = usePagination({
        total,
        initialPage: page,
        boundaries: 2,
        onChange: (page) => {
            core.changePage(page)
        },
    })

    const handleChangeLimit = () => {
        core.changeLimit()
    }

    return (
        <Box mt={mt || 40} mb={mb} sx={{ position: 'relative' }} {...rest}>
            {/* {!hideArrow && !!store.data.rows.length && (
                <Box className={style.scroll} mb={12}>
                    <Button variant='default' className={style.arrow} onClick={() => scrollTo({ y: 0 })}>
                        <IconArrowUp />
                    </Button>
                </Box>
            )} */}

            <PaginationCore.Root value={page} total={total} onChange={pagination.setPage} disabled={disabled}>
                <Group spacing={12} sx={{ position: 'relative' }}>
                    <PaginationCore.First />
                    <PaginationCore.Previous />
                    <Box className={clsx(style.label, { [style.disabled]: page === 1 || disabled })} mr={'auto'}>
                        Предыдущая
                    </Box>

                    {!!store.data.rows.length && (
                        <Box className={style.count}>
                            Страница {page} / {total || 1}
                        </Box>
                    )}

                    <Box className={clsx(style.label, { [style.disabled]: page === total || disabled })} ml={'auto'}>
                        Следующая
                    </Box>
                    <PaginationCore.Next />
                    <PaginationCore.Last />
                    <PaginationCore.Control
                        disabled={disabled && store.data.meta?.per_page !== store.data.limit.max}
                        px={12}
                        onClick={handleChangeLimit}
                    >
                        {store.data.meta?.per_page === store.data.limit.min ? 'Показать больше' : 'Показать меньше'}
                    </PaginationCore.Control>
                </Group>
            </PaginationCore.Root>
        </Box>
    )
})
