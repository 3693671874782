import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'
import { CatalogFilterCard, CatalogFilterLinks, CatalogFilterMeta } from './models.types'
import { CatalogCarModel } from '@/widget/catalog/models'

type CatalogModelsRequest = {
    filters?: {
        [property: string]: number
    }
}
type CatalogModelsResponse = {
    data: CatalogFilterCard[]
    links: CatalogFilterLinks
    meta: CatalogFilterMeta
    success: boolean
}

export const CatalogModelsController = { ref: null }

export const CatalogModels = async (req: CatalogModelsRequest) => {
    CatalogModelsController.ref = new AbortController()

    try {
        const response = await apiConfig.fetch.ilsa<CatalogModelsResponse>({
            url: '/catalog',
            config: {
                method: 'POST',
                data: {
                    view_mode: 'models',
                    ...req.filters,
                },
                cache: false,
                signal: CatalogModelsController.ref.signal,
            },
        })

        const cars = response.data.data.map((card) => new CatalogCarModel(card))

        return { cars, links: response.data.links, meta: response.data.meta }
    } catch (err: any) {
        throwAdapterError(err, 'CatalogModels')
    }
}
