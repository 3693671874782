import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import { observer } from 'mobx-react-lite'
import { store } from '@/pages/analytics/store'

interface IControlSelectedProps {
    classes?: {
        root?: string
    }
}

export const ControlSelected: React.FC<IControlSelectedProps> = observer(({ classes, ...rest }) => {
    const cls = { root: clsx(style.root, classes?.root) }

    return (
        <div className={cls.root} {...rest}>
            Выбрано {store.table.selected.length} из {store.table.rows.length}
        </div>
    )
})
