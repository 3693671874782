import React from 'react'
import { useForm } from '@mantine/form'
import { TextInput, Text, Paper, Group, Button, Anchor, Stack, Center, Box } from '@mantine/core'
import { IconArrowLeft } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'

type ResetFormProps = {}

export const ResetForm: React.FC<ResetFormProps> = () => {
    const navigate = useNavigate()
    const form = useForm({
        initialValues: {
            email: '',
        },

        validate: {
            email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Не корректный email'),
        },
    })

    return (
        <Paper radius={32} py={32} px={32} sx={{ width: 440 }}>
            <Text size='lg' mb={8} weight={500}>
                Забыли свой пароль?
            </Text>

            <Text size={12} mt={4} mb={12} color={'gray'}>
                Введите email, чтобы получить ссылку для сброса
            </Text>

            <form
                onSubmit={form.onSubmit((data) => {
                    navigate('/dropped', { state: data })
                })}
            >
                <Stack>
                    <TextInput
                        required
                        label='Email'
                        placeholder='name@elisa.ru'
                        value={form.values.email}
                        onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
                        error={form.errors.email && 'Не корректный email'}
                    />
                </Stack>

                <Group position='apart' mt='xl' spacing='sm'>
                    <Anchor
                        component='button'
                        type='button'
                        color='dimmed'
                        onClick={() => navigate('/')}
                        sx={{ display: 'inline-flex', alignItems: 'center' }}
                        mb={10}
                    >
                        <IconArrowLeft size={13} stroke={2} />
                        <Text size={12} ml={8} mt={0}>
                            Вернуться на авторизацию
                        </Text>
                    </Anchor>
                    <Button type='submit'>Сбросить пароль</Button>
                </Group>
            </form>
        </Paper>
    )
}
