import React from 'react'
import { Box } from '@mantine/core'
import { TableWidget } from '@/widget'
import { service } from '@/api'
import style from './users.style.scss'
import { IconCheck, IconTrash, IconX } from '@tabler/icons-react'
import { showToast } from '@/shared'
import { TableFilterModel } from '@/widget/table/models/filter'
import { MantineProvider } from '@/theme'

type UsersPageProps = {}

export const UsersPage: React.FC<UsersPageProps> = ({ ...rest }) => {
    return (
        <MantineProvider withNotify>
            <Box className={style.root} {...rest}>
                <TableWidget
                    coreProps={{
                        widget: {
                            name: 'users',
                        },
                        service: {
                            get: {
                                request: service.users.users,
                                controller: service.users.controller.users,
                            },
                            create: {
                                request: service.users.create,
                            },
                            remove: {
                                request: service.users.delete,
                            },
                            update: {
                                request: service.users.update,
                            },
                        },
                        params: {
                            search: true,
                            reset: false,
                            selected: true,
                            onClickDelete(rows) {
                                return [rows[0].id.value]
                            },
                            onClickEdit(rows) {
                                return {
                                    ['id']: rows[0].id.value,
                                }
                            },
                        },
                        data: {
                            page: 'Пользователи',
                            // title: 'Найдено пользователей',
                            create: 'Создать пользователя',
                            menu: [
                                {
                                    id: 'edit',
                                },
                                {
                                    id: 'delete',
                                },
                            ],
                            actions: [
                                {
                                    label: 'Активировать',
                                    onClick: async (rows, core) => {
                                        core.store.setState({ searching: true })

                                        const ids = rows.map((row) => row.id.value)

                                        await service.users.active({ ids })
                                        await core.search()

                                        core.store.setState({ searching: false })

                                        showToast({ message: 'Значения обновлены' })
                                    },
                                    icon: <IconCheck size={20} />,
                                    confirm: true,
                                },
                                {
                                    label: 'Заблокировать',
                                    onClick: async (rows, core) => {
                                        core.store.setState({ searching: true })

                                        const ids = rows.map((row) => row.id.value)

                                        await service.users.disable({ ids })
                                        await core.search()

                                        core.store.setState({ searching: false })

                                        showToast({ message: 'Значения обновлены' })
                                    },
                                    icon: <IconX size={20} />,
                                    confirm: true,
                                },
                                {
                                    label: 'Удалить',
                                    onClick: (rows, core) => {
                                        const ids = rows.map((row) => row.id.value)

                                        core.delete(ids)
                                    },
                                    icon: <IconTrash size={20} />,
                                    confirm: true,
                                },
                            ],
                            // states: new Map([
                            //     [
                            //         'all',
                            //         {
                            //             id: 'all',
                            //             param: 'params[is_archival]',
                            //             enabled: false,
                            //             value: null,
                            //             label: 'Все',
                            //         },
                            //     ],
                            //     [
                            //         'active',
                            //         {
                            //             id: 'active',
                            //             param: 'params[is_archival]',
                            //             enabled: true,
                            //             value: '0',
                            //             label: 'Активные',
                            //         },
                            //     ],
                            //     [
                            //         'archive',
                            //         {
                            //             id: 'archive',
                            //             param: 'params[is_archival]',
                            //             enabled: false,
                            //             value: '1',
                            //             label: 'Заблокированные',
                            //         },
                            //     ],
                            // ]),
                            filters: [
                                new TableFilterModel({
                                    id: 1,
                                    label: null,
                                    params: [
                                        {
                                            name: 'params[cities]',
                                        },
                                    ],
                                    placeholders: ['Город'],
                                    value: [],
                                    values: [],
                                    defaultValues: [],
                                    depends_on: [],
                                    depends_for: [],
                                    group_id: 1,
                                    sort_order: 1,
                                    type: 'multi-select',
                                    __filter_type_id: null,
                                    __type: {
                                        id: 'params[cities]',
                                        value: 'params[cities]',
                                    },
                                    __types: [
                                        {
                                            id: 'params[cities]',
                                            value: 'params[cities]',
                                        },
                                    ],
                                    __service: {
                                        loadValues: async () => {
                                            const response = await service.users.cities()
                                            // const items = response.map((item) => ({
                                            //     ...item,
                                            //     id: item.value,
                                            // }))

                                            return response
                                        },
                                    },

                                    // ...{
                                    //     id: 'city',
                                    //     param: ['params[cities]'],
                                    //     name: null,
                                    //     label: ['Город'],
                                    //     category: 'Сортировка',
                                    //     type: 'multi-select',
                                    //     defaultValues: [],
                                    //     value: [],
                                    //     values: [],
                                    //     depends_on: [],
                                    //     depends_for: [],
                                    //     service: {
                                    //         values: async () => {
                                    //             const response = await service.users.cities()
                                    //             const items = response.map((item) => ({
                                    //                 ...item,
                                    //                 id: item.value,
                                    //             }))
                                    //             return items
                                    //         },
                                    //     },
                                    // },
                                }),
                                new TableFilterModel({
                                    id: 2,
                                    label: null,
                                    params: [
                                        {
                                            name: 'params[roles]',
                                        },
                                    ],
                                    placeholders: ['Роль'],
                                    value: [],
                                    values: [],
                                    defaultValues: [],
                                    depends_on: [],
                                    depends_for: [],
                                    group_id: 1,
                                    sort_order: 1,
                                    type: 'multi-select',
                                    __filter_type_id: null,
                                    __type: {
                                        id: 'params[roles]',
                                        value: 'params[roles]',
                                    },
                                    __types: [
                                        {
                                            id: 'params[roles]',
                                            value: 'params[roles]',
                                        },
                                    ],
                                    __service: {
                                        loadValues: async () => {
                                            const response = await service.users.roles()
                                            // const items = response.map((item) => ({
                                            //     ...item,
                                            //     id: item.value,
                                            // }))

                                            return response
                                        },
                                    },

                                    // ...{
                                    //     id: 'role',
                                    //     param: ['params[roles]'],
                                    //     name: null,
                                    //     label: ['Роль'],
                                    //     category: 'Сортировка',
                                    //     type: 'multi-select',
                                    //     defaultValues: [],
                                    //     value: [],
                                    //     values: [],
                                    //     depends_on: [],
                                    //     depends_for: [],
                                    //     service: {
                                    //         values: async () => {
                                    //             const response = await service.users.roles()
                                    //             const items = response.map((item) => ({
                                    //                 ...item,
                                    //                 id: item.value,
                                    //             }))
                                    //             return items
                                    //         },
                                    //     },
                                    // },
                                }),
                                new TableFilterModel({
                                    id: 3,
                                    label: null,
                                    params: [
                                        {
                                            name: 'params[status]',
                                        },
                                    ],
                                    placeholders: ['Статус'],
                                    value: [],
                                    values: [],
                                    defaultValues: [],
                                    depends_on: [],
                                    depends_for: [],
                                    group_id: 1,
                                    sort_order: 1,
                                    type: 'multi-select',
                                    __filter_type_id: null,
                                    __type: {
                                        id: 'params[status]',
                                        value: 'params[status]',
                                    },
                                    __types: [
                                        {
                                            id: 'params[status]',
                                            value: 'params[status]',
                                        },
                                    ],
                                    __service: {
                                        loadValues: async () => {
                                            const response = await service.users.statuses()
                                            // const items = response.map((item) => ({
                                            //     ...item,
                                            //     id: item.value,
                                            // }))

                                            return response
                                        },
                                    },

                                    // ...{
                                    //     id: 'status',
                                    //     param: ['params[status]'],
                                    //     name: null,
                                    //     label: ['Статус'],
                                    //     category: 'Сортировка',
                                    //     type: 'multi-select',
                                    //     defaultValues: [],
                                    //     value: [],
                                    //     values: [],
                                    //     depends_on: [],
                                    //     depends_for: [],
                                    //     service: {
                                    //         values: async () => {
                                    //             const response = await service.users.statuses()
                                    //             const items = response.map((item) => ({
                                    //                 ...item,
                                    //                 id: item.value,
                                    //             }))
                                    //             return items
                                    //         },
                                    //     },
                                    // },
                                }),
                            ],
                        },
                    }}
                />
            </Box>
        </MantineProvider>
    )
}
