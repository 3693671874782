import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import Instance, { TablePaginationProps } from '@mui/material/TablePagination'

interface ITablePaginationProps {}

export const TablePagination: React.FC<ITablePaginationProps & TablePaginationProps> = ({ classes, ...rest }) => {
    const cls = { root: clsx(style.root, classes?.root) }

    return (
        <Instance
            rowsPerPageOptions={[5, 10, 15, 25, 50]}
            component='div'
            labelRowsPerPage={'Показывать по:'}
            classes={cls}
            {...rest}
        />
    )
}
