import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'

interface IPaperProps extends React.HTMLAttributes<HTMLDivElement> {
    classes?: {
        root?: string
    }
    nopadding?: boolean
}

export const Paper: React.FC<IPaperProps> = ({ classes, children, nopadding, ...rest }) => {
    const cls = { root: clsx(style.root, classes?.root, { [style.nopadding]: nopadding }) }

    return (
        <div className={cls.root} {...rest}>
            {children}
        </div>
    )
}
