import React from 'react'
import { Box, LoadingOverlay } from '@mantine/core'
import { Paper } from '../paper'
import { observer } from 'mobx-react-lite'
import { store } from '../../catalog.store'
import { OptionsSearch } from '../options-search'
import { OptionsGrid } from '../options-grid'
import { OptionsSelected } from '../options-selected'
import { core } from '../../catalog.core'

interface OptionsProps {}

export const Options: React.FC<OptionsProps> = observer(({ ...rest }) => {
    const handleOpen = () => {
        core.loadOptions()
    }

    return (
        !!store.getOptionsFilter() && (
            <Box {...rest}>
                <Paper title='Опции' onOpen={handleOpen}>
                    <OptionsSearch />
                    <OptionsSelected />
                    <OptionsGrid />

                    <LoadingOverlay
                        loaderProps={{ variant: 'bars' }}
                        visible={store.state.loadingOptions}
                        overlayOpacity={0.5}
                    />
                </Paper>
            </Box>
        )
    )
})
