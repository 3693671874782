import React from 'react'
import { observer } from 'mobx-react-lite'
import { useAnalyticsTableAddModal } from './modal'
import { ControlButton } from '../control/button'
import style from './index.style.scss'
import { store } from '@/pages/analytics/store'

interface IAnalyticsTableAddProps extends React.HTMLAttributes<HTMLDivElement> {}

export const AnalyticsTableAdd: React.FC<IAnalyticsTableAddProps> = observer(({ ...rest }) => {
    const { Modal, ...modalState } = useAnalyticsTableAddModal()
    const label = store.table.isMode('passport') ? 'Привязать значение' : 'Добавить строку'

    return (
        <div className={style.root} {...rest}>
            <ControlButton
                className={style.button}
                onClick={() => modalState.setOpen(true)}
                label={label}
                icon={'add'}
                classes={{ icon: style.icon }}
                isActive={modalState.open}
            />

            <Modal {...modalState} />
        </div>
    )
})
