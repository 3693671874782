import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import ClearIcon from '@mui/icons-material/Clear'
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import SaveAltIcon from '@mui/icons-material/SaveAlt'

type IconType = SvgIconTypeMap['props']
export type Icons = keyof typeof icons

const icons = {
    add: AddOutlinedIcon,
    archive: ArchiveOutlinedIcon,
    eye: VisibilityOutlinedIcon,
    arrowDropdown: ArrowDropDownOutlinedIcon,
    edit: ModeEditOutlinedIcon,
    delete: DeleteOutlinedIcon,
    copy: ContentCopyOutlinedIcon,
    cross: ClearIcon,
    paste: ContentPasteOutlinedIcon,
    more: MoreVertOutlinedIcon,
    cancel: RotateLeftOutlinedIcon,
    save: SaveOutlinedIcon,
    eyeClose: VisibilityOffOutlinedIcon,
    search: SearchOutlinedIcon,
    drag: DragIndicatorOutlinedIcon,
    filters: FilterListOutlinedIcon,
    angleRight: ChevronRightOutlinedIcon,
    angleLeft: ChevronLeftOutlinedIcon,
    filter: FilterAltIcon,
    download: SaveAltIcon,
}
interface IconProps extends IconType {
    className?: string
    icon: Icons
}

export const Icon: React.FC<IconProps> = ({ className, icon, ...rest }) => {
    const cls = clsx(style.root, className)

    const Component = icons[icon]

    return <Component className={cls} {...rest} />
}
