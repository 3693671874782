import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'
import { CatalogFilter } from '../filters/filters.types'

export type CatalogFilterValuesRequest = {
    name: string
    type: number
    filters?: {
        [property: string]: number
    }
    // isGroup?: boolean
}

type CatalogFilterValuesResponse = {
    data: CatalogFilter['values']
    success: boolean
}

export const CatalogFilterValuesController = { ref: null }

export const CatalogFilterValues = async (req: CatalogFilterValuesRequest) => {
    CatalogFilterValuesController.ref = new AbortController()

    try {
        const response = await apiConfig.fetch.ilsa<CatalogFilterValuesResponse>({
            url: `/catalog/filters/available-values`,
            config: {
                method: 'POST',
                data: {
                    filter: req.name,
                    vehicle_type_id: req.type,
                    ...req.filters,
                },
                // cache: true,
                // cacheTimeout: false,
                signal: CatalogFilterValuesController.ref.signal,
            },
        })

        const data = response.data.data || (response.data as unknown as CatalogFilterValuesResponse['data'])

        // data.push({ id: 176, value: 'ALFA ROMEO' })

        const values = data
            .filter((value) => value.id !== undefined && value.id !== null && value.value)
            .map((value) => ({
                ...value,
                label: String(value.value),
                value: String(value.value),
                items: (value.items || []).map((value) => ({
                    ...value,
                    label: String(value.value),
                })),
            }))

        const map_values = values.map((value) => {
            if (value.type === 'item' || value.type === undefined) {
                return {
                    ...value,
                    items: [
                        {
                            id: value.id,
                            type: value.type,
                            value: value.value,
                            label: value.label,
                        },
                    ],
                }
            }

            return value
        })

        return map_values

        // const getUniqueValue = (value: number) => ({
        //     id: value,
        //     value: String(value),
        //     label: String(value),
        // })

        // const getValues = (acc: CatalogFilter['values'], value: CatalogFilterValue, group?: CatalogFilterGroup) => {
        // if (value.engine_volume_cm !== undefined) {
        //     acc.push(getUniqueValue(value.engine_volume_cm))
        // } else if (value.engine_power_hp !== undefined) {
        //     acc.push(getUniqueValue(value.engine_power_hp))
        // } else if (value.body_length !== undefined) {
        //     acc.push(getUniqueValue(value.body_length))
        // } else if (value.model_year !== undefined) {
        //     acc.push(getUniqueValue(value.model_year))
        // } else if (value.brand_code !== undefined) {
        //     acc.push(getUniqueValue(value.brand_code))
        // } else

        //     if (value.id && value.name) {
        //         acc.push({
        //             id: value.id,
        //             value: value.name,
        //             label: value.name,
        //             type: value.type || null,
        //             group: group
        //                 ? {
        //                       id: group.id,
        //                       value: group.name,
        //                       label: group.name,
        //                       type: group.type || null,
        //                   }
        //                 : null,
        //         })
        //     }

        //     return acc
        // }

        // const getGroup = (group: CatalogFilterGroup, values: CatalogFilterValue[]) => {
        //     const accValues: CatalogFilter['values'] = values.reduce((acc, value) => getValues(acc, value, group), [])

        /**
         * Добавляем группу как опцийю для выбора + проверка на дубль по названию
         */
        // if (!accValues.find((item) => item.label === group.name)) {
        //     accValues.unshift({
        //         id: group.id,
        //         value: group.name,
        //         label: group.name,
        //         type: group.type,
        //         group: {
        //             id: group.id,
        //             value: group.name,
        //             label: group.name,
        //             type: group.type || null,
        //         },
        //     })
        // }

        //     return {
        //         id: group.id,
        //         value: group.name,
        //         label: group.name,
        //         type: group.type || null,
        //         values: accValues,
        //     }
        // }

        // const values: CatalogFilter['values'] =
        // req.isGroup
        //     ? (response.data.data as CatalogFilterGroup[]).reduce((acc, group) => {
        //           if (group.models) {
        //               acc.push(getGroup(group, group.models))
        //           } else {
        //               acc.push(acc.push(getGroup(group, group.items)))
        //           }

        //           return acc
        //       }, [])
        //     :
        // (response.data.data as CatalogFilterValue[])
        //     .reduce((acc, value) => getValues(acc, value), [])
        //     .filter((value) => value.id && value.label)

        // return values
    } catch (err: any) {
        throwAdapterError(err, 'CatalogFilterValues')
    }
}
