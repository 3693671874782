import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import Instance, { TableProps } from '@mui/material/Table'
import { TablePagination } from './pagination'
import { TableBody } from './body'
import { TableRow } from './row'
import { TableCell } from './cell'
import { TableHead } from './head'
import { TableSortLabel } from './sortLabel'
import { TableContainer } from './container'

interface ITableProps extends TableProps {
    classes?: {
        root?: string
    }
}

type ITable = React.ForwardRefExoticComponent<ITableProps & React.RefAttributes<HTMLTableElement>> & {
    Pagination?: typeof TablePagination
    Body?: typeof TableBody
    Row?: typeof TableRow
    Cell?: typeof TableCell
    Head?: typeof TableHead
    SortLabel?: typeof TableSortLabel
    Container?: typeof TableContainer
}

export const Table: ITable = React.forwardRef<HTMLTableElement, ITableProps>(({ classes, children, ...rest }, ref) => {
    const cls = { root: clsx(style.root, classes?.root) }

    return (
        <Instance {...rest} classes={cls} ref={ref}>
            {children}
        </Instance>
    )
})

Table.Pagination = TablePagination
Table.Body = TableBody
Table.Row = TableRow
Table.Cell = TableCell
Table.Head = TableHead
Table.SortLabel = TableSortLabel
Table.Container = TableContainer
