import clsx from 'clsx'
import React from 'react'
import style from './index.style.scss'

interface IFormFieldProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

export const FormField = React.forwardRef<HTMLDivElement, IFormFieldProps>(({ className, children, ...props }, ref) => {
    const classes = clsx(style.root, className)

    return (
        <div className={classes} ref={ref} {...props}>
            {children}
        </div>
    )
})
