import React from 'react'
import { Box } from '@mantine/core'
import style from './catalog.style.scss'
import { CatalogWidget } from '@/widget'
import { useCatalogPage } from './catalog.lib'
import * as Pages from './[id]'
import { MantineProvider } from '@/theme'

interface CatalogPageProps {}

export const CatalogPage = ({ ...rest }: CatalogPageProps) => {
    const { coreProps } = useCatalogPage({
        widget: {
            id: 1,
            name: 'catalog',
        },
    })

    return (
        <MantineProvider withNotify>
            <Box className={style.root} {...rest}>
                <CatalogWidget coreProps={coreProps} />
            </Box>
        </MantineProvider>
    )
}

CatalogPage.Bus = Pages.CatalogBusPage
CatalogPage.Complectation = Pages.CmoplectationPage
CatalogPage.Hcv = Pages.CatalogHcvPage
CatalogPage.Lcv = Pages.CatalogLcvPage
CatalogPage.Sv = Pages.CatalogSvPage
CatalogPage.Tr = Pages.CatalogTrPage
CatalogPage.Wh = Pages.CatalogWhPage
