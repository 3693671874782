import React from 'react'
import clsx from 'clsx'
import style from './cell-link.style.scss'
import { TableCell } from '@/widget/table/table.types'
import { store } from '@/widget/table/table.store'
import { CellNull } from '../cell-null'
import { Link } from 'react-router-dom'
import { tojs } from '@/shared'

type CellLinkProps = React.HTMLAttributes<HTMLAnchorElement> & {
    cell: TableCell
    idx: number
}

export const CellLink: React.FC<CellLinkProps> = ({ className, cell, idx, ...rest }) => {
    const url = React.useMemo(() => {
        return store.params.getLinkCell(tojs(store.data.rows[idx]))
    }, [store.data.rows, idx])

    return cell.value === null ? (
        <CellNull cell={cell} />
    ) : (
        <Link to={url} className={clsx(style.root, className, 'elisa-table-widget-cell')} title={cell.value} {...rest}>
            {cell.value}
        </Link>
    )
}
