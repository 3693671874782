import React from 'react'
import { Box, Button, Group } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { store } from '../../table.store'
import { core } from '../../table.core'
import style from './states.style.scss'

interface StatesProps {}

export const States: React.FC<StatesProps> = observer(({ ...rest }) => {
    const handleChange = (e: any) => {
        const id = e.currentTarget.dataset.id

        core.changeState(id)
    }

    return (
        !!store.states.length && (
            <Box {...rest}>
                <Group spacing={6} className={style.root}>
                    {store.states.map((state) => {
                        return (
                            <Button
                                data-id={state.id}
                                key={state.value}
                                variant={state.enabled ? 'filled' : 'default'}
                                className={style.button}
                                onClick={handleChange}
                                // sx={{ opacity: store.state.searching && state.enabled ? 0.6 : 1 }}
                                // disabled={store.state.searching && !state.enabled}
                            >
                                {state.label}
                            </Button>
                        )
                    })}
                </Group>
            </Box>
        )
    )
})
