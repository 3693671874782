import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'
import { User } from './users.types'
import { TableModel } from '@/widget/table/table.types'
import { UsersCities } from '../cities'
import { UsersRoles } from '../roles'
import { UsersStatuses, userStatuses } from '../statuses'
import { TableFilterModel } from '@/widget/table/models/filter'
import { AnalyticsReferenceRead } from '../../analytics/reference-get'

// export type UsersParams =
//     | {
//           city?: string
//           role?: string
//           status?: string
//           search?: string
//           page?: number
//           limit?: number
//           sort_type?: 'asc' | 'desc'
//           sort_by?: string
//       }
//     | string

type UsersRequest = {
    params?: ObjectType
}
type UsersResponse = {
    success: boolean
    data: User[]
    params?: any
}

export const UsersController = { ref: null }

export const Users = async (req?: UsersRequest) => {
    UsersController.ref = new AbortController()

    try {
        const response = await apiConfig.fetch.ilsa<UsersResponse>({
            url: '/auth/users',
            config: {
                params: req.params,
                signal: UsersController.ref.signal,
            },
        })

        if (!response?.data?.data?.length) {
            throw Error('Не удалось получить список пользователей')
        }

        const citiesResponse = await UsersCities()
        const rolesResponse = await UsersRoles()
        const statusesResponse = await UsersStatuses()

        const {
            rows,
            columns,
            meta,
        }: {
            rows: TableModel['rows']
            meta: TableModel['meta']
            columns: TableModel['columns']
        } = {
            rows: response.data.data.map((user, i) => ({
                id: {
                    value: user.id,
                },
                login: {
                    value: user.login,
                },
                first_name: {
                    value: user.first_name,
                },
                family_name: {
                    value: user.family_name,
                },
                email: {
                    value: user.email,
                },
                city: {
                    id: user.city,
                    value: user.city,
                },
                status: {
                    id: !user.is_active ? userStatuses[0].id : userStatuses[1].id,
                    value: !user.is_active ? userStatuses[0].value : userStatuses[1].value,
                },
                roles: {
                    value: user.roles.map((role) => ({ id: role.id, value: role.name, label: role.name })),
                },
                brands: {
                    value: (user.brands || []).map((brand, i) => ({ id: i, value: brand, label: brand })),
                    // value: [
                    //     { id: 104, value: 'HYUNDAI', label: 'HYUNDAI' },
                    //     // { id: 1, value: 'Ford', label: 'Ford' },
                    //     // { id: 2, value: 'Skoda', label: 'Skoda' },
                    // ],
                },
            })),
            columns: [
                {
                    id: 'id',
                    label: 'ID',
                    type: 'number',
                    editable: false,
                },
                {
                    id: 'login',
                    label: 'Логин',
                    type: 'string',
                    creatable: true,
                    required: true,
                    editable: true,
                    model: new TableFilterModel({
                        id: 4,
                        label: 'Логин',
                        params: [
                            {
                                name: 'login',
                            },
                        ],
                        placeholders: ['Введите'],
                        value: [],
                        values: [],
                        defaultValues: [],
                        depends_on: [],
                        depends_for: [],
                        group_id: 1,
                        sort_order: 1,
                        type: 'select',
                        __filter_type_id: null,
                        __type: {
                            id: 'login',
                            value: 'login',
                        },
                        __types: [
                            {
                                id: 'login',
                                value: 'login',
                            },
                        ],
                    }),
                },
                {
                    id: 'first_name',
                    label: 'Имя',
                    type: 'string',
                    creatable: true,
                    required: true,
                    editable: true,
                    model: new TableFilterModel({
                        id: 2,
                        label: 'Имя',
                        params: [
                            {
                                name: 'first_name',
                            },
                        ],
                        placeholders: ['Введите'],
                        value: [],
                        values: [],
                        defaultValues: [],
                        depends_on: [],
                        depends_for: [],
                        group_id: 1,
                        sort_order: 1,
                        type: 'select',
                        __filter_type_id: null,
                        __type: {
                            id: 'first_name',
                            value: 'first_name',
                        },
                        __types: [
                            {
                                id: 'first_name',
                                value: 'first_name',
                            },
                        ],

                        // ...{
                        //     id: 'first_name',
                        //     param: ['first_name'],
                        //     name: null,
                        //     label: ['Введите'],
                        //     category: '',
                        //     type: 'string',
                        //     defaultValues: [],
                        //     value: [],
                        //     values: [],
                        //     depends_on: [],
                        //     depends_for: [],
                        // },
                    }),
                },
                {
                    id: 'family_name',
                    label: 'Фамилия',
                    type: 'string',
                    creatable: true,
                    required: true,
                    editable: true,
                    model: new TableFilterModel({
                        id: 3,
                        label: 'Фамилия',
                        params: [
                            {
                                name: 'family_name',
                            },
                        ],
                        placeholders: ['Введите'],
                        value: [],
                        values: [],
                        defaultValues: [],
                        depends_on: [],
                        depends_for: [],
                        group_id: 1,
                        sort_order: 1,
                        type: 'select',
                        __filter_type_id: null,
                        __type: {
                            id: 'family_name',
                            value: 'family_name',
                        },
                        __types: [
                            {
                                id: 'family_name',
                                value: 'family_name',
                            },
                        ],

                        // ...{
                        //     id: 'family_name',
                        //     param: ['family_name'],
                        //     name: null,
                        //     label: ['Введите'],
                        //     category: '',
                        //     type: 'string',
                        //     defaultValues: [],
                        //     value: [],
                        //     values: [],
                        //     depends_on: [],
                        //     depends_for: [],
                        // },
                    }),
                },
                {
                    id: 'email',
                    label: 'E-mail',
                    type: 'string',
                    creatable: true,
                    required: true,
                    editable: true,
                    model: new TableFilterModel({
                        id: 4,
                        label: 'Фамилия',
                        params: [
                            {
                                name: 'email',
                            },
                        ],
                        placeholders: ['Введите'],
                        value: [],
                        values: [],
                        defaultValues: [],
                        depends_on: [],
                        depends_for: [],
                        group_id: 1,
                        sort_order: 1,
                        type: 'select',
                        __filter_type_id: null,
                        __type: {
                            id: 'email',
                            value: 'email',
                        },
                        __types: [
                            {
                                id: 'email',
                                value: 'email',
                            },
                        ],

                        // ...{
                        //     id: 'email',
                        //     param: ['email'],
                        //     name: null,
                        //     label: ['Введите'],
                        //     category: '',
                        //     type: 'string',
                        //     defaultValues: [],
                        //     value: [],
                        //     values: [],
                        //     depends_on: [],
                        //     depends_for: [],
                        // },
                    }),
                },
                {
                    id: 'city',
                    label: 'Город',
                    type: 'select',
                    creatable: true,
                    required: true,
                    editable: true,
                    model: new TableFilterModel({
                        id: 5,
                        label: 'Город',
                        params: [
                            {
                                name: 'city',
                            },
                        ],
                        placeholders: ['Введите'],
                        value: [],
                        values: citiesResponse,
                        defaultValues: [],
                        depends_on: [],
                        depends_for: [],
                        group_id: 1,
                        sort_order: 1,
                        type: 'select',
                        __filter_type_id: null,
                        __type: {
                            id: 'city',
                            value: 'city',
                        },
                        __types: [
                            {
                                id: 'city',
                                value: 'city',
                            },
                        ],

                        // ...{
                        //     id: 'city',
                        //     param: ['city'],
                        //     name: null,
                        //     label: ['Введите'],
                        //     category: '',
                        //     type: 'select',
                        //     value: [],
                        //     values: [],
                        //     defaultValues: citiesResponse,
                        //     depends_on: [],
                        //     depends_for: [],
                        // },
                    }),
                },
                {
                    id: 'status',
                    label: 'Статус',
                    type: 'select',
                    creatable: true,
                    required: true,
                    editable: true,
                    model: new TableFilterModel({
                        id: 7,
                        label: 'Статус',
                        params: [
                            {
                                name: 'status',
                            },
                        ],
                        placeholders: ['Введите'],
                        value: [],
                        values: statusesResponse,
                        defaultValues: [],
                        depends_on: [],
                        depends_for: [],
                        group_id: 1,
                        sort_order: 1,
                        type: 'select',
                        __filter_type_id: null,
                        __type: {
                            id: 'status',
                            value: 'status',
                        },
                        __types: [
                            {
                                id: 'status',
                                value: 'status',
                            },
                        ],

                        // ...{
                        //     id: 'status',
                        //     param: ['status'],
                        //     name: null,
                        //     label: ['Выберите'],
                        //     category: '',
                        //     type: 'select',
                        //     value: [],
                        //     values: [],
                        //     defaultValues: statusesResponse,
                        //     depends_on: [],
                        //     depends_for: [],
                        // },
                    }),
                },
                {
                    id: 'roles',
                    label: 'Роль',
                    type: 'multi-select',
                    creatable: true,
                    required: true,
                    editable: true,
                    model: new TableFilterModel({
                        id: 8,
                        label: 'Роль',
                        params: [
                            {
                                name: 'roles',
                            },
                        ],
                        placeholders: ['Введите'],
                        value: [],
                        values: rolesResponse,
                        defaultValues: [],
                        depends_on: [],
                        depends_for: [],
                        group_id: 1,
                        sort_order: 1,
                        type: 'select',
                        __filter_type_id: null,
                        __type: {
                            id: 'roles',
                            value: 'roles',
                        },
                        __types: [
                            {
                                id: 'roles',
                                value: 'roles',
                            },
                        ],

                        // ...{
                        //     id: 'roles',
                        //     param: ['roles'],
                        //     name: null,
                        //     label: ['Выберите'],
                        //     category: '',
                        //     type: 'select',
                        //     value: [],
                        //     values: [],
                        //     defaultValues: rolesResponse,
                        //     depends_on: [],
                        //     depends_for: [],
                        // },
                    }),
                },
                {
                    id: 'brands',
                    label: 'Бренды',
                    type: 'multi-select',
                    creatable: true,
                    editable: false,
                    model: new TableFilterModel({
                        id: 1101,
                        label: 'Бренды',
                        params: [
                            {
                                name: 1101,
                            },
                        ],
                        placeholders: ['Введите'],
                        value: [],
                        values: [],
                        defaultValues: [],
                        depends_on: [],
                        depends_for: [],
                        group_id: 1,
                        sort_order: 1,
                        type: 'select',
                        __filter_type_id: null,
                        __type: {
                            id: 1101,
                            value: '1101',
                        },
                        __types: [
                            {
                                id: 1101,
                                value: '1101',
                            },
                        ],
                        __service: {
                            loadValues: async ({ name, type, filters }) => {
                                const response = await AnalyticsReferenceRead({
                                    url: '/brands',
                                    data: {
                                        'page[number]': '1',
                                        'page[size]': '99999',
                                    },
                                })

                                const values = response.data.map((item) => ({
                                    id: item.id.value,
                                    value: String(item.name.value),
                                    label: String(item.name.value),
                                }))

                                return values
                            },
                        },

                        // ...{
                        //     id: '1101',
                        //     param: ['1101'],
                        //     name: null,
                        //     label: ['Выберите'],
                        //     category: '',
                        //     type: 'select',
                        //     value: [],
                        //     values: [],
                        //     defaultValues: [],
                        //     depends_on: [],
                        //     depends_for: [],
                        //     service: {
                        //         values: CarstockFilterValues,
                        //     },
                        // },
                    }),
                },
            ],
            meta: {
                current_page: 1,
                last_page: 1,
                per_page: response.data.data.length,
                total: response.data.data.length,
            },
        }

        const newRows = rows.map((row) => {
            const newRow: TableModel['rows'][0] = {}

            const values = Object.values(row)
            const keys = Object.keys(row)

            values.forEach((cell, i) => {
                const column = columns.find((column) => column.id === keys[i])

                newRow[keys[i]] = {
                    ...cell,
                    column,
                }
            })

            return newRow
        })

        const search = (req.params['filters[search]'] || '').toLowerCase()
        const cities = req.params['params[cities]'] || []
        const roles = req.params['params[roles]'] || []
        const status: number[] = req.params['params[status]'] || []

        const getRowAsString = (row: any) => {
            return Object.values(row).reduce((acc, curr: any) => {
                return (acc + ' ' + JSON.stringify(curr.value)).toLowerCase()
            }, '') as string
        }

        const filtered = newRows
            .filter((row) => {
                if (search) {
                    return getRowAsString(row).includes(search)
                }

                return true
            })
            .filter((row) => {
                if (cities.length) {
                    const value = row['city'].value.toLowerCase()

                    return cities.find((city) => city.toLowerCase() === value)
                }

                return true
            })
            .filter((row) => {
                if (roles.length) {
                    const values = row['roles'].value.map((value) => value.value.toLowerCase())

                    return roles.find((role) => values.includes(role.toLowerCase()))
                }

                return true
            })
            .filter((row) => {
                if (status.length) {
                    return status.includes(row['status'].id)
                }

                return true
            })

        return {
            rows: filtered,
            columns,
            meta,
        }
    } catch (err: any) {
        throwAdapterError(err, 'Users')
    }
}
