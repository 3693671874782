import React from 'react'
import { Box, LoadingOverlay, SimpleGrid } from '@mantine/core'
import { store } from '../../catalog.store'
import { observer } from 'mobx-react-lite'
import { GridState } from '../grid-state'
import { GridSort } from '../grid-sort'
import style from './grid.style.scss'
import { GridTitle } from '../grid-title'
import { Empty } from '../empty'
import { Modifications } from '../modifications'
import { CarCard } from '../car-card'

interface GridProps {}

export const Grid: React.FC<GridProps> = observer(({ ...rest }) => {
    const isCards = store.data.cars.length
    const isModifications = store.data.modifications.length

    const isCardsModifications = store.isCurrentMode('modifications')

    const isEmpty = (!isCards && !isCardsModifications) || (!isModifications && isCardsModifications)
    const cols = isEmpty || isCardsModifications ? 1 : 3

    return (
        <Box {...rest} mt={100}>
            <GridTitle />
            <Box className={style.sort}>
                <GridState />
                <GridSort />
            </Box>
            <SimpleGrid cols={cols} spacing={24} verticalSpacing={40} className={style.grid}>
                <LoadingOverlay
                    loaderProps={{ variant: 'bars' }}
                    visible={store.state.searching}
                    overlayOpacity={0}
                    radius={24}
                />

                {!isCardsModifications && store.data.cars.map((car) => <CarCard key={car.data.model_id} car={car} />)}
                {!!isCardsModifications && !!store.data.model && <Modifications />}

                {!!isEmpty && !store.state.searching && <Empty />}
            </SimpleGrid>
        </Box>
    )
})
