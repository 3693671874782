import React from 'react'
import clsx from 'clsx'
import style from './header.style.scss'
import { Logo, Menu, Navigation, SubNavigation } from './ui'
import { Container } from '@mantine/core'

type HeaderWidgetProps = React.HTMLAttributes<HTMLElement> & {}

export const HeaderWidget: React.FC<HeaderWidgetProps> = ({ className, ...rest }) => {
    return (
        <div className={clsx(style.root, style.sticky, className)} {...rest}>
            <div className={style.navigation}>
                <Container>
                    <div className={style.inner}>
                        <Logo />
                        <Navigation />
                        <Menu className={style.menu} />
                    </div>
                </Container>
            </div>
            <SubNavigation />
        </div>
    )
}
