import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'

export type AnalyticsReferenceUploadImageRequest = {
    id: any
    image: any
}

export type AnalyticsReferenceUploadImageResponse = {
    success: boolean
    message: string
}

export const AnalyticsReferenceUploadImage = async (req: AnalyticsReferenceUploadImageRequest) => {
    try {
        const formData = new FormData()

        formData.append('image', req.image)

        const response: ApiResponse<AnalyticsReferenceUploadImageResponse> =
            await apiConfig.fetch.ilsa<AnalyticsReferenceUploadImageResponse>({
                url: '/complectations/specific-body-colors/' + req.id + '/image',
                config: {
                    method: 'POST',
                    data: formData,
                    headers: { 'Content-Type': 'multipart/form-data' },
                },
            })

        if (!response?.data?.success) {
            throw Error('Не удалось привязать картинку в таблице аналитика')
        }

        const payload = response.data

        return payload
    } catch (err: any) {
        throwAdapterError(err, 'AnalyticsReferenceUploadImage')
    }
}
