import { throwAdapterError } from '@/shared'
import { apiConfig } from '@/api/api.config'

export type AnalyticsExportRequest = {
    params: any
    id: string
}
export type AnalyticsExportResponse = {
    success: boolean
    data: {
        file_link: string
    }
}

export const AnalyticsExport = async (request: AnalyticsExportRequest) => {
    try {
        const response: ApiResponse<AnalyticsExportResponse> = await apiConfig.fetch.ilsa<AnalyticsExportResponse>({
            url: `/${request.id}/export`,
            config: {
                method: 'GET',
                params: request.params,
            },
        })

        if (!response?.data?.success) {
            throw Error('Не удалось получить ссылку на файл экспорта')
        }

        const filelink = response.data.data.file_link

        const data = {
            link: filelink,
            extension: filelink.split('.').pop(),
        }

        return data
    } catch (err: any) {
        throwAdapterError(err, 'AnalyticsExport')
    }
}
