import clsx from 'clsx'
import React from 'react'
import style from './index.style.scss'

interface IFormLabelProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
    required?: boolean
}

export const FormLabel = React.forwardRef<HTMLDivElement, IFormLabelProps>(
    ({ className, children, required, ...props }, ref) => {
        const classes = clsx(style.root, { [style.required]: required }, className)

        return (
            <div className={classes} ref={ref} {...props}>
                {children}
            </div>
        )
    }
)
