import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import { Paper } from '@/pages/analytics/components/ui'
import { ControlCrud } from './crud'
import { ControlTop } from './top'
import { ControlFilters } from './filters'

interface IReferenceControlProps {
    classes?: {
        root?: string
    }
}

export const ReferenceControl: React.FC<IReferenceControlProps> = ({ classes, ...rest }) => {
    const cls = { root: clsx(style.root, classes?.root) }

    return (
        <Paper classes={{ root: cls.root }} {...rest}>
            <ControlTop />
            <ControlFilters />
            <ControlCrud />
        </Paper>
    )
}
