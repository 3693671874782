import React from 'react'
import { Box, SimpleGrid } from '@mantine/core'
import { Filter } from '../filter'
import { observer } from 'mobx-react-lite'
import { store } from '../../catalog.store'

interface FiltersListProps {
    group_id: number
}

export const FiltersList: React.FC<FiltersListProps> = observer(({ group_id, ...rest }) => {
    const filters = store.getFiltersByGroupId(group_id)

    return (
        <Box {...rest}>
            <SimpleGrid spacing={22} verticalSpacing={24} cols={3}>
                {filters.map((filter) => (
                    <Filter key={filter.data.id} filter={filter} />
                ))}
            </SimpleGrid>
        </Box>
    )
})
