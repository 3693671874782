import { throwAdapterError } from '@/shared'
import { apiConfig } from '@/api/api.config'
import { TableModel, TableParams } from '@/widget/table/table.types'
import { Row } from './configurations.types'

export type ConfigurationsRequest = {
    params: TableParams
}

export type ConfigurationsResponse = {
    data: Row[]
    meta: TableModel['meta']
    columns: TableModel['columns']
    success: boolean
}

export const ConfigurationsController = { ref: null }

export const Configurations = async (req: ConfigurationsRequest) => {
    ConfigurationsController.ref = new AbortController()

    try {
        const response = await apiConfig.fetch.ilsa<ConfigurationsResponse>({
            url: '/configurations',
            config: {
                method: 'GET',
                params: req.params,
                signal: ConfigurationsController.ref.signal,
            },
        })

        if (!response?.data?.success) {
            throw Error('Произошла ошибка при поиске избранного')
        }

        const meta = response.data.meta
        const columns = response.data.columns.map((column) => {
            if (column.id === 'created_at') {
                column.type = 'datetime'
            }

            if (column.id === 'is_archival') {
                column.type = 'boolean'
            }

            if (column.id === 'name') {
                column.type = 'link'
            }

            if (
                column.id === 'specific_option_id' ||
                column.id === 'option_package_id' ||
                column.id === 'specific_color_id' ||
                column.id === 'parameter_value_id'
            ) {
                column.type = 'multi-select'
            }

            return column
        })

        const rows = response.data.data.map((row) => {
            const newRow: TableModel['rows'][0] = {}

            const values = Object.values(row)
            const keys = Object.keys(row)

            values.forEach((cell, i) => {
                const column = columns.find((column) => column.id === keys[i])

                if (keys[i] === 'is_archival') {
                    cell.value = cell.value ? 'Да' : 'Нет'
                }

                if (keys[i] === 'offer_id') {
                    cell.value = cell.value || String(cell.id)
                }

                // Фикс для вывода значений, т.к. с бэка в cell приходит массив, а не {id,value: []}
                if (column.type === 'multi-select') {
                    newRow[keys[i]] = {
                        value: cell,
                        column,
                    } as any
                } else {
                    newRow[keys[i]] = {
                        ...cell,
                        column,
                    }
                }
            })

            return newRow
        })

        return {
            meta,
            rows,
            columns,
        }
    } catch (err: any) {
        throwAdapterError(err, 'Configurations')
    }
}
