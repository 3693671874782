import React from 'react'
import { Button, Group, clsx } from '@mantine/core'
import { IconPlus, IconX } from '@tabler/icons-react'
import style from './create-button.style.scss'
import { observer } from 'mobx-react-lite'
import { store } from '@/widget/table/table.store'
// import { useNavigate } from 'react-router-dom'

interface CreateButtonProps {
    className?: string
}

export const CreateButton: React.FC<CreateButtonProps> = observer(({ className, ...rest }) => {
    // const navigate = useNavigate('')

    const handleClick = () => {
        store.setState({
            createModalOpened: true,
        })
    }

    return (
        !!store.data.create && (
            <div className={clsx(style.root, className)}>
                <Button onClick={handleClick} leftIcon={<IconPlus size={24} />} className={style.button} {...rest}>
                    {store.data.create}
                </Button>
            </div>
        )
    )
})
