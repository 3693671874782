import React from 'react'
import clsx from 'clsx'
import style from './search.style.scss'
import { ActionIcon, TextInput } from '@mantine/core'
import { IconSearch, IconX } from '@tabler/icons-react'
import { debounce } from '@/shared'
import { styles } from '@/theme/styles'
import { observer } from 'mobx-react-lite'
import { store } from '../../table.store'
import { core } from '../../table.core'

type SearchProps = React.HTMLAttributes<HTMLElement> & {}

export const Search: React.FC<SearchProps> = observer(({ className, ...rest }) => {
    const [value, setValue] = React.useState('')

    const handleReset = () => {
        setValue('')
        core.changeSearch(null)
    }

    const handleSearch = React.useCallback(
        debounce(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value || null

                core.changeSearch(value)
            },
            300,
            (e) => {
                setValue(e.target.value || '')
            }
        ),
        []
    )

    return (
        store.params.search && (
            <div className={clsx(style.root, className)} {...rest}>
                <TextInput
                    sx={{ flexGrow: 1 }}
                    icon={<IconSearch size={24} />}
                    iconWidth={48}
                    placeholder='Поиск'
                    value={value}
                    onChange={handleSearch}
                    rightSection={
                        !!value && (
                            <ActionIcon variant='subtle' radius={'50%'} mr={4} onClick={handleReset}>
                                <IconX size={14} color={styles.secondary} />
                            </ActionIcon>
                        )
                    }
                />
            </div>
        )
    )
})
