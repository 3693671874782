import React from 'react'
import { Box } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { FilterSelect } from '../filter-select'
import { FilterMultiSelect } from '../filter-multi-select'
import { FilterRangeSelect } from '../filter-range-select'
import { FilterGroupSelect } from '../filter-group-select'
import { CatalogFilterModel } from '../../models'
import { FilterRangeInteger } from '../filter-range-integer'
import { FilterSwitch } from '../filter-switch'
import { FilterBoolSelect } from '../filter-bool-select'
import { FilterMultiSelectCustom } from '../filter-multi-select-custom'

export interface FilterProps {
    filter: CatalogFilterModel
}

export const Filter: React.FC<FilterProps> = observer(({ filter, ...rest }) => {
    return (
        <Box {...rest}>
            {
                {
                    select: <FilterSelect filter={filter} />,
                    'multi-select': <FilterMultiSelectCustom filter={filter} />,
                    'range-select': <FilterRangeSelect filter={filter} />,
                    'range-integer': <FilterRangeInteger filter={filter} />,
                    switch: <FilterSwitch filter={filter} />,
                    'bool-select': <FilterBoolSelect filter={filter} />,
                    'group-select': <FilterGroupSelect filter={filter} />,
                    // 'group-select': <FilterGroupSelect filter={filter} />,
                }[filter.data.type]
            }
        </Box>
    )
})
