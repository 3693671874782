import React from 'react'
import clsx from 'clsx'
import style from './cell-string.style.scss'
import { TableCell } from '@/widget/table/table.types'
import { CellNull } from '../cell-null'

type CellStringProps = React.HTMLAttributes<HTMLTableCellElement> & {
    cell: TableCell
}

export const CellString: React.FC<CellStringProps> = ({ className, cell, ...rest }) => {
    return cell.value === null ? (
        <CellNull cell={cell} />
    ) : (
        <div className={clsx(style.root, className, 'elisa-table-widget-cell')} title={cell.value} {...rest}>
            {cell.value}
        </div>
    )
}
