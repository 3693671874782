import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'
import { AnalyticsFilterResponseData } from './interfaces'

export type AnalyticsFilterRequest = {
    url: string
    column: string
    params?: {
        search?: string
        'page[size]'?: number
    }
}
export type AnalyticsFilterResponse = {
    data: AnalyticsFilterResponseData[]
}

export const AnalyticsFilter = async (request?: AnalyticsFilterRequest) => {
    try {
        const response: ApiResponse<AnalyticsFilterResponse> = await apiConfig.fetch.ilsa<AnalyticsFilterResponse>({
            url: request.url,
            config: {
                method: 'GET',
                params: request?.params,
            },
        })

        if (!response?.data?.data || response.status !== 200) {
            throw Error('Не удалось получить список фильтров')
        }

        const data = response.data.data.map((filter) => ({
            id: filter.id,
            value: filter?.value || filter?.id,
            type: 'filter',
            visible: true,
            column: request.column,
        }))

        return data
    } catch (err: any) {
        throwAdapterError(err, 'AnalyticsAnalyticsFilter')
    }
}
