import React from 'react'
import { appStore } from '@/app/app.store'
import { observer } from 'mobx-react-lite'
import { ErrorBoundary } from 'react-error-boundary'
import { AppError } from './ui/error/error'
import style from './app.style.scss'
import { Apploader } from './ui/loader'
import { HeaderWidget } from '@/widget'
import { MantineProvider } from '@/theme'

export const AppLayout: React.FC = observer(({ children }) => {
    return (
        <div className={style.layout}>
            <div className={style.layout_wrapper}>
                <ErrorBoundary FallbackComponent={AppError as any}>
                    {!!(!appStore.state.loading && appStore.state.error) && <AppError />}
                    {!!(!appStore.state.error && appStore.state.init) && (
                        <>
                            {appStore.session && (
                                <MantineProvider withModals={false}>
                                    <HeaderWidget />
                                </MantineProvider>
                            )}

                            <div className={style.layout_content}>{children}</div>
                        </>
                    )}

                    <Apploader isActive={appStore.state.loading} isTransparent={appStore.state.init} />
                </ErrorBoundary>
            </div>
        </div>
    )
})
