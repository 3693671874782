import { MantineProvider as Provider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import React from 'react'
import { theme } from './theme'
import { ModalsProvider } from '@mantine/modals'

interface MantineProviderProps {
    withNotify?: boolean
    withModals?: boolean
}

export const MantineProvider: React.FC<MantineProviderProps> = ({ withNotify, withModals, children }) => {
    return (
        <Provider withGlobalStyles withNormalizeCSS theme={theme}>
            {withNotify && <Notifications position='top-right' zIndex={1002} top={100} />}
            {withModals && <ModalsProvider modalProps={{ zIndex: 1000 }}>{children}</ModalsProvider>}

            {!withModals && children}
        </Provider>
    )
}

MantineProvider.defaultProps = {
    withModals: true,
}
