import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import Instance, { TableSortLabelProps } from '@mui/material/TableSortLabel'

interface ITableSortLabelProps extends TableSortLabelProps {}

export const TableSortLabel: React.FC<ITableSortLabelProps> = ({ classes, children, ...rest }) => {
    const cls = { root: clsx(style.root, classes?.root) }

    return (
        <Instance classes={cls} {...rest}>
            {children}
        </Instance>
    )
}
