import React from 'react'
import { LinkProps, useMatch, useNavigate, useResolvedPath } from 'react-router-dom'
import { Link as ReactRouterLink } from 'react-router-dom'

interface ILinkProps extends ObjectType {
    to: string
    Component: ReactFC
    beforeCallback?: FunctionType
    className?: string
    nav?: boolean
}

export const Link: React.FC<ILinkProps> = ({ children, to, Component, beforeCallback, nav = true, ...props }) => {
    const resolved = useResolvedPath(to)
    const match = useMatch({ path: resolved.pathname, end: false })
    const navigate = useNavigate()

    const onClickButton = React.useCallback(
        (url: string) => async () => {
            await beforeCallback?.()
            nav && navigate(url)
        },
        []
    )

    return (
        <Component onClick={onClickButton(to)} {...props} {...(match ? { 'data-active': '' } : {})}>
            {children}
        </Component>
    )
}

export const LinkRouter: React.FC<LinkProps> = ({ children, to, ...props }) => {
    const resolved = useResolvedPath(to)
    const match = useMatch({ path: resolved.pathname, end: false })

    return (
        <ReactRouterLink to={to} {...(match ? { 'data-active': '' } : {})} {...props}>
            {children}
        </ReactRouterLink>
    )
}
