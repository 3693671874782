import React from 'react'
import SimpleBar from 'simplebar-react'
import { observer } from 'mobx-react-lite'
import { useSelect } from 'downshift'
import style from './index.style.scss'

// @ts-ignore
export const Select = observer(({ data, onChange, getList }) => {
    const {
        value: { list, selected },
    } = data
    const itemToString = (item) => (item ? item.value : '')
    const searchHandler = (event) => setTimeout(() => getList({ ...data, params: { search: event.target.value } }), 2)
    const { isOpen, getToggleButtonProps, getMenuProps, getItemProps, getLabelProps, highlightedIndex } = useSelect({
        items: list || [],
        selectedItem: selected,
        itemToString,
        onIsOpenChange: () => (!isOpen ? getList(data) : false),
        onSelectedItemChange: ({ selectedItem }) =>
            onChange({
                id: data.id,
                value: selectedItem,
            }),
    })
    const isShowList = isOpen && list && list.length > 0

    return (
        <div className={style.root}>
            <div className={style.header}>
                <label className={style.label} {...getLabelProps()}>
                    {data.label}
                </label>
                <button className={isOpen ? style.open : style.button} {...getToggleButtonProps()}>
                    <div>{selected ? selected.value : 'Выбрать'}</div>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M6.98438 9.98438H17.0156L12 15L6.98438 9.98438Z' fill='black' fillOpacity='0.54' />
                    </svg>
                </button>
            </div>
            <div className={style.list} {...getMenuProps()}>
                {isOpen && (
                    <>
                        <div className={style.search}>
                            <input type='text' placeholder='Найти' onChange={searchHandler} />
                            <svg
                                width='20'
                                height='20'
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    d='M12.9167 11.6667H12.2583L12.025 11.4417C12.8417 10.4917 13.3333 9.25833 13.3333 7.91667C13.3333 4.925 10.9083 2.5 7.91667 2.5C4.925 2.5 2.5 4.925 2.5 7.91667C2.5 10.9083 4.925 13.3333 7.91667 13.3333C9.25833 13.3333 10.4917 12.8417 11.4417 12.025L11.6667 12.2583V12.9167L15.8333 17.075L17.075 15.8333L12.9167 11.6667ZM7.91667 11.6667C5.84167 11.6667 4.16667 9.99167 4.16667 7.91667C4.16667 5.84167 5.84167 4.16667 7.91667 4.16667C9.99167 4.16667 11.6667 5.84167 11.6667 7.91667C11.6667 9.99167 9.99167 11.6667 7.91667 11.6667Z'
                                    fill='#808D9A'
                                />
                            </svg>
                        </div>
                        <div className={style.hint}>
                            Начните вводить название <br />и выберите подходящее значение из списка:
                        </div>
                        <SimpleBar autoHide={false} style={{ maxHeight: 160 }}>
                            {isShowList &&
                                list.map((item, index) => (
                                    // @ts-ignore
                                    <div
                                        className={highlightedIndex === index ? style.highlighted : style.item}
                                        key={index}
                                        {...getItemProps({ item, index })}
                                    >
                                        <div>{item.value}</div>
                                    </div>
                                ))}
                        </SimpleBar>
                    </>
                )}
            </div>
        </div>
    )
})
