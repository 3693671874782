import React from 'react'
import { Box, clsx, createStyles } from '@mantine/core'
import { styles } from '@/theme/styles'
import { observer } from 'mobx-react-lite'
import { RiCheckFill } from 'react-icons/ri'

interface OptionProps extends React.HTMLAttributes<HTMLDivElement> {
    item: SelectItemData
}

const useStyles = createStyles((theme) => ({
    root: {
        borderRadius: 12,
        padding: '8px 12px',
        fontSize: 14,
        lineHeight: '20px',
        // cursor: 'text',
        transition: 'all .1s ease',
        display: 'flex',
        alignItems: 'center',
        // userSelect: 'none',
        background: styles.background,
        color: styles.primary,

        ':hover': {
            background: theme.fn.darken(styles.background, 0.04),
        },

        // ':active': {
        //     background: theme.fn.darken(styles.background, 0.08),
        // },
    },

    selected: {
        background: styles.blueLight,
        color: styles.blue,

        ':hover': {
            background: theme.fn.darken(styles.blueLight, 0.04),
        },

        // ':active': {
        //     background: theme.fn.darken(styles.blueLight, 0.08),
        // },
    },

    icon: {
        marginRight: 12,
        color: '#B5BDC4',
        fontSize: 24,
        flexShrink: 0,
    },
}))

export const Option: React.FC<OptionProps> = observer(({ item, ...rest }) => {
    const { classes } = useStyles()

    return (
        <Box data-value={item.value} className={clsx(classes.root, { [classes.selected]: false })} {...rest}>
            <Box component={RiCheckFill} className={classes.icon}></Box>
            <Box>
                {item.label} - {item.value}
            </Box>
        </Box>
    )
})
