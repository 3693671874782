import React from 'react'
import style from './index.style.scss'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Table } from '@/pages/analytics/components/ui'
import Switch from '@mui/material/Switch'
import { observer } from 'mobx-react-lite'
import { store } from '@/pages/analytics/store'

interface IAnalyticsTablePaginationProps {}

export const AnalyticsTablePagination: React.FC<IAnalyticsTablePaginationProps> = observer(({ ...rest }) => {
    return (
        <Box classes={{ root: style.root }} {...rest} sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
            <FormControlLabel
                control={<Switch size='small' checked={store.table.dense} onChange={store.table.onChangeDense} />}
                label='Компактный размер'
                classes={{
                    label: style.label,
                }}
            />
            <Table.Pagination
                count={store.table.count}
                rowsPerPage={store.table.size}
                page={store.table.page}
                onPageChange={store.table.onChangePage}
                onRowsPerPageChange={store.table.onChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) => {
                    return `${from}–${to} из ${count !== -1 ? count : `больше ${to}`}`
                }}
            />
        </Box>
    )
})
