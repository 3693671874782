import React from 'react'
import styles from './HistoryItemInfo.style.scss'
import { toJS } from 'mobx'

const HistoryItemInfo = ({ data, handleOpenInfo }) => {
    return (
        <div className={styles.container}>
            <ul className={styles.list}>
                {data.map((item) => {
                    const value = item.auto ? item.auto.name : item.value

                    return (
                        <li className={styles.item} key={item.id}>
                            <div>{item.title}</div>
                            <div>{value === '' ? '-' : value}</div>
                        </li>
                    )
                })}
            </ul>
            <button className={styles.button} onClick={handleOpenInfo}>
                <span>Свернуть</span>
                <svg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M11.0156 5.01575L0.984376 5.01575L6 0.000121021L11.0156 5.01575Z' fill='#1C4BCF' />
                </svg>
            </button>
        </div>
    )
}

export default HistoryItemInfo
