import React from 'react'
import clsx from 'clsx'
import style from './editable.style.scss'
import { Loader, MultiSelect, Select, TextInput } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { useFormContext } from 'react-hook-form'
import { IconChevronDown } from '@tabler/icons-react'
import { styles } from '@/theme/styles'
import { TableColumn } from '../../table.types'
import { TableFilterModel } from '../../models'

type EditableProps = React.HTMLAttributes<HTMLElement> & {
    fields: {
        column: TableColumn
        model: TableFilterModel
    }[]
}

export const Editable: React.FC<EditableProps> = observer(({ className, fields, ...rest }) => {
    const form = useFormContext()

    return (
        <div className={clsx(style.root, className)} {...rest}>
            {fields.map((field) => {
                if (!field.column.creatable || !field.model) return null

                const ref = { el: null }

                // if (column.type === 'number') {
                //     field = (
                //         <NumberInput
                //             placeholder={'Введите'}
                //             label={column.label}
                //             autoComplete='off'
                //             withAsterisk={column.required}
                //         />
                //     )
                // }

                if (field.column.type === 'string') {
                    const register = form.register(field.column.id, {
                        required: field.column.required,
                        minLength: 1,
                        // value: field.model.data.value?.[0]?.value,
                    })

                    ref.el = (
                        <TextInput
                            placeholder={'Введите'}
                            label={field.column.label}
                            autoComplete='off'
                            withAsterisk={field.column.required}
                            name={register.name}
                            ref={register.ref}
                            // onChange={register.onChange}
                            onChange={(e) => {
                                const value = e.currentTarget.value

                                value
                                    ? form.clearErrors(field.column.id)
                                    : field.column.required
                                    ? form.setError(field.column.id, {})
                                    : null

                                form.setValue(field.column.id, value)

                                field.model.set.string(value)
                            }}
                            value={field.model.data.value?.[0]?.value}
                            onBlur={register.onBlur}
                            error={Boolean(form.formState.errors[field.column.id])}
                        />
                    )
                }

                if (field.column.type === 'select') {
                    const register = form.register(field.column.id, {
                        required: field.column.required,
                        minLength: 1,
                        // value: field.model.data.value?.[0]?.value || null,
                    })

                    ref.el = (
                        // Select.value не умеет работать с undefined
                        <Select
                            placeholder={'Выберите'}
                            label={field.column.label}
                            clearable
                            searchable
                            creatable
                            value={
                                field.model.data.value?.[0]?.value !== undefined
                                    ? field.model.data.value?.[0]?.value
                                    : null
                            }
                            data={field.model.get.values()}
                            autoComplete='none'
                            withAsterisk={field.column.required}
                            maxDropdownHeight={160}
                            getCreateLabel={(query) => `+ ${query}`}
                            onCreate={(query) => {
                                const item = { id: query, value: query, label: query }

                                field.model.setData({
                                    values: [item, ...field.model.data.values],
                                })

                                return item
                            }}
                            name={register.name}
                            ref={register.ref}
                            onChange={(value) => {
                                value
                                    ? form.clearErrors(field.column.id)
                                    : field.column.required
                                    ? form.setError(field.column.id, {})
                                    : null

                                form.setValue(field.column.id, field.model.getValueByValue(value)[0]?.id)

                                field.model.set.value(value)
                            }}
                            onBlur={register.onBlur}
                            error={Boolean(form.formState.errors[field.column.id])}
                        />
                    )
                }

                if (field.column.type === 'multi-select') {
                    const register = form.register(field.column.id, {
                        required: field.column.required,
                        minLength: 1,
                        // value: field.model.data.value?.map((value) => value.value) || [],
                    })

                    ref.el = (
                        <MultiSelect
                            placeholder={'Выберите'}
                            label={field.column.label}
                            clearable
                            searchable
                            autoComplete='off'
                            withAsterisk={field.column.required}
                            maxDropdownHeight={160}
                            value={field.model.data.value?.map((value) => value.value)}
                            data={field.model.get.values()}
                            onChange={(value) => {
                                value.length
                                    ? form.clearErrors(field.column.id)
                                    : field.column.required
                                    ? form.setError(field.column.id, {})
                                    : null

                                const values = field.model.getValueByValue(value).map((value) => value.id)

                                form.setValue(field.column.id, values)

                                field.model.set.multiValue(value)
                            }}
                            name={register.name}
                            ref={register.ref}
                            onBlur={register.onBlur}
                            error={Boolean(form.formState.errors[field.column.id])}
                            rightSection={
                                field.model.state.loading ? (
                                    <Loader variant='dots' size={20} />
                                ) : field.model.data.value.length ? null : (
                                    <IconChevronDown color={styles.secondary} size={16} />
                                )
                            }
                            onFocus={() => {
                                field.model.loadValues()
                            }}
                        />
                    )
                }

                return ref.el ? <div className={style.field}>{ref.el}</div> : null
            })}
        </div>
    )
})
