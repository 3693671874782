import React from 'react'
import clsx from 'clsx'
import style from './navigation.style.scss'
import { Tabs } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { appStore } from '@/app/app.store'
import { routes } from '@/app/app.routes'
import { Link, useLocation, useNavigate } from 'react-router-dom'

type NavigationProps = React.HTMLAttributes<HTMLElement> & {}

export const Navigation: React.FC<NavigationProps> = observer(({ className, ...rest }) => {
    // const { dragStart, dragStop, dragMove } = useDrag()
    const navigate = useNavigate()
    const location = useLocation()

    const defaultValue = '/' + location.pathname.split('/')[1]

    // const handleDrag =
    //     ({ scrollContainer }) =>
    //     (ev: React.MouseEvent) =>
    //         dragMove(ev, (posDiff) => {
    //             if (scrollContainer.current) {
    //                 scrollContainer.current.scrollLeft += posDiff
    //             }
    //         })

    const handleChange = (value: string) => {
        // const newLink = value.includes('analytics') ? value + '/references/brands' : value
        // navigate(newLink)
    }

    return (
        <div className={clsx(style.root, className)} {...rest}>
            <Tabs
                classNames={{
                    tabsList: style.tabs_list,
                    tab: style.tab,
                }}
                variant='default'
                value={defaultValue}
                onTabChange={handleChange}
            >
                <Tabs.List>
                    {/* <ScrollMenu
                        onWheel={onDragWheel}
                        onMouseDown={() => dragStart}
                        onMouseUp={() => dragStop}
                        onMouseMove={handleDrag}
                    >
                        {appStore.session.tabs.map(
                            (tab) =>
                                routes[tab.label]?.path && (
                                    <Tabs.Tab value={routes[tab.label]?.path} key={tab.label}>
                                        {tab.title}
                                    </Tabs.Tab>
                                )
                        )}
                    </ScrollMenu> */}

                    {/* <Tabs.Tab value='Справочник'>Справочник</Tabs.Tab>
                    <Tabs.Tab value='Авто в наличии'>Авто в наличии</Tabs.Tab> */}

                    {appStore.session.data.tabs.map((tab, key) => {
                        const route = routes[tab.label]

                        if (route && route.visible) {
                            return (
                                <Link key={key} to={route.linkPath || route.path}>
                                    {route.tab}
                                </Link>
                            )
                        }

                        return null
                    })}
                </Tabs.List>
            </Tabs>
        </div>
    )
})
