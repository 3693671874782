import React from 'react'
import { Box } from '@mantine/core'
import { styles } from '@/theme/styles'
import { IconDatabaseExclamation, IconDatabaseOff } from '@tabler/icons-react'

interface EmptyProps {
    error?: boolean
}

export const Empty: React.FC<EmptyProps> = ({ error, ...rest }) => {
    return (
        <Box
            mx={'auto'}
            mt={32}
            mb={32}
            sx={(theme) => ({
                background: theme.fn.lighten(styles.tertiary, 0.6),
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            })}
            w={100}
            h={100}
            {...rest}
        >
            {error && <IconDatabaseExclamation stroke={1.2} size={60} color={styles.tertiary} />}
            {!error && <IconDatabaseOff stroke={1.2} size={60} color={styles.tertiary} />}
        </Box>
    )
}
