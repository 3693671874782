import clsx from 'clsx'
import React from 'react'
import style from './index.style.scss'

interface IModalLabelProps {
    label: string | JSX.Element
    className?: string
}

export const ModalLabel: React.FC<IModalLabelProps> = ({ label, className }) => {
    const classes = clsx(style.root, className)

    return <div className={classes}>{label}</div>
}
