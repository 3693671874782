import React from 'react'
import { Box, SimpleGrid } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { store } from '../../carstock.store'
import { Option } from '../option'

interface OptionsGridProps {}

export const OptionsGrid: React.FC<OptionsGridProps> = observer(({ ...rest }) => {
    const options = store.data.options

    return (
        <Box {...rest}>
            <SimpleGrid spacing={0} verticalSpacing={0} cols={1}>
                {options.map((option) => (
                    <Option key={option.data.id} isOnlyOne={options.length === 1} option={option} />
                ))}
            </SimpleGrid>
        </Box>
    )
})
