import React from 'react'
import clsx from 'clsx'
import { TableMenu, TableRow } from '@/widget/table/table.types'
import { ActionIcon, Menu } from '@mantine/core'
import { IconDots } from '@tabler/icons-react'
import style from './cell-menu.style.scss'
import { list } from './cell-menu.data'
import { core } from '@/widget/table/table.core'
import { store } from '@/widget/table/table.store'
import { observer } from 'mobx-react-lite'
import { tojs } from '@/shared'

type CellMenuProps = React.HTMLAttributes<HTMLTableCellElement> & {
    menu: TableMenu
    row: TableRow
}

export const CellMenu: React.FC<CellMenuProps> = observer(({ className, menu, row, ...rest }) => {
    const title = `${row.name?.value || row.login?.value || ''} - ${row.id.value}`

    const handleDeleteClick = () => {
        const ids = store.params.onClickDelete([row])

        core.delete(ids)
    }

    const handleEditClick = () => {
        core.edit(row)
    }

    return (
        <td className={clsx(style.root, className, 'elisa-table-widget-cell')} {...rest}>
            <Menu width={260} offset={12} withinPortal classNames={{ dropdown: style.dropdown }}>
                <Menu.Target>
                    <ActionIcon>
                        <IconDots />
                    </ActionIcon>
                </Menu.Target>

                <Menu.Dropdown>
                    <Menu.Label title={title}>{title}</Menu.Label>

                    {menu.map((item) => {
                        return (
                            <Menu.Item
                                key={item.id}
                                icon={item.icon || list[item.id].icon}
                                onClick={
                                    item.onClick ||
                                    {
                                        delete: handleDeleteClick,
                                        edit: handleEditClick,
                                    }[item.id]
                                }
                            >
                                {item.label || list[item.id].label}
                            </Menu.Item>
                        )
                    })}
                </Menu.Dropdown>
            </Menu>
        </td>
    )
})
