import React from 'react'
import style from './header.style.scss'
import { Paper } from '../paper'
import { observer } from 'mobx-react-lite'
import { store } from '../../car.store'
import { SpecificationList } from '../specification-list'
import { Button, UnstyledButton, clsx } from '@mantine/core'
import { IconHeart } from '@tabler/icons-react'
import { useImmer } from 'use-immer'
import { sleep } from '@/shared'
import { modals } from '@mantine/modals'
import { appStore } from '@/app/app.store'

type Header = {}

export const Header: React.FC<Header> = observer(() => {
    const [state, setState] = useImmer({ loader: true, error: false, booking: false })

    const car = store.data.car
    const is_booking_role = appStore.is_booking_role

    const handleClickFavorite = (e: React.MouseEvent<HTMLButtonElement>) => {
        car.favoriteToggle()
    }

    const handleClickBooking = async (e: React.MouseEvent<HTMLButtonElement>) => {
        if (state.booking) return

        setState((draft) => {
            draft.booking = true
        })

        await sleep(300)

        modals.openConfirmModal({
            modalId: 'carstock-booking',
            title: 'Автомобиль забронирован',
            children: (
                <div>
                    {car.equipment.name} - {car.data.dealer.name} забронирован. Если этого не произошло, пожалуйста
                    обратитесь в тех. поддержку
                </div>
            ),
            labels: { confirm: 'Понятно', cancel: 'Отмена' },
            onCancel: () => {
                modals.close('carstock-save-confirm')
            },
            groupProps: {
                style: {
                    justifyContent: 'center',
                },
            },
            // onConfirm: () => {},
            closeOnConfirm: true,
            cancelProps: {
                display: 'none',
            },
        })

        setState((draft) => {
            draft.booking = false
        })
    }

    return (
        <Paper className={style.root}>
            <div className={style.inner}>
                <div className={style.image}>
                    <img src={car.image} alt={car.equipment.name} />
                </div>
                <div className={style.info}>
                    <div className={style.title}>{car.equipment.name}</div>
                    <div className={style.modification}>({car.modifiction})</div>

                    <SpecificationList data={car.getDealerParams()} />
                </div>

                <div className={style.buttons}>
                    {car.isFavorite && (
                        <Button
                            onClick={handleClickFavorite}
                            className={clsx(
                                style.favorite,
                                { [style.favorite_active]: car.data.is_favorite },
                                { [style.favorite_loading]: car.state.loading }
                            )}
                            leftIcon={<IconHeart />}
                            data-favorite
                            variant='subtle'
                        >
                            В избранное
                        </Button>
                    )}

                    {is_booking_role && (
                        <Button loading={state.booking} variant='subtle' onClick={handleClickBooking}>
                            Забронировать
                        </Button>
                    )}
                </div>
            </div>
        </Paper>
    )
})
