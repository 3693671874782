import { BaseModel } from '@/shared/core/model'

interface ModelProps {
    id: number
    name: string
    price: number
    description: string

    selected?: boolean
}

export class ComplectationPackageModel extends BaseModel<ModelProps, ComplectationPackageModel> {
    constructor(props: ModelProps) {
        super(props, ComplectationPackageModel)
    }

    toggleSelect() {
        this.setData({
            selected: !this.data.selected,
        })
    }

    unselect() {
        this.setData({
            selected: false,
        })
    }
}
