import { Table } from '@/pages/analytics/components/ui'
import { store } from '@/pages/analytics/store'
import { TableStoreModel } from '@/pages/analytics/store/table/interfaces'
import Checkbox from '@mui/material/Checkbox'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { AnalyticsTableMenu } from '../../menu'
import { AnalyticsTableCells } from './cells'
import style from './index.style.scss'

interface AnalyticsTableRowProps {
    row: TableStoreModel['rows'][0]
    isDuplicate?: boolean
}

export const AnalyticsTableRow: React.FC<AnalyticsTableRowProps> = observer(({ row, isDuplicate, ...rest }) => {
    const isSelected = store.table.isSelected(row.id.value)
    const isPassportMode = store.table.isMode('passport')
    const isViewMode = store.table.isMode('view')
    const isVisible = !!((isPassportMode && store.table.scheme.passport) || !isPassportMode) && !isViewMode

    const checkbox = (
        <Table.Cell
            align='left'
            padding='checkbox'
            style={{
                position: 'sticky',
                left: 0,
                background: 'white',
                zIndex: 3,
            }}
        >
            {!isDuplicate && (
                <Checkbox
                    color='primary'
                    checked={isSelected}
                    onChange={(e) => store.table.onChangeSelected(e as any, row.id.value)}
                />
            )}
        </Table.Cell>
    )

    return (
        <Table.Row className={style.root} selected={!isDuplicate ? isSelected : false} {...rest}>
            {isVisible && checkbox}

            <AnalyticsTableCells isDuplicate={isDuplicate} row={row} />

            <Table.Cell
                align='center'
                width={40}
                sx={{
                    padding: !store.table.dense ? '10px 16px' : '6px 16px',
                }}
            >
                {!isDuplicate && !isViewMode && <AnalyticsTableMenu dense={store.table.dense} row={row} />}
            </Table.Cell>
        </Table.Row>
    )
})
