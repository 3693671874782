import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'
import { SessionModel } from '@/app/models'
import { AuthSessionResponseMainTab, AuthSessionResponseUser } from './interfaces'

export type AuthSessionResponse = {
    success: boolean
    data: {
        user: AuthSessionResponseUser
        main_tabs: AuthSessionResponseMainTab[]
    }
    message?: string
}

export const AuthSession = async () => {
    try {
        const response = await apiConfig.fetch.ilsa<AuthSessionResponse>({ url: '/auth/session' })

        if (response?.status === 401 || response?.data?.message === 'Unauthenticated.') {
            throw Error('Доступ запрещен')
        }

        const session = new SessionModel({
            user: {
                login: response.data.data.user.login,
                name: response.data.data.user.name,
                email: response.data.data.user.email,
                city: response.data.data.user.city,
                roles: response.data.data.user.roles.map((role) => ({ id: String(role.id), title: role.name })),
                token: response.data.data.user.token,
            },
            tabs: [...response.data.data.main_tabs],
        })

        return session
    } catch (err: any) {
        throwAdapterError(err, 'AuthSession')
    }
}
