import React from 'react'
import clsx from 'clsx'
import style from './table.style.scss'
import { observer } from 'mobx-react-lite'
import { LoadingOverlay, ScrollArea, Table as TableCore } from '@mantine/core'
import { Cell, CellHead, CellHeadEmpty, Row, CellMenu, Empty, CellCheckbox } from './ui'
import { store } from '../../table.store'
import { useImmer } from 'use-immer'

type TableProps = React.HTMLAttributes<HTMLElement> & {}

export const Table: React.FC<TableProps> = observer(({ className, ...rest }) => {
    const isExist = !!store.data.rows.length

    const refRoot = React.useRef<HTMLDivElement>(null)
    const refTable = React.useRef<HTMLTableElement>(null)
    const refHead = React.useRef<HTMLTableSectionElement>(null)
    const [borders, setBorders] = useImmer({
        left: false,
        right: false,
    })

    React.useEffect(() => {
        if (refTable.current?.offsetWidth > refRoot.current?.offsetWidth) {
            setBorders((draft) => {
                draft.right = true
            })
        }
    }, [])

    return (
        <div className={clsx(style.root, className)} ref={refRoot} {...rest}>
            <LoadingOverlay
                loaderProps={{ variant: 'bars' }}
                visible={store.state.searching}
                overlayOpacity={0}
                radius={24}
            />

            {!isExist && <Empty />}

            {isExist && (
                <ScrollArea.Autosize
                    maw={'100%'}
                    mah={628}
                    styles={{
                        root: {
                            paddingRight: 8,
                        },
                    }}
                    className={clsx({ [style.searching]: store.state.searching })}
                    onScrollPositionChange={(position) => {
                        setBorders((draft) => {
                            draft.left = position.x > 0
                            draft.right = refTable.current.offsetWidth > refRoot.current.offsetWidth + position.x
                        })
                    }}
                >
                    <TableCore
                        className={clsx(
                            style.table,
                            { [style.table_left_sticky]: borders.left && !store.params.selected },
                            { [style.table_left_sticky_selected]: borders.left && store.params.selected },
                            { [style.table_right_sticky]: borders.right }
                        )}
                        ref={refTable}
                    >
                        <thead className={style.thead} ref={refHead}>
                            <Row className={style.tr}>
                                {store.params.selected && (
                                    <CellHeadEmpty className={clsx(style.td, style.td_selected)} />
                                )}

                                {store.data.columns.map((column) =>
                                    column.params?.hidden ? null : (
                                        <CellHead key={column.id} column={column} className={style.th} />
                                    )
                                )}

                                {store.data.menu && <CellHeadEmpty className={clsx(style.td, style.td_right_sticky)} />}
                            </Row>
                        </thead>
                        <tbody className={style.tbody}>
                            {store.data.rows.map((row, idx) => {
                                return (
                                    <Row key={idx} className={style.tr}>
                                        {store.params.selected && (
                                            <Cell checkbox row={row} className={clsx(style.td, style.td_selected)} />
                                        )}

                                        {store.data.columns.map((column, i) =>
                                            column.params?.hidden ? null : (
                                                <Cell
                                                    key={i}
                                                    idx={idx}
                                                    cell={row[column.id]}
                                                    className={clsx(style.td, {
                                                        [style.td_text]:
                                                            column.type === 'string' || column.type === 'select',
                                                    })}
                                                />
                                            )
                                        )}

                                        {store.data.menu && (
                                            <CellMenu
                                                row={row}
                                                menu={store.data.menu}
                                                className={clsx(style.td, style.td_right_sticky)}
                                            />
                                        )}
                                    </Row>
                                )
                            })}
                        </tbody>
                    </TableCore>
                </ScrollArea.Autosize>
            )}
        </div>
    )
})
