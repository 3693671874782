import React from 'react'
import { useSearchParams } from 'react-router-dom'

export const useTableSearchParams = () => {
    const [searchParams] = useSearchParams()

    const filters = React.useMemo(() => {
        const searchKeys = searchParams.keys()
        const filters = {
            forRequest: {},
            forTable: [],
        }

        // @ts-ignore
        for (const key of searchKeys) {
            if (key.indexOf('f_') === 0) {
                searchParams.getAll(key).forEach((item) => {
                    const column = key.split('f_')[1].split('__')[0]
                    const value = key.split('f_')[1].split('__')[1]

                    filters.forRequest[`filters[${column}]`] = item

                    if (column === 'archive') {
                        filters.forTable.push({ value: 'archive', id: item })
                    } else {
                        filters.forTable.push({ column: column, value: value, id: item })
                    }
                })
            }
        }

        const sort = [searchParams.get('sort_by') || 'id', searchParams.get('sort_type') || 'desc']
        const filterSort = {}

        if (sort[0] && sort[1]) {
            filterSort[`sorts[${sort[0]}]`] = sort[1]
        }

        const data = {
            'page[number]': searchParams.get('page') || '1',
            'page[size]': searchParams.get('size') || '25',
            ...filters.forRequest,
            ...filterSort,
            'filters[is_archival]': searchParams.get('f_archive') === 'true',
        }
        return {
            ...filters,
            data,
        }
    }, [searchParams])

    return filters
}
