import { clone } from '@/shared'
import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { EditDataRows, EditFieldType, TableStoreModel, SelectData, TableMode } from './interfaces'

export class TableStore {
    state = {
        init: false,
        loading: false,
        error: null,
    }

    /** Table */
    schemes: TableStoreModel['schemes'] = []
    scheme: TableStoreModel['scheme'] = null
    columns: TableStoreModel['columns'] = []
    rows: TableStoreModel['rows'] = []

    onChange: TableStoreModel['onChange']
    handlesRows: TableStoreModel['handlesRows'] = null
    mode: TableMode = 'reference'

    /** Params */
    order: TableStoreModel['order'] = 'asc'
    orderBy: TableStoreModel['orderBy'] = ''
    size: TableStoreModel['size'] = 15
    page: TableStoreModel['page'] = 0
    dense: TableStoreModel['dense'] = false
    count: TableStoreModel['count'] = 0

    selected: number[] = []
    editRows: TableStoreModel['rows'] = []
    duplicatesRows: TableStoreModel['rows'] = []
    hiddenColumns: TableStoreModel['columns'][0]['id'][] = []
    columnSort: TableStoreModel['columns'][0]['id'] = null
    filters: TableStoreModel['filters'] = []
    anchorElSort: HTMLElement | null = null

    options: TableStoreModel['options'] = {
        editPage: false,
    }

    editDataRows: EditDataRows = {}
    selectData: SelectData = {}
    selectRequestData: TableStoreModel['selectRequestData'] = {}

    error = null

    constructor() {
        makeAutoObservable(this)
    }

    setState = (state: Partial<typeof this.state>) => {
        this.state = {
            ...clone(this.state),
            ...state,
        }
    }

    initialize = (data: TableStoreModel) => {
        // const filters = getUrlSearchParamsAsObject()
        // console.log(filters)

        if (this.state.error) {
            this.destroy()
        }

        this.destroy()

        for (const key in data) {
            const value = toJS(data[key])

            if (value) {
                this[key] = toJS(data[key])
            }
        }
    }

    destroy = () => {
        this.schemes = []
        this.schemes = []
        this.scheme = null
        this.columns = []
        this.rows = []
        this.page = 0
        this.count = 0
        this.selected = []
        this.editRows = []
        this.duplicatesRows = []
        this.setState({ error: null })
        this.selectRequestData = {}
    }

    /** Проверка на активную строку */
    isSelected = (id: number) => {
        return this.selected.indexOf(id) !== -1
    }

    /** Проверка на строку редактирования */
    isEdit = (id: number) => {
        return this.editRows.find((editRow) => editRow.id.value === id)
    }

    /** Проверка на строку дубликат */
    isDuplicate = (id: number) => {
        return this.duplicatesRows.find((duplicateRow) => duplicateRow.id.value === id)
    }

    /** Обработчик изменение страницы */
    onChangePage = (event: unknown, page: number) => {
        this.selected = []
        this.editRows = []
        this.duplicatesRows = []
        this.page = page

        this.onChange.page(page + 1)
    }

    /** Обработчик изменения лимита */
    onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value, 10)

        this.size = value
        this.page = 0
        this.selected = []

        this.onChange.size(value)
    }

    /** Обработчик выбора всех строк */
    onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = this.rows.map((n) => n?.id.value)
            this.selected = newSelected

            return
        }

        this.selected = []
    }

    isEditPage = () => {
        const isExist = this.options?.editPage !== undefined

        if (isExist) {
            const isBoolean = typeof this.options.editPage === 'boolean'
            const isObject = typeof this.options.editPage === 'object'

            if (isBoolean) {
                return isBoolean
            }

            if (isObject /* && options.editPage?.[scheme.id] !== undefined */) {
                return this.options.editPage?.[this.scheme.id]
            }
        }

        return true
    }

    isArchiveMode = () => {
        const isBoolean = typeof this.options?.archiveMode === 'boolean'
        const isObject = typeof this.options?.archiveMode === 'object'

        if (isBoolean) {
            return isBoolean
        }

        if (isObject) {
            return this.options.archiveMode?.[this.scheme.id]
        }

        return true
    }

    /** Обработчик выбора строки */
    onChangeSelected = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = this.selected.indexOf(id)
        let newSelected: number[] = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(this.selected, id)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(this.selected.slice(1))
        } else if (selectedIndex === this.selected.length - 1) {
            newSelected = newSelected.concat(this.selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                this.selected.slice(0, selectedIndex),
                this.selected.slice(selectedIndex + 1)
            )
        }

        this.selected = newSelected
    }

    /** Обработчик сортировки */
    onChangeSort = (id: string, type?: string) => {
        return (e) => {
            e.stopPropagation()

            const needType = type ? type : this.orderBy === id && this.order === 'asc' ? 'desc' : 'asc'

            this.order = needType
            this.orderBy = id
            this.selected = []
            this.page = 0

            this.onChange.sort(id, needType)
        }
    }

    /** Обработчик изменения отступов таблицы */
    onChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.dense = event.target.checked

        this.onChange.dense?.(event.target.checked)
    }

    handleOpenSortPopover = (event: React.MouseEvent<HTMLElement>) => {
        this.anchorElSort = event.currentTarget
        this.columnSort = event.currentTarget.dataset.column
    }

    handleCloseSortPopover = () => {
        this.anchorElSort = null
    }

    handleSearch = (e) => {
        this.onChange.search(e.target.value)
    }

    handleFiltersReset = () => {
        this.onChange.resetFilter(this.filters)
        this.filters = []
    }

    isFilterExist(item: typeof this.filters[0]) {
        const isExist = this.filters.find((filter) => String(filter.id) == String(item.id))

        return Boolean(isExist)
    }

    removeFilter = (filter: typeof this.filters[0]) => {
        this.filters = this.filters.filter((filter) => filter.id != filter.id)
        this.onChange.resetFilter([filter])
    }

    addFilter = (item: typeof this.filters[0]) => {
        const isExist = this.isFilterExist(item)

        if (!isExist) {
            this.filters = [...this.filters, item]
            this.onChange.filter(this.filters)
        }
    }

    toggleFilter = (item: typeof this.filters[0]) => {
        const isExist = this.isFilterExist(item)

        // TODO: Временно скрываем множественный выбор
        // if (isExist) {
        //     this.filters = this.filters.filter((filter) => filter.id != item.id)
        //     this.removeFilter(item)
        // } else {
        //     this.filters = [...this.filters, item]
        //     this.onChange.filter(this.filters)
        // }

        if (isExist) {
            this.filters = toJS(this.filters).filter((filter) => filter.id != item.id)
            this.removeFilter(item)
        } else {
            this.filters = [...toJS(this.filters).filter((filter) => filter.column !== item.column), item]
            this.onChange.filter(this.filters)
        }
    }

    filterArchive = (state: boolean) => {
        this.onChange.archive(state)
    }

    handleEditDataRowsReset = () => {
        this.editDataRows = {}
    }

    handleChangeField = (rowId: number, column: TableStore['columns'][0], type: EditFieldType) => (event: any) => {
        const isDependencyFor =
            column?.dependencies && column.dependencies.find((dependency) => dependency.type === 'depends_for')

        const resetData = {}

        /** Если от этого поля зависят другие, сбрасываем их значения в data и selectData */
        if (isDependencyFor) {
            column.dependencies.forEach((dependency) => {
                if (dependency.type === 'depends_for') {
                    dependency.values.forEach((tableId) => {
                        resetData[tableId] = null
                    })
                }
            })
        }

        const data = {
            type,
            value: event.target.value,
        }

        runInAction(() => {
            this.editDataRows = {
                ...this.editDataRows,
                [rowId]: {
                    ...this.editDataRows[rowId],
                    ...resetData,
                    [column.id]: data,
                },
            }

            this.selectData = {
                ...this.selectData,
                [rowId]: {
                    ...this.selectData[rowId],
                    ...resetData,
                },
            }
        })
    }

    isMode(mode: typeof this.mode) {
        return this.mode === mode
    }
}
