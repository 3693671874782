import * as lockr from 'lockr'

const config = {
    version: '1.0.17',
    prefix: 'elisa_',
}

export const storage = {
    ...lockr,
    setWithExpiry,
    getWithExpiry,
    getStorageSize,
    flush: () => {
        lockr.keys().forEach(function (key) {
            localStorage.removeItem(key)
        })
    },
    reversion: () => {
        const version = config.version

        if (storage.get('version') !== version) {
            storage.flush()
        }

        storage.set('version', version)
    },
}

storage.setPrefix(config.prefix)

function setWithExpiry(key: string, value: any, expire: number) {
    const now = new Date()

    if (getStorageSize() / 1000 >= 5) {
        storage.flush()
    }

    const item = {
        value: value,
        expiry: now.getTime() + expire,
    }

    storage.set(key, item)
}

function getWithExpiry(key: string) {
    const item = storage.get(key)

    if (!item) {
        return null
    }

    const now = new Date()

    if (now.getTime() > item.expiry) {
        storage.rm(key)
        return null
    }

    return item.value
}

function getStorageSize() {
    let allStrings = ''

    for (let key in window.localStorage) {
        if (window.localStorage.getItem(key)) {
            allStrings += window.localStorage[key]
        }
    }

    return allStrings ? 3 + (allStrings.length * 16) / (8 * 1024) : 0
}

export interface StorageProps {
    version?: string
}
