import React from 'react'
import clsx from 'clsx'
import style from './selected.style.scss'
import { Button, Checkbox, Text } from '@mantine/core'
import { core } from '../../table.core'
import { store } from '../../table.store'
import { observer } from 'mobx-react-lite'
import { modals } from '@mantine/modals'

type SelectedProps = React.HTMLAttributes<HTMLElement> & {}

export const Selected: React.FC<SelectedProps> = observer(({ className, ...rest }) => {
    const isExist = store.isSelectedAll()
    const isExistOne = store.isSelectedOne()
    const isExistRows = !!store.data.rows.length

    const handleClick = () => {
        core.selectAll()
    }

    const handleClickAction = (action) => () => {
        if (action.confirm) {
            modals.openConfirmModal({
                title: 'Пожалуйста подтвердите',
                children: (
                    <Text size='md'>Это действие изменяет выбранные строки, вам необходимо его подтвердить.</Text>
                ),
                labels: { confirm: action.label, cancel: 'Отмена' },
                // onCancel: () => console.log('Cancel'),
                onConfirm: () => core.actions(action),
                className: style.modal,
            })
        } else {
            core.actions(action)
        }
    }

    return (
        <div
            className={clsx(
                clsx(style.root, {
                    [style.searching]: store.state.searching,
                }),
                className
            )}
            {...rest}
        >
            {isExistRows && <Checkbox size='sm' checked={isExist} onChange={handleClick} label={'Выбрать всех'} />}

            {isExistOne && (
                <div className={style.buttons}>
                    {store.data.actions.map((action) => (
                        <Button
                            key={action.label}
                            size='xs'
                            variant='subtle'
                            onClick={handleClickAction(action)}
                            leftIcon={action.icon}
                        >
                            {action.label}
                        </Button>
                    ))}
                </div>
            )}
        </div>
    )
})
