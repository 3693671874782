import React from 'react'
import { ActionIcon, Box, Collapse, Group, ScrollArea, SimpleGrid, Tabs, Text } from '@mantine/core'
import { OptionCheckbox } from '../option-checkbox'
import style from './option.style.scss'
import { useDisclosure } from '@mantine/hooks'
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react'
import { styles } from '@/theme/styles'
import { core } from '../../catalog.core'
import { observer } from 'mobx-react-lite'
import { Count } from '../count'
import { CatalogOptionModel } from '../../models'

interface OptionProps {
    option: CatalogOptionModel
    isOnlyOne?: boolean
}

export const Option: React.FC<OptionProps> = observer(({ option, isOnlyOne, ...rest }) => {
    const [opened, { toggle }] = useDisclosure(isOnlyOne)
    const ref = React.useRef({ open: false })
    const isTabs = option.data.groups.length > 1

    const handleChange = (e: React.MouseEvent<HTMLDivElement>) => {
        const id = parseInt((e.target as any).closest('[data-id]').dataset.id)

        if (id) {
            core.changeOption(id)
        }
    }

    const handleOpen = () => {
        ref.current.open = true
        toggle()
    }

    return (
        <Box className={style.root} {...rest}>
            {!isOnlyOne && (
                <>
                    <Group
                        onClick={handleOpen}
                        sx={{
                            cursor: 'pointer',
                            borderBottom: '1px solid',
                            borderColor: opened ? 'transparent' : styles.border,
                        }}
                    >
                        <Group sx={{ flexGrow: '1!important' as any }}>
                            <Text py={16} size={18} fw={600} lh={'24px'} sx={{ position: 'relative' }}>
                                {option.data.name}
                                <Count count={option.data.selected.length} visible={!!option.data.selected.length} />
                            </Text>
                        </Group>
                        <ActionIcon
                            variant='default'
                            w={32}
                            h={32}
                            ml={12}
                            radius={'50%'}
                            sx={{
                                borderColor: opened ? styles.accent : styles.border,
                            }}
                        >
                            {
                                {
                                    false: <IconChevronDown size={22} stroke={2} color={styles.secondary} />,
                                    true: <IconChevronUp size={22} stroke={2} color={styles.accent} />,
                                }[String(opened)]
                            }
                        </ActionIcon>
                    </Group>
                </>
            )}

            <Collapse in={opened}>
                {isTabs ? (
                    <Tabs variant='default' defaultValue={option.data.groups[0].name}>
                        <Tabs.List mb={20}>
                            {option.data.groups.map((group) => (
                                <Tabs.Tab key={group.name} value={group.name}>
                                    {group.name}
                                </Tabs.Tab>
                            ))}
                        </Tabs.List>

                        {option.data.groups.map((group) => (
                            <Tabs.Panel key={group.name} value={group.name}>
                                <ScrollArea.Autosize mah={660} mt={0} mb={24} offsetScrollbars>
                                    <SimpleGrid
                                        spacing={24}
                                        verticalSpacing={12}
                                        cols={2}
                                        onClick={handleChange}
                                        pr={4}
                                    >
                                        {group.options.map((item) => (
                                            <OptionCheckbox item={item} key={item.id} />
                                        ))}
                                    </SimpleGrid>
                                </ScrollArea.Autosize>
                            </Tabs.Panel>
                        ))}
                    </Tabs>
                ) : (
                    <ScrollArea.Autosize mah={660} mt={0} mb={24} offsetScrollbars>
                        <SimpleGrid spacing={24} verticalSpacing={12} cols={2} onClick={handleChange} pr={4}>
                            {option.data.groups[0]?.options?.map((item) => (
                                <OptionCheckbox item={item} key={item.id} />
                            ))}
                        </SimpleGrid>
                    </ScrollArea.Autosize>
                )}

                {/* {ref.current.open && (
                    <ScrollArea.Autosize mah={660} mt={0} mb={24} offsetScrollbars>
                        <SimpleGrid spacing={24} verticalSpacing={12} cols={2} onClick={handleChange} pr={4}>
                            {option.data.options.map((item) => (
                                <OptionCheckbox item={item} key={item.id} />
                            ))}
                        </SimpleGrid>
                    </ScrollArea.Autosize>
                )} */}
            </Collapse>
        </Box>
    )
})
