import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'
import { AnalyticsSchemePayload } from './interfaces'
import { AnalyticsSchemeMock } from './mock'

export type AnalyticsSchemeRequest = any

export type AnalyticsSchemeResponse = {
    status: string
    payload: AnalyticsSchemePayload[]
}

export const AnalyticsScheme = async (params?: AnalyticsSchemeRequest) => {
    try {
        const response = await apiConfig.fetch.ilsa<AnalyticsSchemeResponse>({
            url: '/analytics/scheme',
            config: {
                method: 'GET',
                data: params,
            },
            mock: {
                enable: true,
                data: AnalyticsSchemeMock.data,
            },
        })

        if (response?.data?.status !== 'ok') {
            throw Error('Не удалось получить схему аналитики')
        }

        const payload = response.data.payload

        return payload
    } catch (err: any) {
        throwAdapterError(err, 'AnalyticsScheme')
    }
}
