import React from 'react'
import { ScrollArea, SimpleGrid, Stack, Text } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { OptionCheckbox } from '../option-checkbox'
import { Count } from '../count'
import { store } from '@/widget/complectation/complectation.store'
import { core } from '@/widget/complectation/complectation.core'
import { OptionPackageCheckbox } from '../option-package-checkbox'

interface OptionsSelectedProps {}

export const OptionsSelected: React.FC<OptionsSelectedProps> = observer(({ ...rest }) => {
    const items = store.getOptionsSelectedItems()
    const packages = store.getPackagesSelected()
    const count = items.length + packages.length

    const handleChange = (e: React.MouseEvent<HTMLDivElement>) => {
        const id_option = parseInt((e.target as any).closest('[data-option-id]')?.dataset?.optionId)
        const id_package = parseInt((e.target as any).closest('[data-package-id]')?.dataset?.packageId)

        if (id_option) {
            core.toggleSelectedOption(id_option)
        }

        if (id_package) {
            core.toggleSelectedPackage(id_package)
        }
    }

    return (
        count > 0 && (
            <Stack mb={32} {...rest}>
                {
                    <Stack onClick={handleChange}>
                        <Text
                            size={18}
                            fw={600}
                            lh={'24px'}
                            mt={12}
                            sx={{ position: 'relative', alignSelf: 'flex-start' }}
                        >
                            Выбранные опции:
                            <Count count={count} visible sx={{ top: -6, right: -2 }} />
                        </Text>
                        <ScrollArea.Autosize mah={1080} mt={12} mb={24} offsetScrollbars pr={4}>
                            <SimpleGrid spacing={24} verticalSpacing={12} cols={2} pr={4}>
                                {packages.map((item) => (
                                    <OptionPackageCheckbox item={item} key={item.data.id} />
                                ))}
                                {items.map((item) => (
                                    <OptionCheckbox item={item} key={item.id} />
                                ))}
                            </SimpleGrid>
                        </ScrollArea.Autosize>
                    </Stack>
                }
            </Stack>
        )
    )
})
