import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'

export type AnalyticsResidualCreateRequest = {
    id: string
    data: {
        id: {
            value: string
        }
        coefficient: {
            value: string
        }
        month: {
            value: string
        }
        year: {
            value: string
        }
    }[]
}
export type AnalyticsResidualCreateResponse = {
    success: boolean
    message: string
}

export const AnalyticsResidualCreate = async (request?: AnalyticsResidualCreateRequest) => {
    try {
        /** Разделяем данные на post/patch */
        const prepareData = request.data.reduce((acc, curr) => {
            /** patch */
            if (curr.id.value) {
                if (acc[0]) {
                    acc[0].data.push(curr)
                } else {
                    acc.push({
                        method: 'PATCH',
                        data: [],
                    })

                    acc[0].data.push(curr)
                }
            } else {
                /** post */
                if (acc[1]) {
                    acc[1].data.push(curr)
                } else {
                    if (!acc[0]) {
                        acc.push({
                            method: 'PATCH',
                            data: [],
                        })
                    }

                    acc.push({
                        method: 'POST',
                        data: [],
                    })

                    acc[1].data.push(curr)
                }
            }

            return acc
        }, [])

        const response = await Promise.all(
            prepareData.map((data) =>
                apiConfig.fetch.ilsa<AnalyticsResidualCreateResponse>({
                    url: `/modifications/${request.id}/residual-prices`,
                    config: {
                        method: data.method,
                        data: data.data,
                    },
                })
            )
        )

        if (response[0] && (!response[0].data.success || response[0].status !== 200)) {
            throw Error('Не удалось обновить остаточные стоимостей')
        }

        if (response[1] && (!response[1].data.success || response[1].status !== 200)) {
            throw Error('Не удалось создать остаточные стоимостей')
        }

        const data = response[0].data

        return data
    } catch (err: any) {
        throwAdapterError(err, 'AnalyticsResidualCreate')
    }
}
