import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    classes?: {
        root?: string
    }
    fluid?: boolean
}

export const Container: React.FC<ContainerProps> = ({ children, className, classes, fluid, ...rest }) => {
    const cls = { root: clsx(style.root, className, classes?.root, { [style.fluid]: fluid }) }

    return (
        <div className={cls.root} {...rest}>
            {children}
        </div>
    )
}
