import './index.scss'
import { configure } from 'mobx'

configure({ enforceActions: 'never' })

window.__ELISA_CONFIG__ = {
    initApiConfig: process.env.PROJECT_API_CONFIG === 'true',
    apiLog: process.env.NODE_ENV === 'development' || process.env.PROJECT_STAGE === 'development',
    ...window.__ELISA_CONFIG__,
}

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { App } from './app'
import { appStore } from './app/app.store'
import { apiConfig } from './api'

export interface ElisaGlobalConfig {
    initApiConfig?: boolean
    apiLog?: boolean
}

class Elisa {
    el = document.querySelector('#elisa-root')

    async run() {
        try {
            if (window.__ELISA_CONFIG__.initApiConfig) {
                await apiConfig.initialize()
            }

            await appStore.initialize()

            this.el.innerHTML = ''

            ReactDOM.render(
                <BrowserRouter>
                    <App />
                </BrowserRouter>,
                this.el
            )
        } catch (err) {
            console.log('Ошибка запуска приложения: ', err)
        }
    }
}

new Elisa().run()
