import { appStore } from '@/app/app.store'
import { Button, Container } from '@/theme/@deprecated/components/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import style from './error.style.scss'
import { messages } from '@/shared'

interface AppError {
    message?: string
    status?: number | string
}

export const AppError: React.FC<AppError> = observer(({ message, status }) => {
    const navigate = useNavigate()

    const errorStatus = appStore.state.error?.status || status
    const errorMessage = message || appStore.state.error?.message || messages.errors_https[errorStatus]

    const reset = () => {
        navigate('/')
        location.reload()
        // appStore.setState({ error: null, init: false })
        // appStore.initialize()
    }

    React.useEffect(() => {
        appStore.setState({ error: { status: errorStatus, message: errorMessage } })
    }, [])

    return (
        <div className={style.root}>
            <Container>
                <div className={style.wrapper}>
                    <p className={style.title}>
                        {messages.errors_https[errorStatus] ? (
                            <>
                                {errorStatus} | {errorMessage}
                            </>
                        ) : (
                            message || messages.errors.app
                        )}
                    </p>
                    {errorStatus === 'app' && (
                        <Button onClick={reset} type='neutral-error' size='sm' className={style.button}>
                            Перезагрузить приложение
                        </Button>
                    )}
                    {errorStatus === 404 && (
                        <Button onClick={reset} type='neutral-error' size='sm' className={style.button}>
                            Перейти на главную
                        </Button>
                    )}
                </div>
            </Container>
        </div>
    )
})
