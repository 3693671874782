import React from 'react'
import styles from './HistoryItem.style.scss'

import HistoryItemActions from '../HistoryItemActions'
import HistoryItemInfo from '../HistoryItemInfo'

const HistoryItem = ({ data, isOpen, setIsOpen }) => {
    const handleOpenInfo = () => {
        if (data.status.name === 'done') setIsOpen(data.index)
        else return false
    }
    const link = ['pdf', 'jpg', 'png', 'jpeg'].indexOf(data.extension) !== -1 ? data.view_url : data.download_url

    return (
        <li className={isOpen ? styles.open : styles.container}>
            <div className={styles.wrapper} onClick={handleOpenInfo}>
                <div>{new Date(data.created_at).toLocaleDateString('ru-RU')}</div>
                <div className={styles.info}>
                    <a className={styles.link} href={link} target='_blank' onClick={(e) => e.stopPropagation()}>
                        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM6 20V4H13V9H18V20H6Z'
                                fill='#788196'
                            />
                        </svg>
                    </a>
                    <span className={styles.name}>{data.filename}</span>
                    <span>{data.extension}</span>
                    <span>{data.size.human}</span>
                </div>
                <div className={styles[data.status.name]}>{data.status.title}</div>
                <HistoryItemActions data={data} />
            </div>
            {isOpen && data.status.name === 'done' && (
                <HistoryItemInfo data={data.entities} handleOpenInfo={handleOpenInfo} />
            )}
        </li>
    )
}

export default HistoryItem
