import React from 'react'
import clsx from 'clsx'
import { StoreData } from '@/widget/table/table.store'
import style from './cell-head.style.scss'

type CellHeadProps = React.HTMLAttributes<HTMLTableCellElement> & {
    column: StoreData['columns'][0]
}

export const CellHead: React.FC<CellHeadProps> = ({ className, column, ...rest }) => {
    return (
        <th className={clsx(style.root, className, 'elisa-table-widget-cell')} title={column.label} {...rest}>
            {column.label}
        </th>
    )
}
