import React from 'react'
import clsx from 'clsx'
import { Group, Menu as MenuBase, UnstyledButton, Avatar, Text, Stack } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { appStore } from '@/app/app.store'
import PersonPng from './assets/person.png'
import style from './menu.style.scss'
import { Link } from 'react-router-dom'
import { RiLineChartLine, RiSearchLine, RiMessage2Line, RiLogoutBoxRLine, RiGroupLine } from 'react-icons/ri'

type MenuProps = React.HTMLAttributes<HTMLElement> & {}

export const Menu: React.FC<MenuProps> = observer(({ className, ...rest }) => {
    // const currentRoute = '/' + location.pathname.split('/')[1]

    const handleExist = () => {
        appStore.logout()
    }

    return (
        <div className={clsx(style.root, className)} {...rest}>
            <MenuBase>
                <MenuBase.Target>
                    <UnstyledButton>
                        <Group spacing={12}>
                            <Avatar className={style.avatar} src={PersonPng} />
                            <Stack spacing={0}>
                                <Text className={style.name}>{appStore.session.data.user.name}</Text>
                                <Text
                                    className={style.role}
                                    lineClamp={1}
                                    title={appStore.session.data.user.roles.map((role) => role.title).join(', ')}
                                >
                                    {appStore.session.data.user.roles.map((role) => role.title).join(', ')}
                                </Text>
                            </Stack>
                        </Group>
                    </UnstyledButton>
                </MenuBase.Target>
                <MenuBase.Dropdown>
                    <MenuBase.Item icon={<RiGroupLine className={style.item_icon} />} component={Link} to={'/users'}>
                        Пользователи
                    </MenuBase.Item>
                    <MenuBase.Item icon={<RiSearchLine className={style.item_icon} />} component={Link} to={'/catalog'}>
                        Поиск по справочнику ТС
                    </MenuBase.Item>
                    <MenuBase.Item
                        icon={<RiLineChartLine className={style.item_icon} />}
                        component={Link}
                        to={'/analytics'}
                    >
                        Интерфейс аналитика
                    </MenuBase.Item>
                    <MenuBase.Item
                        component='a'
                        icon={<RiMessage2Line className={style.item_icon} />}
                        href='mailto:4000@vtb-leasing.com?subject=Обращение в техподдержку&body=Здравствуйте!'
                    >
                        Написать в техподдержку
                    </MenuBase.Item>
                    <MenuBase.Item onClick={handleExist} icon={<RiLogoutBoxRLine className={style.item_icon} />}>
                        Выход
                    </MenuBase.Item>
                </MenuBase.Dropdown>
            </MenuBase>
        </div>
    )
})
