import React from 'react'
import IconButton from '@mui/material/IconButton'
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined'
import Tooltip from '@mui/material/Tooltip'
import { Link } from 'react-router-dom'
import { Icon, Table } from '@/pages/analytics/components/ui'
import TextField from '@mui/material/TextField'
import { ReferenceRowSelect } from '../../../add/select'
import { useModalConfirm } from '@/pages/analytics/components/modal/confirm'
import { observer } from 'mobx-react-lite'
import { TableStore } from '@/pages/analytics/store/table/store'
import { prepareRowData } from '@/pages/analytics/store/table/utils'
import { store } from '@/pages/analytics/store'
import ImageViewer from 'react-simple-image-viewer'
import { Portal } from 'react-portal'
import Checkbox from '@mui/material/Checkbox'
import { toJS } from 'mobx'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { apiConfig } from '@/api/api.config'

interface IAnalyticsTableCellsProps {
    row: TableStore['rows'][0]
    isDuplicate?: boolean
}

export const AnalyticsTableCells: React.FC<IAnalyticsTableCellsProps> = observer(({ row, isDuplicate }) => {
    const isViewMode = store.table.isMode('view')
    const isPassportMode = store.table.isMode('passport')
    const isVisible = !!((isPassportMode && store.table.scheme.passport) || !isPassportMode) && !isViewMode

    const { Modal: ModalUpdateConfirm, ...modalUpdateConfirmState } = useModalConfirm()
    const isEdit = isDuplicate || Boolean(store.table.editRows.find((editRow) => editRow.id.value === row.id.value))

    const [currentImage, setCurrentImage] = React.useState(0)
    const [isViewerOpen, setIsViewerOpen] = React.useState(false)

    const openImageViewer = React.useCallback((index) => {
        setCurrentImage(index)
        setIsViewerOpen(true)
    }, [])

    const closeImageViewer = () => {
        setCurrentImage(0)
        setIsViewerOpen(false)
    }

    const images = React.useMemo(
        () =>
            Object.keys(row)
                .map((columnName, i) => {
                    if (String(row[columnName]?.value || '').indexOf('images') > -1) {
                        return apiConfig.hosts.base + row[columnName]?.value
                    }
                })
                .filter(Boolean),
        [row]
    )

    const handleUpdate = async () => {
        const prevData = store.table.columns.reduce((acc, curr) => {
            return {
                ...acc,
                [curr.id]: {
                    type: curr.type,
                    value: row[curr.id]?.value,
                },
            }
        }, {})

        const prepareData = {
            ...prepareRowData([prevData])[0],
            ...prepareRowData([store.table.editDataRows[row.id.value]])[0],
        }

        /** Если это последний элемент, то обновляем таблицу */
        const needUpdate = !(store.table.editRows.length + store.table.duplicatesRows.length === 1)

        await store.table.handlesRows.update([prepareData], () => handleCancelUpdate(), needUpdate)()

        modalUpdateConfirmState.setOpen(false)
    }

    const handleCancelUpdate = () => {
        // const editableEl = store.table.rows.find((el) => el.id.value === row.id.value)
        // editableEl.name.value = store.table.editDataRows[row.id.value].name.value

        store.table.editRows = [...store.table.editRows.filter((prev) => prev.id.value !== row.id.value)]
        store.table.selected = [...store.table.selected.filter((prev) => prev !== row.id.value)]
    }

    const handleCreateDuplicate = async () => {
        const prevData = store.table.columns.reduce((acc, curr) => {
            return {
                ...acc,
                [curr.id]: {
                    type: curr.type,
                    value: row[curr.id]?.value,
                },
            }
        }, {})

        const prepareData = {
            ...prepareRowData([prevData], {
                isDuplicate: true,
            })[0],
            ...prepareRowData([store.table.editDataRows[row.id.value]])[0],
        }

        /** Флаг для обновления таблицы, если это последний дубликат в списке */
        const isDuplicateLatest = store.table.editRows.length + store.table.duplicatesRows.length === 1

        await store.table.handlesRows.create(
            [prepareData],
            (err) => {
                if (!err) {
                    /** После создания удаляем созданный дубликат из текущего списка дубликатов */
                    store.table.duplicatesRows = store.table.duplicatesRows.filter(
                        (prev) => prev.id.value !== row.id.value
                    )
                }
            },
            !isDuplicateLatest
        )()
    }

    const handleCancelDuplicate = () => {
        const isLatest = store.table.editRows.length + store.table.duplicatesRows.length === 1

        store.table.duplicatesRows = store.table.duplicatesRows.filter((prev) => prev.id.value !== row.id.value)

        if (isLatest) {
            store.table.handlesRows.get()
        }
    }

    const columns123 = toJS(store.table.columns)
    let newRow = []

    columns123.forEach((item) => {
        newRow.push(item.id)
    })

    return (
        <>
            {newRow.map((columnName, i) => {
                const column = store.table.columns.find((column) => column.id === columnName)
                const value = store.table.editDataRows[row.id.value]?.[column.id]?.value

                /** Скрываем колонки */
                if (store.table.hiddenColumns.includes(column?.id) || !column) {
                    return
                }

                if (isEdit) {
                    if (column.editable !== false && column.id !== 'id') {
                        if (column.type === 'number' || column.type === 'string') {
                            const isDate = column.label.toLowerCase().includes('дата')
                            let dateCurrentValue = ''

                            if (isDate) {
                                const dateValue =
                                    (value && value?.split('-')) ||
                                    (row[columnName]?.value as string).split('.').reverse() ||
                                    ''
                                dateCurrentValue = (
                                    dateValue ? `${dateValue[0]}-${dateValue[1]}-${dateValue[2]}` : ''
                                ) as string
                            }

                            return (
                                <Table.Cell key={i} align='left'>
                                    <TextField
                                        // label={column.label}
                                        variant='outlined'
                                        size='small'
                                        value={
                                            isDate
                                                ? dateCurrentValue
                                                : value === ''
                                                ? value
                                                : value || row[columnName]?.value || ''
                                        }
                                        onChange={store.table.handleChangeField(row.id.value, column, 'string')}
                                        type={isDate ? 'date' : column.type}
                                        sx={{
                                            minWidth: 250,
                                            '& .MuiInputBase-input': {
                                                fontSize: '14px',
                                            },
                                        }}
                                    />
                                </Table.Cell>
                            )
                        } else if (column.type === 'date') {
                            //? Апи возвращает дату наоборот + вместо тире, точки
                            const dateValue =
                                (value && value?.split('-')) ||
                                (row[columnName]?.value && (row[columnName]?.value as string).split('.').reverse()) ||
                                ''
                            const dateCurrentValue = (
                                dateValue ? `${dateValue[0]}-${dateValue[1]}-${dateValue[2]}` : ''
                            ) as string

                            return (
                                <Table.Cell key={i} align='left'>
                                    <TextField
                                        variant='outlined'
                                        size='small'
                                        value={dateCurrentValue}
                                        onChange={store.table.handleChangeField(row.id.value, column, 'date')}
                                        type={'date'}
                                        sx={{
                                            minWidth: 250,
                                            '& .MuiInputBase-input': {
                                                fontSize: '14px',
                                            },
                                        }}
                                    />
                                </Table.Cell>
                            )
                        } else if (column.type === 'select') {
                            return (
                                <Table.Cell key={i} align='left'>
                                    <ReferenceRowSelect
                                        key={i}
                                        column={column}
                                        mode='edit'
                                        row={row}
                                        style={{ maxWidth: '400px' }}
                                    />
                                </Table.Cell>
                            )
                        } else if (column.type === 'boolean') {
                            const boolValue = value !== undefined ? value : row[columnName]?.value

                            return (
                                <Table.Cell key={i} align='left'>
                                    <Checkbox
                                        checked={boolValue}
                                        onChange={() =>
                                            store.table.handleChangeField(
                                                row.id.value,
                                                column,
                                                'boolean'
                                            )({ target: { value: !boolValue } })
                                        }
                                    />
                                </Table.Cell>
                            )
                        } else if (column.type === 'image') {
                            console.log(111, value)

                            return (
                                <Table.Cell key={i} align='left'>
                                    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '12px' }}>
                                        <Button
                                            component='label'
                                            variant='outlined'
                                            // startIcon={<UploadFileIcon />}
                                            fullWidth
                                            sx={{ minWidth: 180 }}
                                        >
                                            {!!value?.base64 && `Заменить фото`}
                                            {!value?.base64 && `Выбрать фото`}
                                            <input
                                                type='file'
                                                accept='.jpeg,.png,.jpg'
                                                hidden
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    const file = e.target.files[0]

                                                    const reader = new FileReader()

                                                    reader.readAsDataURL(file)

                                                    reader.onload = (evt) => {
                                                        if (!evt?.target?.result) {
                                                            return
                                                        }
                                                        const { result } = evt.target

                                                        store.table.handleChangeField(
                                                            row.id.value,
                                                            column,
                                                            'image'
                                                        )({ target: { value: { base64: result, file } } })
                                                    }
                                                }}
                                            />
                                        </Button>
                                        {!!value?.base64 && (
                                            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                                <img
                                                    style={{ minWidth: 60, cursor: 'pointer' }}
                                                    src={value.base64}
                                                    // src={apiConfig.hosts.base + row[columnName]?.value}
                                                    // onClick={() => openImageViewer(0)}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                </Table.Cell>
                            )
                        }
                    }
                }

                if (columnName === 'id') {
                    return (
                        <Table.Cell
                            key={i}
                            align='left'
                            padding='none'
                            style={{
                                minWidth: 140,
                                width: 140,
                                position: 'sticky',
                                left: isVisible ? (store.table.dense ? 52 : 46) : 0,
                                background: 'white',
                                zIndex: 2,
                                borderRight: '1px solid #E0E0E0',
                                paddingLeft: !store.table.isEditPage() || isViewMode ? '12px' : '',
                            }}
                        >
                            {isEdit && !isDuplicate ? (
                                <>
                                    <IconButton onClick={handleCancelUpdate}>
                                        <Icon icon='cancel' />
                                    </IconButton>
                                    <IconButton
                                        sx={{ marginRight: '8px' }}
                                        onClick={() => modalUpdateConfirmState.setOpen(true)}
                                    >
                                        <Icon icon='save' />
                                    </IconButton>
                                </>
                            ) : !isDuplicate ? (
                                <>
                                    {store.table.isEditPage() ? (
                                        <Tooltip title='Открыть' arrow>
                                            <Link to={`${row.id?.value}`}>
                                                <IconButton sx={{ marginRight: '8px', color: 'primary.main' }}>
                                                    <ContentPasteOutlinedIcon />
                                                </IconButton>
                                            </Link>
                                        </Tooltip>
                                    ) : null}
                                    {row[columnName]?.value}
                                </>
                            ) : (
                                <>
                                    <IconButton onClick={handleCancelDuplicate}>
                                        <Icon icon='cancel' />
                                    </IconButton>
                                    <IconButton onClick={handleCreateDuplicate}>
                                        <Icon icon='add' />
                                    </IconButton>
                                </>
                            )}
                        </Table.Cell>
                    )
                }

                if (columnName === 'description') {
                    return (
                        <Table.Cell key={i} align='left'>
                            <div
                                title={`${row[columnName]?.value}`}
                                style={{
                                    width: '300px',
                                    minWidth: '300px',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 3,
                                    overflow: 'hidden',
                                }}
                            >
                                {row[columnName]?.value}
                            </div>
                        </Table.Cell>
                    )
                }

                if (String(row[columnName]?.value || '').indexOf('images') > -1) {
                    return (
                        <Table.Cell
                            key={i}
                            align='left'
                            sx={{
                                whiteSpace: 'nowrap',
                                maxWidth: '350px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                            // title={row[columnName]?.value as string}
                        >
                            <img
                                style={{ width: 60, cursor: 'pointer' }}
                                src={apiConfig.hosts.image_analytics + row[columnName]?.value}
                                onClick={() => openImageViewer(0)}
                            />
                        </Table.Cell>
                    )
                }

                const style = { whiteSpace: 'nowrap', maxWidth: '350px', overflow: 'hidden', textOverflow: 'ellipsis' }

                if (column.type === 'boolean') {
                    return (
                        <Table.Cell key={i} align='left' sx={style}>
                            {row[columnName]?.value ? 'Да' : 'Нет'}
                        </Table.Cell>
                    )
                }

                const getValue = (data) => {
                    if (Array.isArray(data))
                        return data.reduce(
                            (prev, curr, index) => (index === 0 ? curr.value : `${prev}, ${curr.value}`),
                            ''
                        )

                    return data?.value
                }

                return (
                    <Table.Cell key={i} align='left' sx={style}>
                        {getValue(row[columnName])}
                    </Table.Cell>
                )
            })}

            <ModalUpdateConfirm
                title={`Вы действительно хотите обновить выбранную строку? (${row.id.value}:${row.name?.value})`}
                onAction={handleUpdate}
                {...modalUpdateConfirmState}
            />

            {isViewerOpen && (
                <Portal>
                    <ImageViewer
                        src={images}
                        currentIndex={currentImage}
                        disableScroll={false}
                        closeOnClickOutside={true}
                        onClose={closeImageViewer}
                    />
                </Portal>
            )}
        </>
    )
})
