import { apiConfig } from '@/api'
import { number } from '@/shared'
import { BaseModel } from '@/shared/core/model'

type CatalogCarModelProps = {
    brand_id: number
    brand_name: string
    model_id: number
    model_name: string
    rrp: number
    image: string
    is_parallel_import: boolean
}

export class CatalogCarModel extends BaseModel<CatalogCarModelProps, CatalogCarModel> {
    constructor(props: CatalogCarModelProps) {
        super(props, CatalogCarModel)
    }

    get image() {
        const image = this.data.image

        return image ? apiConfig.hosts.image_catalog + image : '/static/car-placeholder.png'
    }

    get price() {
        const price = number.format(this.data.rrp)

        return price
    }

    get priceType() {
        const labelType = this.data.is_parallel_import ? 'СРЦ' : 'РРЦ'

        return labelType
    }

    get priceLabel() {
        const price = this.price

        const priceLabel = `от ${price} ₽`
        const priceLabelRequest = `по запросу`

        return this.data.rrp ? priceLabel : priceLabelRequest
    }
}
