import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import Popover from '@mui/material/Popover'
import { Icon } from '@/pages/analytics/components/ui'
import ButtonBase from '@mui/material/ButtonBase'
import { observer } from 'mobx-react-lite'
import CircularProgress from '@mui/material/CircularProgress'
import { runInAction } from 'mobx'
import { store } from '@/pages/analytics/store'
import { FilterPopoverMenu } from '../control/top/filter/popover/menu'
import { service } from '@/api'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import { notifyError } from '@/theme/@deprecated/components/ui'

interface IAnalyticsTableSortProps {
    classes?: {
        root?: string
    }
}

export const AnalyticsTableSort: React.FC<IAnalyticsTableSortProps> = observer(({ classes, ...rest }) => {
    const cls = { root: clsx(style.root, classes?.root) }
    const open = Boolean(store.table.anchorElSort)

    const [loading, setLoading] = React.useState(false)
    const [menuFilter, setFilterMenu] = React.useState(null)

    // const { bind, ...inputState } = useInput()

    // const handleSearch = React.useCallback(
    //     () =>
    //         debounce((e: any) => {
    //             if (!inputState.value) return

    //             if (e.key && e.key !== 'Enter') return

    //             setLoading(true)

    //             setTimeout(() => {
    //                 setLoading(false)
    //                 store.table.addFilter({ id: store.table.columnSort, value: inputState.value })
    //                 store.table.handleCloseSortPopover()
    //             }, 600)
    //         }, 300),
    //     [inputState.value, store.table.columnSort]
    // )

    const handleHidden = (e) => {
        e.stopPropagation()

        const newHiddenColumns = [...store.table.hiddenColumns, store.table.columnSort]

        runInAction(() => {
            store.table.hiddenColumns = newHiddenColumns
            store.table.handleCloseSortPopover()
        })
    }

    const handleSort = (type: string) => (e) => {
        e.stopPropagation()

        store.table.onChangeSort(store.table.columnSort, type)(e)
        store.table.handleCloseSortPopover()
    }

    const isActiveSort = (order: string) =>
        store.table.order === order && store.table.orderBy === store.table.columnSort

    const handleFilterClick = async () => {
        try {
            setLoading(true)

            const response = await service.analytics.filters({
                url: store.table.scheme.api,
            })
            const menu = response.find((item) => item.id === store.table.columnSort)

            const newMenu = {
                url: menu?.url,
                current: menu,
                stack: [],
                stackItems: [],
                items: [],
            }

            if (menu?.url) {
                const items = await service.analytics.filter({
                    url: menu.url,
                    column: menu.id,
                    params: {
                        'page[size]': 50,
                    },
                })

                newMenu.items = items
            }

            setFilterMenu(newMenu)

            setLoading(false)
        } catch (err) {
            console.log(err)
            notifyError(err?.message)
        }
    }

    const handleGetFilter = (item: ObjectType, params?: ObjectType) => async () => {
        try {
            setLoading(true)

            const response = await service.analytics.filter({
                url: item.url,
                column: item.id,
                params,
            })

            setLoading(false)

            return response
        } catch (err) {
            setLoading(false)
            throw err
        }
    }

    React.useEffect(() => {
        if (open) {
            setFilterMenu(null)
        }
    }, [open])

    return (
        <Popover
            open={open}
            anchorEl={store.table.anchorElSort}
            onClose={store.table.handleCloseSortPopover}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            classes={{
                paper: cls.root,
            }}
            {...rest}
        >
            <div className={style.wrapper}>
                {/* <div className={style.search}>
                    <TextField
                        placeholder='Найти'
                        size='small'
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='start'>
                                    <Icon icon='search' sx={{ fontSize: 20 }} />
                                </InputAdornment>
                            ),
                            onKeyDown: handleSearch(),
                        }}
                        className={style.input}
                        sx={{
                            '& .MuiInputBase-adornedEnd': {
                                paddingRight: '0px',
                            },
                        }}
                        {...bind}
                    />
                </div> */}

                {menuFilter === null ? (
                    <div className={style.rows}>
                        {store.table.columnSort !== 'id' ? (
                            <ButtonBase className={style.row} onClick={handleHidden}>
                                <Icon className={style.rowIcon} icon='eye' sx={{ fontSize: 20 }} />
                                <span>Скрыть</span>
                            </ButtonBase>
                        ) : null}
                        <ButtonBase
                            className={clsx(style.row, { [style.isActive]: isActiveSort('asc') })}
                            onClick={handleSort('asc')}
                        >
                            <Icon
                                className={style.rowIcon}
                                icon='arrowDropdown'
                                sx={{ fontSize: 20, transform: 'rotate(180deg)' }}
                            />
                            <span>Сортировать от А до Я</span>
                        </ButtonBase>
                        <ButtonBase
                            className={clsx(style.row, { [style.isActive]: isActiveSort('desc') })}
                            onClick={handleSort('desc')}
                        >
                            <Icon className={style.rowIcon} icon='arrowDropdown' sx={{ fontSize: 20 }} />
                            <span>Сортировать от Я до А</span>
                        </ButtonBase>
                        <ButtonBase className={style.row} onClick={handleFilterClick}>
                            <Icon className={style.rowIcon} icon='filters' sx={{ fontSize: 20 }} />
                            <span>Фильтровать по значению</span>
                        </ButtonBase>
                    </div>
                ) : null}

                {menuFilter ? (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            margin: '-6px -6px 4px -6px',
                        }}
                    >
                        <IconButton onClick={() => setFilterMenu(null)}>
                            <Icon icon='angleLeft' />
                        </IconButton>
                        <div>{menuFilter.current?.value}</div>
                        <IconButton className={style.close} onClick={store.table.handleCloseSortPopover}>
                            <Icon icon='cross' />
                        </IconButton>
                    </Box>
                ) : null}

                {menuFilter ? (
                    <FilterPopoverMenu
                        menu={menuFilter}
                        setMenu={setFilterMenu}
                        setLoading={setLoading}
                        handleGetFilter={handleGetFilter}
                        style={{ margin: '-10px', width: 'calc(100% + 20px)', minWidth: '200px' }}
                    />
                ) : null}

                {loading && (
                    <div className={style.loader}>
                        <CircularProgress />
                    </div>
                )}
            </div>
        </Popover>
    )
})
