import React from 'react'
import { useLocation, useNavigate, Location } from 'react-router-dom'
import { appStore } from './app.store'
import { observer } from 'mobx-react-lite'
import { storage } from '@/shared'

type AppCheckProps = {
    isAuthPage?: boolean
}

export const AppCheckAuth = observer(({ isAuthPage }: AppCheckProps) => {
    const location = useLocation()
    const navigate = useNavigate()

    React.useEffect(() => {
        const prevLocation: Location = storage.get('authLocation')

        if (appStore.state.auth && isAuthPage) {
            /**
             * Кейс, когда сессия протухла во время нахождения на странице
             */
            storage.set('authLocation', location)
        } else if (!appStore.state.auth) {
            /**
             * Кейс, когда сессия протухла при переходе по ссылке через ручной ввод в url
             */
            if (!storage.get('authLocation') && location.pathname !== '/') {
                storage.set('authLocation', location)
            }
        } else if (prevLocation) {
            /**
             * Кейс, когда авторизируемся и есть сохраненный предыдущий url
             */
            storage.rm('authLocation')

            const url = prevLocation.pathname + prevLocation.hash + prevLocation.search

            navigate(url)
        } else if (appStore.session && !location.pathname.includes(appStore.session.data.tabs[0].label)) {
            /**
             * Стандартный кейс, когда открываем первую страницу с апи
             */
            navigate(`/${appStore.session.data.tabs[0].label}`)
        }
    }, [appStore.state.auth])

    return <></>
})
