import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'

type CatalogFiltersResponse = {
    data: Category[]
}

export const CatalogCategoriesController = { ref: null }

export const CatalogCategories = async () => {
    CatalogCategoriesController.ref = new AbortController()

    try {
        const response = await apiConfig.fetch.ilsa<CatalogFiltersResponse>({
            url: '/interface-builder/catalog/filters',
            config: {
                signal: CatalogCategoriesController.ref.signal,
                cache: true,
                cacheTimeout: false,
            },
        })

        const categories = response.data.data

        return categories
    } catch (err: any) {
        throwAdapterError(err, 'CatalogCategories')
    }
}
