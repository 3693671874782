import React from 'react'
import { observer } from 'mobx-react-lite'
import style from './index.style.scss'

// @ts-ignore
export const Input = observer(({ data, onChange }) => {
    const onChangeHandler = (event) => {
        onChange({ ...data, value: event.target.value })
    }

    return (
        <label className={style.root}>
            <div className={style.label}>{data.label}</div>
            <input
                className={style.input}
                type={data.type}
                value={data.value}
                onChange={onChangeHandler}
                placeholder={'Введите значение'}
            />
        </label>
    )
})
