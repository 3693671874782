import React from 'react'
import style from './specifications.style.scss'
import { Paper } from '../paper'
import { observer } from 'mobx-react-lite'
import { store } from '../../complectation.store'
import { SpecificationList } from '../specification-list'

type SpecificationsProps = {}

export const Specifications: React.FC<SpecificationsProps> = observer(() => {
    const complectation = store.data.complectation

    return (
        <Paper title='Технические характеристики' className={style.root}>
            <div className={style.inner}>
                <SpecificationList
                    // grid={3}
                    data={[
                        ...complectation.data.parameters,
                        // complectation.discount
                        //     ? {
                        //           label: <div className={style.price}>{complectation.discount.label}</div>,
                        //           value: complectation.discount.value,
                        //       }
                        //     : null,
                        // {
                        //     label: <div className={style.price}>{complectation.price_type} {complectation.price} ₽</div>,
                        // },
                        // {
                        //     label: (
                        //         <div className={style.rrc}>
                        //             <div className={style.date_value}>{complectation.price_type}</div>
                        //             <div className={style.date_value}>{`${complectation.rrc} ₽`}</div>
                        //             {!!complectation.matchedOptions.length && (
                        //                 <div>
                        //                     <Popover withArrow offset={0} shadow='sm' position='top-end'>
                        //                         <Popover.Target>
                        //                             <Button rightIcon={<IconChevronDown />} variant='subtle'>
                        //                                 Опции
                        //                             </Button>
                        //                         </Popover.Target>
                        //                         <Popover.Dropdown>
                        //                             <MatchedOptions />
                        //                         </Popover.Dropdown>
                        //                     </Popover>
                        //                 </div>
                        //             )}
                        //         </div>
                        //     ),
                        // },
                    ].filter(Boolean)}
                />

                {/* <div className={style.date}>
                    <div className={style.date_label}>{complectation.rrp_updated.label}</div>
                    <div className={style.date_value}>{complectation.rrp_updated.value}</div>
                </div> */}
            </div>
        </Paper>
    )
})
