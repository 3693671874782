import React from 'react'
import style from './specification-list.style.scss'
import clsx from 'clsx'
import { Parameter } from '../../complectation.types'
import { ScrollArea } from '@mantine/core'

interface SpecificationListProps extends React.HTMLAttributes<HTMLDivElement> {
    data: Partial<Omit<Parameter, 'label'> & { label: string | React.ReactNode }>[]
    grid?: number
}

export const SpecificationList: React.FC<SpecificationListProps> = ({ data, className, grid, ...rest }) => {
    const count = data.length
    const isScrollArea = count > 12
    const clsxWrapper = clsx(style.wrapper, { [style.grid]: !!grid })

    const items = data.map((attr) => (
        <div key={attr.id} className={style.row}>
            <div className={style.label}>{attr.label}</div>
            {!!attr.value && <div className={style.value}>{attr.value}</div>}
        </div>
    ))

    return (
        <div className={clsx(style.root, className)} {...rest}>
            {/* {isScrollArea && (
                <ScrollArea.Autosize
                    mah={600}
                    styles={{
                        root: {
                            paddingRight: 20,
                        },
                    }}
                >
                    <div className={clsxWrapper}>{items}</div>
                </ScrollArea.Autosize>
            )}

            {!isScrollArea && <div className={clsxWrapper}>{items}</div>} */}

            <div className={clsxWrapper}>{items}</div>
        </div>
    )
}
