import clsx from 'clsx'
import React from 'react'
import SimpleBar from 'simplebar-react'

interface IScrollProps {
    height?: number
    className?: string
    autoHide?: boolean
}

export const Scroll: React.FC<IScrollProps> = ({ children, height, autoHide, className }) => {
    const maxHeight = height || 160
    const classes = clsx('elisa-scroll', className)

    return (
        <SimpleBar style={{ maxHeight }} className={classes} autoHide={autoHide} data-elisa-scroll>
            {children}
        </SimpleBar>
    )
}
