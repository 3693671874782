import React from 'react'
import { ActionIcon, Button, Group, ScrollArea, SimpleGrid, Stack, Text, TextInput } from '@mantine/core'
import style from './option-search.style.scss'
import { IconSearch, IconX } from '@tabler/icons-react'
import { debounce } from '@/shared'
import { observer } from 'mobx-react-lite'
import { OptionCheckbox } from '../option-checkbox'
import { styles } from '@/theme/styles'
import { Count } from '../count'
import { core } from '@/widget/complectation/complectation.core'

interface OptionsSearchProps {}

export const OptionsSearch: React.FC<OptionsSearchProps> = observer(({ ...rest }) => {
    const [options, setOptions] = React.useState([])
    const [value, setValue] = React.useState('')

    const handleReset = () => {
        setOptions([])
        setValue('')
        core.resetOptions()
    }

    const handleSearch = React.useCallback(
        debounce(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value || ''

                setOptions(value ? core.searchOptions(value) : [])
            },
            300,
            (e) => {
                setValue(e.target.value || '')
            }
        ),
        []
    )

    const handleChange = (e: React.MouseEvent<HTMLDivElement>) => {
        const id_option = parseInt((e.target as any).closest('[data-option-id]')?.dataset?.optionId)

        if (id_option) {
            core.toggleSelectedOption(id_option)
        }
    }

    return (
        <Stack className={style.root} mb={32} {...rest}>
            <Group spacing={12}>
                <TextInput
                    sx={{ flexGrow: 1 }}
                    icon={<IconSearch size={24} />}
                    iconWidth={48}
                    placeholder='Поиск'
                    value={value}
                    onChange={handleSearch}
                    rightSection={
                        !!value && (
                            <ActionIcon variant='subtle' radius={'50%'} mr={4} onClick={handleReset}>
                                <IconX size={14} color={styles.secondary} />
                            </ActionIcon>
                        )
                    }
                />
                <Button rightIcon={<IconX size={20} />} onClick={handleReset}>
                    Сбросить все
                </Button>
            </Group>

            {!!options.length && (
                <Stack onClick={handleChange}>
                    <Text size={18} fw={600} lh={'24px'} mt={12} sx={{ position: 'relative', alignSelf: 'flex-start' }}>
                        Найденные опции:
                        <Count count={options.length} visible={!!options.length} sx={{ top: -6, right: -2 }} />
                    </Text>
                    <ScrollArea.Autosize mah={1080} mt={12} mb={24} offsetScrollbars>
                        <SimpleGrid spacing={24} verticalSpacing={12} cols={2} pr={4}>
                            {options.map((item) => (
                                <OptionCheckbox item={item} key={item.id} />
                            ))}
                        </SimpleGrid>
                    </ScrollArea.Autosize>
                </Stack>
            )}
        </Stack>
    )
})
