import React from 'react'
import style from './index.style.scss'
import { Icon, Table } from '@/pages/analytics/components/ui'
import Checkbox from '@mui/material/Checkbox'
import TableSortLabel from '@mui/material/TableSortLabel'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { store } from '@/pages/analytics/store'
import IconButton from '@mui/material/IconButton'

interface IAnalyticsTableHeadProps {}

export const AnalyticsTableHead: React.FC<IAnalyticsTableHeadProps> = observer(({ ...rest }) => {
    const isViewMode = store.table.isMode('view')
    const isPassportMode = store.table.isMode('passport')
    const isVisible = !!((isPassportMode && store.table.scheme.passport) || !isPassportMode) && !isViewMode

    const checkbox = (
        <Table.Cell
            align='left'
            padding='checkbox'
            style={{
                position: 'sticky',
                left: 0,
                background: 'white',
                zIndex: 4,
                // borderRight: '1px solid #E0E0E0',
            }}
            data-action
        >
            <Checkbox
                color='primary'
                indeterminate={store.table.selected.length > 0 && store.table.selected.length < store.table.rows.length}
                checked={store.table.rows.length > 0 && store.table.selected.length === store.table.rows.length}
                onChange={store.table.onSelectAllClick}
            />
        </Table.Cell>
    )

    return (
        <>
            <Table.Head
                classes={{ root: style.root }}
                {...rest}
                style={{
                    zIndex: 4,
                    position: 'relative',
                }}
            >
                <Table.Row>
                    {isVisible && checkbox}

                    {store.table.columns.map((column) => {
                        /** Скрываем колонки */
                        if (store.table.hiddenColumns.includes(column.id)) {
                            return
                        }

                        return (
                            <Table.Cell
                                key={column?.id}
                                align={'left'}
                                style={
                                    column?.id === 'id'
                                        ? {
                                              minWidth: 120,
                                              maxWidth: 120,
                                              position: 'sticky',
                                              left: isVisible ? (store.table.dense ? 52 : 46) : 0,
                                              background: 'white',
                                              zIndex: 3,
                                              borderRight: '1px solid #E0E0E0',
                                          }
                                        : {}
                                }
                                className={clsx(style.cell, {
                                    [style.isActive]: store.table.anchorElSort?.dataset.column === column?.id,
                                })}
                            >
                                {column?.id ? (
                                    <>
                                        <TableSortLabel
                                            active={store.table.orderBy === column?.id}
                                            direction={
                                                store.table.orderBy === column?.id ? (store.table.order as any) : 'asc'
                                            }
                                            onClick={store.table.onChangeSort(column?.id)}
                                        >
                                            {column?.label}
                                        </TableSortLabel>
                                        <IconButton
                                            size='small'
                                            data-column={column?.id}
                                            onClick={store.table.handleOpenSortPopover}
                                        >
                                            <Icon icon='filters' fontSize='small' />
                                        </IconButton>
                                    </>
                                ) : null}
                            </Table.Cell>
                        )
                    })}

                    {/* Пустая ячейка для AnalyticsTableMenu */}
                    <Table.Cell
                        align={'left'}
                        width={40}
                        sx={{
                            padding: !store.table.dense ? '16px 16px' : '18px 16px',
                        }}
                        // style={{
                        //     position: 'sticky',
                        //     right: 0,
                        //     background: 'white',
                        //     zIndex: 2,
                        //     borderLeft: '1px solid #E0E0E0',
                        // }}
                    ></Table.Cell>
                </Table.Row>
            </Table.Head>
        </>
    )
})
