import React from 'react'
import style from './specifications.style.scss'
import { Paper } from '../paper'
import { observer } from 'mobx-react-lite'
import { store } from '../../car.store'
import { SpecificationList } from '../specification-list'
import { Button, Popover } from '@mantine/core'
import { MatchedOptions } from '../matched-options'
import { IconChevronDown } from '@tabler/icons-react'

type SpecificationsProps = {}

export const Specifications: React.FC<SpecificationsProps> = observer(() => {
    const car = store.data.car

    return (
        <Paper defaultValue={true} title='Технические характеристики' className={style.root}>
            <div className={style.inner}>
                <SpecificationList
                    grid={3}
                    data={[
                        ...car.getSpecifications(),
                        {
                            label: (
                                <div className={style.price}>
                                    <div className={style.date_value}>Цена</div>
                                    <div className={style.date_value}>{`${car.price} ₽`}</div>
                                </div>
                            ),
                        },
                        {
                            label: (
                                <div className={style.rrc}>
                                    <div className={style.date_value}>РРЦ</div>
                                    <div className={style.date_value}>{`${car.rrc} ₽`}</div>
                                    {!!car.matchedOptions.length && (
                                        <div>
                                            <Popover withArrow offset={0} shadow='sm' position='top-end'>
                                                <Popover.Target>
                                                    <Button rightIcon={<IconChevronDown />} variant='subtle'>
                                                        Опции
                                                    </Button>
                                                </Popover.Target>
                                                <Popover.Dropdown>
                                                    <MatchedOptions />
                                                </Popover.Dropdown>
                                            </Popover>
                                        </div>
                                    )}
                                </div>
                            ),
                        },
                    ]}
                />

                <div className={style.date}>
                    <div className={style.date_label}>Дата обновления</div>
                    <div className={style.date_value}>{car.updateTime}</div>
                </div>
            </div>
        </Paper>
    )
})
