import React from 'react'
import style from './index.style.scss'
import { Table } from '@/pages/analytics/components/ui'
import { observer } from 'mobx-react-lite'
import { AnalyticsTableRow } from './row'
import { store } from '@/pages/analytics/store'

interface IAnalyticsTableBodyProps {}

export const AnalyticsTableBody: React.FC<IAnalyticsTableBodyProps> = observer(({ ...rest }) => {
    return (
        <Table.Body classes={{ root: style.root }} {...rest}>
            {store.table.duplicatesRows.map((row, i) => (
                <AnalyticsTableRow isDuplicate row={row} key={i} />
            ))}
            {store.table.rows.map((row, i) => (
                <AnalyticsTableRow row={row} key={i} />
            ))}
        </Table.Body>
    )
})
