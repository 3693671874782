import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'

type AuthLoginRequest = {
    data: {
        email: string
        password: string
    }
}

type AuthLoginResponse = {
    success: boolean
    message: string
}

export const AuthLogin = async (req: AuthLoginRequest) => {
    try {
        const response = await apiConfig.fetch.base<AuthLoginResponse>({
            url: '/auth/login',
            config: {
                method: 'POST',
                data: req.data,
            },
        })

        if (!response?.data?.success) {
            throw Error('Доступ запрещен')
        }

        return response
    } catch (err: any) {
        throwAdapterError(err, 'AuthLogin')
    }
}
