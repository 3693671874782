import { service } from '@/api'
import { sleep } from '@/shared'
import { store } from '@/pages/analytics/store'
import { Box } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { notifyError } from '@/theme/@deprecated/components/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useParams } from 'react-router-dom'
import { residualScheme } from '..'
import { EditTable, EditTableData } from './table'
import { MantineProvider } from '@/theme'

interface AnalyticsResidualEditScreenProps {}

const data = [
    {
        id: '1',
        title: '1 год',
        months: {
            '1': '95',
            '2': '95',
            '3': '95',
            '4': '95',
            '5': '95',
            '6': '95',
            '7': '95',
            '8': '95',
            '9': '95',
            '10': '95',
            '11': '95',
            '12': '95',
        },
    },
]

export const AnalyticsResidualEditScreen: React.FC<AnalyticsResidualEditScreenProps> = observer(({ ...rest }) => {
    const { id } = useParams()
    const [currentData, setCurrentData] = React.useState<EditTableData[]>([])
    const [visibleLoader, setVisibleLoader] = React.useState(false)
    const refCurrentData = React.useRef<EditTableData[]>([])
    const refPrevData = React.useRef<EditTableData[]>([])

    const handleSetData = React.useCallback(
        async (reset = false) => {
            try {
                if (reset && refPrevData.current.length) {
                    refCurrentData.current = refPrevData.current
                } else {
                    setVisibleLoader(true)

                    const data = await service.analytics.residual_get({ id })

                    refCurrentData.current = data
                    refPrevData.current = data
                }

                await sleep(800)

                setVisibleLoader(false)
                setCurrentData(refCurrentData.current)
            } catch (err) {
                console.log(err)
                notifyError(err?.message)
            }
        },
        [id]
    )

    const handleSaveData = React.useCallback(async () => {
        try {
            setVisibleLoader(true)

            const data = []

            /** Подготавливаем данные к отправке */
            refCurrentData.current.forEach((year) => {
                Object.keys(year.months).forEach((month) => {
                    const currentMonth = year.months[month]

                    data.push({
                        id: {
                            value: currentMonth.id,
                        },
                        coefficient: {
                            value: currentMonth.value || '0',
                        },
                        month: {
                            value: month,
                        },
                        year: {
                            value: year.value,
                        },
                    })
                })
            })

            const response = await service.analytics.residual_create({
                id,
                data: data,
            })

            await sleep(300)

            if (response.success) {
                showNotification({
                    message: 'Таблица успешно обновлена!',
                    sx: { maxWidth: 300, right: -140 },
                    color: 'green.6',
                })
            }

            setVisibleLoader(false)
            handleSetData()
        } catch (err) {
            console.log(err)
            setVisibleLoader(false)
            showNotification({
                message: 'Произошла ошибка во время обновления таблицы, попробуйте позже!',
                sx: { maxWidth: 340, right: -100 },
                color: 'red.6',
            })
        }
    }, [id])

    const handleGetModification = async () => {
        const response = await service.analytics.reference_get({
            url: residualScheme.api,
            data: {
                'page[number]': '1',
                'page[size]': '25',
                'filters[id]': id,
                minimal_column_set: true,
            },
        })
        store.app.setTitle(`Остаточные стоимости - ${response?.data?.[0].name.value}`)
    }

    React.useEffect(() => {
        handleGetModification()
    }, [])

    React.useEffect(() => {
        handleSetData()
    }, [handleSetData])

    return (
        <Box p={24} {...rest}>
            <EditTable
                data={currentData}
                refData={refCurrentData}
                visibleLoader={visibleLoader}
                setVisibleLoader={setVisibleLoader}
                handleSetData={handleSetData}
                handleSaveData={handleSaveData}
            />
        </Box>
    )
})
