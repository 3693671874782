import { IModalProps, Modal } from '@/theme/@deprecated/components/ui/modal'
import { IUseModalProps, useModal } from '@/theme/@deprecated/components/ui/modal/hook'
import { Button } from '@/theme/@deprecated/components/ui'
import clsx from 'clsx'
import React from 'react'
import { ModalLabel, ModalTitle } from '@/theme/@deprecated/components/modal/_components'
import style from './index.style.scss'

interface IModalAlertProps extends IModalProps {
    title?: string
    label?: string | JSX.Element
    actionLabel?: string
    onAction: FunctionType
}

export const ModalAlert: React.FC<IModalAlertProps> = ({
    className,
    title,
    label,
    actionLabel,
    onAction,
    ...props
}) => {
    return (
        <Modal {...props} className={clsx(style.root, className)}>
            <Modal.Header>
                <ModalTitle title={title} className={clsx(style.title)} />
            </Modal.Header>
            <Modal.Body className={style.body}>
                <ModalLabel label={label} className={style.label} />
                <Button type='accent' onClick={onAction}>
                    {actionLabel}
                </Button>
            </Modal.Body>
        </Modal>
    )
}

ModalAlert.defaultProps = {
    title: 'Что-то пошло не так',
    label: 'Произошла ошибка во время выполнения запроса, пожалуйста попробуйте позже!',
    actionLabel: 'Понятно',
}

export const useModalAlert = (data?: IUseModalProps) => useModal<IModalAlertProps>({ ...data, instance: ModalAlert })
