import React from 'react'
import clsx from 'clsx'
import style from './cell-image.style.scss'
import { TableCell } from '@/widget/table/table.types'
import { useSetState } from '@mantine/hooks'
import { LoadingOverlay, Image } from '@mantine/core'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import FsLightbox from 'fslightbox-react'

type CellImageProps = React.HTMLAttributes<HTMLTableCellElement> & {
    cell: TableCell
}

export const CellImage: React.FC<CellImageProps> = ({ className, cell, ...rest }) => {
    const [state, setState] = useSetState({ loader: true, error: false, open: false })

    const handleViewer = () => {
        setState({ open: !state.open })
    }

    return (
        <div className={clsx(style.root, className, 'elisa-table-widget-cell')} title={cell.value} {...rest}>
            <div className={style.image}>
                <LoadingOverlay loaderProps={{ size: 'sm' }} visible={state.loader} zIndex={1} />
                {!state.error ? (
                    <>
                        <LazyLoadImage
                            height={'auto'}
                            src={cell.value || '/static/car-placeholder.png'}
                            draggable={false}
                            afterLoad={async () => {
                                setState({ loader: false })
                            }}
                            onError={() => setState({ loader: false, error: true })}
                            onClick={handleViewer}
                            className={style.image_inner}
                        />
                        <FsLightbox toggler={state.open} sources={[cell.value || '/static/car-placeholder.png']} />
                    </>
                ) : (
                    <Image sx={{ img: { maxHeight: 51 } }} draggable={false} src={'/static/car-placeholder.png'} />
                )}
            </div>
        </div>
    )
}
