import { apiConfig } from '@/api/api.config'
import { sortArrayObjectsByKey, throwAdapterError } from '@/shared'
import { CarstockFilterValue } from './filter-values.types'
import { CarstockFilter } from '../filters/filters.types'

export type CarstockFilterValuesRequest = {
    id: string
    filters?: {
        [property: string]: number
    }
}

type CarstockFilterValuesResponse = {
    data: CarstockFilterValue[]
    success: boolean
}

export const CarstockFilterValuesController = { ref: null }

export const CarstockFilterValues = async (req: CarstockFilterValuesRequest) => {
    CarstockFilterValuesController.ref = new AbortController()

    try {
        const response = await apiConfig.fetch.ilsa<CarstockFilterValuesResponse>({
            url: `/ilsa/attribute-values/${req.id}`,
            config: {
                method: 'GET',
                params: req.filters,
                cache: true,
                signal: CarstockFilterValuesController.ref.signal,
            },
        })

        const getValues = (acc: CarstockFilter['values'], value: CarstockFilterValue) => {
            if (value.id && value.name) {
                acc.push({
                    id: value.id,
                    value: value.name,
                    label: value.name,
                    type: value.type || null,
                    group: null,
                })
            }

            return acc
        }

        const values: CarstockFilter['values'] = response.data.data
            .reduce((acc, value) => getValues(acc, value), [])
            .filter((value) => value.id && value.label)

        return sortArrayObjectsByKey(values, 'value')
    } catch (err: any) {
        throwAdapterError(err, 'CarstockFilterValues')
    }
}
