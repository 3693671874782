import React from 'react'
import { observer } from 'mobx-react-lite'
import { ComplectationPackageModel } from '@/widget/complectation/models'
import { Checkbox, clsx } from '@mantine/core'
import { number } from '@/shared'
import style from './option-package.style.scss'
import { IconCheck } from '@tabler/icons-react'
import { styles } from '@/theme/styles'

interface OptionPackageProps extends React.HTMLAttributes<HTMLDivElement> {
    item: ComplectationPackageModel
}

export const OptionPackage: React.FC<OptionPackageProps> = observer(({ item }) => {
    return (
        <div className={clsx(style.root, { [style.selected]: item.data.selected })}>
            <div className={style.title} data-package-id={item.data.id}>
                <Checkbox
                    className={style.checkbox}
                    onChange={() => null}
                    checked={item.data.selected}
                    variant='option'
                    size='sm'
                    label={item.data.name}
                    icon={IconCheck}
                    color={styles.dark}
                />
            </div>
            <div className={style.description}>
                {item.data.description}
                {/* {el.list.map((item, index) => {
                    return (
                    <div key={index} className={style.group_selected_item}>
                        {item.selected && icon || icon}
                        {item.text}
                    </div>
                    )
                })} */}
            </div>
            <div className={style.price}>
                <span>Стоимость: {number.format(item.data.price)} рублей</span>
            </div>
        </div>
    )
})
