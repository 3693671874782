import React from 'react'
import styles from './Proposal.style.scss'

import { Container } from '@/theme/@deprecated/components/ui'
import Download from '@/pages/@deprecated/references/pages/proposal/components/Download'
import History from '@/pages/@deprecated/references/pages/proposal/components/History'

const ProposalPage = () => {
    return (
        <Container className={styles.container}>
            <div className={styles.title}>Распознавание коммерческих предложений</div>
            <Download />
            <History />
        </Container>
    )
}

export default ProposalPage
