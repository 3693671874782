import { BaseModel } from '@/shared/core/model'
import { Header, Parameter } from '../../complectation.types'
import dayjs from 'dayjs'
import { number } from '@/shared'
import { apiConfig } from '@/api'

type ComplectationModelProps = {
    id?: any
    header: Header & {
        body_type_label?: string
    }
    parameters: Parameter[]
    custom_parameters: Parameter[]
    carstock_parameters: {
        brand_id: number
        model_id: number
        version_id: number
    }

    has_options: boolean
    is_parallel_import: boolean

    selected_color?: Header['color']
}

export class ComplectationModel extends BaseModel<ComplectationModelProps, ComplectationModel> {
    constructor(props: ComplectationModelProps) {
        super(props, ComplectationModel)

        // this.data.selected_options = []

        if (!this.data.header.color) {
            this.setCheapestColor()
            // const color = this.data.header.colors[0]

            // this.setColor(color)
            // this.data.header = {
            //     ...this.data.header,
            //     color: color || null,
            // }
            // this.data.selected_color = color || null

            // if (color) {
            // this.setColor(color)
            // this.data.selected_options.push({
            //     id: color.id,
            //     label: `Цвет ${color.name}`,
            //     value: number.format(color.price),
            // })
            // }
        }
    }

    /* -------------------------------------------------------------------------- */
    /*                                    Data                                    */
    /* -------------------------------------------------------------------------- */

    get image() {
        const image = this.color?.image

        return image ? apiConfig.hosts.image_catalog + image : '/static/car-placeholder.png'
    }

    get rrp_updated() {
        const rrp = this.getCustomParameter('rrp_updated_at')

        return {
            label: rrp.label,
            value: rrp.value ? dayjs(rrp.value).format('DD.MM.YYYY') : null,
        }
    }

    get price() {
        const rrp = this.getCustomParameter('rrp')

        return number.format(rrp.value)
    }

    get price_type() {
        const labelType = this.data.is_parallel_import ? 'СРЦ' : 'РРЦ'

        return labelType
    }

    get price_label() {
        const rrp = this.getCustomParameter('rrp')

        const price = number.format(rrp.value)

        const priceLabel = `${this.price_type} ${price} ₽`
        const priceLabelRequest = `${this.price_type} по запросу`

        return rrp.value ? priceLabel : priceLabelRequest
    }

    get price_with_color() {
        const rrp = this.getCustomParameter('rrp')
        const color = this.color?.price || 0

        return rrp.value + color
    }

    // get body_type() {
    //     const isExist = !!this.data.header.body_type

    //     return isExist
    //         ? {
    //               label: this.data.header.body_type_label,
    //               value: this.data.header.body_type,
    //           }
    //         : null
    // }

    // get vehicle_type() {
    //     const isExist = !!this.data.header.vehicle_type_name

    //     return isExist
    //         ? {
    //               label: 'Тип транспортного средства',
    //               value: this.data.header.vehicle_type_name,
    //           }
    //         : null
    // }

    // get country_name() {
    //     const isExist = !!this.data.header.vehicle_type_name

    //     return isExist
    //         ? {
    //               label: 'Страна происхождения марки',
    //               value: this.data.header.origin_country_name,
    //           }
    //         : null
    // }

    get header_parameters() {
        const parameters = this.data.header.items

        return parameters.filter(Boolean)
    }

    get color() {
        return this.data.header.color
    }

    get colors() {
        return this.data.header.colors
    }

    get discount() {
        const discount_percent = this.getCustomParameter('model_discount_value_percent')
        const discount_price = this.getCustomParameter('model_discount_value_rub')
        const is_exist = !!discount_percent.value || !!discount_price.value

        return is_exist
            ? {
                  label: 'Скидка',
                  value: discount_percent.value
                      ? `${discount_percent.value}%`
                      : `${number.format(discount_price.value)} (руб.)`,
              }
            : null
    }

    get carstock_parameters() {
        return this.data.carstock_parameters?.brand_id ? this.data.carstock_parameters : null
    }

    get carstock_count() {
        const param = this.getCustomParameter('in_stock_count')

        return param?.value ? param : null
    }

    // get selected_options() {
    //     return this.data.selected_options
    // }

    // get selected_options_count() {
    //     return this.selected_options.length
    // }

    getCustomParameter(id: string) {
        return this.data.custom_parameters.find((parameter) => parameter.id === id)
    }

    getColorById(id: number) {
        const color = this.data.header.colors.find((color) => color.id === id)

        return color
    }

    getCheapestColor() {
        const colors = this.colors

        const color = colors.reduce((acc, curr) => {
            if (!acc) {
                return curr
            }

            if (acc.price > curr.price) {
                return curr
            }

            return acc
        }, null)

        return color
    }

    setColor(color: Header['color']) {
        // const filtered_options = this.data.selected_options.filter((option) => option.id !== this.color.id)

        // // if (this.colors[0].id !== color.id) {
        // filtered_options.push({
        //     id: color.id,
        //     label: `Цвет ${color.name}`,
        //     value: number.format(color.price),
        // })
        // }

        // if (color.price > 0) {
        //     filtered_options.push({
        //         id: color.id,
        //         label: `Цвет ${color.name}`,
        //         value: number.format(color.price),
        //     })
        // }

        this.setData({
            header: {
                ...this.data.header,
                color: color || this.data.header.colors[0],
            },
            selected_color: color || this.data.header.colors[0],
            // selected_color: color && this.colors[0].id !== color.id ? color : null,
            // selected_options: filtered_options,
        })
    }

    setCheapestColor() {
        const color = this.getCheapestColor()

        this.setColor(color)
    }

    // isExistSelectedOption(option: SelectItem) {
    //     const isExist = this.selected_options.find((selected_option) => selected_option.id === option.id)

    //     return isExist
    // }

    // setSelectedOption(option: SelectItem) {
    //     const isExist = this.isExistSelectedOption(option)
    //     const filtered = isExist
    //         ? this.selected_options.filter((selected_option) => selected_option.id !== option.id)
    //         : this.selected_options

    //     this.setData({ selected_options: [...filtered, option] })
    // }

    // deleteSelectedOption(option: SelectItem) {
    //     const filtered = this.selected_options.filter((selected_option) => selected_option.id !== option.id)

    //     /** Сбрасываем на дефолтный цвет, если удаляем цвет из выбранных опций */
    //     if (this.color.id === option.id) {
    //         this.setColor(this.colors[0])
    //     }

    //     this.setData({ selected_options: filtered })
    // }
}
