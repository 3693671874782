import { makeAutoObservable } from 'mobx'
import { apiConfig } from '@/api'

class DocumentsStore {
    documents = null
    meta = null

    constructor() {
        makeAutoObservable(this)
    }

    getDocuments = async (params) => {
        try {
            const res = await apiConfig.fetch.ilsa({
                url: '/ocr/documents',
                config: {
                    method: 'GET',
                    params: params
                        ? params
                        : {
                              'page[size]': 10,
                              'page[number]': 1,
                          },
                },
            })

            this.setDocuments(res.data)
        } catch (err) {
            throw Error(`Произошла ошибка во время выполнения запроса getDocuments: ${err?.message || ''}`)
        }
    }

    deleteDocument = async (id) => {
        try {
            const res = await apiConfig.fetch.ilsa({ url: `/ocr/documents/${id}`, config: { method: 'DELETE' } })

            if (res.status === 200) this.getDocuments()
        } catch (err) {
            throw Error(`Произошла ошибка во время выполнения запроса deleteDocument: ${err?.message || ''}`)
        }
    }

    deleteDocumentFront = (id) => {
        const current = this.documents.findIndex((item) => item.id === id)

        this.documents.splice(current, 1)
    }

    setDocuments = (payload) => {
        this.documents = payload.data
        this.meta = payload.meta
    }

    setDocumentStatus = (id) => {
        const current = this.documents.find((item) => item.id === id)

        if (current)
            current.status = {
                name: 'front-error',
                title: 'Ошибка',
            }
    }

    setDocument = (payload) => {
        this.documents.unshift(payload)
    }

    uploadFile = async (data) => {
        try {
            return await apiConfig.fetch.ilsa({ url: '/ocr/documents', config: { method: 'POST', data } })
        } catch (err) {
            return false
        }
    }
}

export default new DocumentsStore()
