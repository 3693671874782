import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'

type UsersCitiesResponse = {
    data: string[]
    success: boolean
}

export const UsersCities = async () => {
    try {
        const response = await apiConfig.fetch.ilsa<UsersCitiesResponse>({ url: '/auth/users/cities' })

        if (!response?.data?.data?.length) {
            throw Error('Не удалось получить список городов')
        }

        const cities = response.data.data.map((city, i) => ({
            id: city,
            value: city,
            label: city,
        }))

        return cities
    } catch (err: any) {
        throwAdapterError(err, 'UsersCities')
    }
}
