import { makeObservable, observable } from 'mobx'
import { clone } from '../lib/object'

export class BaseModel<P, M, S = any> {
    readonly _: {
        props: P
        Model: any
    }
    readonly data: P = null
    readonly state: State = {
        loading: false,
        loaded: false,
        disabled: false,
        init: false,
        error: null,
    }
    readonly service: S = {} as any
    readonly __service: S = {} as any

    constructor(props: P, Model?: any) {
        makeObservable(this, {
            data: observable,
            state: observable,
        })

        this.service = (props as any).service || {}
        this.__service = (props as any).__service || {}

        this.data = clone(props)
        this._ = {
            props: {
                ...clone(props),
                __service: this.__service || {},
            },
            Model: Model,
        }
    }

    setData(data: Partial<P>) {
        ;(this as any).data = {
            ...this.data,
            ...data,
        }
    }

    setState(state: State) {
        ;(this as any).state = {
            ...this.state,
            ...state,
        }
    }

    clone(): M {
        if (this._.Model) {
            return new this._.Model(this._.props) as M
        } else {
            throw Error(`Не передана модель в super для ${JSON.stringify(this._.props)}`)
        }
    }
}
