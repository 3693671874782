import { store } from '@/pages/analytics/store'
import { notifyError } from '@/theme/@deprecated/components/ui'
import React from 'react'
import style from './index.style.scss'

interface TableErrorFallbackProps {}

export const TableErrorFallback: React.FC<TableErrorFallbackProps> = ({ ...rest }) => {
    React.useEffect(() => {
        store.table.setState({ error: { message: '', status: 500 } })
        notifyError(`Произошла ошибка компонента таблицы ${store.table.scheme.id}`)
    }, [])

    return <span className={style.root} {...rest}></span>
}
