import React from 'react'
import { Modal } from '..'

export interface IUseModalProps<T = any> {
    state?: boolean
    instance?: ReactFC<T>
}

export const useModal = <T = any,>(data?: IUseModalProps<T>) => {
    const [open, setOpen] = React.useState(data?.state)

    const onClose = () => setOpen(false)

    return {
        open,
        setOpen,
        onClose,
        Modal: data?.instance || Modal,
    }
}
