import React from 'react'
import { IconEdit, IconTrash } from '@tabler/icons-react'

export const list = {
    edit: {
        id: 'edit',
        label: 'Редактировать',
        icon: <IconEdit />,
    },
    delete: {
        id: 'delete',
        label: 'Удалить',
        icon: <IconTrash />,
    },
}
