import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import Instance, { TableBodyProps } from '@mui/material/TableBody'

interface ITableBodyProps extends TableBodyProps {}

export const TableBody: React.FC<ITableBodyProps> = ({ classes, children, ...rest }) => {
    const cls = { root: clsx(style.root, classes?.root) }

    return (
        <Instance classes={cls} {...rest}>
            {children}
        </Instance>
    )
}
