import { showToast, showToastError } from '@/shared/vendors/mantine'
import { StoreData, StoreParams, initial_default_data, store } from './carstock.store'
import { service } from '@/api'
import { CarstockFilters, DefaultData } from './carstock.types'
import { CarstockCarModel, CarstockFilterModel } from './models'

type CoreService = {
    filters?: ServiceItem<() => Promise<CarstockFilters>>
    search?: ServiceItem<typeof service.carstock.search>
    modifications?: ServiceItem<typeof service.carstock.search>
    options?: ServiceItem<typeof service.carstock.options>
    save?: ServiceItem<typeof service.carstock.search_save>
    edit?: ServiceItem<typeof service.carstock.search_edit>
}

export type CoreProps = {
    widget: {
        name: string
    }
    service: CoreService
    params?: StoreParams
    data?: StoreData
}

const serviceItem = {
    request: null,
    controller: { ref: null },
}

const initialWidget: CoreProps['widget'] = {
    name: null,
}

const initialService: CoreService = {
    filters: serviceItem,
    search: serviceItem,
    modifications: serviceItem,
    options: serviceItem,
    save: serviceItem,
}

class Core {
    widget = initialWidget
    service = initialService
    data: CoreProps['data'] = {}

    init(props: CoreProps) {
        const default_data_url = this.getDefaultParamsFromUrl()

        if (this.widget.name && this.widget.name === props.widget.name) {
            if (
                default_data_url &&
                JSON.stringify(store.data.default_data) === JSON.stringify(default_data_url) &&
                Object.keys(default_data_url).length
            ) {
                return this
            } else if (
                !Object.keys(default_data_url).length &&
                JSON.stringify(store.data.default_data) === JSON.stringify(initial_default_data)
            ) {
                return this
            }
        }

        store.reset()

        Object.values(this.service || {}).forEach((service) => {
            service?.controller?.ref?.abort?.()
        })

        this.widget = props.widget || initialWidget
        this.service = props.service || initialService

        this.data = props.data || {}

        if (props.data) {
            store.setData(props.data)
        }

        if (props.params) {
            store.setParams(props.params)
        }

        store.setData({
            default_data: {
                ...store.data.default_data,
                ...default_data_url,
            },
        })

        if (default_data_url.tab) {
            const state = store.data.tabs.get(default_data_url.tab)
            const states = store.getTabs()
            states.forEach((state) => (state.enabled = false))
            state.enabled = true
        }

        if (default_data_url.sort) {
            const sort = store.data.sorts.get(default_data_url.sort[0])
            const sorts = store.getSorts()
            sorts.forEach((sort) => (sort.enabled = false))
            sort.enabled = true
        }

        if (default_data_url.limit) {
            store.data.meta = {
                ...store.data.meta,
                per_page: String(default_data_url.limit),
            } as any
        }

        return this
    }

    async mount() {
        try {
            if (store.state.init) {
                this.search()

                return
            }

            store.setState({ loading: true })

            const default_data = store.data.default_data

            if (Object.keys(default_data.options || {}).length) {
                await this.loadOptions()
            }

            await this.filters()

            if (!store.state.searched && store.params.load) {
                this.search(default_data.page || 1)
            }

            store.setState({ init: true, loading: false })
        } catch (err) {
            console.log(err)
            store.setState({ init: false, loading: false, loaded: false })
            showToastError({ message: err?.message })
        }
    }

    async unmount() {
        // ...
    }

    async filters() {
        const filters = await this.service.filters.request()
        const filters_array = Array.from(filters.values()).flat()
        const filters_finded: { value: any; model: CarstockFilterModel }[] = []

        /** Собираем модели, у которых должно быть значение по умолчанию */
        if (Object.keys(store.data.default_data.filters).length) {
            for (const key in store.data.default_data.filters) {
                const finded = filters_array.find((filter) => filter.data.id === key)
                const value = store.data.default_data.filters[key]

                if (finded) {
                    filters_finded.push({
                        value,
                        model: finded,
                    })
                }
            }
        }

        /**
         * Проставляем значения по умолчанию.
         * Запрашиваем список значения, т.к. из url мы получаем только id.
         * Для корректной работы ui нам нужен id/value/label
         */
        await Promise.all(
            filters_finded.map((filter) =>
                filter.model.loadValues({}, () => {
                    this.setFilter(filter.value, filter.model)
                    filter.model.setState({ loaded: false })
                })
            )
        )

        store.setData({ filters })
    }

    async resetFilters() {
        Array.from(store.data.filters.values())
            .flat()
            .forEach((filter) => filter.setData({ value: [] }))

        const default_data = initial_default_data

        if (store.data.default_data.id) {
            default_data['id'] = store.data.default_data.id
            default_data['name'] = store.data.default_data.name
        }

        store.setData({
            tabs: this.data.tabs,
            sorts: this.data.sorts,
            default_data,
            // meta: {
            //     ...store.data.meta,
            //     current_page: 1,
            // },
        })

        this.resetOptions()
        this.changePage(1)
    }

    async search(page?: number) {
        try {
            store.setState({ searching: true })

            // store.meta.current_page = page || 1

            const values = store.getValuesForSearch()
            const { cars, meta, links } = await this.service.search.request({
                filters: {
                    ...values,
                    view_mode: values.view_mode,
                    page: page || 1,
                },
            })

            if (!cars.length) {
                showToast({ message: 'Значений по данным параметрам не найдено!', color: 'yellow.6' })
            }

            store.setData({ cars, modifications: [], car: null, meta, links })
            store.setState({ searching: false, searched: true })

            this.updateHistoryUrl()
        } catch (err) {
            console.log(err)
            store.setState({ searching: false, searched: false })
            showToastError({ message: err?.message })
        }
    }

    async changePage(page: number) {
        this.search(page)
    }

    async changeLimit(value?: number) {
        store.data.meta.per_page = String(
            value
                ? value
                : parseInt(store.data.meta.per_page) === store.data.limit.min
                ? store.data.limit.max
                : store.data.limit.min
        )

        this.search()
    }

    async changeViewState(id: string) {
        const state = store.data.tabs.get(id)

        if (!state.enabled) {
            const states = store.getTabs()

            states.forEach((state) => (state.enabled = false))

            state.enabled = true

            this.search()
        }
    }

    async changeSort(id: string) {
        const sort = store.data.sorts.get(id)

        if (!sort.enabled) {
            const sorts = store.getSorts()

            sorts.forEach((sort) => (sort.enabled = false))

            sort.enabled = true

            this.search()
        }
    }

    changeFilter(value: string[], filter: CarstockFilterModel) {
        const filters = store.filters
        const dependsFor = store.getFilterDependsFor(filter)

        this.setFilter(value, filter)

        /** Сбрасываем параметры зависимые на текущий фильтр */
        dependsFor.forEach((dependFilter) => dependFilter.reset())

        if (!value.length) {
            filter.setState({ loaded: false })
        }

        filters.forEach((iterate_filter) => {
            if (
                iterate_filter.state.loaded &&
                iterate_filter.data.id !== filter.data.id
                //  &&
                // !iterate_filter.data.value[0]
            ) {
                iterate_filter.setState({ loaded: false })
            }
        })
    }

    setFilter(value: string[], filter: CarstockFilterModel) {
        if (filter.data.type === 'select') {
            filter.set.value(value[0])
        } else if (filter.data.type === 'multi-select') {
            filter.set.multiValue(value)
        } else if (filter.data.type === 'range-select') {
            filter.set.rangeValue(value)
        } else if (filter.data.type === 'group-select') {
            filter.set.multiValue(value)
        }
    }

    changeFilterType(value: string, filter: CarstockFilterModel) {
        const dependsFor = store.getFilterDependsFor(filter)

        filter.set.type(value)
        filter.set.rangeValue([null, null])

        /** Сбрасываем параметры зависимые на текущий фильтр */
        dependsFor.forEach((dependFilter) => dependFilter.reset())
    }

    async changeOption(id: number) {
        try {
            const option = store.getOptionByIdItem(id)

            option.setSelectedOption(id)
        } catch (err) {
            console.log(err)
        }
    }

    async loadFilterValues(filter: CarstockFilterModel) {
        const filters = store.getFilterValuesByDependsOnForLoadValues(filter)

        await filter.loadValues(filters)
    }

    async loadOptions() {
        try {
            if (store.state.loadingOptions || store.state.loadedOptions) return

            store.setState({ loadingOptions: true })

            const values = store.getFilterValuesForSearchOptions()

            const { options } = await this.service.options.request({
                params: values,
            })

            if (Object.keys(store.data.default_data.options).length) {
                for (const key in store.data.default_data.options) {
                    options[0].setSelectedOption(parseInt(key))
                }
            }

            if (!options.length) {
                showToast({ message: 'Опций не найдено!', color: 'yellow.6' })
            }

            store.setData({ options })

            store.setState({ loadingOptions: false, loadedOptions: !!options.length })
        } catch (err) {
            console.log(err)
            store.setState({ loadingOptions: false, loadedOptions: false })
            showToastError({ message: err?.message })
        }
    }

    async resetOptions() {
        store.data.options.forEach((option) => {
            option.resetSelected()
        })
    }

    searchOptions(value: string) {
        const options = store.getOptionsItems()

        return options.filter((option) => option.name?.toLowerCase?.().includes?.(value.toLowerCase()))
    }

    async saveSearch(name: string) {
        try {
            if (store.state.saving) return

            store.setState({ saving: true })

            const values = store.getValuesForSearchSave()

            await this.service.save.request({
                id: 1,
                name,
                data: values,
            })

            this.updateHistoryUrl(values)

            store.setState({ saving: false })

            showToast({ message: 'Шаблон поиска сохранен' })
        } catch (err) {
            console.log(err)
            store.setState({ saving: false })
            showToastError({ message: err?.message })
        }
    }

    async saveEdit(name: string) {
        try {
            if (store.state.saving) return

            store.setState({ saving: true })

            const values = store.getValuesForSearchSave()
            const default_data = store.data.default_data

            if (!default_data.id || !default_data.name) {
                store.setState({ saving: false })
                showToastError({ message: 'Невозможно обновить шаблон без id и name' })
                return
            }

            await this.service.edit.request({
                id: default_data.id,
                name,
                data: values,
            })

            this.updateHistoryUrl(values)

            store.setState({ saving: false })

            showToast({ message: 'Шаблон поиска обновлен' })
        } catch (err) {
            console.log(err)
            store.setState({ saving: false })
            showToastError({ message: err?.message })
        }
    }

    /* -------------------------------------------------------------------------- */
    /*                                     Url                                    */
    /* -------------------------------------------------------------------------- */
    getDefaultParamsFromUrl(): DefaultData {
        const params = new URLSearchParams(location.search)
        const data = JSON.parse(params.get('data') || '{}')

        return data
    }

    updateHistoryUrl(data?: ObjectType) {
        const values = data || store.getValuesForSearchSave()
        const defaults_params = this.getDefaultParamsFromUrl()
        const json = JSON.stringify({ ...defaults_params, ...values })

        // console.log('updateHistoryUrl', defaults_params, values)
        // console.log('updateHistoryUrl query', json)

        const query = `${location.pathname}?data=${json}`

        // window.history.replaceState({ query }, '', query)
        window.history.pushState({ query }, '', query)
    }
}

export const core = new Core()
