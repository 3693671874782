import React from 'react'
import style from './car-deduplication.style.scss'
import { Link } from 'react-router-dom'
import { Popover, Text } from '@mantine/core'

interface CarDeduplicationProps {
    data: {
        id: any
        name: string
    }[]
    opened: boolean
}

export const CarDeduplication: React.FC<CarDeduplicationProps> = ({ data, opened, ...rest }) => {
    return (
        data.length > 0 && (
            <Popover offset={3} width={'auto'} position='bottom' withArrow shadow='md' opened={opened}>
                <Popover.Target>
                    <span className={style.count}>{data.length}</span>
                </Popover.Target>
                <Popover.Dropdown miw={240} data-carstock-car-deduplictation className={style.dropdown}>
                    <div className={style.title}>Количество дубликатов&nbsp;{data.length}:</div>
                    <ul className={style.list}>
                        {data.map((item) => (
                            <li className={style.list_item} key={item.id}>
                                <Link
                                    onClick={(e) => {
                                        e.stopPropagation()
                                    }}
                                    target='_blank'
                                    className={style.list_title}
                                    to={`/carstock/car/${item.id}`}
                                >
                                    <Text lineClamp={1} title={`${item.id} - ${item.name}`}>
                                        {item.id} - {item.name}
                                    </Text>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </Popover.Dropdown>
            </Popover>
        )
    )
}
