import React from 'react'
import { Button } from '@mantine/core'
import style from './search.style.scss'
import { core } from '../../catalog.core'
import { store } from '../../catalog.store'
import { styles } from '@/theme/styles'

interface SearchProps {}

export const Search: React.FC<SearchProps> = ({ ...rest }) => {
    const handleClick = () => {
        core.search()
    }

    return (
        <Button
            fullWidth
            onClick={handleClick}
            disabled={store.state.loading || store.state.searching || store.state.searchingModifications}
            variant='light'
            className={style.root}
            loaderPosition='right'
            loaderProps={{ variant: 'oval', color: styles.blue }}
            mt={32}
            {...rest}
        >
            Найти
        </Button>
    )
}
