import React from 'react'
import { ActionIcon, Box, Collapse, Paper as PaperCore, Title, clsx } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { useDisclosure } from '@mantine/hooks'
import style from './paper.style.scss'
import { IconMinus, IconPlus } from '@tabler/icons-react'

type PaperProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'defaultValue'> & {
    title?: string
    order?: number
    children: React.ReactNode
    onOpen?(): void
    defaultValue?: boolean
}

export const Paper: React.FC<PaperProps> = observer(
    ({ children, title, order, onOpen, className, defaultValue, ...rest }) => {
        const [opened, { toggle }] = useDisclosure(defaultValue, {
            onOpen,
        })
        const ref = React.useRef({ open: true })
        const isFirst = order === 0 || !title

        const handleOpen = () => {
            ref.current.open = true
            toggle()
        }

        return (
            <PaperCore key={title} className={clsx(style.root, className, { [style.main]: order === 0 })} {...rest}>
                {!!title && (
                    <Title order={2} className={style.title} onClick={handleOpen}>
                        {title}

                        {order !== 0 && (
                            <ActionIcon size={38} className={style.icon} variant='transparent'>
                                {opened ? <IconMinus size={36} stroke={2} /> : <IconPlus size={36} stroke={2} />}
                            </ActionIcon>
                        )}
                    </Title>
                )}

                <Collapse in={isFirst ? true : opened}>
                    <Box className={style.children} mih={40} mt={isFirst ? 0 : 32}>
                        {!!(ref.current.open || order === 0) && children}
                    </Box>
                </Collapse>
            </PaperCore>
        )
    }
)
