import React from 'react'
import { ActionIcon, Box, Collapse, Group, SimpleGrid, Stack, Text, clsx } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { store } from '@/widget/complectation/complectation.store'
import { core } from '@/widget/complectation/complectation.core'
import { OptionPackage } from '../option-package'
import { useDisclosure } from '@mantine/hooks'
import { styles } from '@/theme/styles'
import { IconChevronRight } from '@tabler/icons-react'
import style from './options-package.style.scss'

interface OptionsPackagesProps {}

export const OptionsPackages: React.FC<OptionsPackagesProps> = observer(({ ...rest }) => {
    const [opened, { toggle }] = useDisclosure(true)

    const packages = store.packages
    const is_exist = packages.length > 0

    const handleChange = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()

        const target: any = e.target
        const id = parseInt((target.closest('[data-package-id]') || e.target)?.dataset?.packageId)

        if (id) {
            core.toggleSelectedPackage(id)
        }
    }

    return (
        is_exist && (
            <Box {...rest}>
                <div className={style.inner}>
                    <Group onClick={toggle} className={clsx(style.title, { [style.title_opened]: opened })}>
                        <Text>Пакеты опций</Text>
                        <ActionIcon
                            variant='default'
                            w={32}
                            h={32}
                            ml={12}
                            radius={'50%'}
                            className={clsx(style.icon, { [style.icon_opened]: opened })}
                        >
                            <IconChevronRight size={20} stroke={2} color={styles.secondary} />
                        </ActionIcon>
                    </Group>
                    <Collapse in={opened}>
                        <SimpleGrid
                            className={style.grid}
                            spacing={18}
                            verticalSpacing={15}
                            cols={3}
                            pr={4}
                            onClick={handleChange}
                        >
                            {packages.map((item) => (
                                <OptionPackage item={item} key={item.data.id} />
                            ))}
                        </SimpleGrid>
                    </Collapse>
                </div>
            </Box>
        )
    )
})
