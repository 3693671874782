import React from 'react'
import style from './installed.style.scss'
import { Paper } from '../paper'
import { observer } from 'mobx-react-lite'
import { store } from '../../complectation.store'
import { NumberInput, Textarea } from '@mantine/core'

type InstalledProps = {}

export const Installed: React.FC<InstalledProps> = observer(() => {
    const additional_equipment = store.data.additional_equipment
    const additional_equipment_price = store.data.additional_equipment_price

    const handleText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value

        store.setData({ additional_equipment: value })
    }

    const handlePrice = (value: any) => {
        store.setData({ additional_equipment_price: value })
    }

    return (
        <Paper title='Дополнительное оборудование, установленное у дилера' className={style.root}>
            <div className={style.inner}>
                <Textarea
                    value={additional_equipment}
                    onChange={handleText}
                    minRows={3}
                    placeholder='Добавить опции'
                    className={style.textarea}
                />
                <NumberInput
                    value={additional_equipment_price || null}
                    onChange={handlePrice}
                    placeholder={'Цена'}
                    onKeyUp={({ currentTarget }) => {
                        currentTarget?.blur()
                        currentTarget?.focus()
                    }}
                    parser={(value) => value.replace(/ /g, '')}
                    formatter={(value) =>
                        !Number.isNaN(parseFloat(value))
                            ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ' ')
                            : ''
                    }
                    hideControls
                    min={0}
                    max={999999999}
                    className={style.input}
                />
            </div>
        </Paper>
    )
})
