import clsx from 'clsx'
import React, { HTMLAttributes } from 'react'
import style from './index.style.scss'

interface Button extends HTMLAttributes<HTMLButtonElement> {
    type?: '' | 'accent' | 'neutral' | 'neutral-error' | 'ghost' | 'white'
    size?: '' | 'sm' | 'md' | 'lg'
    disabled?: boolean
    ripplet?: boolean
    fluid?: boolean
    loading?: boolean | string
    onClick?: FunctionType
    className?: string
    children?: React.ReactNode
    mode?: string
}

export const Button = React.forwardRef<HTMLButtonElement, Button>(
    ({ className, children, type, size, fluid, disabled, loading, ripplet, mode, ...props }, ref) => {
        const prefix = 'ui-button'
        const attrs: any = {}
        const classes = clsx(style[prefix], style[`type-${type}`], style[`size-${size}`], {
            [style.disabled]: disabled,
            [style.fluid]: fluid,
            [style.loading]: loading,
            [className]: className,
        })

        if (disabled || loading) {
            attrs.disabled = true
        }

        if (ripplet) {
            attrs['data-ripplet'] = ripplet
        }

        if (loading && typeof loading == 'boolean') {
            attrs['data-spinner'] = true
        }

        return (
            <button className={classes} {...attrs} {...props} ref={ref} type={mode}>
                {loading ? <div className='ui-loader small inverted'></div> : null}
                {/* {icon ? <Icon icon={icon} className={style.icon} /> : null} */}
                <span className={style.label}>{loading && typeof loading == 'string' ? <>{loading}</> : children}</span>
            </button>
        )
    }
)

Button.defaultProps = {
    type: '',
    size: '',
    disabled: false,
    ripplet: true,
    mode: 'button',
}
