import React from 'react'
import { Box, createStyles, NumberInput } from '@mantine/core'

interface EditTableCellProps {
    handleEdit: FunctionType
    value: string
    reset: number
}

const useStyles = createStyles(() => ({
    root: { padding: '0px!important' },
    input: {
        input: {
            height: 36,
            padding: 4,
            textAlign: 'center',
            ':focus': { outline: '#1976d2 solid 2px', borderRadius: '2px' },
        },
    },
}))

export const EditTableCell: React.FC<EditTableCellProps> = ({ handleEdit, value, reset, ...rest }) => {
    const { classes } = useStyles()
    const refReset = React.useRef(reset)
    const refInput = React.useRef<HTMLInputElement>(null)
    const [currentValue, setCurrentValue] = React.useState(parseFloat(value))

    const handleChange = () => {
        const value = refInput.current.value

        setCurrentValue(parseFloat(value))
        handleEdit(value)
    }

    /** Сброс редактирования */
    React.useEffect(() => {
        if (reset !== refReset.current && refInput.current) {
            refReset.current = reset

            setCurrentValue(parseFloat(value))
        }
    }, [reset])

    return (
        <Box className={classes.root} component='td' align='center' {...rest}>
            <NumberInput
                className={classes.input}
                variant='unstyled'
                value={currentValue}
                onChange={handleChange}
                type='number'
                // precision={1}
                // decimalSeparator='.'
                ref={refInput}
            />
        </Box>
    )
}
