export const messages = {
    errors: {
        not_found: 'Доступ запрещен',
        forbidden: 'Данные для аутентификации не переданы',
        bad_request: 'Во время обработки запроса произошла ошибка, пожалуйста, попробуйте позже',
        app: 'Произошла непредвиденная ошибка, пожалуйста, попробуйте позже',
    },
    errors_https: {
        400: 'Доступ запрещен',
        401: 'Данные для аутентификации не переданы',
        403: 'Во время обработки запроса произошла ошибка, пожалуйста, попробуйте позже',
        404: 'Произошла непредвиденная ошибка, пожалуйста, попробуйте позже',
        500: 'Произошла непредвиденная ошибка, пожалуйста, попробуйте позже',
    },
}
