import React from 'react'
import { Box, clsx, createStyles } from '@mantine/core'
import { styles } from '@/theme/styles'
import { observer } from 'mobx-react-lite'
import { IconCheck } from '@tabler/icons-react'
import { number } from '@/shared'
import { ComplectationPackageModel } from '@/widget/complectation/models'

interface OptionPackageCheckboxProps extends React.HTMLAttributes<HTMLDivElement> {
    item: ComplectationPackageModel
}

const useStyles = createStyles((theme) => ({
    root: {
        borderRadius: 12,
        padding: '8px 12px',
        fontSize: 14,
        lineHeight: '20px',
        cursor: 'pointer',
        transition: 'all .1s ease',
        display: 'flex',
        alignItems: 'center',
        userSelect: 'none',
        background: styles.background,
        color: styles.primary,

        ':hover': {
            background: theme.fn.darken(styles.background, 0.04),
        },

        ':active': {
            background: theme.fn.darken(styles.background, 0.08),
        },
    },

    selected: {
        background: styles.blueLight,
        color: styles.blue,

        ':hover': {
            background: theme.fn.darken(styles.blueLight, 0.04),
        },

        ':active': {
            background: theme.fn.darken(styles.blueLight, 0.08),
        },
    },
}))

export const OptionPackageCheckbox: React.FC<OptionPackageCheckboxProps> = observer(({ item, ...rest }) => {
    const { classes } = useStyles()

    return (
        <Box
            data-package-id={item.data.id}
            className={clsx(classes.root, { [classes.selected]: item.data.selected })}
            {...rest}
        >
            {item.data.selected && <Box component={IconCheck} mr={12} sx={{ flexShrink: 0 }} />}

            <Box>{item.data.name}</Box>

            <Box ml={12} sx={{ whiteSpace: 'nowrap' }}>
                +{number.format(item.data.price)} ₽
            </Box>
        </Box>
    )
})
