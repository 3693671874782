import React from 'react'
import { Box, LoadingOverlay, Image, clsx, Text, ActionIcon, Button } from '@mantine/core'
import style from './car-card.style.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useImmer } from 'use-immer'
import { store } from '../../carstock.store'
import { observer } from 'mobx-react-lite'
import { core } from '../../carstock.core'
import { IconBrandBooking, IconHeart } from '@tabler/icons-react'
import { CarstockCarModel } from '../../models'
import { CarDeduplication } from '../car-deduplication'
import { useDisclosure } from '@mantine/hooks'
import { modals } from '@mantine/modals'
import { sleep } from '@/shared'
import { appStore } from '@/app/app.store'
import { Link, useNavigate } from 'react-router-dom'

interface CarCardProps {
    car: CarstockCarModel
}

export const CarCard: React.FC<CarCardProps> = observer(({ car, ...rest }) => {
    const [state, setState] = useImmer({ loader: true, error: false, booking: false })
    const [opened, { close, open }] = useDisclosure(false)
    const navigate = useNavigate()

    const is_booking_role = appStore.is_booking_role

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (
            (e.target as any).dataset['favorite'] ||
            (e.target as any).closest('[data-booking]') ||
            (e.target as any).closest('[data-carstock-car-deduplictation]')
        ) {
            return
        }

        // if (e.button === 0 || e.button === 1) {
        // store.params.onClickCar?.(car)
        // navigate('/carstock/car/' + car.data.id)
        // }
    }

    const handleClickFavorite = (e: React.MouseEvent<HTMLButtonElement>) => {
        car.favoriteToggle()
    }

    const handleClickBooking = async (e: React.MouseEvent<HTMLButtonElement>) => {
        if (state.booking) return

        setState((draft) => {
            draft.booking = true
        })

        await sleep(300)

        modals.openConfirmModal({
            modalId: 'carstock-booking',
            title: 'Автомобиль забронирован',
            children: (
                <div>
                    {car.booking_label} забронирован. Если этого не произошло, пожалуйста обратитесь в тех. поддержку
                </div>
            ),
            labels: { confirm: 'Понятно', cancel: 'Отмена' },
            onCancel: () => {
                modals.close('carstock-save-confirm')
            },
            groupProps: {
                style: {
                    justifyContent: 'center',
                },
            },
            // onConfirm: () => {},
            closeOnConfirm: true,
            cancelProps: {
                display: 'none',
            },
        })

        setState((draft) => {
            draft.booking = false
        })
    }

    return (
        <Box {...rest}>
            <div
                className={clsx(
                    style.root,
                    { [style.searching]: store.state.searching },
                    { [style.booking_loader]: store.state.booking }
                )}
            >
                <div className={style.icons}>
                    {/* <ActionIcon onClick={handleClickBooking} data-favorite>
                        <IconBrandBooking />
                    </ActionIcon> */}

                    {car.isFavoriteAvailable() && (
                        <ActionIcon
                            onClick={handleClickFavorite}
                            className={clsx(
                                style.favorite,
                                { [style.favorite_active]: car.data.is_favorite },
                                { [style.favorite_loading]: car.state.loading }
                            )}
                            data-favorite
                        >
                            <IconHeart />
                        </ActionIcon>
                    )}
                </div>

                <Box className={style.image}>
                    <LoadingOverlay visible={state.loader} />
                    <div>
                        {!state.error ? (
                            <Box
                                component={LazyLoadImage}
                                height={'auto'}
                                src={car.getImage() || '/static/car-placeholder.png'}
                                draggable={false}
                                afterLoad={async () => {
                                    setState((draft) => {
                                        draft.loader = false
                                    })
                                }}
                                onError={() =>
                                    setState((draft) => {
                                        draft.loader = false
                                        draft.error = true
                                    })
                                }
                            />
                        ) : (
                            <Image draggable={false} src={'/static/car-placeholder.png'} />
                        )}
                    </div>
                </Box>
                {!!car.data.params?.length && (
                    <div className={style.list}>
                        {car.getFormatParams().map((param, i) => (
                            <div key={i} className={style.item}>
                                {car.data.params.length !== i && <span className={style.circle}></span>}
                                {param}
                            </div>
                        ))}
                    </div>
                )}
                <Link
                    className={style.title}
                    // onClick={(e) => {
                    //     e.preventDefault()
                    // }}
                    to={'/carstock/car/' + car.data.id}
                >
                    {car.data.brand_name} {/* &mdash; */} {car.data.model_name}
                    {!!car.deduplication && (
                        <span onMouseEnter={open} onMouseLeave={close}>
                            <CarDeduplication opened={opened} data={car.deduplication} />
                        </span>
                    )}
                </Link>
                {!!car.data.dealer && (
                    <div className={style.dealer}>
                        <span className={style.dealer_label}>Дилер</span>
                        <Text truncate className={style.dealer_value} title={car.data.dealer.name}>
                            {car.data.dealer.name}
                        </Text>
                    </div>
                )}
                <div className={style.price_block}>
                    <div className={style.price}>
                        <span className={style.price_label}>РРЦ</span>
                        <span className={style.price_value}>{car.getRrpMinFormat()}</span>
                    </div>
                </div>

                {is_booking_role && (
                    <div className={style.booking}>
                        <Button loading={state.booking} variant='light' data-booking onClick={handleClickBooking}>
                            Забронировать
                        </Button>
                    </div>
                )}
            </div>
        </Box>
    )
})
