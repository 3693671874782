import clsx from 'clsx'
import React from 'react'
import style from './index.style.scss'

interface IModalHeaderProps extends React.HTMLAttributes<HTMLDivElement> {}

const ModalHeader = React.forwardRef<HTMLDivElement, IModalHeaderProps>(({ children, className, ...props }, ref) => {
    const classes = clsx(style.root, className)

    return (
        <div className={classes} {...props} ref={ref}>
            {children}
        </div>
    )
})

export default ModalHeader
