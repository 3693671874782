import { showNotification, NotificationProps } from '@mantine/notifications'
import { IconExclamationCircle } from '@tabler/icons-react'
import React from 'react'

export const showToast = (props?: NotificationProps) => {
    const values: NotificationProps = Object.assign(
        {
            sx: { maxWidth: 340, right: -100 },
            color: 'blue.6',
        },
        props,
        {
            message: props?.message || 'Успешно!',
        }
    )

    showNotification(values)
}

export const showToastError = (props?: NotificationProps) => {
    const values = Object.assign(
        {
            sx: { maxWidth: 340, right: -100 },
            color: 'red.6',
            icon: <IconExclamationCircle />,
        },
        props,
        {
            message: props?.message || 'Не удалось загрузить данные, попробуйте позже!',
        }
    )

    if (props?.message !== 'Запрос отменен') {
        showNotification(values)
    }
}
