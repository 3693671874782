import { useState } from 'react'
import { Group, Box, Collapse, ThemeIcon, Text, UnstyledButton, createStyles, clsx } from '@mantine/core'
import { Icon, IconChevronRight } from '@tabler/icons-react'
import React from 'react'
import { LinkRouter } from '@/theme/@deprecated/components/ui/link'

const useStyles = createStyles((theme) => ({
    control: {
        fontWeight: 500,
        display: 'block',
        width: '100%',
        padding: `10px 32px 10px 10px`,
        color: theme.black,
        fontSize: theme.fontSizes.sm,
        borderRadius: 4,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        position: 'relative',

        '&:hover': {
            backgroundColor: theme.colors.gray[0],
            color: theme.black,
        },
    },

    active: {
        '&': {
            backgroundColor: theme.fn.lighten(theme.colors.gray[0], 0.2),
        },
    },

    link: {
        fontWeight: 500,
        display: 'block',
        textDecoration: 'none',
        padding: `10px 12px`,
        paddingLeft: 28,
        marginLeft: 28,
        fontSize: theme.fontSizes.sm,
        color: theme.colors.gray[7],
        borderLeft: `1px solid ${theme.colors.gray[3]}`,
        cursor: 'pointer',

        '&[data-active], &:hover': {
            backgroundColor: theme.colors.gray[0],
            color: theme.black,
        },
    },

    chevron: {
        transition: 'transform 200ms ease',
        position: 'absolute',
        right: 12,
    },
}))

interface DrawerLinksProps extends React.HTMLAttributes<HTMLButtonElement> {
    icon: Icon
    title: string
    initialOpened?: boolean
    path?: string
    items?: { title: string; path: string; visible?: boolean }[]
}

export const DrawerLinks: React.FC<DrawerLinksProps> = ({ icon: Icon, title, initialOpened, items, path, ...rest }) => {
    const { classes } = useStyles()
    const hasLinks = Array.isArray(items)
    const [opened, setOpened] = useState(initialOpened || false)

    const Button = (
        <UnstyledButton
            onClick={() => setOpened((o) => !o)}
            className={clsx(classes.control, { [classes.active]: initialOpened && !hasLinks })}
            {...rest}
        >
            <Group position='apart'>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ThemeIcon variant='light' size={30}>
                        <Icon size={18} />
                    </ThemeIcon>
                    <Box ml='sm' sx={{ lineHeight: '18px' }}>
                        {title}
                    </Box>
                </Box>

                {hasLinks && (
                    <IconChevronRight
                        className={classes.chevron}
                        size={14}
                        stroke={1.5}
                        style={{
                            transform: opened ? `rotate(90deg)` : 'none',
                        }}
                    />
                )}
            </Group>
        </UnstyledButton>
    )

    return (
        <>
            {!hasLinks ? <LinkRouter to={path}>{Button}</LinkRouter> : Button}
            {hasLinks ? (
                <Collapse in={opened}>
                    {items.map(
                        (link) =>
                            link.visible !== false && (
                                <LinkRouter className={classes.link} to={link.path} key={link.title}>
                                    {link.title}
                                </LinkRouter>
                            )
                    )}
                </Collapse>
            ) : null}
        </>
    )
}
