import React from 'react'
import SimpleBar from 'simplebar-react'
import { useSelect } from 'downshift'
import { observer } from 'mobx-react-lite'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import styles from './index.style.scss'

// @ts-ignore
export const MultiSelect = observer(({ data, getList, onChange, loading }) => {
    const {
        value: { list, selected },
    } = data
    const value = selected.reduce((a, b, index) => (index === 0 ? b.value : `${a}, ${b.value}`), '')
    const { isOpen, highlightedIndex, getMenuProps, getItemProps, getToggleButtonProps, getLabelProps } = useSelect({
        items: list,
        selectedItem: null,
        onIsOpenChange: () => {
            if (!isOpen) getList(data)
        },
        onSelectedItemChange: ({ selectedItem }) => {
            onChange({
                id: data.id,
                value: selectedItem,
            })
        },
        stateReducer: (state, actionAndChanges) => {
            const { changes, type } = actionAndChanges

            switch (type) {
                case useSelect.stateChangeTypes.MenuKeyDownEnter:
                case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
                case useSelect.stateChangeTypes.ItemClick:
                    return {
                        ...changes,
                        isOpen: true,
                        highlightedIndex: state.highlightedIndex,
                    }
                default:
                    return changes
            }
        },
    })
    const searchHandler = (event) =>
        setTimeout(
            () =>
                getList({
                    ...data,
                    params: { search: event.target.value },
                }),
            2
        )

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <label className={styles.label} {...getLabelProps()}>
                    {data.label}
                </label>
                <button className={styles.button} {...getToggleButtonProps()} title={value}>
                    <div>{value === '' ? 'Введите значение' : value}</div>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M6.98438 9.98438H17.0156L12 15L6.98438 9.98438Z' fill='black' fillOpacity='0.54' />
                    </svg>
                </button>
            </div>
            <div className={styles.list} {...getMenuProps()}>
                {isOpen && (
                    <>
                        <div className={styles.search}>
                            <input type='text' placeholder='Найти' onChange={searchHandler} />
                            <svg
                                width='20'
                                height='20'
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    d='M12.9167 11.6667H12.2583L12.025 11.4417C12.8417 10.4917 13.3333 9.25833 13.3333 7.91667C13.3333 4.925 10.9083 2.5 7.91667 2.5C4.925 2.5 2.5 4.925 2.5 7.91667C2.5 10.9083 4.925 13.3333 7.91667 13.3333C9.25833 13.3333 10.4917 12.8417 11.4417 12.025L11.6667 12.2583V12.9167L15.8333 17.075L17.075 15.8333L12.9167 11.6667ZM7.91667 11.6667C5.84167 11.6667 4.16667 9.99167 4.16667 7.91667C4.16667 5.84167 5.84167 4.16667 7.91667 4.16667C9.99167 4.16667 11.6667 5.84167 11.6667 7.91667C11.6667 9.99167 9.99167 11.6667 7.91667 11.6667Z'
                                    fill='#808D9A'
                                />
                            </svg>
                        </div>
                        <div className={styles.hint}>
                            Начните вводить название <br />и выберите подходящее значение из списка:
                        </div>
                        {loading ? (
                            <div className={styles.loader}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <SimpleBar autoHide={false} style={{ maxHeight: 260 }}>
                                {list.length > 0 ? (
                                    list.map((item, index) => (
                                        <div
                                            className={highlightedIndex === index ? styles.highlighted : styles.item}
                                            key={item.id}
                                            {...getItemProps({ item, index })}
                                        >
                                            <Checkbox
                                                checked={data.value.selected.some((el) => el.id === item.id)}
                                                size='small'
                                            />
                                            <div>{item.value}</div>
                                        </div>
                                    ))
                                ) : (
                                    <div className={styles.highlighted}>Ничего не найдено</div>
                                )}
                            </SimpleBar>
                        )}
                    </>
                )}
            </div>
        </div>
    )
})
