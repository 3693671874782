import React from 'react'
import clsx from 'clsx'
import style from './cell-head-empty.style.scss'

type CellHeadEmptyProps = React.HTMLAttributes<HTMLTableCellElement> & {}

export const CellHeadEmpty: React.FC<CellHeadEmptyProps> = ({ className, children, ...rest }) => {
    return (
        <th className={clsx(style.root, className, 'elisa-table-widget-cell')} {...rest}>
            {children}
        </th>
    )
}
