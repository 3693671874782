import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'
import { AnalyticsReferenceUploadImage } from '../reference-upload-image'

export type AnalyticsReferenceUpdateRequest = {
    url: string
    data: {
        id: {
            value: number
        }
        name?: {
            value: string
        }
        [property: string]: {
            id?: number
            value?: string | number | File
        }
    }[]
}

export type AnalyticsReferenceUpdateResponse = {
    success: boolean
    message: string
}

export const AnalyticsReferenceUpdate = async (req: AnalyticsReferenceUpdateRequest) => {
    try {
        const id = req.data[0].id.value
        const fileImage = req.data[0].image?.value

        if (fileImage && fileImage instanceof File) {
            try {
                await AnalyticsReferenceUploadImage({ id, image: fileImage })

                delete req.data[0].image
            } catch (err) {
                console.log(err)
            }
        }

        const response: ApiResponse<AnalyticsReferenceUpdateResponse> =
            await apiConfig.fetch.ilsa<AnalyticsReferenceUpdateResponse>({
                url: req.url,
                config: {
                    method: 'PUT',
                    data: req.data,
                },
            })

        if (!response?.data?.success) {
            throw Error('Не удалось обновить запись в таблице аналитика')
        }

        const payload = response.data

        return payload
    } catch (err: any) {
        throwAdapterError(err, 'AnalyticsReferenceUpdate')
    }
}
