import { BaseModel } from '@/shared'

export interface SessionModelProps {
    user: {
        login: string
        name: string
        email: string
        city: string
        roles: { id: string; title: string }[]
        token: string
    }
    tabs: {
        title: string
        label: string
    }[]
}

export class SessionModel extends BaseModel<SessionModelProps, SessionModel> {
    constructor(props: SessionModelProps) {
        super(props)
    }

    isRole(role: string) {
        return this.data.user.roles.find((userRole) => userRole.title === role)
    }
}
