import React from 'react'
import { Input } from '..'

export interface IUseInputProps {
    instance?: ReactFC
    value?: string
}

export const useInput = (data?: IUseInputProps) => {
    const [value, setValue] = React.useState(data?.value || '')

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value)
    }, [])

    return {
        value,
        setValue,
        Input: data?.instance || Input,
        reset: () => setValue(''),
        bind: {
            value,
            onChange,
        },
    }
}
