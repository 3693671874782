import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import Popover from '@mui/material/Popover'
import { Icon } from '@/pages/analytics/components/ui'
import { FilterPopoverMenu } from './menu'
import IconButton from '@mui/material/IconButton'
import { debounce } from '@/shared'
import CircularProgress from '@mui/material/CircularProgress'
import { service } from '@/api'
import { notifyError } from '@/theme/@deprecated/components/ui'
import { store } from '@/pages/analytics/store'
import { Input } from './input'

interface IFilterPopoverProps {
    classes?: {
        root?: string
    }
    open: boolean
    anchorEl: HTMLElement
    onClose: () => void
}

export type FilterPopoverMenuType = {
    /** api url для получения фильтров, нужен для сравнения предыдущего меню */
    url: string
    /** Текущий FilterPopoverMenuItem */
    current: FilterPopoverMenuItem
    /** Стек выбранных меню FilterPopoverMenuItem[] */
    stack: FilterPopoverMenuItem[]
    /** Стек предыдущих значений FilterPopoverMenuItem[] */
    stackItems: FilterPopoverMenuItem[]
    /** Фильтры FilterPopoverMenuItem[], которые выводятся в меню */
    items: FilterPopoverMenuItem[]
}

export type FilterPopoverMenuItem = {
    id: string
    value: string
    type: 'menu' | 'filter'
    visible: boolean
    /** Есть только у type:menu */
    url: string
    /** Есть только у type:filter */
    column: string
}

const defaultMenu = {
    url: null,
    current: null,
    stack: [],
    stackItems: [],
    items: [],
} as FilterPopoverMenuType

const modifyItemsVisible = (items: FilterPopoverMenuItem[]) => {
    return items.map((item) => {
        item.visible = true

        return item
    })
}

export const FilterPopover: React.FC<IFilterPopoverProps> = ({ classes, open, anchorEl, onClose, ...rest }) => {
    const cls = { root: clsx(style.root, classes?.root) }

    const [menu, setMenu] = React.useState<FilterPopoverMenuType>(defaultMenu)
    const [filters, setFilters] = React.useState<FilterPopoverMenuItem[]>([])
    const [loading, setLoading] = React.useState(false)

    const handleBack = async () => {
        try {
            const newStack = menu.stack.slice(0, -1)
            const newCurrent = menu.stack.splice(-1)?.[0]
            let items = filters

            if (newCurrent) {
                items = await handleGetFilter(newCurrent)()
            }

            setMenu(
                (prev) =>
                    ({
                        ...prev,
                        current: newCurrent,
                        stack: newStack,
                        items: modifyItemsVisible(items),
                    } as FilterPopoverMenuType)
            )
        } catch (err) {
            console.log(err)
            notifyError(err?.message)
        }
    }

    const handleGetFilter = (item: FilterPopoverMenuItem, params?: ObjectType) => async () => {
        try {
            setLoading(true)

            const response = (await service.analytics.filter({
                url: item.url,
                params,
                column: item.id,
            })) as FilterPopoverMenuItem[]

            setLoading(false)

            return response
        } catch (err) {
            setLoading(false)
            throw err
        }
    }

    const handleClose = () => {
        onClose()
    }

    const handleSearch = React.useCallback(
        () =>
            debounce((e) => {
                const value = e.target.value

                setLoading(true)

                setMenu((prev) => ({
                    ...prev,
                    items: prev.items.map((item) => {
                        if (item.value.includes(value)) {
                            item.visible = true
                        } else {
                            item.visible = false
                        }

                        return item
                    }),
                }))

                setTimeout(() => {
                    setLoading(false)
                }, 200)
            }, 200),
        [menu]
    )

    React.useEffect(() => {
        if (open && store.table.scheme?.api !== menu.url) {
            ;(async () => {
                try {
                    setLoading(true)

                    const response = (await service.analytics.filters({
                        url: store.table.scheme.api,
                    })) as FilterPopoverMenuItem[]

                    setFilters(response)

                    setMenu((prev) => ({
                        ...prev,
                        url: store.table.scheme?.api,
                        items: response,
                    }))

                    setTimeout(() => {
                        setLoading(false)
                    }, 600)
                } catch (err) {
                    console.log(err)
                    setLoading(false)
                }
            })()
        } else if (open) {
            setMenu((prev) => ({
                ...prev,
                items: modifyItemsVisible(prev.items),
            }))
        }
    }, [open, store.table.scheme?.api, menu.url])

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            classes={{
                paper: cls.root,
            }}
            {...rest}
        >
            <div className={style.wrapper}>
                {menu.current === null ? (
                    <>
                        <div className={style.search}>
                            <Input onChange={handleSearch()} />
                        </div>
                        <div className={style.label}>
                            Начните вводить название фильтра и выберите подходящее значение из списка:
                        </div>
                    </>
                ) : (
                    <div className={style.header}>
                        <IconButton className={style.back} onClick={handleBack}>
                            <Icon icon='angleLeft' />
                        </IconButton>
                        <div className={style.title}>{menu.current?.value}</div>
                        <IconButton className={style.close} onClick={handleClose}>
                            <Icon icon='cross' />
                        </IconButton>
                    </div>
                )}

                <FilterPopoverMenu
                    menu={menu}
                    setMenu={setMenu}
                    handleClose={handleClose}
                    handleGetFilter={handleGetFilter}
                    setLoading={setLoading}
                />

                {loading && (
                    <div className={style.loader}>
                        <CircularProgress />
                    </div>
                )}
            </div>
        </Popover>
    )
}
