import React from 'react'
import { Button, Group, TextInput } from '@mantine/core'
import { IconX } from '@tabler/icons-react'
import style from './reset.style.scss'
import { core } from '../../carstock.core'
import { observer } from 'mobx-react-lite'
import { store } from '../../carstock.store'
import { modals } from '@mantine/modals'
import { useForm } from 'react-hook-form'

interface ResetProps {}

export const Reset: React.FC<ResetProps> = observer(({ ...rest }) => {
    // const values = store.getFilterValues()
    const form = useForm<{ name: string }>()
    const register = form.register('name', {
        required: true,
        minLength: 1,
        value: store.data.default_data.name || '',
    })

    const handleClick = () => {
        core.resetFilters()
    }

    const handleClickSave = () => {
        const is_exist = !!store.data.default_data.name

        modals.openConfirmModal({
            modalId: 'carstock-save-confirm',
            title: 'Введите название шаблона',
            children: (
                <form
                    id='carstock-save-confirm-form'
                    onSubmit={form.handleSubmit((values) => {
                        modals.close('carstock-save-confirm')

                        if (is_exist) {
                            core.saveEdit(values.name)
                        } else {
                            core.saveSearch(values.name)
                        }

                        form.reset()
                    })}
                >
                    <TextInput {...register} placeholder='Введите название' />
                </form>
            ),
            labels: { confirm: is_exist ? 'Обновить' : 'Сохранить', cancel: 'Отмена' },
            onCancel: () => {
                modals.close('carstock-save-confirm')
            },
            // onConfirm: () => {},
            confirmProps: {
                type: 'submit',
                form: 'carstock-save-confirm-form',
            },
            closeOnConfirm: false,
        })
    }

    return (
        <Group spacing={10} className={style.root}>
            {store.params.save && (
                <Button
                    onClick={handleClickSave}
                    variant='outline'
                    className={style.button}
                    loading={store.state.saving}
                    disabled={store.state.searching}
                >
                    Сохранить шаблон поиска
                </Button>
            )}
            <Button
                onClick={handleClick}
                rightIcon={<IconX size={20} />}
                className={style.button}
                // disabled={!Object.keys(values).length}
                {...rest}
            >
                Сбросить все
            </Button>
        </Group>
    )
})
