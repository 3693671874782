import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'

type UsersCreateRequest = {
    data: {
        login: string
        first_name: string
        family_name: string
        email: string
        city: string
        roles: string[]
        brands?: string[]
        status: string
    }
}
type UsersCreateResponse = {
    success: boolean
}

export const UsersCreate = async (req: UsersCreateRequest) => {
    try {
        const response = await apiConfig.fetch.ilsa<UsersCreateResponse>({
            url: '/auth/users',
            config: {
                method: 'POST',
                data: JSON.stringify({
                    ...req.data,
                    roles: req.data.roles?.map?.((role) => parseInt(role)),
                    brands: req.data.brands?.map?.((brand) => parseInt(brand)),
                    is_active: parseInt(req.data.status) ? true : false,
                }),
            },
        })

        if (!response?.data?.success) {
            throw Error('Не удалось создать пользователя')
        }

        return response
    } catch (err: any) {
        throwAdapterError(err, 'UsersCreate')
    }
}
