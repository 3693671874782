import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'

export type AnalyticsReferenceDeleteRequest = {
    url: string
    data: number[]
}
export type AnalyticsReferenceDeleteResponse = {
    success: boolean
    message: string
}

export const AnalyticsReferenceDelete = async (req: AnalyticsReferenceDeleteRequest) => {
    try {
        const response: ApiResponse<AnalyticsReferenceDeleteResponse> =
            await apiConfig.fetch.ilsa<AnalyticsReferenceDeleteResponse>({
                url: req.url,
                config: {
                    method: 'DELETE',
                    data: req.data,
                },
            })

        if (!response?.data?.success) {
            throw Error('Не удалось удалить записи в таблице аналитика')
        }

        const payload = response.data

        return payload
    } catch (err: any) {
        throwAdapterError(err, 'AnalyticsReferenceDelete')
    }
}
