import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'
import { CarstockFilterCard, Links, Meta } from './search.types'
import { CarstockFavoriteAdd } from '../favorite-add'
import { CarstockFavoriteRemove } from '../favorite-remove'
import { CarstockCarModel } from '@/widget/carstock/models'
import { getDeduplicationData } from './search.lib'

type CarstockSearchRequest = {
    filters?: {
        [property: string]: number
    }
}
type CarstockSearchResponse = {
    data: CarstockFilterCard[]
    links: Links
    meta: Meta
    success: boolean
}

export const CarstockFilterSearchController = { ref: null }

export const CarstockSearch = async (req: CarstockSearchRequest) => {
    CarstockFilterSearchController.ref = new AbortController()

    try {
        const response = await apiConfig.fetch.ilsa<CarstockSearchResponse>({
            url: '/ilsa/search',
            config: {
                method: 'GET',
                params: req.filters,
                cache: true,
                signal: CarstockFilterSearchController.ref.signal,
            },
        })

        const cars = getDeduplicationData(response.data.data).map(
            (card) =>
                new CarstockCarModel({
                    id: card.id,
                    brand_id: card.edition.equipment.brand.id,
                    brand_name: card.edition.equipment.brand.name,
                    model_name: card.edition.equipment.model.name,
                    model_year: card.model_year,
                    rrp_min: card.prices[0].price,
                    specific_colors_with_hex: [
                        {
                            image: card.edition.equipment.picture,
                        },
                    ],
                    dealer: card.dealer,
                    params: card.edition.attributes.filter((attr) =>
                        [1106, 6104, 6201, 6105, 6101, 6301, 2101].includes(attr.id)
                    ) as any,
                    is_favorite: card.is_favorite,
                    service: {
                        favoriteAdd: CarstockFavoriteAdd,
                        favoriteRemove: CarstockFavoriteRemove,
                    },
                    deduplication: (card as any)?.deduplication || null,
                    imageAbsolute: true,
                })
        )

        return {
            cars,
            modifications: [],
            links: response.data.links,
            meta: response.data.meta,
        }
    } catch (err: any) {
        throwAdapterError(err, 'CarstockSearch')
    }
}
