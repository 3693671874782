import React from 'react'
import { Box, Checkbox, clsx, createStyles } from '@mantine/core'
import { styles } from '@/theme/styles'
import { observer } from 'mobx-react-lite'
import { CatalogOptionModel } from '../../models'

interface OptionCheckboxProps extends React.HTMLAttributes<HTMLDivElement> {
    item: CatalogOptionModel['data']['options'][0]
}

const useStyles = createStyles((theme) => ({
    root: {
        borderRadius: 12,
        padding: 12,
        fontSize: 14,
        lineHeight: '20px',
        cursor: 'pointer',
        transition: 'all .1s ease',
        display: 'flex',
        alignItems: 'center',
        userSelect: 'none',
        background: styles.background,
        color: styles.primary,

        ':hover': {
            background: theme.fn.darken(styles.background, 0.04),
        },

        ':active': {
            background: theme.fn.darken(styles.background, 0.08),
        },
    },

    selected: {
        background: styles.blueLight,
        color: styles.blue,

        ':hover': {
            background: theme.fn.darken(styles.blueLight, 0.04),
        },

        ':active': {
            background: theme.fn.darken(styles.blueLight, 0.08),
        },
    },
}))

export const OptionCheckbox: React.FC<OptionCheckboxProps> = observer(({ item, ...rest }) => {
    const { classes } = useStyles()

    return (
        <Box data-id={item.id} className={clsx(classes.root, { [classes.selected]: item.selected })} {...rest}>
            {item.checkboxes && (
                <Checkbox variant='option' mr={12} size='sm' checked={item.selected} onChange={() => null} />
            )}
            <Box>{item.name}</Box>
        </Box>
    )
})
